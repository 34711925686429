import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';

import SearchIcon from '@atlaskit/icon/glyph/search';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';
import Select, { ValueType } from '@atlaskit/select';

import { OrganismSelect, Target, TargetConnection, TargetSortField, useGetAllTargetsQuery } from 'apollo/graphql';
import OrganismsSelect, { OrganismSelectOptionType } from 'components/genome/organism-select';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import TargetList from 'components/antisense/target-list';
import useSearch from 'hooks/use-search';
import CustomTargetInclude from 'components/antisense/custom-target-include';
import { DrawerType } from 'components/drawers/drawer-manager';
import { openDrawer } from 'redux/drawers';

export interface SplicedSelectOptionType {
  label: string;
  value: boolean | null;
}

export interface TargetOrderBy {
  field: Exclude<keyof Target, '__typename'>;
  order: AscOrDesc;
}

function Targets(): JSX.Element {
  const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 20,
    defaultTotalCount: 0,
    defaultFilteredCount: 0,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [includeCustomTarget, setIncludeCustomTarget] = useState<boolean>(true);
  const [spliced, setSpliced] = useState<SplicedSelectOptionType>({ label: 'mRNA / pre-mRNA', value: null });
  const [organism, setOrganism] = useState<OrganismSelectOptionType>({ label: 'All', value: OrganismSelect.None });
  const [setSearch, { search, debouncedSearch }] = useSearch();

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<TargetOrderBy[]>([
    { field: 'symbol', order: 'ASC' },
    { field: 'spliced', order: 'DESC' },
  ]);

  const { loading, data, previousData } = useGetAllTargetsQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        ...(!includeCustomTarget && { custom: includeCustomTarget }),
        ...(spliced.value !== null && { spliced: spliced.value }),
        search: debouncedSearch,
        ...(organism.value && { organism: organism.value }),
      },
      orderBy: orderByList.map(orderBy => `${orderBy.field}_${orderBy.order}` as TargetSortField),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    updatePagination<TargetConnection>(data?.targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <OrganismsSelect
        organism={organism}
        setOrganism={setOrganism}
        setCurrentPage={setCurrentPage}
      />
      <RowsSelect />
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateTargetDrawer))}
      >
        <span style={{ fontWeight: 400 }}>
          Create target
        </span>
      </Button>
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
      <div style={{ flex: '0 0 172px', marginLeft: 16, zIndex: 3 }}>
        <Select
          value={spliced}
          onChange={(value: ValueType<SplicedSelectOptionType>): void => {
            if (value) setSpliced(value);
          }}
          options={[
            { label: 'mRNA / pre-mRNA', value: null },
            { label: 'mRNA', value: true },
            { label: 'pre-mRNA', value: false },
          ]}
          spacing="compact"
          placeholder="mRNA / pre-mRNA"
          aria-label="Choose an option"
        />
      </div>
      <div style={{ marginLeft: '8px' }}>
        <CustomTargetInclude
          includeCustomTarget={includeCustomTarget}
          setIncludeCustomTarget={setIncludeCustomTarget}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Targets" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Targets
          </PageHeader>

          <TargetList
            targets={data?.targets.edges || previousData?.targets.edges || []}
            isLoading={loading}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Targets;
