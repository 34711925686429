import React from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@atlaskit/drawer';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import CreateRefseqTarget from '../antisense/create-refseq-target';
// import CreateCustomTarget from '../antisense/create-custom-target';

interface Props extends DrawerProps {
  isOpen: boolean;
  totalCount?: number;
}

function CreateTargetDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ marginRight: '12px' }}>
        <Tabs id="create-target-drawer-tabs">
          <TabList>
            <Tab>RefSeq RNA</Tab>
            <Tab>Custom Sequence</Tab>
          </TabList>
          <TabPanel>
            <CreateRefseqTarget />
          </TabPanel>
          {/*<TabPanel>*/}
          {/*  <CreateCustomTarget />*/}
          {/*</TabPanel>*/}
        </Tabs>
      </div>
    </Drawer>
  );
}

export default CreateTargetDrawer;
