import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import Button, { ButtonGroup } from '@atlaskit/button';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import SearchIcon from '@atlaskit/icon/glyph/search';
import PageHeader from '@atlaskit/page-header';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import Tooltip from '@atlaskit/tooltip';

import {
  SirnaDuplex,
  SirnaDuplexConnection,
  SirnaDuplexSortField,
  useGetTargetByIdRnaIdQuery,
  useGetTargetSirnaDuplexesQuery,
} from 'apollo/graphql';
import SirnaDuplexList from 'components/antisense/sirna-duplex-list';
import { PanelType } from 'components/panels/panel-manager';
import { SidebarType } from 'components/sidebars/sidebar-manager';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import usePanel from 'hooks/use-panel';
import useSearch from 'hooks/use-search';
import useSidebar from 'hooks/use-sidebar';
import { TargetProps } from 'types/antisense';
import { DrawerType } from 'components/drawers/drawer-manager';
import { openDrawer } from 'redux/drawers';
import FlipDuplex from '../../components/antisense/flip-duplex';
import ExpandAllButton from '../../components/tables/expand-all-button';
import { InlineDialog } from './target-antisense-series';
import { RouterStateProps } from './target-overview';

export interface SirnaDuplexOrderBy {
  field: Exclude<keyof SirnaDuplex, '__typename'>;
  order: AscOrDesc;
}

function TargetSirnaSeries(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { targetIdRnaId } = useParams();
  const { state }: { state?: RouterStateProps } = useLocation();  // TODO: Add siRNA duplex config to router state.

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    // RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 100,
    defaultTotalCount: 0,
    defaultFilteredCount: 0,
    defaultPageCount: 1,
  });

  // Setup display options.
  const [flipDuplex, setFlipDuplex] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const toggleExpandAll = () => setExpandAll(prevExpandAll => !prevExpandAll);

  // Setup filters.
  const [target, setTarget] = useState<TargetProps>();  // Setup state to track the active target.
  // TODO: Setup state to track siRNA duplex config.

  const { data: targetData } = useGetTargetByIdRnaIdQuery({
    variables: {
      ...(parseInt(targetIdRnaId!) ? { id: targetIdRnaId! } : { rnaId: targetIdRnaId! }),
      spliced: true,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (targetData) {
      if (targetData.target) {
        if (parseInt(targetIdRnaId!) && targetData.target.rnaId) navigate(`/targets/${targetData.target.rnaId}/sirna`, { state });  // Redirect if target_id is provided but the target is a RefSeq RNA.
        const {
          organismId,
          id,
          rnaId,
          spliced,
          custom,
          symbol,
          geneName,
          title,
          length: targetLength,
          exonCount,
        } = targetData.target;
        // Update target state.
        setTarget({
          organismId,
          id,
          rnaId,
          spliced,
          custom,
          symbol,
          geneName,
          title,
          length: targetLength,
          exonCount,
        });
        // TODO: Update config state.
        // const seriesList = targetData.target.antisenseSeries?.map(({ length }) => length) || [];
        // if (!seriesList.includes(length)) {
        //   if (seriesList.includes(16)) {
        //     setLength(16);
        //   } else if (seriesList.length > 0) {
        //     setLength(seriesList[0]);
        //   } else {
        //     setLength(-1);
        //   }
        // }
      } else {
        navigate('/antisense/targets');  // Redirect to Targets page if target does not exist.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData]);

  const [setSeq, {
    search: seq,
    debouncedSearch: debouncedSeq,
  }] = useSearch({ updateFn: term => term.replace(/ /g, '') });
  // const [startMin, setStartMin] = useState<number>(1);
  // const [startMax, setStartMax] = useState<number>(2304198 - (length > 0 ? length - 1 : 0));  // Default to length of the longest pre-mRNA.
  // const [exonId, setExonId] = useState<string>('');
  // const [intronId, setIntronId] = useState<string>('');
  // const [region, setRegion] = useState<string>('');
  // const [atContent, setAtContent] = useState<string>('');
  // const [gcContent, setGcContent] = useState<string>('');
  // const [cpgCount, setCpgCount] = useState<string>('');
  // const [tmMin, setTmMin] = useState<number>(0);
  // const [tmMax, setTmMax] = useState<number>(100);
  // const [hairpin, setHairpin] = useState<string>('');
  // const [excludeN5, setExcludeN5] = useState<boolean>(false);
  // const [blastMatches, setBlastMatches] = useState<string>('');
  // const [blastNextBestLength, setBlastNextBestLength] = useState<string>('');

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSeq]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<SirnaDuplexOrderBy[]>([
    { field: 'lengthAs', order: 'ASC' },
    { field: 'lengthS', order: 'ASC' },
    { field: 'startAs', order: 'DESC' },
  ]);

  const { mountSidebar, unmountSidebar } = useSidebar({
    sidebarType: SidebarType.TargetSidebar,
    // urlStart: '/targets',
    id: target?.id,
    custom: target?.custom,
    rnaId: target?.rnaId || targetData?.target?.rnaId || (!parseInt(targetIdRnaId!) ? targetIdRnaId : undefined),
    spliced: true,
    symbol: target?.symbol || targetData?.target?.symbol || '',
    geneName: target?.geneName || targetData?.target?.geneName || '',
  });

  const { isPanelOpen, mountPanel, unmountPanel, togglePanel } = usePanel({
    panelType: PanelType.SirnaDuplexFilter,
    setCurrentPage,
    targetIdRnaId,
    custom: target?.custom,
    spliced: true,
    targetLength: target?.length || 2304198,
  });

  useEffect(() => {
    mountSidebar();
    mountPanel(isPanelOpen);

    return () => {
      unmountSidebar();
      unmountPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  const { loading, data, previousData, refetch } = useGetTargetSirnaDuplexesQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        ...(parseInt(targetIdRnaId!) ? { targetId: parseInt(targetIdRnaId!) } : { rnaId: targetIdRnaId! }),
        seq: debouncedSeq,
      },
      orderBy: orderByList.map(orderBy => `${orderBy.field}_${orderBy.order}` as SirnaDuplexSortField),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    updatePagination<SirnaDuplexConnection>(data?.sirnaDuplexes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const breadcrumbs = (
    <div style={{ display: 'flex' }}>
      <Breadcrumbs>
        <BreadcrumbsItem
          text="Targets"
          onClick={() => navigate('/antisense/targets')}
        />
        <BreadcrumbsItem
          text={target && target.custom ? `CUSTOM_${targetIdRnaId!.padStart(3, '0')}` : (target?.rnaId || targetIdRnaId!)}
          onClick={() => refetch()}
        />
        <BreadcrumbsItem
          text="siRNA"
          onClick={() => refetch()}
        />
      </Breadcrumbs>
      <span style={{ marginLeft: '8px' }}>
        <ExpandAllButton
          expandAll={expandAll}
          toggleExpandAll={toggleExpandAll}
          marginBottom={0}
        />
      </span>
    </div>
  );

  const actionsContent = (
    <ButtonGroup>
      {/*<div style={{ zIndex: 3 }}>*/}
      {/*  <Select*/}
      {/*    value={{ label: length > -1 ? `${length} nt` : 'All', value: length }}*/}
      {/*    onChange={(value: ValueType<SelectOptionNumberValueType>): void => {*/}
      {/*      if (value) setLength(value.value);*/}
      {/*    }}*/}
      {/*    options={[*/}
      {/*      ...(targetData?.target?.antisenseSeries! || []).map(series => ({*/}
      {/*        label: `${series!.length} nt`, value: series!.length,*/}
      {/*      })),*/}
      {/*      { label: 'All', value: -1 },*/}
      {/*    ]}*/}
      {/*    spacing="compact"*/}
      {/*    placeholder="Length"*/}
      {/*    aria-label="Choose antisense series length"*/}
      {/*    styles={{ control: base => ({ ...base, width: '80px' }) }}*/}
      {/*  />*/}
      {/*</div>*/}
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateSirnaSeriesDrawer, { target }))}
      >
        <span style={{ fontWeight: 400 }}>
          Create series
        </span>
      </Button>
    </ButtonGroup>
  );

  const options = target?.rnaId
    ? [
      {
        label: `mRNA (${targetData?.target?.rna?.mrnaLength.toLocaleString()})`,
        value: true,
        isDisabled: !targetData?.target?.rna?.targets?.map(({ spliced }) => spliced).includes(true),
      },
      {
        label: `pre-mRNA (${targetData?.target?.rna?.rnaLength.toLocaleString()})`,
        value: false,
        isDisabled: true,
      },
    ]
    : [{ label: `RNA (${targetData?.target?.length.toLocaleString()})`, value: true }];

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 260px' }}>
        <TextField
          value={seq}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.persist();
            if (e.target.value) {
              if (e.target.value.split('').every(char => ['A', 'C', 'G', 'T', 'U', ' '].includes(char.toUpperCase()))) {
                setSeq(e.target.value);
              }
            } else {
              setSeq('');
            }
          }}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
      <div style={{ flex: '0 0 164px', marginLeft: '16px', zIndex: 3 }}>
        <Select
          value={target
            ? { label: `mRNA (${targetData?.target?.rna?.mrnaLength.toLocaleString()})`, value: true }
            : { label: 'mRNA', value: true }}
          onChange={() => {}}
          options={options}
          spacing="compact"
          placeholder="mRNA / pre-mRNA"
          aria-label="Choose an option"
          key={(target && target.id) || targetIdRnaId}
        />
      </div>
      <div style={{ marginLeft: '4px' }}>
        <FlipDuplex
          flipDuplex={flipDuplex}
          setFlipDuplex={setFlipDuplex}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div style={{ marginLeft: '10px' }}>
        <Button
          appearance="subtle"
          isSelected={isPanelOpen}
          iconBefore={<FilterIcon label="Open filter" />}
          onClick={togglePanel}
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Target" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={actionsContent}
            bottomBar={barContent}
          >
            <Tooltip
              component={InlineDialog}
              content={target?.title || targetData?.target?.title || `${targetIdRnaId} ${(target?.custom || targetData?.target?.custom) ? '' : 'm'}RNA`}
            >
              <div
                style={{
                  fontSize: '1em',
                  maxWidth: '1000px',
                  whiteSpace: 'nowrap',
                  ...(isPanelOpen && { overflow: 'hidden' }),
                  textOverflow: 'ellipsis',
                }}
              >
                {target?.title || targetData?.target?.title || `${targetIdRnaId} ${(target?.custom || targetData?.target?.custom) ? '' : 'm'}RNA`}
              </div>
            </Tooltip>
          </PageHeader>

          <SirnaDuplexList
            targetId={target?.id || targetIdRnaId || "0"}
            targetLength={targetData?.target?.length}
            symbol={
              `${
                target?.organismId === 10090
                  ? "m"
                  : target?.organismId === 10116
                  ? "r"
                  : ""
              }${targetData?.target?.symbol}` || ""
            }
            sirnaDuplexes={data?.sirnaDuplexes.edges || previousData?.sirnaDuplexes.edges || []}
            flipDuplex={flipDuplex}
            isLoading={loading}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
            expandAll={expandAll}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default TargetSirnaSeries;
