import { SidebarType } from 'components/sidebars/sidebar-manager';
import { SidebarProps } from './actions';

export interface Sidebar extends SidebarProps {
  sidebarType: SidebarType | null;
}

export enum SidebarActionType {
  OPEN_SIDEBAR = 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR = 'CLOSE_SIDEBAR',
}
