import React from 'react';
import { useSelector } from 'react-redux';

import { selectPanel } from 'redux/panels';
import AntisenseSequenceFilter from './antisense-sequence-filter';
import SirnaDuplexFilter from './sirna-duplex-filter';
import OligoFilter from './oligo-filter';

export enum PanelType {
  AntisenseSequenceFilter = 'AntisenseSequenceFilter',
  SirnaDuplexFilter = 'SirnaDuplexFilter',
  OligoFilter = 'OligoFilter',
}

const panelLookup: Record<PanelType, React.FC<any>> = {
  AntisenseSequenceFilter,
  SirnaDuplexFilter,
  OligoFilter,
};

const PanelManager = () => {
  let renderedPanel: JSX.Element | null = null;

  const { panelType, isOpen, ...panelProps } = useSelector(selectPanel);
  if (panelType) {
    const PanelComponent = panelLookup[panelType];

    renderedPanel = <PanelComponent isOpen={isOpen} {...panelProps} />;
  }

  return renderedPanel;
};

export default PanelManager;
