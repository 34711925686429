import { useApolloClient } from '@apollo/client';
import { LoadingButton } from '@atlaskit/button';
import Drawer from '@atlaskit/drawer';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  GetAllTargetsDocument,
  SearchTargetsDocument,
  SearchTargetsQuery,
  useCreateSirnaSeriesMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { closeDrawer, DrawerProps } from 'redux/drawers';
import { SelectOptionStringValueType } from 'types/select-option';
import createTargetOptions from '../antisense/create-target-options';
import { TargetProps } from 'types/antisense';

interface Props extends DrawerProps {
  isOpen: boolean;
  target: TargetProps;
}

function CreateSirnaSeriesDrawer({
  isOpen,
  target: { organismId, id, rnaId, spliced, symbol, length },
}: Props): JSX.Element {
  const [target, setTarget] = useState<SelectOptionStringValueType>({
    label: `${rnaId}  [ ${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol} ] ${spliced ? '' : 'pre-'}mRNA (${length.toLocaleString()} nt)`,
    value: id,
  });
  const [config, setConfig] = useState<[number, number, number]>([23, 21, 0]);

  useEffect(() => {
    setTarget({
      label: `${rnaId}  [ ${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol} ] ${spliced ? '' : 'pre-'}mRNA (${length.toLocaleString()} nt)`,
      value: id,
    });
    setConfig([23, 21, 0]);
  }, [id, spliced, length, organismId, rnaId, symbol]);

  const client = useApolloClient();
  const dispatch = useDispatch();

  const [createSirnaSeries, { loading }] = useCreateSirnaSeriesMutation({
    onCompleted: ({ createSirnaSeries: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) setConfig([23, 21, 0]);
    },
    refetchQueries: [GetAllTargetsDocument],
  });

  const promiseOptions = (inputValue: string) => new Promise(resolve => {
    client.query<SearchTargetsQuery>({
      query: SearchTargetsDocument,
      variables: {
        filter: {
          search: inputValue,
          spliced: true,
        },
      },
      fetchPolicy: 'network-only',
    })
      .then(res => createTargetOptions(res, 'drawer'))
      .then(options => resolve(options));
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <AsyncSelect
          autoFocus
          defaultOptions
          loadOptions={promiseOptions}
          placeholder="Search for target..."
          value={target}
          onChange={(value: ValueType<SelectOptionStringValueType, false>): void => {
            if (value) setTarget(value);
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '24px',
          }}
        >

          <div
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              width: '252px',
            }}
          >
            <div style={{ width: '76px' }}>
              <Textfield
                autoComplete="off"
                placeholder="AS length"
                type="number"
                min={12}
                max={30}
                value={config[0]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (parseInt(e.target.value)) {
                    if (parseInt(e.target.value) < 13) {
                      setConfig([13, config[1], config[2]]);
                    } else if (parseInt(e.target.value) > 30) {
                      setConfig([30, config[1], config[2]]);
                    } else {
                      setConfig([parseInt(e.target.value), config[1], config[2]]);
                    }
                  } else {
                    setConfig([23, config[1], config[2]]);
                  }
                }}
                isCompact
                elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>AS</span>}
              />
            </div>
            <div style={{ width: '68px' }}>
              <Textfield
                autoComplete="off"
                placeholder="S length"
                type="number"
                min={12}
                max={30}
                value={config[1]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (parseInt(e.target.value)) {
                    if (parseInt(e.target.value) < 13) {
                      setConfig([config[0], 13, config[2]]);
                    } else if (parseInt(e.target.value) > 30) {
                      setConfig([config[0], 30, config[2]]);
                    } else {
                      setConfig([config[0], parseInt(e.target.value), config[2]]);
                    }
                  } else {
                    setConfig([config[0], 21, config[2]]);
                  }
                }}
                isCompact
                elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>S</span>}
              />
            </div>
            <div style={{ width: '100px' }}>
              <Textfield
                autoComplete="off"
                placeholder="Offset"
                type="number"
                min={-10}
                max={10}
                value={config[2]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (parseInt(e.target.value)) {
                    if (parseInt(e.target.value) < -10) {
                      setConfig([config[0], config[1], -10]);
                    } else if (parseInt(e.target.value) > 10) {
                      setConfig([config[0], config[1], 10]);
                    } else {
                      setConfig([config[0], config[1], parseInt(e.target.value)]);
                    }
                  } else {
                    setConfig([config[0], config[1], 0]);
                  }
                }}
                isCompact
                elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>offset</span>}
              />
            </div>
          </div>
          <LoadingButton
            type="submit"
            appearance="primary"
            isLoading={loading}
            onClick={() => {
              createSirnaSeries({
                variables: {
                  targetId: target.value,
                  config,
                },
              });
            }}
            isDisabled={!target.value}
          >
          <span style={{ fontWeight: 400 }}>
            Submit
          </span>
          </LoadingButton>
        </div>
      </div>
    </Drawer>
  );
}

export default CreateSirnaSeriesDrawer;
