import { CheckboxSelect } from '@atlaskit/select';
import { layers } from '@atlaskit/theme';

import { CustomerSortField, useGetAllCustomersQuery } from 'apollo/graphql';
import React, { useEffect, useState } from 'react';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props {
  setCustomers: Function;
  setCurrentPage: Function;
}

function CustomerMultiSelect({ setCustomers, setCurrentPage }: Props): JSX.Element {
  const [customerIds, setCustomerIds] = useState<SelectOptionStringValueType[]>([]);

  useEffect(() => {
    setCustomers(customerIds.map(({ value }) => value));
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIds]);

  const { data } = useGetAllCustomersQuery({
    variables: {
      orderBy: CustomerSortField.FullNameAsc,
    },
    ssr: false,
    // fetchPolicy: 'network-only',
  });

  const allCustomers = data?.customers.edges || [];

  return (
    <div
      style={{
        zIndex: layers.card(),
        marginLeft: '16px',
        flexWrap: 'nowrap',
      }}
    >
      <CheckboxSelect
        spacing="compact"
        options={allCustomers.map(({ node: { id, fullName } }) => ({ label: fullName, value: id }))}
        placeholder="All customers"
        // @ts-ignore
        value={customerIds}
        // @ts-ignore
        isMulti
        onChange={((value: any): void => {
          setCustomerIds(value ? value.map(({ label, value }: { label: string; value: string }) => ({ label: label.split(' ').map(s => s.substring(0, 1)).join(''), value })) as SelectOptionStringValueType[] : []);
        })}
        styles={{
          control: base => ({
            ...base,
            minWidth: '220px',
            maxWidth: '500px',
          }),
        }}
      />
    </div>
  )
}

export default CustomerMultiSelect;
