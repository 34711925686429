import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Page, { Grid, GridColumn } from "@atlaskit/page";
import PageHeader from "@atlaskit/page-header";
import TextField from "@atlaskit/textfield";
import SearchIcon from "@atlaskit/icon/glyph/search";
import { colors } from "@atlaskit/theme";

import {
  AmiditeConnection,
  AmiditeSortField,
  useGetAllAmiditesQuery,
} from "apollo/graphql";
import usePagination from "hooks/use-pagination";
import useSearch from "hooks/use-search";
import useWindowSize from "hooks/use-window-size";
import ScrollableContent from "components/tables/scrollable-content";
import SugarMultiSelect from "components/chemistry/sugar-multi-select";
import BaseMultiSelect from "components/chemistry/base-multi-select";
import AmiditeCard from "components/chemistry/amidite-card";

function Amidites(): JSX.Element {
  const size = useWindowSize();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    // RowsSelect,
    TablePagination,
  } = usePagination({
    // defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 12,
    defaultTotalCount: 11,
    defaultFilteredCount: 11,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const [sugarIds, setSugarIds] = useState<string[]>([]);
  const [baseIds, setBaseIds] = useState<string[]>([]);

  const { loading, data, previousData } = useGetAllAmiditesQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
        sugarIds,
        baseIds,
      },
      orderBy: ["id_ASC" as AmiditeSortField],
    },
  });

  useEffect(() => {
    updatePagination<AmiditeConnection>(data?.amidites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const barContent = (
    <div style={{ display: "flex", paddingLeft: "6px" }}>
      <div style={{ flex: "0 0 184px" }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: "6px" }} />
            </>
          }
        />
      </div>
      <SugarMultiSelect
        setSugarIds={setSugarIds}
        setCurrentPage={setCurrentPage}
      />
      <BaseMultiSelect
        setBaseIds={setBaseIds}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );

  const amidites = data
    ? data.amidites.edges
    : previousData?.amidites.edges || [];

  return (
    <Page>
      <Helmet title="Amidites" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader bottomBar={barContent}>
            <span style={{ paddingLeft: "6px" }}>Amidites</span>
          </PageHeader>
        </GridColumn>

        <ScrollableContent withBar>
          <Grid layout="fluid">
            {!loading && amidites.length === 0 ? (
              <h3 style={{ margin: "40px auto" }}>No amidites found.</h3>
            ) : (
              amidites.map(
                ({
                  node: {
                    id,
                    symbol,
                    name,
                    molecularWeight,
                    formulaWeight,
                    hasDmt,
                    casNumber,
                    cml,
                  },
                }) => (
                  <AmiditeCard
                    id={id}
                    symbol={symbol}
                    name={name}
                    molecularWeight={molecularWeight}
                    formulaWeight={formulaWeight}
                    hasDmt={hasDmt}
                    casNumber={casNumber || ""}
                    structure={cml || ""}
                    key={`${id}_${size.width}`}
                  />
                )
              )
            )}
          </Grid>
        </ScrollableContent>

        <GridColumn medium={12}>
          <span style={{ paddingLeft: "6px" }}>
            <TablePagination />
          </span>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Amidites;
