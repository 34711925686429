import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { Target, TargetEdge } from 'apollo/graphql';
import { TargetOrderBy } from 'pages/antisense/targets';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';
import AntisenseSeriesLozengeList from './antisense-series-lozenge-list';
import SirnaSeriesLozengeList from './sirna-series-lozenge-list';
import TargetListActionsPopup from './target-list-actions-popup';

interface Props {
  targets: TargetEdge[];
  isLoading: boolean;
  setFilters?: Function;
  orderByList: TargetOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function TargetList({
  targets,
  isLoading,
  setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Target>({
        fieldKey: 'rnaId',
        fieldName: 'ID',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'symbol',
        fieldName: 'Symbol',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'geneName',
        fieldName: 'Gene name',
        width: 11,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'spliced',
        fieldName: 'mRNA / pre-mRNA',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'length',
        fieldName: 'Length',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'antisenseSeriesLength',
        fieldName: 'Antisense series',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Target>({
        fieldKey: 'sirnaSeriesLength',
        fieldName: 'siRNA series',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: 'actions',
        colSpan: 1,
        content: '',
      },
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={targets.map(({
          node: { organismId, id, custom, rnaId, spliced, symbol, geneName, length, antisenseSeries, sirnaSeries },
        }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <TableLink to={`/targets/${rnaId}`} state={{ spliced }}>
                  {custom ? symbol : rnaId}
                </TableLink>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: `${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol}`,
            },
            {
              key: id,
              colSpan: 1,
              content: geneName,
            },
            {
              key: id,
              colSpan: 1,
              content: custom ? '\u2013' : `${spliced ? '' : 'pre-'}mRNA`,
            },
            {
              key: id,
              colSpan: 1,
              content: length.toLocaleString(),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <AntisenseSeriesLozengeList
                  antisenseSeries={antisenseSeries || []}
                  targetId={id}
                  rnaId={rnaId || undefined}
                  spliced={spliced}
                  custom={custom}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                spliced ? (
                  <SirnaSeriesLozengeList
                    sirnaSeries={sirnaSeries || []}
                    targetId={id}
                    rnaId={rnaId || undefined}
                    spliced={spliced}
                    custom={custom}
                  />
                ) : '\u2013'
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                  <TargetListActionsPopup
                    target={{ id, rnaId: rnaId || '', spliced, custom, symbol, length, organismId }}
                  />
                </div>
              ),
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No targets found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default TargetList;
