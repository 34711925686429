import React, { useState } from 'react';

import RowsSelect from '../components/tables/rows-select';
import TablePagination from '../components/tables/table-pagination';
import { SelectOptionNumberValueType } from '../types/select-option';

interface PaginationDetails {
  totalCount?: number | null;
  filteredCount?: number | null;
  pageCount?: number | null;
  currentPage?: number | null;
}

interface Props {
  defaultRowsOptions?: number[];
  defaultRowsPerPage?: number;
  defaultTotalCount?: number;
  defaultFilteredCount?: number;
  defaultPageCount?: number;
}

const usePagination = ({
  defaultRowsOptions = [10, 20, 50],
  defaultRowsPerPage = 20,
  defaultTotalCount = 0,
  defaultFilteredCount = 0,
  defaultPageCount = 1,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState<SelectOptionNumberValueType>({
    label: `${defaultRowsPerPage} rows`,
    value: defaultRowsPerPage,
  });
  const [totalCount, setTotalCount] = useState<number>(defaultTotalCount);
  const [filteredCount, setFilteredCount] = useState<number>(defaultFilteredCount);
  const [pageCount, setPageCount] = useState<number>(defaultPageCount);
  const [currentPage, setCurrentPage] = useState<number>(1);

  function updatePagination<T extends PaginationDetails>(queryResult?: T): void {
    setTotalCount(prevTotalCount => queryResult ? queryResult.totalCount! : prevTotalCount);
    setFilteredCount(prevFilteredCount => queryResult ? queryResult.filteredCount! : prevFilteredCount);
    setPageCount(prevPageCount => queryResult ? (queryResult.pageCount || 1) : prevPageCount);
    // setCurrentPage(prevCurrentPage => queryResult.currentPage || prevCurrentPage);  // Pagination enters infinite loop when enabled.
  }

  return {
    rowsPerPage,
    setTotalCount,
    setFilteredCount,
    setPageCount,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect: () => (
      <RowsSelect
        rowsOptions={defaultRowsOptions}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setCurrentPage={setCurrentPage}
      />
    ),
    TablePagination: () => (
      <TablePagination
        rowsPerPage={rowsPerPage}
        totalCount={totalCount}
        filteredCount={filteredCount}
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    ),
  };
};

export default usePagination;
