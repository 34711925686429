import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { ButtonGroup } from '@atlaskit/button';

import {
  Chromosome,
  ChromosomeConnection,
  ChromosomeSortField,
  OrganismSelect,
  useGetAllChromosomesQuery,
} from 'apollo/graphql';
import ChromosomeList from 'components/genome/chromosome-list';
import OrganismsSelect, { OrganismSelectOptionType } from 'components/genome/organism-select';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';

export interface ChromosomeOrderBy {
  field: Exclude<keyof Chromosome, '__typename'>;
  order: AscOrDesc;
}

function Chromosomes(): JSX.Element {
  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [25, 50, 100],
    defaultRowsPerPage: 25,
    defaultTotalCount: 70,
    defaultFilteredCount: 70,
    defaultPageCount: 3,
  });

  // Setup filters.
  const [organism, setOrganism] = useState<OrganismSelectOptionType>({ label: 'All', value: OrganismSelect.None });

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<ChromosomeOrderBy[]>([{ field: 'organism', order: 'ASC' }]);

  const { loading, data, previousData } = useGetAllChromosomesQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        organism: organism.value,
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as ChromosomeSortField),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    updatePagination<ChromosomeConnection>(data?.chromosomes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <OrganismsSelect
        organism={organism}
        setOrganism={setOrganism}
        setCurrentPage={setCurrentPage}
      />
      <RowsSelect />
    </ButtonGroup>
  );

  return (
    <Page>
      <Helmet title="Chromosomes" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent}>
            Chromosomes
          </PageHeader>

          <ChromosomeList
            chromosomes={data?.chromosomes.edges || previousData?.chromosomes.edges || []}
            isLoading={loading}
            // setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Chromosomes;
