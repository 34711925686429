import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { format, parseISO as parse } from 'date-fns';

import { Delivery } from 'apollo/graphql';

interface Props {
  orderId: string;
  deliveries: Delivery[];
}

function DeliveryLozengeList({ orderId, deliveries }: Props) {
  const navigate = useNavigate();

  return (
    <div onMouseOver={() => {}}>
      {deliveries && deliveries.length > 0 ? (
        deliveries.map(({ id, deliverAt }) => (
          <span
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              navigate(`/deliveries/${id}`, { state: { orderId } });
            }}
            key={id}
            style={{ cursor: 'pointer', marginRight: '4px' }}
          >
            {deliverAt ? (
              <Tooltip content={format(parse(deliverAt), 'dd-MMM-yyyy, h:mm aaa')} position={'top-start'}>
                {(tooltipProps) => (
                  <span {...tooltipProps}>
                  <Lozenge>
                    <div style={{ paddingBottom: '1px' }}>{id}</div>
                  </Lozenge>
                </span>
                )}
              </Tooltip>
            ) : (
              <Lozenge>
                <div style={{ paddingBottom: '1px' }}>{id}</div>
              </Lozenge>
            )}
          </span>
        ))
      ) : '\u2013'}
    </div>
  );
}

export default DeliveryLozengeList;
