import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Badge from '@atlaskit/badge';
import Lozenge from '@atlaskit/lozenge';
import { format } from 'date-fns';

import { Sku, SkuEdge } from 'apollo/graphql';
import { SkuOrderBy } from 'pages/reagent/inventory';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import SkuQuantity from './sku-quantity';

interface Props {
  skus: SkuEdge[];
  isLoading: boolean;
  setFilters?: Function;
  orderByList: SkuOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function InventoryList({
  skus,
  isLoading,
  setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Sku>({
        fieldKey: 'id',
        fieldName: 'Category',
        width: 3,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'title',
        fieldName: 'Title',
        width: 13,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'productCode',
        fieldName: 'Product code',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'unitSize',
        fieldName: 'Unit amount',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'packSize',
        fieldName: 'Pack size',
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'quantityBiop',
        fieldName: 'Stock [Biop]',
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'quantityNasbio',
        fieldName: 'Stock [NASBio]',
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'listPrice',
        fieldName: 'List price',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'discount',
        fieldName: 'Discount %',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'unitPrice',
        fieldName: 'Unit price',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'normalizedPrice',
        fieldName: 'Normalized price',
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Sku>({
        fieldKey: 'updatedAt',
        fieldName: 'Last updated',
        width: 7,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={skus.map(({
          node: {
            oligoReagentId,
            // peptideReagentId,
            id,
            title,
            productCode,
            packSize,
            unitSize,
            unitOfMeasure,
            listPrice,
            discount,
            unitPrice,
            normalizedPrice,
            quantityBiop,
            quantityNasbio,
            // createdAt,
            updatedAt,
            // createdBy,
            // updatedBy,
          },
        }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <Lozenge
                  appearance={oligoReagentId ? 'inprogress' : 'success'}
                >
                  {oligoReagentId ? 'Oligo' : 'Peptide'}
                </Lozenge>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <span>
                  {title}&nbsp;&nbsp;
                  <Badge appearance={quantityBiop + quantityNasbio < 1 ? 'important' : 'primary'}>
                    {quantityBiop + quantityNasbio}
                  </Badge>
                </span>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: productCode,
            },
            {
              key: id,
              colSpan: 1,
              content: `${unitSize} ${unitOfMeasure}`,
            },
            {
              key: id,
              colSpan: 1,
              content: packSize,
            },
            {
              key: id,
              colSpan: 1,
              content: <SkuQuantity id={id} quantity={quantityBiop} lab="biop" />,
            },
            {
              key: id,
              colSpan: 1,
              content: <SkuQuantity id={id} quantity={quantityNasbio} lab="nasbio" />,
            },
            {
              key: id,
              colSpan: 1,
              content: listPrice,
            },
            {
              key: id,
              colSpan: 1,
              content: discount,
            },
            {
              key: id,
              colSpan: 1,
              content: unitPrice,
            },
            {
              key: id,
              colSpan: 1,
              content: normalizedPrice,
            },
            {
              key: id,
              colSpan: 1,
              content: format(new Date(updatedAt), 'dd-MMM-yyyy, p'),
              // content: updatedBy ? (
              //   <span>{format(new Date(updatedAt), 'dd-MMM-yyyy, p')}&nbsp;&nbsp;by&nbsp;&nbsp;{updatedBy?.username}</span>
              // ) : (
              //   <span>{format(new Date(createdAt), 'dd-MMM-yyyy, p')}&nbsp;&nbsp;by&nbsp;&nbsp;{createdBy?.username}</span>
              // ),
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No SKUs found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default InventoryList;
