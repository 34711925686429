import React from 'react';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

import { useAuth } from 'contexts/auth';

const Me = (): JSX.Element => {
  const { currentUser } = useAuth()!;

  return (
    <Page>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            {currentUser!.email}
          </PageHeader>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default Me;
