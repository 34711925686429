import React, { useState } from 'react';
import { GridColumn } from '@atlaskit/page';
import Popup from '@atlaskit/popup';
import Button, { ButtonGroup } from '@atlaskit/button';
import { FormFooter } from '@atlaskit/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStructureFile from 'hooks/use-structure-file';
// import BuildingBlockNameInlineEditableField from '../forms/nucleotide/fields/building-block-name-inline-editable-field';
// import InlineEditableField from '../forms/nucleotide/fields/inline-editable-field';
// import SymbolInlineEditableField from '../forms/nucleotide/fields/symbol-inline-editable-field';
import EditIcon from '../logos/edit-icon';
import Card from '../tables/card';
import Structure from './structure';

interface Props {
  id: string;
  symbol: string;
  name: string;
  mass: number;
  structure: string;
}

function SugarCard({ id, symbol, name, mass, structure }: Props): JSX.Element {
  const [editDialogOpen, toggleEditDialog] = useState(false);
  const [uploadDialogOpen, toggleUploadDialog] = useState(false);

  const { cml, setCml, StructureFileInput, UploadIcon } = useStructureFile({
    toggleEditDialog,
    uploadDialogOpen,
    toggleUploadDialog,
  });

  return (
    <GridColumn medium={3}>
      <Card
        onClick={() => console.log(`${symbol} clicked`)}
        style={{ height: '314px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{name}</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '36px',
            }}
          >
            <Popup
              isOpen={editDialogOpen}
              onClose={() => toggleEditDialog(false)}
              placement="bottom-end"
              content={() => (
                <div style={{ width: '240px', padding: '16px 20px' }}>
                  {/*<BuildingBlockNameInlineEditableField*/}
                  {/*  buildingBlock='sugar'*/}
                  {/*  fieldValue={name}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (value === name) return;*/}
                  {/*  //   update({*/}
                  {/*  //     variables: { id, name: value },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateSugar: {*/}
                  {/*  //         __typename: 'SugarUpdateBuildingBlockResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         buildingBlock: {*/}
                  {/*  //           __typename: 'Sugar',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol,*/}
                  {/*  //           name: value,*/}
                  {/*  //           mass,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*  originalValue={name}*/}
                  {/*/>*/}
                  {/*<SymbolInlineEditableField*/}
                  {/*  buildingBlock='sugar'*/}
                  {/*  fieldValue={symbol}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (value === symbol) return;*/}
                  {/*  //   update({*/}
                  {/*  //     variables: { id, symbol: value },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateSugar: {*/}
                  {/*  //         __typename: 'SugarUpdateBuildingBlockResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         buildingBlock: {*/}
                  {/*  //           __typename: 'Sugar',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol: value,*/}
                  {/*  //           name,*/}
                  {/*  //           mass,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*  originalValue={symbol}*/}
                  {/*/>*/}
                  {/*<InlineEditableField*/}
                  {/*  label="Mass"*/}
                  {/*  fieldValue={mass}*/}
                  {/*  validate={value => {*/}
                  {/*    if (!value || !parseFloat(value)) return 'Mass cannot be empty.';*/}
                  {/*  }}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (parseFloat(value) === mass) return;*/}
                  {/*  //   return update({*/}
                  {/*  //     variables: { id, mass: parseFloat(value) },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateSugar: {*/}
                  {/*  //         __typename: 'SugarUpdateBuildingBlockResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         buildingBlock: {*/}
                  {/*  //           __typename: 'Sugar',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol,*/}
                  {/*  //           name,*/}
                  {/*  //           mass: parseFloat(value),*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*/>*/}
                </div>
              )}
              trigger={triggerProps => (
                <EditIcon
                  {...triggerProps}
                  editDialogOpen={editDialogOpen}
                  onClick={() => {
                    toggleUploadDialog(false);
                    toggleEditDialog(open => !open);
                  }}
                >
                  <FontAwesomeIcon icon={editDialogOpen ? ['far', 'edit'] : ['fas', 'edit']} />
                </EditIcon>
              )}
            />
            <Popup
              isOpen={uploadDialogOpen}
              onClose={() => toggleUploadDialog(false)}
              placement="bottom-end"
              content={() => (
                <div style={{ padding: '20px' }}>
                  <StructureFileInput />
                  <FormFooter>
                    <ButtonGroup>
                      <Button
                        appearance="danger"
                        // onClick={() => {
                        //   update({
                        //     variables: { id, structure: '' },
                        //     optimisticResponse: {
                        //       __typename: 'Mutation',
                        //       updateSugar: {
                        //         __typename: 'SugarUpdateBuildingBlockResponse',
                        //         success: true,
                        //         message: 'Building block successfully updated',
                        //         buildingBlock: {
                        //           __typename: 'Sugar',
                        //           id,
                        //           symbol,
                        //           name,
                        //           mass,
                        //           structure: '',
                        //         },
                        //       },
                        //     },
                        //   });
                        // }}
                        isDisabled={!structure}
                      >
                        Remove
                      </Button>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          if (cml === structure) return;
                          if (cml) {
                            // update({
                            //   variables: { id, structure: cml as string },
                            //   optimisticResponse: {
                            //     __typename: 'Mutation',
                            //     updateSugar: {
                            //       __typename: 'SugarUpdateBuildingBlockResponse',
                            //       success: true,
                            //       message: 'Building block successfully updated',
                            //       buildingBlock: {
                            //         __typename: 'Sugar',
                            //         id,
                            //         symbol,
                            //         name,
                            //         mass,
                            //         structure: cml as string,
                            //       },
                            //     },
                            //   },
                            // })
                            //   .then(() => setCml(null));
                            setCml(null);
                          }
                        }}
                        isDisabled={!cml}
                        style={{ marginLeft: '5px' }}
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  </FormFooter>
                </div>
              )}
              trigger={UploadIcon}
            />
          </div>
        </div>
        <div style={{ minHeight: '246px' }}>
          <Structure id={id} structure={structure}/>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: 500 }}>{symbol}</span>
          <span>M.W. {mass.toFixed(2)}</span>
        </div>
      </Card>
    </GridColumn>
  );
}

export default SugarCard;
