import React from 'react';
import { useMatch } from 'react-router-dom';
import { MenuGroup, Section } from '@atlaskit/menu';

import {
  ChromosomeSortField,
  // GeneSortField,
  OrganismSelect,
  RnaSortField,
  useGetAllChromosomesLazyQuery,
  // useGetAllGenesLazyQuery,
  useGetAllRnasLazyQuery,
} from 'apollo/graphql';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Genome = ({ onClose }: ContentProps): JSX.Element => {
  return (
    <MenuGroup minWidth="240px">
      <Section>
        <ButtonLink to="/genome/chromosomes" onClick={onClose}>
          Chromosomes
        </ButtonLink>
      </Section>
      <Section title="RefSeq" hasSeparator>
        {/*<ButtonLink onClick={onClose} to="/genome/genes">*/}
        {/*  Genes*/}
        {/*</ButtonLink>*/}
        <ButtonLink onClick={onClose} to="/genome/rnas">
          RNAs
        </ButtonLink>
        {/*<ButtonLink onClick={onClose} to="/genome/proteins">*/}
        {/*  Proteins*/}
        {/*</ButtonLink>*/}
      </Section>
      {/*<Section title="Genome browser" hasSeparator>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/browser">*/}
      {/*    IGV*/}
      {/*  </ButtonLink>*/}
      {/*</Section>*/}
    </MenuGroup>
  );
};

const GenomeContent = (): JSX.Element => {
  const match = useMatch('/genome/*');

  const [getAllChromosomes] = useGetAllChromosomesLazyQuery({
    variables: {
      page: 1,
      perPage: 25,
      filter: {
        organism: OrganismSelect.None,
      },
      orderBy: [ChromosomeSortField.OrganismAsc],
    },
  });

  // const [getAllGenes] = useGetAllGenesLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 20,
  //     filter: {
  //       organism: OrganismSelect.Human,
  //     },
  //     orderBy: [GeneSortField.IdAsc],
  //   },
  // });

  const [getAllRnas] = useGetAllRnasLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: {
        excludeModel: true,
        organism: OrganismSelect.Human,
        id: '',
        symbol: '',
        title: '',
        chrNo: '',
        chrStart: '',
        chrEnd: '',
        chrStrand: '',
        rnaLength: '',
        exonCount: '',
        mrnaLength: '',
        utr5Length: '',
        utr3Length: '',
      },
      orderBy: [RnaSortField.IdAsc],
    },
  });

  return (
    <span
      onMouseOver={() => {
        getAllChromosomes();
        // getAllGenes();
        getAllRnas();
      }}
    >
      <PrimaryDropdown
        content={Genome}
        text="Genome"
        isHighlighted={!!match}
      />
    </span>
  );
};

export default GenomeContent;
