import { ModalType } from 'components/modals/modal-manager';
import { ModalProps } from './actions';

export interface Modal extends ModalProps {
  modalType: ModalType | null;
}

export enum ModalActionType {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}
