import React, { useEffect, useState } from 'react';
import Pagination from '@atlaskit/pagination';

import { SelectOptionNumberValueType } from 'types/select-option';

const createPages = (pageCount: number): number[] => {
  return [...Array(pageCount + 1).keys()].slice(1);
};

interface Props {
  rowsPerPage: SelectOptionNumberValueType;
  totalCount: number;
  filteredCount: number;
  pageCount: number;
  currentPage: number;
  setCurrentPage: (n: number) => void;
}

function TablePagination({
  rowsPerPage,
  totalCount,
  filteredCount,
  pageCount,
  currentPage,
  setCurrentPage,
}: Props): JSX.Element {
  const [pages, setPages] = useState<number[]>(createPages(pageCount));
  const [page, setPage] = useState<string>('');

  useEffect(() => {
    setPages(createPages(pageCount));
  }, [pageCount]);

  const currentPageStart = (currentPage - 1) * rowsPerPage.value + (!filteredCount ? 0 : 1);
  const currentPageEnd = (currentPage * rowsPerPage.value) < totalCount ? (currentPage * rowsPerPage.value) : totalCount;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        marginTop: '8px',
      }}
    >
      <div style={{ padding: '6px 0 0' }}>
        <span style={{ color: 'gray', margin: '0 15px 0 0' }}>
          {currentPage < 1
            ? `Showing 1 \u2013 ${rowsPerPage.value < totalCount ? rowsPerPage.value : totalCount} `
            : currentPage === pageCount
              ? `Showing ${currentPageStart.toLocaleString()} \u2013 ${filteredCount.toLocaleString()} `
              : currentPage > pageCount
                ? `Showing 0 `
                : `Showing ${currentPageStart.toLocaleString()} \u2013 ${currentPageEnd.toLocaleString()} `
          }
          {`of ${filteredCount.toLocaleString()} entr${filteredCount > 1 ? 'ies' : 'y'}`}
          {totalCount ? ` (filtered from ${totalCount.toLocaleString()} total entr${totalCount > 1 ? 'ies' : 'y'})` : ''}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px',
        }}
      >
        <div style={{ marginTop: '3px' }}>
          Page
          <input
            type="number"
            placeholder={(currentPage || 1).toString()}
            value={page}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if ([27, 13, 69, 189].includes(event.keyCode)) {
                event.preventDefault();
                if (event.keyCode === 27) {
                  setPage('');
                } else if (event.keyCode === 13) {
                  setCurrentPage(parseInt(page));
                } else if (event.keyCode === 189) {  // Disable negative input
                  setCurrentPage(parseInt(page));
                }
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (parseInt(event.target.value) > pageCount) {
                setPage(pageCount.toString());
              } else if (parseInt(event.target.value)) {
                setPage(event.target.value);
              } else if (!event.target.value) {
                setPage('');
              } else {
                setPage(prevPage => prevPage || '');
              }
            }}
            onFocus={() => setPage(currentPage.toString())}
            onBlur={() => setPage('')}
            style={{ width: '56px', margin: '0 10px', padding: '4px 5px' }}
          />
        </div>
        <Pagination
          pages={pages}
          selectedIndex={currentPage - 1}
          onChange={(e, nextPage: number): void => setCurrentPage(nextPage)}
        />
      </div>
    </div>
  );
}

export default TablePagination;
