import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Code } from '@atlaskit/code';
import { colors } from '@atlaskit/theme';

import { GetCouplingByCompositionDocument, GetCouplingByCompositionQuery } from 'apollo/graphql';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props {
  pos: number;
  bbId: '1' | '2';
  oligoSeq: SelectOptionStringValueType[];
  setBbIds: Function;
  setCpIds: Function;
}

function BackboneToggler({ pos, bbId, oligoSeq, setBbIds, setCpIds }: Props) {
  const client = useApolloClient();

  const [backbone, setBackbone] = useState<'1' | '2'>('1');
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setBackbone(bbId);
  }, [bbId]);

  return (
    <div
      style={{
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
      }}
    >
      <Code
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          setBackbone(prevBackbone => {
            const newBackbone = prevBackbone === '1' ? '2' : '1';

            if (oligoSeq[pos].value) {
              client.query<GetCouplingByCompositionQuery>({
                query: GetCouplingByCompositionDocument,
                variables: {
                  filter: {
                    sugarId: oligoSeq[pos].value.split('_')[0],
                    baseId: oligoSeq[pos].value.split('_')[1],
                    backboneId: newBackbone,
                  },
                },
              })
                .then(({ data: { couplingByComposition } }) => {
                  if (couplingByComposition) {
                    setCpIds((prevCpIds: string[]) => prevCpIds.map((cpId, i) => {
                      if (i === pos) {
                        return couplingByComposition.id;
                      } else {
                        return cpId;
                      }
                    }));
                  }
                });
            }

            return newBackbone;
          });
          setBbIds((prevBbIds: ('1' | '2')[]) => prevBbIds.map((bbId, i) => {
            if (pos === i) {
              return bbId === '1' ? '2' : '1';
            } else {
              return bbId;
            }
          }));
        }}
        style={{
          ...(backbone === '1' ? { backgroundColor: isHover ? '#EBECF0' : '' } : { backgroundColor: isHover ? colors.Y100 : colors.Y75 }),
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            width: '14px',
            textAlign: 'center',
            fontWeight: 'bolder',
          }}
        >
          {backbone === '1' ? 'p' : '\u2217'}
        </div>
      </Code>
    </div>
  );
}

export default BackboneToggler;
