import { ButtonGroup } from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';

import { Sample, SampleConnection, SampleSortField, SampleStatusSelect, useGetAllSamplesQuery } from 'apollo/graphql';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ProductionList from '../../components/production/production-list';
// import { DrawerType } from 'components/drawers/drawer-manager';
// import { openDrawer } from 'redux/drawers';

export interface SampleOrderBy {
  field: Exclude<keyof Sample, '__typename'>;
  order: AscOrDesc;
}

function Production(): JSX.Element {
  // const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [25, 50, 100],
    defaultRowsPerPage: 50,
    defaultTotalCount: 73,
    defaultFilteredCount: 73,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<SampleOrderBy[]>([{ field: 'id', order: 'ASC' }]);

  const { loading, data, previousData } = useGetAllSamplesQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
        status: SampleStatusSelect.Active,
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as SampleSortField),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    updatePagination<SampleConnection>(data?.samples);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <RowsSelect/>
      {/*<Button*/}
      {/*  appearance="primary"*/}
      {/*  onClick={() => dispatch(openDrawer(DrawerType.CreateSampleDrawer))}*/}
      {/*>*/}
      {/*  <span style={{ fontWeight: 400 }}>*/}
      {/*    Create sample*/}
      {/*  </span>*/}
      {/*</Button>*/}
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }}/>
            </>
          }
          placeholder="Search"
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Production"/>

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Production
          </PageHeader>

          <ProductionList
            samples={data?.samples.edges || previousData?.samples.edges || []}
            isLoading={loading}
            // setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination/>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Production;
