import { combineReducers, createStore } from 'redux';

import alertsReducer, { Alert } from './alerts';
import drawerReducer, { Drawer } from './drawers';
import panelReducer, { Panel } from './panels';
import sidebarReducer, { Sidebar } from './sidebars';
import modalReducer, { Modal } from './modals';

export interface RootState {
  alerts: Alert[];
  drawer: Drawer;
  sidebar: Sidebar;
  panel: Panel;
  modal: Modal;
}

const rootReducer = combineReducers<RootState>({
  alerts: alertsReducer,
  drawer: drawerReducer,
  sidebar: sidebarReducer,
  panel: panelReducer,
  modal: modalReducer,
});

const store = createStore(rootReducer);

export default store;
