import React from 'react';
import { useDispatch } from 'react-redux';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Toggle from '@atlaskit/toggle';
import { format, formatDistance } from 'date-fns';

import {
  GetAllUsersDocument,
  User,
  UserEdge,
  useUpdateUserAdminMutation,
  useUpdateUserBlockedMutation,
} from 'apollo/graphql';
import { UserOrderBy } from 'pages/admin/users';
import { useAuth } from 'contexts/auth';
import { setAlert } from 'redux/alerts';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';

interface Props {
  users: UserEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: UserOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function UserList({
  users,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { currentUser } = useAuth()!;

  const head = {
    cells: [
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'id',
        colSpan: 1,
        content: 'ID',
      },
      // createTableHead<User>({
      //   fieldKey: 'lastName',
      //   fieldName: 'Full name',
      //   width: 3,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<User>({
        fieldKey: 'username',
        fieldName: 'Username',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<User>({
        fieldKey: 'email',
        fieldName: 'Email',
        width: 3,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<User>({
      //   fieldKey: 'isAdmin',
      //   fieldName: 'Admin',
      //   width: 2,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: 'isAdmin',
        colSpan: 1,
        content: 'Admin',
      },
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: 'isBlocked',
        colSpan: 1,
        content: 'Active',
      },
      createTableHead<User>({
        fieldKey: 'loginsCount',
        fieldName: 'Logins count',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // {
      //   isSortable: false,
      //   width: 2,
      //   shouldTruncate: false,
      //   key: 'lastIp',
      //   colSpan: 1,
      //   content: 'Last login IP',
      // },
      {
        isSortable: false,
        width: 4,
        shouldTruncate: false,
        key: 'lastLogin',
        colSpan: 1,
        content: 'Last login',
      },
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: 'createdAt',
        colSpan: 1,
        content: 'Joined',
      },
      // {
      //   isSortable: false,
      //   width: 2,
      //   shouldTruncate: false,
      //   key: 'updatedAt',
      //   colSpan: 1,
      //   content: 'Updated at',
      // },
    ],
  };

  const [updateUserAdmin] = useUpdateUserAdminMutation({
    onError: ({ message }) => {
      dispatch(setAlert({
        title: message,
        icon: <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    onCompleted: ({ updateUserAdmin: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    refetchQueries: [GetAllUsersDocument],
  });

  const [updateUserBlocked] = useUpdateUserBlockedMutation({
    onError: ({ message }) => {
      dispatch(setAlert({
        title: message,
        icon: <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    onCompleted: ({ updateUserBlocked: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    refetchQueries: [GetAllUsersDocument],
  });

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={users.map(({ node: { id, username, email, emailVerified, isAdmin, isBlocked, loginsCount, lastLoginIp, lastLogin, createdAt, updatedAt } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: id,
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: firstName + ' ' + lastName,
            // },
            {
              key: id,
              colSpan: 1,
              content: username,
            },
            {
              key: id,
              colSpan: 1,
              content: <span>{email}&nbsp;&nbsp;<Lozenge appearance={emailVerified ? 'success' : 'default'}>{emailVerified ? 'verified' : 'pending'}</Lozenge></span>,
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <Toggle
                  key={id}
                  defaultChecked={isAdmin!}
                  onChange={() => {
                    updateUserAdmin({
                      variables: { id },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateUserAdmin: {
                          __typename: 'UpdateUserResponse',
                          success: true,
                          message: 'Successfully updated user',
                          user: {
                            id,
                            username,
                            email,
                            isAdmin: !isAdmin,
                          },
                        },
                      },
                    });
                  }}
                  isDisabled={currentUser?.id === id}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <Toggle
                  key={id}
                  defaultChecked={!isBlocked!}
                  onChange={() => {
                    updateUserBlocked({
                      variables: { id },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        updateUserBlocked: {
                          __typename: 'UpdateUserResponse',
                          success: true,
                          message: 'Successfully updated user',
                          user: {
                            id,
                            username,
                            email,
                            isBlocked: !isBlocked!,
                          },
                        },
                      },
                    });
                  }}
                  isDisabled={currentUser?.id === id}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: loginsCount || '\u2212',
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: lastIp,
            // },
            {
              key: id,
              colSpan: 1,
              content: lastLogin ? (
                <span>
                  {formatDistance(new Date(lastLogin), new Date(), { addSuffix: true })}&nbsp;&nbsp;&nbsp;<Lozenge appearance={'inprogress'} maxWidth={240}>{lastLoginIp}</Lozenge>
                </span>
              ) : '\u2212',
            },
            {
              key: id,
              colSpan: 1,
              content: format(new Date(createdAt), 'dd-MMM-yyyy'),
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: format(new Date(updatedAt), 'dd-MMM-yyyy, p'),
            // },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No users found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default UserList;
