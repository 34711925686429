import React from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

interface Props {
  autoFocus?: boolean;
}

function EmailField({ autoFocus = false }: Props): JSX.Element {
  return (
    <Field
      name="email"
      label="Email"
      defaultValue=""
      validate={value => {
        if (!value) {
          return 'Email is required.';
        } else if (!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          return 'Invalid email.';
        } else if (value.length > 30) {
          return 'Email should not exceed 30 characters.';
        }
      }}
    >
      {({ fieldProps, error }) => (
        <>
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder="Enter email"
            autoFocus={autoFocus}
          />
          {!error && <HelperMessage>&nbsp;</HelperMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
}

export default EmailField;
