import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit as farEdit } from '@fortawesome/free-regular-svg-icons';
import {
  // faAtom,
  // faDna,
  faEdit as fasEdit,
  faMinusSquare,
  faPlusSquare,
  faSort,
  faSortDown,
  faSortUp,
  // faStethoscope,
  // faSyringe,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  // faAtom,
  // faDna,
  faMinusSquare,
  faPlusSquare,
  farEdit,
  fasEdit,
  faSort,
  faSortDown,
  faSortUp,
  // faStethoscope,
  // faSyringe,
);
