import React from 'react';
import { useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { colors, fontSizeSmall } from '@atlaskit/theme';

import { useGetProductQuery } from 'apollo/graphql';
import useOrders from 'hooks/use-orders';

function ProductOverview() {
  useOrders();
  const { productId } = useParams();

  const { data } = useGetProductQuery({
    variables: {
      id: productId!,
    },
  });

  return (
    <Page>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            {data?.product?.name || productId}
          </PageHeader>
        </GridColumn>

        <GridColumn medium={12}>
          <div
            style={{
              fontSize: fontSizeSmall(),
              color: colors.subtleHeading(),
              fontWeight: 500,
            }}
          >
            Name
          </div>
          <div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{data?.product?.name}</div>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default ProductOverview;
