import { SidebarType } from 'components/sidebars/sidebar-manager';
import { Sidebar, SidebarActionType } from './types';

interface OpenSidebarAction {
  type: SidebarActionType.OPEN_SIDEBAR;
  payload: Omit<Sidebar, 'isOpen'>;
}

export interface SidebarProps {}

export const openSidebar = (sidebarType: SidebarType, sidebarProps: SidebarProps = {}): OpenSidebarAction => ({
  type: SidebarActionType.OPEN_SIDEBAR,
  payload: { sidebarType, ...sidebarProps },
});

interface CloseSidebarAction {
  type: SidebarActionType.CLOSE_SIDEBAR;
}

export const closeSidebar = (): CloseSidebarAction => ({
  type: SidebarActionType.CLOSE_SIDEBAR,
});

export type SidebarAction = OpenSidebarAction | CloseSidebarAction;
