import Lozenge from '@atlaskit/lozenge';
import React from 'react';
// import Lozenge from '@atlaskit/lozenge';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { format, parseISO as parse } from 'date-fns';

import { Delivery, DeliveryEdge } from 'apollo/graphql';
import { DeliveryOrderBy } from 'pages/order/deliveries';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';

interface Props {
  deliveries: DeliveryEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: DeliveryOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function DeliveryList({
  deliveries,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Delivery>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Delivery>({
        fieldKey: 'deliverAt',
        fieldName: 'Deliver at',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Delivery>({
        fieldKey: 'mode',
        fieldName: 'Mode',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={deliveries.map(({ node: { id, deliverAt, mode } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: <TableLink to={`/deliveries/${id}`}>{id}</TableLink>,
            },
            {
              key: id,
              colSpan: 1,
              content: format(parse(deliverAt), 'dd-MMM-yyyy, h:mm aaa'),
            },
            {
              key: id,
              colSpan: 1,
              content: mode
                ? (
                  <Lozenge
                    isBold
                    appearance={mode === 'grab' ? 'success' : mode === 'in-person' ? 'inprogress' : mode === 'digital' ? 'new' : 'default'}
                  >
                    {mode}
                  </Lozenge>
                ) : '\u2013',
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No deliveries found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default DeliveryList;
