import React, { useEffect, useState } from 'react';
import { GridColumn } from '@atlaskit/page';
import { PanelStateless } from '@atlaskit/panel';
import { codeFontFamily, colors } from '@atlaskit/theme';
import styled from 'styled-components';

const SirnaDuplexInfo = styled.div`
  & > div {
    margin: -2px 0 0 0;
  }

  & > div > div {
    //padding-top: 0;
    //padding-bottom: 0;
    margin: 0 0 0 0;
  }
`;

interface Props {
  isOpen?: boolean;
  id: string;
  seqTop: string;
  basePairing: string;
  seqBtm: string;
}

const ExpandableSirnaDuplexInfo: React.FC<Props> = ({
  isOpen = false,
  id,
  seqTop,
  basePairing,
  seqBtm,
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isOpen);

  useEffect(() => {
    if (isOpen && !isExpanded) setIsExpanded(true);
    if (!isOpen) setIsExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setIsExpanded(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // const [isExpanded, setIsExpanded] = useState(false);
  //
  // useEffect(() => {
  //   setIsExpanded(false);
  // }, [id]);

  return (
    <GridColumn>
      <SirnaDuplexInfo>
        <PanelStateless
          header={(
            <span
              style={{
                fontFamily: codeFontFamily(),
                fontSize: '15px',
                // lineHeight: '0.8rem',
                whiteSpace: 'nowrap',
              }}
            >
              {seqTop}
            </span>
          )}
          isExpanded={isExpanded}
          onChange={() => setIsExpanded(isExpanded => !isExpanded)}
        >
          <div
            style={{
              whiteSpace: 'normal',
              color: colors.N300,
            }}
          >
            <span
              style={{
                fontFamily: codeFontFamily(),
                fontSize: '15px',
                lineHeight: '0.8rem',
              }}
            >
              <div
                style={{
                  fontWeight: 100,
                  transform: 'scale(1, .7)',
                  lineHeight: '0.8rem',
                }}
              >
                {basePairing}
              </div>
              <div style={{ lineHeight: '0.8rem' }}>
                {seqBtm}
              </div>
            </span>
          </div>
        </PanelStateless>
      </SirnaDuplexInfo>
    </GridColumn>
  );
};

export default ExpandableSirnaDuplexInfo;
