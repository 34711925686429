import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button, { ButtonGroup } from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

import { Product, ProductConnection, ProductSortField, useGetAllProductsQuery } from 'apollo/graphql';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import { DrawerType } from 'components/drawers/drawer-manager';
import { openDrawer } from 'redux/drawers';
import ProductList from 'components/order/product-list';
import useOrders from 'hooks/use-orders';

export interface ProductOrderBy {
  field: Exclude<keyof Product, '__typename'>;
  order: AscOrDesc;
}

function Products(): JSX.Element {
  useOrders();
  const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [25, 50, 100],
    defaultRowsPerPage: 25,
    defaultTotalCount: 198,
    defaultFilteredCount: 198,
    defaultPageCount: 8,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<ProductOrderBy[]>([{ field: 'name', order: 'ASC' }]);

  const { loading, data, previousData } = useGetAllProductsQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as ProductSortField),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    updatePagination<ProductConnection>(data?.products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <RowsSelect />
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateProductDrawer))}
      >
        <span style={{ fontWeight: 400 }}>
          Create product
        </span>
      </Button>
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
          placeholder="Search"
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Products" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Products
          </PageHeader>

          <ProductList
            products={data?.products.edges || previousData?.products.edges || []}
            isLoading={loading}
            // setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Products;
