import React, { useEffect, useState } from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import { layers } from '@atlaskit/theme';

import { useGetAllBasesQuery } from 'apollo/graphql';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props {
  setBaseIds: Function;
  setCurrentPage: Function;
}

function BaseMultiSelect({ setBaseIds, setCurrentPage }: Props): JSX.Element {
  const [bases, setBases] = useState<SelectOptionStringValueType[]>([]);

  useEffect(() => {
    setBaseIds(bases.map(({ value }) => value));
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bases]);

  const { data } = useGetAllBasesQuery({
    ssr: false,
    fetchPolicy: 'network-only',
  });

  const allBases = data?.bases.edges || [];

  return (
    <div
      style={{
        zIndex: layers.card(),
        marginLeft: '16px',
        flexWrap: 'nowrap',
      }}
    >
      <CheckboxSelect
        spacing="compact"
        options={allBases.map(({ node: { id, name } }) => ({ label: name === 'Empty' ? 'None' : name, value: id }))}
        placeholder="All bases"
        // @ts-ignore
        value={bases}
        // @ts-ignore
        isMulti
        onChange={((value: any): void => {
          setBases(value ? value as SelectOptionStringValueType[] : []);
        })}
        styles={{
          control: base => ({
            ...base,
            minWidth: '200px',
            maxWidth: '500px',
          }),
        }}
      />
    </div>
  );
}

export default BaseMultiSelect;
