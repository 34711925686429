import { colors } from '@atlaskit/theme';
import styled from '@emotion/styled';

const UploadIcon = styled.span`
  color: ${(p: { uploadDialogOpen: boolean }) => p.uploadDialogOpen ? colors.G500 : colors.subtleHeading};
  width: 12.25px;

  &:hover {
    color: ${colors.G400};
  }

  &:active {
    color: ${colors.G500};
  }
`;

export default UploadIcon;
