import React from 'react';
import { useApolloClient } from '@apollo/client';
import { ErrorMessage, Field } from '@atlaskit/form';
// import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { GetAllProductsDocument, GetAllProductsQuery } from 'apollo/graphql';

interface Props {
  autoFocus?: boolean;
}

function ProductNameField({ autoFocus = false }: Props): JSX.Element {
  const client = useApolloClient();

  return (
    <Field
      name="name"
      label="Name"
      defaultValue=""
      isRequired
      validate={async value => {
        if (!value) {
          return 'Name is required.';
        } else {
          const { data } = await client.query<GetAllProductsQuery>({
            query: GetAllProductsDocument,
            variables: {
              filter: {
                name: value,
              },
            },
            fetchPolicy: 'network-only',
          });
          if (data && data.products.filteredCount) {
            return `Product <${value}> already exists.`;
          }
        }
      }}
    >
      {({ fieldProps, error }) => (
        <>
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder="Enter name"
            autoFocus={autoFocus}
          />
          {/*{!error && <HelperMessage>&nbsp;</HelperMessage>}*/}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
}

export default ProductNameField;
