import { RootState } from '../store';
import { DrawerAction } from './actions';
import { Drawer, DrawerActionType } from './types';

export const selectDrawer = (rootState: RootState) => rootState.drawer;

const drawerReducer = (
  state: Drawer = { drawerType: null, isOpen: false },
  action: DrawerAction,
) => {
  switch (action.type) {
    case DrawerActionType.OPEN_DRAWER:
      return { ...action.payload, isOpen: true };
    case DrawerActionType.CLOSE_DRAWER:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default drawerReducer;
