import { useApolloClient } from '@apollo/client';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@atlaskit/button';
import Form, { ErrorMessage, Field, FormFooter, HelperMessage } from '@atlaskit/form';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Drawer from '@atlaskit/drawer';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import {
  CreateCustomerInput,
  GetAllCompaniesDocument,
  GetAllCompaniesQuery,
  GetAllCustomersDocument,
  useCreateCustomerMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { SelectOptionStringValueType } from 'types/select-option';
import NameField from '../forms/order/name-field';

interface Props extends DrawerProps {
  isOpen: boolean;
  totalCount?: number;
}

function CreateCustomerDrawer({ isOpen }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [companyId, setCompanyId] = useState('');

  const [count, setCount] = useState<number>(0);
  const reset = () => {
    setCount(prevCount => prevCount + 1);
    setCompanyId('');
  };

  const [createCustomer, { loading }] = useCreateCustomerMutation({
    onCompleted: ({ createCustomer: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllCustomersDocument],
  });

  const promiseOptions = (inputValue: string) => client.query<GetAllCompaniesQuery>({
    query: GetAllCompaniesDocument,
    variables: {
      filter: {
        search: inputValue,
      },
      orderBy: ['id_ASC'],
    },
    fetchPolicy: 'network-only',
  })
    .then(({ data }) => {
      const companies = data?.companies.edges || [];

      return companies.map(({ node: { id, name } }) => ({
        label: name,
        value: id,
      }));
    });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        setCompanyId('');
        dispatch(closeDrawer());
      }}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <Form
          onSubmit={({ companyId, firstName, lastName }: CreateCustomerInput) => {
            createCustomer({
              variables: {
                companyId,
                firstName,
                lastName,
              },
            });
          }}
          key={count}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div style={{ marginLeft: '2px' }}>
                <Field
                  name="companyId"
                  label="Company"
                  defaultValue={companyId}
                  validate={value => {
                    if (!value) {
                      return 'Company is required.';
                    }
                  }}
                >
                  {({ error }) => (
                    <>
                      <AsyncSelect
                        autoFocus
                        // cacheOptions
                        defaultOptions
                        loadOptions={promiseOptions}
                        placeholder="Select company"
                        onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                          if (value) {
                            setCompanyId(value.value);
                          }
                        }}
                        key={count}
                        validationState={error ? 'error' : 'default'}
                      />
                      {!error && <HelperMessage>&nbsp;</HelperMessage>}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
                <NameField fieldName="firstName" fieldLabel="First name" />
                <NameField fieldName="lastName" fieldLabel="Last name" />
              </div>
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                >
                <span style={{ fontWeight: 400 }}>
                  Submit
                </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateCustomerDrawer;
