import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page, { Grid, GridColumn } from "@atlaskit/page";
import { Helmet } from "react-helmet-async";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import Button, { ButtonGroup } from "@atlaskit/button";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import SearchIcon from "@atlaskit/icon/glyph/search";
import PageHeader from "@atlaskit/page-header";
import Select, { ValueType } from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import { colors } from "@atlaskit/theme";
import Tooltip, { TooltipPrimitive } from "@atlaskit/tooltip";
import styled from "@emotion/styled";

import {
  AntisenseSequence,
  AntisenseSequenceConnection,
  AntisenseSequenceSortField,
  useGetTargetAntisenseSequencesQuery,
  useGetTargetByIdRnaIdQuery,
} from "apollo/graphql";
import AntisenseSequenceList from "components/antisense/antisense-sequence-list";
import { AscOrDesc } from "components/tables/create-table-head";
import usePagination from "hooks/use-pagination";
import useSearch from "hooks/use-search";
import {
  SelectOptionBooleanValueType,
  SelectOptionNumberValueType,
} from "types/select-option";
import { PanelType } from "components/panels/panel-manager";
import usePanel from "hooks/use-panel";
import { TargetProps } from "types/antisense";
import { DrawerType } from "components/drawers/drawer-manager";
import { openDrawer } from "redux/drawers";
import { SidebarType } from "components/sidebars/sidebar-manager";
import useSidebar from "hooks/use-sidebar";
import TargetAntisenseCartItemsCounter from "components/antisense/target-antisense-cart-items-counter";
import { RouterStateProps } from "./target-overview";

export const InlineDialog = styled(TooltipPrimitive)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: content-box;
  padding: 8px 12px;
`;

export interface AntisenseSequenceOrderBy {
  field: Exclude<keyof AntisenseSequence, "__typename">;
  order: AscOrDesc;
}

function TargetAntisenseSeries(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { targetIdRnaId } = useParams();
  const { state }: { state?: RouterStateProps } = useLocation();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    // RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 100,
    defaultTotalCount: 0,
    defaultFilteredCount: 0,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [target, setTarget] = useState<TargetProps>(); // Setup state to track the active target.
  const [spliced, setSpliced] = useState<SelectOptionBooleanValueType>({
    label: `${!state || state.spliced ? "" : "pre-"}mRNA`,
    value: !state ? true : state.spliced === undefined ? true : state.spliced, // Extract spliced status from router state if available, default to true.
  });
  useEffect(() => {
    // Update spliced status when spliced prop from router state changes, i.e. from TargetSwitcher.
    if (state?.spliced !== undefined) {
      setSpliced({
        label: `${state.spliced ? "" : "pre-"}mRNA`,
        value: state.spliced,
      });
    }
  }, [state]);
  const [length, setLength] = useState<number>(state?.length || 16); // Extract series length from router state if available, default to 16.

  const { data: targetData } = useGetTargetByIdRnaIdQuery({
    variables: {
      ...(parseInt(targetIdRnaId!)
        ? { id: targetIdRnaId! }
        : { rnaId: targetIdRnaId! }),
      spliced: spliced.value,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (targetData) {
      if (targetData.target) {
        if (parseInt(targetIdRnaId!) && targetData.target.rnaId)
          navigate(`/targets/${targetData.target.rnaId}/antisense`, { state }); // Redirect if target_id is provided but the target is a RefSeq RNA.
        const {
          organismId,
          id,
          rnaId,
          spliced,
          custom,
          symbol,
          geneName,
          title,
          length: targetLength,
          exonCount,
        } = targetData.target;
        // Update target state.
        setTarget({
          organismId,
          id,
          rnaId,
          spliced,
          custom,
          symbol,
          geneName,
          title,
          length: targetLength,
          exonCount,
        });
        // Update spliced state.
        setSpliced({
          label: `${targetData.target.spliced ? "" : "pre-"}mRNA${
            " (" + targetData.target.length.toLocaleString() + ")"
          }`,
          value: targetData.target.spliced,
        });
        // Update length state.
        const seriesList =
          targetData.target.antisenseSeries?.map(({ length }) => length) || [];
        if (!seriesList.includes(length)) {
          if (seriesList.includes(16)) {
            setLength(16);
          } else if (seriesList.length > 0) {
            setLength(seriesList[0]);
          } else {
            setLength(-1);
          }
        }
      } else {
        navigate("/antisense/targets"); // Redirect to Targets page if target does not exist.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData]);

  const [setSeq, { search: seq, debouncedSearch: debouncedSeq }] = useSearch({
    updateFn: (term) => term.replace(/ /g, ""),
  });
  const [startMin, setStartMin] = useState<number>(1);
  const [startMax, setStartMax] = useState<number>(
    2304198 - (length > 0 ? length - 1 : 0)
  ); // Default to length of the longest pre-mRNA.
  const [exonId, setExonId] = useState<string>("");
  const [intronId, setIntronId] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [atContent, setAtContent] = useState<string>("");
  const [gcContent, setGcContent] = useState<string>("");
  const [cpgCount, setCpgCount] = useState<string>("");
  const [tmMin, setTmMin] = useState<number>(0);
  const [tmMax, setTmMax] = useState<number>(100);
  const [hairpin, setHairpin] = useState<string>("");
  const [before5, setBefore5] = useState<string>("");
  const [before10, setBefore10] = useState<string>("");
  const [before20, setBefore20] = useState<string>("");
  const [before40, setBefore40] = useState<string>("");
  const [front8, setFront8] = useState<string>("");
  const [mid8, setMid8] = useState<string>("");
  const [mid16, setMid16] = useState<string>("");
  const [back8, setBack8] = useState<string>("");
  const [after5, setAfter5] = useState<string>("");
  const [after10, setAfter10] = useState<string>("");
  const [after20, setAfter20] = useState<string>("");
  const [after40, setAfter40] = useState<string>("");
  const [excludeN5, setExcludeN5] = useState<boolean>(false);
  const [blastMatches, setBlastMatches] = useState<string>("");
  const [blastNextBestLength, setBlastNextBestLength] = useState<string>("");

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSeq]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<AntisenseSequenceOrderBy[]>([
    { field: "length", order: "ASC" },
    { field: "start", order: "DESC" },
  ]);

  const { mountSidebar, unmountSidebar } = useSidebar({
    sidebarType: SidebarType.TargetSidebar,
    // urlStart: '/targets',
    id: target?.id,
    custom: target?.custom,
    rnaId:
      target?.rnaId ||
      targetData?.target?.rnaId ||
      (!parseInt(targetIdRnaId!) ? targetIdRnaId : undefined),
    spliced: spliced.value,
    symbol: target?.symbol || targetData?.target?.symbol || "",
    geneName: target?.geneName || targetData?.target?.geneName || "",
  });

  const { isPanelOpen, mountPanel, unmountPanel, togglePanel } = usePanel({
    panelType: PanelType.AntisenseSequenceFilter,
    setCurrentPage,
    targetIdRnaId,
    custom: target?.custom,
    spliced: spliced.value,
    targetLength: target?.length || 2304198,
    length:
      length === -1
        ? targetData?.target?.antisenseSeries &&
          targetData?.target?.antisenseSeries.length > 0
          ? targetData.target.antisenseSeries[0].length
          : 0
        : length,
    startMin,
    setStartMin,
    startMax,
    setStartMax,
    exonCount: target?.exonCount || 1,
    setExonId,
    setIntronId,
    setRegion,
    setAtContent,
    setGcContent,
    setCpgCount,
    tmMin,
    setTmMin,
    tmMax,
    setTmMax,
    setHairpin,
    setBefore5,
    setBefore10,
    setBefore20,
    setBefore40,
    setFront8,
    setMid8,
    setMid16,
    setBack8,
    setAfter5,
    setAfter10,
    setAfter20,
    setAfter40,
    setExcludeN5,
    setBlastMatches,
    setBlastNextBestLength,
  });

  useEffect(() => {
    mountSidebar();
    mountPanel(isPanelOpen);

    return () => {
      unmountSidebar();
      unmountPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, length]);

  const { loading, data, previousData, refetch } =
    useGetTargetAntisenseSequencesQuery({
      variables: {
        page: currentPage,
        perPage: rowsPerPage.value,
        filter: {
          ...(parseInt(targetIdRnaId!)
            ? { targetId: parseInt(targetIdRnaId!) }
            : { rnaId: targetIdRnaId! }),
          spliced: spliced.value,
          ...(length > 0 && { length }),
          startRange: [startMin, startMax],
          ...(spliced.value && exonId && { exonId }),
          ...(!spliced.value && intronId && { intronId }),
          ...(region && { region }),
          seq: debouncedSeq,
          ...(atContent && { atContent }),
          ...(gcContent && { gcContent }),
          ...(cpgCount && { cpgCount }),
          tmRange: [tmMin, tmMax],
          ...(hairpin && { hairpin }),
          ...(before5 && { before5 }),
          ...(before10 && { before10 }),
          ...(before20 && { before20 }),
          ...(before40 && { before40 }),
          ...(front8 && { front8 }),
          ...(mid8 && { mid8 }),
          ...(mid16 && { mid16 }),
          ...(back8 && { back8 }),
          ...(after5 && { after5 }),
          ...(after10 && { after10 }),
          ...(after20 && { after20 }),
          ...(after40 && { after40 }),
          ...(excludeN5 && { excludeN5 }),
          ...(blastMatches && { blastMatches }),
          ...(blastNextBestLength && { blastNextBestLength }),
        },
        orderBy: orderByList.map(
          (orderBy) =>
            `${orderBy.field}_${orderBy.order}` as AntisenseSequenceSortField
        ),
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: false,
    });

  useEffect(() => {
    updatePagination<AntisenseSequenceConnection>(data?.antisenseSequences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const breadcrumbs = (
    <Breadcrumbs>
      <BreadcrumbsItem
        text="Targets"
        onClick={() => navigate("/antisense/targets")}
      />
      <BreadcrumbsItem
        text={
          target && target.custom
            ? `CUSTOM_${targetIdRnaId!.padStart(3, "0")}`
            : target?.rnaId || targetIdRnaId!
        }
        onClick={() => refetch()}
      />
      <BreadcrumbsItem text="antisense" onClick={() => refetch()} />
    </Breadcrumbs>
  );

  const actionsContent = (
    <ButtonGroup>
      <div style={{ zIndex: 3 }}>
        <Select
          value={{ label: length > -1 ? `${length} nt` : "All", value: length }}
          onChange={(value: ValueType<SelectOptionNumberValueType>): void => {
            if (value) setLength(value.value);
          }}
          options={[
            ...(targetData?.target?.antisenseSeries! || []).map((series) => ({
              label: `${series!.length} nt`,
              value: series!.length,
            })),
            { label: "All", value: -1 },
          ]}
          spacing="compact"
          placeholder="Length"
          aria-label="Choose antisense series length"
          styles={{ control: (base) => ({ ...base, width: "84px" }) }}
        />
      </div>
      <Button
        appearance="primary"
        onClick={() =>
          dispatch(
            openDrawer(DrawerType.CreateAntisenseSeriesDrawer, { target })
          )
        }
      >
        <span style={{ fontWeight: 400 }}>Create series</span>
      </Button>
    </ButtonGroup>
  );

  const options = target?.rnaId
    ? [
        {
          label: `mRNA (${targetData?.target?.rna?.mrnaLength.toLocaleString()})`,
          value: true,
          isDisabled: !targetData?.target?.rna?.targets
            ?.map(({ spliced }) => spliced)
            .includes(true),
        },
        {
          label: `pre-mRNA (${targetData?.target?.rna?.rnaLength.toLocaleString()})`,
          value: false,
          isDisabled: !targetData?.target?.rna?.targets
            ?.map(({ spliced }) => spliced)
            .includes(false),
        },
      ]
    : [
        {
          label: `RNA (${targetData?.target?.length.toLocaleString()})`,
          value: true,
        },
      ];

  const barContent = (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "0 0 244px" }}>
        <TextField
          value={seq}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.persist();
            if (e.target.value) {
              if (
                e.target.value
                  .split("")
                  .every((char) =>
                    ["A", "C", "G", "T", "U", " "].includes(char.toUpperCase())
                  )
              ) {
                setSeq(e.target.value);
              }
            } else {
              setSeq("");
            }
          }}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: "6px" }} />
            </>
          }
        />
      </div>
      <div style={{ flex: "0 0 172px", marginLeft: "16px", zIndex: 3 }}>
        <Select
          value={spliced}
          onChange={(value: ValueType<SelectOptionBooleanValueType>): void => {
            if (value) setSpliced(value);
          }}
          options={options}
          spacing="compact"
          placeholder="mRNA / pre-mRNA"
          aria-label="Choose an option"
          key={(target && target.id) || targetIdRnaId}
        />
      </div>
      <div style={{ marginLeft: "4px" }}>
        <Button
          appearance="subtle"
          isSelected={isPanelOpen}
          iconBefore={<FilterIcon label="Open filter" />}
          onClick={togglePanel}
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Target" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={actionsContent}
            bottomBar={barContent}
          >
            <Tooltip
              component={InlineDialog}
              content={
                target?.title ||
                targetData?.target?.title ||
                `${targetIdRnaId} ${spliced.value ? "" : "pre-"}mRNA`
              }
            >
              <div
                style={{
                  fontSize: "1em",
                  maxWidth: "1000px",
                  whiteSpace: "nowrap",
                  ...(isPanelOpen && { overflow: "hidden" }),
                  textOverflow: "ellipsis",
                }}
              >
                {target?.title ||
                  targetData?.target?.title ||
                  `${targetIdRnaId} ${spliced.value ? "" : "pre-"}mRNA`}{" "}
                <TargetAntisenseCartItemsCounter targetId={target?.id} />
              </div>
            </Tooltip>
          </PageHeader>

          <AntisenseSequenceList
            targetId={target?.id || targetIdRnaId || "0"}
            targetLength={targetData?.target?.length}
            symbol={
              `${
                target?.organismId === 10090
                  ? "m"
                  : target?.organismId === 10116
                  ? "r"
                  : ""
              }${targetData?.target?.symbol}` || ""
            }
            spliced={spliced.value}
            antisenseSequences={
              data?.antisenseSequences.edges ||
              previousData?.antisenseSequences.edges ||
              []
            }
            isLoading={loading}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default TargetAntisenseSeries;
