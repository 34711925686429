import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Checkbox } from "@atlaskit/checkbox";

import { sirnaCartItemsVar } from 'apollo/cache';

interface Props {
  targetId: string;
  targetLength: number;
  symbol: string;
  id: string;
  startAs: number;
  isInCart: boolean;
}

function SirnaCheckbox({
  targetId,
  targetLength,
  symbol,
  id,
  startAs,
  isInCart,
}: Props): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(isInCart);
  const sirnaCartItems = useReactiveVar(sirnaCartItemsVar);

  useEffect(() => {
    setIsChecked(!!sirnaCartItems.find(({ sirnaId }) => sirnaId === id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sirnaCartItems.length]);

  return (
    <div style={{ paddingLeft: "5px" }}>
      <Checkbox
        value={id}
        key={isChecked.toString()}
        isChecked={isChecked}
        onChange={(e) => {
          const { checked } = e.target;
          setIsChecked(checked);

          if (checked) {
            sirnaCartItemsVar([
              ...sirnaCartItemsVar(),
              {
                targetId,
                targetLength,
                symbol,
                sirnaId: id,
                startAs,
              },
            ]);
          } else {
            sirnaCartItemsVar(sirnaCartItemsVar().filter(({ sirnaId }) => sirnaId !== id));
          }
        }}
      />
    </div>
  );
}

export default SirnaCheckbox;
