import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Button from '@atlaskit/button';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { TriggerProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import Tooltip from '@atlaskit/tooltip';

import ButtonLink from 'layouts/navbar/button-link';
import {
  // GetRnaByIdDocument,
  IsMrnaRnaTargetFragment,
  IsMrnaRnaTargetFragmentDoc,
  useCreateTargetMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { createDummyTarget } from 'types/antisense';

interface Props {
  id: string;
  isMrnaTarget: boolean;
  isRnaTarget: boolean;
}

function CreateTargetPopup({ id, isMrnaTarget, isRnaTarget }: Props): JSX.Element {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  const dispatch = useDispatch();

  const [createTarget] = useCreateTargetMutation({
    onCompleted: ({ createTarget: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    // update: (cache, result, { variables }) => {
    //   const { rnas } = cache.readQuery<GetAllRnasQuery>({
    //     query: GetAllRnasDocument,
    //     variables: listVariables,
    //   })!;
    //   cache.writeQuery({
    //     query: GetAllRnasDocument,
    //     data: {
    //       rnas: {
    //         ...rnas,
    //         edges: rnas.edges.map(edge => ({
    //           ...edge,
    //           ...(edge.node.id === id && {
    //             node: {
    //               ...edge.node,
    //               ...(variables!.spliced ? { isMrnaTarget: true } : { isRnaTarget: true }),
    //             },
    //           }),
    //         })),
    //       },
    //     },
    //   });
    //   const rnaFragment = cache.readFragment<IsMrnaRnaTargetFragment>({
    //     id: `Rna:${id}`,
    //     fragment: IsMrnaRnaTargetFragmentDoc,
    //   });
    //   cache.writeFragment<IsMrnaRnaTargetFragment>({
    //     id: `Rna:${id}`,
    //     fragment: IsMrnaRnaTargetFragmentDoc,
    //     data: { isMrnaTarget: true },
    //   });
    // },
    // refetchQueries: [GetAllRnasDocument],
    // refetchQueries: () => [
    //   {
    //     query: GetRnaByIdDocument,
    //     variables: { id },
    //   },
    // ],
  });

  return (
    <Tooltip content={isMrnaTarget && isRnaTarget ? '' : 'Add to target'} hideTooltipOnClick>
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        content={() => (
          <MenuGroup css={{ minWidth: '200px' }}>
            <Section hasSeparator>
              {isMrnaTarget ? (
                <ButtonLink
                  to={`/targets/${id}`}
                  iconBefore={(
                    <ArrowRightCircleIcon
                      label="Go to mRNA target page"
                      primaryColor={colors.N400}
                    />
                  )}
                >
                  Go to mRNA target page
                </ButtonLink>
              ) : (
                <ButtonItem
                  onClick={() => {
                    createTarget({
                      variables: { rnaId: id, spliced: true },
                      update: (cache) => {
                        cache.writeFragment<IsMrnaRnaTargetFragment>({
                          id: `Rna:${id}`,
                          fragment: IsMrnaRnaTargetFragmentDoc,
                          data: {
                            id,
                            isMrnaTarget: true,
                            isRnaTarget,
                          },
                        });
                      },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        createTarget: {
                          success: true,
                          message: `Added <${id}> mRNA to target`,
                          target: createDummyTarget({ spliced: true, isMrnaTarget, isRnaTarget }),
                        },
                      },
                    });
                    onClose();
                  }}
                  iconBefore={(
                    <AddCircleIcon
                      label="Add mRNA to target"
                      primaryColor={colors.G400}
                    />
                  )}
                >
                  Add mRNA to target
                </ButtonItem>
              )}
              {isRnaTarget ? (
                <ButtonLink
                  to={`/targets/${id}`}
                  iconBefore={(
                    <ArrowRightCircleIcon
                      label="Go to pre-mRNA target page"
                      primaryColor={colors.N400}
                    />
                  )}
                >
                  Go to pre-mRNA target page
                </ButtonLink>
              ) : (
                <ButtonItem
                  onClick={() => {
                    createTarget({
                      variables: { rnaId: id, spliced: false },
                      update: (cache) => {
                        cache.writeFragment<IsMrnaRnaTargetFragment>({
                          id: `Rna:${id}`,
                          fragment: IsMrnaRnaTargetFragmentDoc,
                          data: {
                            id,
                            isMrnaTarget,
                            isRnaTarget: true,
                          },
                        });
                      },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        createTarget: {
                          success: true,
                          message: `Added <${id}> pre-mRNA to target`,
                          target: createDummyTarget({ spliced: false, isMrnaTarget, isRnaTarget }),
                        },
                      },
                    });
                    onClose();
                  }}
                  iconBefore={(
                    <AddCircleIcon
                      label="Add mRNA to target"
                      primaryColor={colors.G400}
                    />
                  )}
                >
                  Add pre-mRNA to target
                </ButtonItem>
              )}
            </Section>
          </MenuGroup>
        )}
        trigger={(triggerProps: TriggerProps) => (
          <div style={{ textAlign: 'center', padding: '1px 0 0' }}>
            <Button
              {...triggerProps}
              iconBefore={isMrnaTarget || isRnaTarget
                ? (
                  <StarFilledIcon
                    size="medium"
                    label="Star filled icon"
                    primaryColor={isFocus || isOpen ? colors.Y200 : colors.Y300}
                  />
                )
                : (
                  <StarIcon
                    size="medium"
                    label="Star icon"
                    primaryColor={isFocus || isOpen ? colors.N500 : colors.N30}
                  />
                )}
              onClick={() => {
                setIsOpen(isOpen => !isOpen);
                setIsFocus(true);
              }}
              onMouseEnter={() => setIsFocus(true)}
              onMouseLeave={() => setIsFocus(false)}
              spacing="compact"
              shouldFitContainer
              style={{ background: 'inherit' }}
            />
          </div>
        )}
      />
    </Tooltip>
  );
}

export default CreateTargetPopup;
