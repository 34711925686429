import React, { useEffect } from 'react';
import { Content, Main, PageLayout } from '@atlaskit/page-layout';
import { ContentWrapper, FlexContainer } from '@atlaskit/right-side-panel';
import NProgress from 'nprogress';

import ModalManager from '../components/modals/modal-manager';
import PanelManager from '../components/panels/panel-manager';
import SidebarManager from '../components/sidebars/sidebar-manager';
import useNprogress from '../hooks/use-nprogress';
import AuthenticatedRoutes from '../routes/authenticated-routes';
import Alerts from './alerts';
import Navbar from './navbar';
import DrawerManager from '../components/drawers/drawer-manager';

NProgress.configure({ showSpinner: false });

function AuthenticatedApp(): JSX.Element {
  useEffect(() => {
    NProgress.start();
  }, []);

  useNprogress();

  return (
    <PageLayout>
      <Navbar />
      <Content>
        <SidebarManager />
        <Main id="main" skipLinkTitle="Main Content">
          <Alerts />
          <ModalManager />
          <FlexContainer id="filter-container">
            <ContentWrapper>
              <DrawerManager />
              <div
                style={{
                  margin: '0 8px 0 24px',
                  maxHeight: `calc(100vh - 56px)`,
                  overflowY: 'hidden',
                }}
              >
                <AuthenticatedRoutes />
              </div>
              <PanelManager />
            </ContentWrapper>
          </FlexContainer>
        </Main>
      </Content>
    </PageLayout>
  );
}

export default AuthenticatedApp;
