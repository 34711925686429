import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lozenge from '@atlaskit/lozenge';

import { AntisenseSeries, useGetTargetByIdRnaIdLazyQuery } from 'apollo/graphql';

function AntisenseSeriesLozengeList({
  antisenseSeries,
  targetId,
  rnaId,
  spliced,
  custom,
}: {
  antisenseSeries: AntisenseSeries[];
  targetId: string;
  rnaId?: string;
  spliced: boolean;
  custom: boolean;
}): JSX.Element {
  const navigate = useNavigate();

  const [getTargetByIdRnaId] = useGetTargetByIdRnaIdLazyQuery({
    variables: {
      ...(custom ? { id: targetId } : { rnaId, spliced }),
    },
  });

  return (
    <div onMouseOver={() => getTargetByIdRnaId()}>
      {antisenseSeries && antisenseSeries.length > 0 ? (
        antisenseSeries.map((antisense) => (
          <span
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              navigate(
                `/targets/${custom ? targetId : rnaId}/antisense`,
                { state: { spliced, length: antisense.length } },
              );
            }}
            key={antisense.id}
            style={{ cursor: 'pointer', marginRight: '4px' }}
          >
            <Lozenge isBold appearance="success">{antisense.length}</Lozenge>
          </span>
        ))
      ) : '\u2013'}
    </div>
  );
}

export default AntisenseSeriesLozengeList;
