import { useReactiveVar } from '@apollo/client';
import Toggle from '@atlaskit/toggle';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import Drawer from '@atlaskit/drawer';
import Tooltip from '@atlaskit/tooltip';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { SelectedType } from '@atlaskit/tabs/types';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import { sirnaCartItemsVar } from 'apollo/cache';
import CreateModifiedSirnaTab from '../antisense/create-modified-sirna-tab';
import CreateStandardSirnaTab from '../antisense/create-standard-sirna-tab';
import SirnaBadge from '../antisense/sirna-badge';

interface Props extends DrawerProps {
  isOpen: boolean;
}

function SirnaCartDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();
  const sirnaCartItems = useReactiveVar(sirnaCartItemsVar);

  const [flipDuplex, setFlipDuplex] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(
    sirnaCartItems.length,
  );

  useEffect(() => {
    setTotalCount(sirnaCartItemsVar().length);
  }, [sirnaCartItems]);

  const [selected, setSelected] = useState(0);

  const handleUpdate = useCallback(
    (index: SelectedType) => setSelected(index),
    [setSelected],
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ marginRight: '12px' }}>
        {totalCount ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '8px',
                marginBottom: '2px',
              }}
            >
              <SirnaBadge />
              <div style={{ display: 'flex', gap: '12px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    // marginBottom: '-2px',
                    cursor: 'pointer',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                  }}
                >
                  <Toggle
                    value={flipDuplex.toString()}
                    isChecked={flipDuplex}
                    onChange={() => setFlipDuplex(prevFlipDuplex => !prevFlipDuplex)}
                  />
                  <div onClick={() => setFlipDuplex(prevFlipDuplex => !prevFlipDuplex)}
                       style={{ paddingBottom: '4px' }}>
                    {' '} Flip duplex
                  </div>
                </div>
                <Tooltip content="Remove all" position="left" delay={100}>
                  <Button
                    spacing="none"
                    iconBefore={
                      <EditorRemoveIcon label="delete icon">
                        remove all
                      </EditorRemoveIcon>
                    }
                    appearance="danger"
                    onClick={() => {
                      setTotalCount(0);
                      sirnaCartItemsVar([]);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <Tabs onChange={handleUpdate} selected={selected} id="create-sirnas">
              <TabList>
                <Tab>Standard</Tab>
                <Tab>Modified</Tab>
              </TabList>
              <TabPanel>
                <CreateStandardSirnaTab flipDuplex={flipDuplex} setTotalCount={setTotalCount} />
              </TabPanel>
              <TabPanel>
                <CreateModifiedSirnaTab flipDuplex={flipDuplex} setTotalCount={setTotalCount} />
              </TabPanel>
            </Tabs>
          </>
        ) : (
          <h4 style={{ margin: '60px', textAlign: 'center' }}>
            No siRNA duplexes in cart.
          </h4>
        )}
      </div>
    </Drawer>
  );
}

export default SirnaCartDrawer;
