import { colors } from '@atlaskit/theme';
import styled from '@emotion/styled';

const EditIcon = styled.span`
  color: ${(p: { editDialogOpen: boolean }) => p.editDialogOpen ? colors.linkActive : colors.subtleHeading};
  width: 15.75px;

  &:hover {
    color: ${colors.linkHover};
  }

  &:active {
    color: ${colors.linkActive};
  }
`;

export default EditIcon;
