import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Avatar from '@atlaskit/avatar';

import { Order, OrderEdge } from 'apollo/graphql';
import { OrderOrderBy } from 'pages/order/orders';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';
import CompanyLozenge from './company-lozenge';
import CreateDeliveryPopup from './create-delivery-popup';
import DeliveryLozengeList from './delivery-lozenge-list';
import OrderTypeLozenge from './order-type-lozenge';

interface Props {
  orders: OrderEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: OrderOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function OrderList({
  orders,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Order>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 6,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'type',
        fieldName: 'Type',
        width: 5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'poNumber',
        fieldName: 'PO no.',
        width: 5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'customerName',
        fieldName: 'Customer',
        width: 9,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // {
      //   isSortable: false,
      //   width: 8,
      //   shouldTruncate: false,
      //   key: 'poIssuedOn',
      //   colSpan: 1,
      //   content: 'Issued on',
      // },
      createTableHead<Order>({
        fieldKey: 'poReceivedOn',
        fieldName: 'Received on',
        width: 5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'leadTime',
        fieldName: 'Lead time',
        width: 4,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'dueOn1',
        fieldName: 'Due date 1',
        width: 4.5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Order>({
        fieldKey: 'dueOn2',
        fieldName: 'Due date 2',
        width: 4.5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<Order>({
      //   fieldKey: 'completedOn',
      //   fieldName: 'Completed',
      //   width: 8,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'inCharge',
        colSpan: 1,
        content: 'In-charge',
      },
      {
        isSortable: false,
        width: 8,
        shouldTruncate: false,
        key: 'remarks',
        colSpan: 1,
        content: 'Remarks',
      },
      {
        isSortable: false,
        width: 10,
        shouldTruncate: false,
        key: 'deliveries',
        colSpan: 1,
        content: 'Deliveries',
      },
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: 'create-delivery',
        colSpan: 1,
        content: '',
      },
    ],
  };

  return (
    <StickyHeader withBar rowHeight={31}>
      <DynamicTableStateless
        head={head}
        rows={orders.map(({
          node: {
            id,
            customerId,
            customerName,
            customerCompany,
            poNumber,
            poIssuedOn,
            poReceivedOn,
            type,
            status,
            leadTime,
            dueOn1,
            dueOn2,
            completedOn,
            inCharge,
            remarks,
            deliveries,
          },
        }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <span style={{ paddingLeft: '4px' }}>
                  <TableLink to={`/orders/${id}`}>{id.toString().padStart(3, '0')}</TableLink>&nbsp;&nbsp;&nbsp;
                  <Lozenge
                    isBold
                    appearance={status === 'active' ? 'inprogress' : status === 'completed' ? 'success' : status === 'modified' ? 'new' : status === 'on hold' ? 'moved' : status === 'void' ? 'removed' : 'default'}
                  >
                    {status}
                  </Lozenge>
                </span>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: <OrderTypeLozenge orderType={type} />,
            },
            {
              key: id,
              colSpan: 1,
              content: poNumber || '\u2013',
            },
            {
              key: id,
              colSpan: 1,
              content: (
                  <span>
                    <TableLink to={`/customers/${customerId}`}>{customerName}</TableLink>&nbsp;&nbsp;
                    <CompanyLozenge id={customerCompany!} />
                  </span>
              ),
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: poIssuedOn || '\u2013',
            // },
            {
              key: id,
              colSpan: 1,
              content: poReceivedOn || '\u2013',
            },
            {
              key: id,
              colSpan: 1,
              content: leadTime[0] === leadTime[1] ? leadTime[0] : leadTime.join('\u2013'),
            },
            {
              key: id,
              colSpan: 1,
              content: dueOn1 || '\u2013',
            },
            {
              key: id,
              colSpan: 1,
              content: dueOn2 || '\u2013',
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: completedOn || '\u2013',
            // },
            {
              key: id,
              colSpan: 1,
              content: inCharge
                ? (
                  <Avatar
                    size="small"
                    src={inCharge.photoUrl!}
                    name={inCharge.username}
                  />)
                : '\u2013',

            },
            {
              key: id,
              colSpan: 1,
              content: <span style={{ textOverflow: 'ellipsis' }}>{remarks || '\u2013'}</span>,
            },
            {
              key: id,
              colSpan: 1,
              content: <DeliveryLozengeList orderId={id} deliveries={deliveries || []} />
              // content: (
              //   deliveries?.length ?
              //     <div style={{
              //       display: 'flex',
              //       justifyContent: 'flex-start',
              //       flexWrap: 'wrap',
              //       gap: '6px',
              //       alignContent: 'end',
              //       marginTop: '3px',
              //     }}>
              //       {deliveries?.map(({ id }) => (
              //         <Lozenge key={id}>
              //           <div style={{ paddingBottom: '1px' }}>{id}</div>
              //         </Lozenge>
              //       ))}
              //     </div>
              //     : '\u2013'
              // ),
            },
            {
              key: id,
              colSpan: 1,
              content: <CreateDeliveryPopup orderId={parseInt(id)} />,
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No orders found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default OrderList;
