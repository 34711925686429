import React from 'react';
import Select, { ValueType } from '@atlaskit/select';

import { SelectOptionNumberValueType } from 'types/select-option';

interface Props {
  rowsOptions: number[];
  rowsPerPage: SelectOptionNumberValueType;
  setRowsPerPage: (value: SelectOptionNumberValueType) => void;
  setCurrentPage: (num: number) => void;
}

function RowsSelect({
  rowsOptions = [10, 20, 50],
  rowsPerPage = { label: '20 rows', value: 20 },
  setRowsPerPage,
  setCurrentPage,
}: Props): JSX.Element {
  const options: SelectOptionNumberValueType[] = rowsOptions.map(rowCount => ({
    label: `${rowCount} rows`, value: rowCount,
  }));

  const onRowsSelectChange = (value: ValueType<SelectOptionNumberValueType>): void => {
    if (value && value.value !== rowsPerPage.value) {
      setRowsPerPage(value);
      setCurrentPage(1);
    }
  };

  return (
    <div style={{ zIndex: 3 }}>
      <Select
        spacing="compact"
        options={options}
        value={rowsPerPage}
        onChange={onRowsSelectChange}
        placeholder={rowsPerPage.label}
        aria-label={rowsPerPage.label as string}
        styles={{ control: base => ({ ...base, width: '108px' }) }}
      />
    </div>
  );
}

export default RowsSelect;
