import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import LoadingButton from '@atlaskit/button/loading-button';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import Toggle from '@atlaskit/toggle';

import {
  GetAllRnasDocument,
  GetAllTargetsDocument,
  GetTargetCountDocument,
  OrganismSelect,
  SearchRnasDocument,
  SearchRnasQuery,
  useCreateTargetMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { SelectOptionStringValueType } from 'types/select-option';
import OrganismsSelect, { OrganismSelectOptionType } from '../genome/organism-select';

interface RnaProps {
  organismId?: number | null;
  id: string;
  symbol?: string | null;
  geneName?: string | null;
  title?: string | null;
  desc?: string | null;
  mrnaLength?: number | null;
  rnaLength?: number | null;
  exonCount?: number | null;
  cdsStart?: number | null;
  cdsEnd?: number | null;
}

interface Props {
  totalCount?: number;
}

function CreateRefseqTarget({ totalCount }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [targetCount, setTargetCount] = useState<number>(totalCount || 0);
  const [organism, setOrganism] = useState<OrganismSelectOptionType>({ label: 'Human', value: OrganismSelect.Human });
  const [options, setOptions] = useState<Record<string, Omit<RnaProps, 'id'>>>({});

  const defaultRnaProps: RnaProps = {
    organismId: null,
    id: '',
    symbol: '',
    geneName: '',
    title: '',
    desc: '',
    mrnaLength: null,
    rnaLength: null,
    exonCount: null,
    cdsStart: null,
    cdsEnd: null,
  };
  const [rna, setRna] = useState<RnaProps>(defaultRnaProps);

  const [spliced, setSpliced] = useState<boolean>(true);
  const toggleSpliced = () => setSpliced(spliced => !spliced);

  const reset = () => {
    setTargetCount(targetCount + 1);
    setOrganism({ label: 'Human', value: OrganismSelect.Human });
    setOptions({});
    setRna(defaultRnaProps);
    setSpliced(true);
  };

  const [createTarget, { loading }] = useCreateTargetMutation({
    onCompleted: ({ createTarget: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllTargetsDocument, GetAllRnasDocument, GetTargetCountDocument],
  });

  const promiseOptions = (inputValue: string) => client.query<SearchRnasQuery>({
    query: SearchRnasDocument,
    variables: {
      filter: {
        excludeModel: true,
        ...(organism.value && { organism: organism.value }),
        search: inputValue,
      },
      orderBy: ['id_ASC'],
    },
    fetchPolicy: 'network-only',
  })
    .then(({ data }) => {
      const rnas = data?.searchRnas.edges || [];

      const options: Record<string, Omit<RnaProps, 'id'>> = {};
      rnas.forEach(({ node: { organismId, id, symbol, geneName, title, desc, exonCount, cdsStart, cdsEnd } }) => {
        options[id] = { organismId, symbol, geneName, title, desc, exonCount, cdsStart, cdsEnd };
      });
      setOptions(options);

      return rnas.map(({ node: { id, title } }) => ({
        label: `${id} - ${title}`,
        value: id,
      }));
    });

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
        <OrganismsSelect
          organism={organism}
          setOrganism={setOrganism}
        />
      </div>
      <div style={{ paddingTop: '16px' }}>
        <AsyncSelect
          autoFocus
          // cacheOptions
          // defaultOptions
          loadOptions={promiseOptions}
          placeholder="Search for RNA..."
          onChange={(value: ValueType<SelectOptionStringValueType>): void => {
            if (value) {
              const selectedOption = value;
              setRna({ id: selectedOption.value, ...options[selectedOption.value] });
            }
          }}
          key={targetCount}
        />
        <div style={{ padding: '32px 4px', minHeight: '160px' }}>
          {rna.id && (
            <>
              <div style={{ width: '200px' }}>
                {rna.id}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '5px',
                  borderBottom: `1px solid ${colors.DN600A}`,
                  marginBottom: '12px',
                }}
              >
                <span>{rna.geneName}</span>
                <span style={{ fontStyle: 'italic' }}>{rna.symbol}</span>
              </div>
              <div>{rna.title}</div>
              {rna.desc !== '-' && <div style={{ fontSize: '0.9em', paddingTop: '6px' }}>{rna.desc}</div>}
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 0',
            borderTop: `1px solid ${colors.DN300A}`,
          }}
        >
          <div
            onClick={rna.id ? toggleSpliced : () => {}}
            style={{
              cursor: 'pointer',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              paddingTop: '2px',
            }}
          >
            <Toggle
              isChecked={spliced}
              onChange={rna.id ? toggleSpliced : () => {}}
              isDisabled={!rna.id}
            />
            <span style={{ color: !rna.id ? colors.N50 : '' }}>{' '} {spliced ? '' : 'pre-'}mRNA</span>
          </div>
          <LoadingButton
            type="submit"
            appearance="primary"
            isLoading={loading}
            onClick={() => {
              createTarget({
                variables: { rnaId: rna.id, spliced },
              });
            }}
            isDisabled={!rna.id}
          >
            <span style={{ fontWeight: 400 }}>
              Submit
            </span>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default CreateRefseqTarget;
