import React from 'react';
import Select from '@atlaskit/select';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Tooltip from '@atlaskit/tooltip';

import { Rna, RnaEdge, RnaWhereInput } from 'apollo/graphql';
import { RnaOrderBy } from 'pages/genome/rnas';
import { ChrStrandSelectOptionType } from 'types/select-option';
import CreateTargetPopup from '../antisense/create-target-popup';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import ExpandableRnaInfo from './expandable-rna-info';
import TableLink from 'components/tables/table-link';
import RefseqRnaIdColumn from './refseq-rna-id-column';

interface Props {
  rnas: RnaEdge[];
  isLoading: boolean;
  setFilters: Function;
  orderByList: RnaOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
  expandAll?: boolean;
}

function RnaList({
  rnas,
  isLoading,
  setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
  expandAll = false,
}: Props): JSX.Element {
  const head = {
    cells: [
      {
        isSortable: false,
        width: 1,
        shouldTruncate: false,
        key: 'isTarget',
        colSpan: 1,
        content: (
          <div style={{ textAlign: 'center', marginTop: '34px' }}>
            <StarFilledIcon label="Star filled icon" size="small" />
          </div>
        ),
      },
      createTableHead<Rna>({
        fieldKey: 'id',
        fieldName: 'RefSeq ID',
        width: 4,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'symbol',
        fieldName: 'Symbol',
        width: 4,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'title',
        fieldName: 'Name',
        width: 16,
        setFilters,
        shouldTruncate: true,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'chrNo',
        fieldName: 'Chrom',
        width: 3,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'chrStart',
        fieldName: 'Start',
        width: 4,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'chrEnd',
        fieldName: 'End',
        width: 4,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'chrStrand',
        fieldName: 'Strand',
        width: 3,
        setFilters,
        select: (
          <div style={{ width: '100%', paddingBottom: '4px' }}>
            <Select
              spacing="compact"
              placeholder="+/&minus;"
              options={[
                { label: '+/\u2212', value: '' },
                { label: '+', value: '+' },
                { label: '\u2212', value: '-' },
              ]}
              onChange={(value): void => {
                if (value) {
                  const selectedOption = value as ChrStrandSelectOptionType;
                  setFilters((prevFilters: Omit<RnaWhereInput, 'excludeModel' | 'organism'>) => ({
                    ...prevFilters,
                    'chrStrand': selectedOption.value,
                  }));
                  setCurrentPage(1);
                }
              }}
              styles={{
                control: base => ({
                  ...base,
                  backgroundColor: 'white',
                  borderColor: 'white',
                  WebkitUserSelect: 'none',
                  MozUserSelect: 'none',
                  msUserSelect: 'none',
                }),
              }}
            />
          </div>
        ),
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'rnaLength',
        fieldName: 'Transcript',
        width: 4,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'exonCount',
        fieldName: 'Exons',
        width: 3,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'mrnaLength',
        fieldName: 'mRNA',
        width: 3,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'utr5Length',
        fieldName: '5\'-UTR',
        width: 3,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Rna>({
        fieldKey: 'utr3Length',
        fieldName: '3\'-UTR',
        width: 3,
        setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
    ],
  };

  return (
    <StickyHeader alignTop>
      <DynamicTableStateless
        head={head}
        rows={rnas.map(({
          node: {
            organismId,
            id,
            symbol,
            synonyms,
            geneName,
            title,
            desc,
            chrNo,
            chrStart,
            chrEnd,
            chrStrand,
            rnaLength,
            exonCount,
            mrnaLength,
            utr5Length,
            utr3Length,
            enstId,
            status,
            type,
            protId,
            protTitle,
            protLength,
            cdsStart,
            cdsEnd,
            isMrnaTarget,
            isRnaTarget,
          },
        }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <CreateTargetPopup
                  id={id}
                  isMrnaTarget={isMrnaTarget || false}
                  isRnaTarget={isRnaTarget || false}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <div style={{ paddingTop: '2px' }}>
                  <RefseqRnaIdColumn
                    id={id}
                    enstId={enstId || ''}
                    status={status || ''}
                  />
                </div>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <div style={{ paddingTop: '2px' }}>
                  <Tooltip
                    content={(
                      <div>
                        {type && <>[ {type} ]<br /></>}
                        {synonyms?.join(' / ')}
                      </div>
                    )}
                    position={'right-start'}
                  >
                    <TableLink to={`/genome/genes/${symbol}`}>
                      <span style={{ paddingTop: '2px' }}>{`${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol}`}</span>
                    </TableLink>
                  </Tooltip>
                </div>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <ExpandableRnaInfo
                  isOpen={expandAll}
                  id={id}
                  geneName={geneName!}
                  title={title!}
                  desc={desc!}
                  protId={protId}
                  protTitle={protTitle}
                  protLength={protLength}
                  cdsStart={cdsStart}
                  cdsEnd={cdsEnd}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{chrNo}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{chrStart!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{chrEnd!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: chrStrand === '+'
                ? <div style={{ paddingTop: '2px' }}>+</div>
                : <div style={{ paddingTop: '2px' }}>&minus;</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{rnaLength!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{exonCount!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{mrnaLength!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{utr5Length!.toLocaleString()}</div>,
            },
            {
              key: id,
              colSpan: 1,
              content: <div style={{ paddingTop: '2px' }}>{utr3Length!.toLocaleString()}</div>,
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No RNAs found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default RnaList;
