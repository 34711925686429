import { RootState } from '../store';
import { AlertAction } from './actions';
import { Alert, AlertActionType } from './types';

export const selectAlerts = (rootState: RootState) => rootState.alerts;

const alertsReducer = (
  state: Alert[] = [],
  action: AlertAction,
) => {
  switch (action.type) {
    case AlertActionType.SET_ALERT:
      return [
        {
          ...action.payload,
          id: state.length + 1,
        },
        ...state,
      ];
    case AlertActionType.REMOVE_ALERT:
      return state.filter(({ id }) => id !== state.length);
    default:
      return state;
  }
};

export default alertsReducer;
