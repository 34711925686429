import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Helmet } from 'react-helmet-async';
import PageHeader from '@atlaskit/page-header';

export interface RouterStateProps {
  spliced?: boolean;
  length?: number;
}

function TargetOverview() {
  const { targetId } = useParams();
  const { state } = useLocation();

  return (
    <Page>
      <Helmet title="Target" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            {`${targetId} ${state?.spliced ? '' : 'pre-'}mRNA`}
          </PageHeader>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default TargetOverview;
