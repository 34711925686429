import React, { useEffect, useState } from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import { layers } from '@atlaskit/theme';

import { useGetAllCompaniesQuery } from 'apollo/graphql';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props {
  setCompanies: Function;
  setCurrentPage: Function;
}

function CompanyMultiSelect({ setCompanies, setCurrentPage }: Props): JSX.Element {
  const [companyIds, setCompanyIds] = useState<SelectOptionStringValueType[]>([]);

  useEffect(() => {
    setCompanies(companyIds.map(({ value }) => value));
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIds]);

  const { data } = useGetAllCompaniesQuery({
    ssr: false,
    // fetchPolicy: 'network-only',
  });

  const allCompanies = data?.companies.edges || [];

  return (
    <div
      style={{
        zIndex: layers.card(),
        marginLeft: '16px',
        flexWrap: 'nowrap',
      }}
    >
      <CheckboxSelect
        spacing="compact"
        options={allCompanies.map(({ node: { id, shortName } }) => ({ label: shortName, value: id }))}
        placeholder="All companies"
        // @ts-ignore
        value={companyIds}
        // @ts-ignore
        isMulti
        onChange={((value: any): void => {
          setCompanyIds(value ? value.map(({ value }: SelectOptionStringValueType) => ({ label: value, value })) as SelectOptionStringValueType[] : []);
        })}
        styles={{
          control: base => ({
            ...base,
            minWidth: '310px',
            maxWidth: '500px',
          }),
        }}
      />
    </div>
  )
}

export default CompanyMultiSelect;
