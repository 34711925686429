import React from 'react';
import Toggle from '@atlaskit/toggle';

interface Props {
  includeCustomTarget: boolean;
  setIncludeCustomTarget: (f: (include: boolean) => boolean) => void;
  setCurrentPage: (num: number) => void;
}

function CustomTargetInclude({
  includeCustomTarget,
  setIncludeCustomTarget,
  setCurrentPage,
}: Props): JSX.Element {
  const toggleInclude = () => {
    setIncludeCustomTarget((prevInclude: boolean) => !prevInclude);
    setCurrentPage(1);
  };

  return (
    <div
      onClick={toggleInclude}
      style={{
        cursor: 'pointer',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        paddingTop: '3px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Toggle
        isChecked={includeCustomTarget}
        onChange={toggleInclude}
      />
      <div style={{ paddingTop: '3px' }}>Show custom</div>
    </div>
  );
}

export default CustomTargetInclude;
