import React from 'react';

import AdminContent from './admin-content';
import GenomeContent from './genome-content';
import AntisenseContent from './antisense-content';
import ChemistryContent from './chemistry-content';
import OligoContent from './oligo-content';
import ProductContent from './product-content';
import ReagentContent from './reagent-content';
import OrderContent from './order-content';
import ProductionContent from './production-content';

const primaryItems = [
  <AdminContent />,
  <GenomeContent />,
  <AntisenseContent />,
  <ChemistryContent />,
  <ProductContent />,
  <OligoContent />,
  <ReagentContent />,
  <OrderContent />,
  <ProductionContent />,
];

export default primaryItems;
