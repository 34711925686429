export interface TargetProps {
  organismId: number;
  id: string;
  rnaId?: string | null;
  spliced: boolean;
  custom?: boolean;
  symbol: string;
  geneName?: string;
  title?: string;
  length: number;
  exonCount?: number;
}

export const createDummyTarget = ({
  organismId = -1,
  id = '-1',
  custom = false,
  rnaId = '-1',
  spliced = true,
  geneId = -1,
  symbol = 'targetSymbol',
  geneName = 'targetGeneName',
  title = 'targetTitle',
  length = -1,
  exonCount = -1,
  cdsStart = -1,
  cdsEnd = -1,
  isMrnaTarget,
  isRnaTarget,
}: {
  organismId?: number;
  id?: string;
  custom?: boolean;
  rnaId?: string;
  spliced?: boolean;
  geneId?: number;
  symbol?: string;
  geneName?: string;
  title?: string;
  length?: number;
  exonCount?: number;
  cdsStart?: number;
  cdsEnd?: number;
  isMrnaTarget?: boolean;
  isRnaTarget?: boolean;
} = {
  organismId: -1,
  id: '-1',
  custom: false,
  rnaId: '-1',
  spliced: true,
  geneId: -1,
  symbol: 'targetSymbol',
  geneName: 'targetGeneName',
  title: 'targetTitle',
  length: -1,
  exonCount: -1,
  cdsStart: -1,
  cdsEnd: -1,
}) => ({
  organismId,
  id,
  custom,
  rnaId,
  spliced,
  geneId,
  symbol,
  geneName,
  title,
  length,
  exonCount,
  cdsStart,
  cdsEnd,
  rna: {
    id: rnaId,
    isMrnaTarget: spliced || isMrnaTarget,
    isRnaTarget: !spliced || isRnaTarget,
  },
});
