import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import PageHeader from '@atlaskit/page-header';
// import { colors, fontSizeSmall } from '@atlaskit/theme';

import { OrderItem, useGetOrderQuery } from 'apollo/graphql';
import OrderTypeLozenge from 'components/order/order-type-lozenge';
import useOrders from 'hooks/use-orders';
import { AscOrDesc } from 'components/tables/create-table-head';
import OrderItemList, { OrderItemProps } from '../../components/order/order-item-list';

export interface OrderItemOrderBy {
  field: Exclude<keyof OrderItem, '__typename'>;
  order: AscOrDesc;
}

function OrderOverview() {
  useOrders();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { loading, data, previousData } = useGetOrderQuery({
    variables: {
      id: orderId!,
    },
  });

  const breadcrumbs = (
    <Breadcrumbs>
      <BreadcrumbsItem
        text="Orders"
        onClick={() => navigate('/orders')}
      />
      <BreadcrumbsItem
        text={orderId!.padStart(3, '0')}
        // onClick={() => refetch()}
      />
    </Breadcrumbs>
  );

  const items: OrderItemProps[] = (data?.order?.items || previousData?.order?.items || []).map(item => ({
    ...item,
    orderType: data?.order?.type || undefined,
    companyId: data?.order?.customerCompany || undefined,
  }));

  return (
    <Page>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader breadcrumbs={breadcrumbs}>
            {orderId!.toString().padStart(3, '0')}&nbsp;&nbsp;
            {data?.order?.type && <OrderTypeLozenge orderType={data?.order?.type} />}
          </PageHeader>

          {/*<div*/}
          {/*  style={{*/}
          {/*    fontSize: fontSizeSmall(),*/}
          {/*    color: colors.subtleHeading(),*/}
          {/*    fontWeight: 500,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Customer name*/}
          {/*</div>*/}
          {/*<div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{data?.order?.customerName}</div>*/}
          {/*<div*/}
          {/*  style={{*/}
          {/*    fontSize: fontSizeSmall(),*/}
          {/*    color: colors.subtleHeading(),*/}
          {/*    fontWeight: 500,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Customer company*/}
          {/*</div>*/}
          {/*<div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{companyData?.company?.name}</div>*/}
          {/*<div*/}
          {/*  style={{*/}
          {/*    fontSize: fontSizeSmall(),*/}
          {/*    color: colors.subtleHeading(),*/}
          {/*    fontWeight: 500,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  PO no.*/}
          {/*</div>*/}
          {/*<div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{data?.order?.poNumber}</div>*/}
          {/*<OrderItemList items={data?.order?.items || []} isLoading={loading} orderByList={} setOrderByList={} setCurrentPage={} />*/}
          {/*<div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>*/}
          {/*  {data?.order?.items?.map(({ id, description, product }) => (*/}
          {/*    <div key={id}>{description}</div>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </GridColumn>
        <GridColumn medium={12}>
          <OrderItemList items={items} isLoading={loading} />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default OrderOverview;
