import React from 'react';
import { useSelector } from 'react-redux';

import { selectSidebar } from 'redux/sidebars';
import TargetSidebar from './target-sidebar';

export enum SidebarType {
  TargetSidebar = 'TargetSidebar',
}

const sidebarLookup: Record<SidebarType, React.FC<any>> = {
  TargetSidebar,
};

const SidebarManager = () => {
  let renderedSidebar: JSX.Element | null = null;

  const { sidebarType, ...sidebarProps } = useSelector(selectSidebar);
  if (sidebarType) {
    const SidebarComponent = sidebarLookup[sidebarType];

    renderedSidebar = <SidebarComponent {...sidebarProps} />;
  }

  return renderedSidebar;
};

export default SidebarManager;
