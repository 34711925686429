import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { TriggerProps } from '@atlaskit/popup';

import { SampleProps } from 'types/order';
import { GetOrderDocument, useCreateSampleMutation } from 'apollo/graphql';
import { setAlert } from 'redux/alerts';

interface Props {
  sample: SampleProps;
}

function OrderItemListActionsPopup({
  sample: {
    orderItemId,
    productId,
    orderId,
    companyId,
    name,
    typeName,
    oligoId,
    // mass,
  },
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  const [createSample] = useCreateSampleMutation({
    onCompleted: ({ createSample: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success"/>
          : <ErrorIcon primaryColor={colors.R400} label="Error"/>,
      }));
      // if (success) reset();
    },
    refetchQueries: [GetOrderDocument],
  });

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-end"
      content={() => (
        <MenuGroup>
          <Section>
            <ButtonItem
              onClick={() => {
                onClose();
                createSample({
                  variables: {
                    ...(orderItemId && { orderItemId }),
                    ...(productId && { productId: parseInt(productId) }),
                    ...(orderId && { orderId: parseInt(orderId) }),
                    ...( companyId && { companyId }),
                    ...(name && { name }),
                    typeName,
                    ...(oligoId && { oligoId }),
                  },
                });
              }}
            >
              Create sample
            </ButtonItem>
            <ButtonItem
              onClick={() => {
                onClose();
                createSample({
                  variables: {
                    ...(orderItemId && { orderItemId }),
                    ...(productId && { productId: parseInt(productId) }),
                    ...(orderId && { orderId: parseInt(orderId) }),
                    companyId: 'NAS',
                    ...(name && { name }),
                    typeName,
                    ...(oligoId && { oligoId }),
                  },
                });
              }}
            >
              Create stock
            </ButtonItem>
          </Section>
          <Section hasSeparator>
            <ButtonItem onClick={onClose} isDisabled>
              Assign sample
            </ButtonItem>
            <ButtonItem onClick={onClose} isDisabled>
              Assign sample from stock
            </ButtonItem>
          </Section>
          <Section hasSeparator>
            <ButtonItem onClick={onClose} isDisabled>
              Delete sample
            </ButtonItem>
          </Section>
        </MenuGroup>
      )}
      trigger={(triggerProps: TriggerProps) => (
        <div style={{ width: '36px' }}>
          <Button
            {...triggerProps}
            onClick={() => setIsOpen(open => !open)}
            iconBefore={<MoreIcon label="More icon" size="medium"/>}
            shouldFitContainer
            appearance="subtle"
            isSelected={isOpen}
          />
        </div>
      )}
    />
  );
}

export default OrderItemListActionsPopup;
