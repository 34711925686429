import React from "react";
import { useNavigate } from "react-router-dom";
import Lozenge from "@atlaskit/lozenge";

interface Props {
  id: string;
}

function CompanyLozenge({ id }: Props) {
  const navigate = useNavigate();

  return (
    <span
      onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(`/companies/${id}`);
      }}
      style={{ cursor: "pointer", marginRight: "4px" }}
    >
      <Lozenge appearance={"moved"}>{id}</Lozenge>
    </span>
  );
}

export default CompanyLozenge;
