import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { colors } from '@atlaskit/theme';

import { BuildingBlockSortField, useGetAllBackbonesQuery, BackboneConnection } from 'apollo/graphql';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import useWindowSize from 'hooks/use-window-size';
import ScrollableContent from 'components/tables/scrollable-content';
import BackboneCard from 'components/chemistry/backbone-card';

function Backbones(): JSX.Element {
  const size = useWindowSize();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    // RowsSelect,
    TablePagination,
  } = usePagination({
    // defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 20,
    defaultTotalCount: 3,
    defaultFilteredCount: 3,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const { loading, data, previousData } = useGetAllBackbonesQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: { search: debouncedSearch },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  useEffect(() => {
    updatePagination<BackboneConnection>(data?.backbones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const barContent = (
    <div style={{ display: 'flex', paddingLeft: '6px' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }}/>
            </>
          }
        />
      </div>
    </div>
  );

  const bases = data ? data.backbones.edges : previousData?.backbones.edges || [];

  return (
    <Page>
      <Helmet title="Backbones"/>

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader bottomBar={barContent}>
            <span style={{ paddingLeft: '6px' }}>Backbones</span>
          </PageHeader>
        </GridColumn>

        <ScrollableContent withBar>
          <Grid layout="fluid">
            {!loading && bases.length === 0
              ? <h3 style={{ margin: '40px auto' }}>No bases found.</h3>
              : bases.map(({ node: { id, symbol, name, mass, cml } }) => (
                <BackboneCard
                  id={id}
                  symbol={symbol}
                  name={name}
                  mass={mass}
                  structure={cml || ''}
                  key={`${id}_${size.width}`}
                />
              ))
            }
          </Grid>
        </ScrollableContent>

        <GridColumn medium={12}>
          <span style={{ paddingLeft: '6px' }}>
            <TablePagination/>
          </span>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Backbones;
