import React, { useEffect, useState } from 'react';
import SearchIcon from '@atlaskit/icon/glyph/search';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSearch from 'hooks/use-search';

export type AscOrDesc = 'ASC' | 'DESC';

const Search = <T, >({
  fieldKey,
  setFilters,
  setCurrentPage,
}: {
  fieldKey: Exclude<keyof T, '__typename'>;
  setFilters: Function;
  setCurrentPage: Function;
}): JSX.Element => {
  const [focus, setFocus] = useState<boolean>(false);
  const [setValue, { search: value, debouncedSearch: debouncedValue }] = useSearch();

  useEffect(() => {
    setFilters((prevFilters: Record<string, string>) => ({
      ...prevFilters,
      [fieldKey]: debouncedValue,
    }));
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div
      style={{
        display: 'inline-block',
        width: '100%',
        paddingBottom: '4px',
      }}
    >
      <TextField
        autoComplete="off"
        value={value}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.keyCode === 27) {
            setValue('');
            event.preventDefault();
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        isCompact
        appearance={!value ? 'subtle' : 'standard'}
        onBlur={() => setFocus(prevFocus => !prevFocus)}
        onFocus={() => setFocus(prevFocus => !prevFocus)}
        elemBeforeInput={(!focus && !value) && (
          <div style={{ lineHeight: '100%', color: colors.N100 }}>
            <SearchIcon size="small" label="Search" />
          </div>
        )}
      />
    </div>
  );
};

const handleClick = <T, >(
  field: Exclude<keyof T, '__typename'>,
  orderByList: { field: Exclude<keyof T, '__typename'>; order: AscOrDesc }[],
  setOrderByList: Function,
  shiftClick: boolean,
  setCurrentPage: (num: number) => void,
) => {
  const orderByIndex = orderByList.findIndex(orderBy => orderBy['field'] === field);

  if (shiftClick) {  // Handle multi-select on shift-click
    if (orderByIndex < 0) {
      setOrderByList((prevOrderByList: typeof orderByList) => {
        return [...prevOrderByList, { field, order: 'ASC' }];
      });
    } else {
      if (orderByList[orderByIndex].order === 'ASC') {
        setOrderByList((prevOrderByList: typeof orderByList) => prevOrderByList.map((orderBy, i) => {
          return i === orderByIndex ? { ...orderBy, order: 'DESC' } : orderBy;
        }));
      } else {
        if (orderByList.length === 1) {
          setOrderByList((prevOrderByList: typeof orderByList) => prevOrderByList.map((orderBy, i) => {
            return i === orderByIndex ? { ...orderBy, order: 'ASC' } : orderBy;
          }));
        } else {
          setOrderByList((prevOrderByList: typeof orderByList) => prevOrderByList.filter((orderBy, i) => {
            return i !== orderByIndex;
          }));
        }
      }
    }
  } else {
    if (orderByIndex < 0) {
      setOrderByList([{ field, order: 'ASC' }]);
    } else {
      setOrderByList((prevOrderByList: typeof orderByList) => {
        return [{ field, order: prevOrderByList[orderByIndex].order === 'ASC' ? 'DESC' : 'ASC' }];
      });
    }
  }

  setCurrentPage(1);
};

const createTableHead = <T,>({
  fieldKey,
  fieldName = fieldKey.toString().toUpperCase(),
  width = 1,
  shouldTruncate = false,
  colSpan = 1,
  setFilters = null,
  select,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: {
  fieldKey: Exclude<keyof T, '__typename'>;
  fieldName?: string | JSX.Element;
  width?: number,
  shouldTruncate?: boolean;
  colSpan?: number;
  setFilters?: Function | null;
  select?: JSX.Element | null;
  orderByList: { field: Exclude<keyof T, '__typename'>; order: AscOrDesc }[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}) => {
  return {
    isSortable: false,  // Override default sorting from atlaskit
    width,
    shouldTruncate,
    key: fieldKey,
    colSpan,
    content: (
      <>
        {setFilters && (
          select
            ? select
            : select !== null
              ? (
                <Search
                  fieldKey={fieldKey}
                  setFilters={setFilters}
                  setCurrentPage={setCurrentPage}
                />
              )
              : null
        )}
        <div
          onClick={e => handleClick<T>(fieldKey, orderByList, setOrderByList, e.shiftKey, setCurrentPage)}
          style={{
            cursor: 'pointer',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
          }}
        >
          {fieldName}
          {!orderByList || !orderByList.some(orderBy => orderBy['field'] === fieldKey)
            ? <FontAwesomeIcon icon="sort" style={{ width: '7px', padding: '0 0 0 4px', color: colors.N40 }} />
            : orderByList.find(orderBy => (orderBy.field === fieldKey && orderBy.order === 'ASC'))
              ? <FontAwesomeIcon icon="sort-up" style={{ width: '7px', padding: '0 0 0 4px' }} />
              : <FontAwesomeIcon icon="sort-down" style={{ width: '7px', padding: '0 0 0 4px' }} />}
        </div>
      </>
    ),
  };
};

export default createTableHead;
