import React from "react";
import Badge from "@atlaskit/badge";

import { sirnaCartItemsVar } from "apollo/cache";

function SirnaBadge(): JSX.Element | null {
  return sirnaCartItemsVar().length > 0 ? (
    <div style={{ marginTop: "5px" }}>
      <Badge appearance="added" max={99}>
        {sirnaCartItemsVar().length}
      </Badge>{" "}
      sequence{sirnaCartItemsVar().length > 1 && "s"} selected
    </div>
  ) : null;
}

export default SirnaBadge;
