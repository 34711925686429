import { useDispatch } from 'react-redux';

import { SidebarType } from '../components/sidebars/sidebar-manager';
import { closeSidebar, openSidebar } from '../redux/sidebars';

interface Props {
  sidebarType: SidebarType;
  urlStart?: string;
  [key: string]: string | undefined | boolean | number;
}

const useSidebar = ({ sidebarType, urlStart, ...sidebarProps }: Props) => {
  const dispatch = useDispatch();

  const mountSidebar = () => {
    dispatch(openSidebar(sidebarType, sidebarProps));
  };

  const unmountSidebar = () => {
    // if (!urlStart || !url.startsWith(urlStart)) {
    //   dispatch(closeSidebar());
    // }
    dispatch(closeSidebar());
  };

  return {
    mountSidebar,
    unmountSidebar,
  };
};

export default useSidebar;
