import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lozenge from '@atlaskit/lozenge';

import { SirnaSeries, useGetTargetByIdRnaIdLazyQuery } from 'apollo/graphql';

function SirnaSeriesLozengeList({
  sirnaSeries,
  targetId,
  rnaId,
  spliced,
  custom,
}: {
  sirnaSeries: SirnaSeries[];
  targetId: string;
  rnaId?: string;
  spliced: boolean;
  custom: boolean;
}): JSX.Element {
  const navigate = useNavigate();

  const [getTargetByIdRnaId] = useGetTargetByIdRnaIdLazyQuery({
    variables: {
      ...(custom ? { id: targetId } : { rnaId, spliced }),
    },
  });

  return (
    <div onMouseOver={() => getTargetByIdRnaId()}>
      {sirnaSeries && sirnaSeries.length > 0 ? (
        sirnaSeries.map(sirna => {
          return (
            <span
              onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                e.stopPropagation();
                navigate(
                  `/targets/${custom ? targetId : rnaId}/sirna`,
                  { state: { spliced, config: sirna.config } },
                );
              }}
              key={sirna.id}
              style={{ cursor: 'pointer', marginRight: '4px' }}
            >
              <Lozenge isBold appearance="new">
                {sirna.config.slice(0, 2).join('/')}{sirna.config[2] ? `(${sirna.config[2]})` : ''}
              </Lozenge>
            </span>
          );
        })
      ) : '\u2013'}
    </div>
  );
}

export default SirnaSeriesLozengeList;
