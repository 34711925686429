import React from 'react';
import { useMatch } from 'react-router-dom';
import { MenuGroup, Section } from '@atlaskit/menu';

import { SkuSortField, useGetAllSkusQuery } from 'apollo/graphql';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Reagent = ({ onClose }: ContentProps): JSX.Element => {
  useGetAllSkusQuery({
    variables: {
      page: 1,
      perPage: 50,
      filter: {
        search: '',
      },
      orderBy: [SkuSortField.IdAsc, SkuSortField.ProductCodeAsc],
    },
    fetchPolicy: 'network-only',
  });

  return (
    <MenuGroup minWidth="240px">
      <Section>
        <ButtonLink to="/inventory" onClick={onClose}>
          Inventory
        </ButtonLink>
      </Section>
      {/*<Section title="RefSeq" hasSeparator>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/genes">*/}
      {/*    Genes*/}
      {/*  </ButtonLink>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/rnas">*/}
      {/*    RNAs*/}
      {/*  </ButtonLink>*/}
      {/*  /!*<ButtonLink onClick={onClose} to="/genome/proteins">*!/*/}
      {/*  /!*  Proteins*!/*/}
      {/*  /!*</ButtonLink>*!/*/}
      {/*</Section>*/}
      {/*<Section title="Genome browser" hasSeparator>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/browser">*/}
      {/*    IGV*/}
      {/*  </ButtonLink>*/}
      {/*</Section>*/}
    </MenuGroup>
  );
};

const ReagentContent = (): JSX.Element => {
  const match1 = useMatch('/inventory');
  const match2 = useMatch('/reagents/*');

  return (
    <span>
      <PrimaryDropdown
        content={Reagent}
        text="Reagents"
        isHighlighted={!!match1 || !!match2}
      />
    </span>
  );
};

export default ReagentContent;
