import React from 'react';
import Button from '@atlaskit/button';

interface Props {
  expandAll: boolean;
  toggleExpandAll: () => void;
  marginBottom?: number;
}

function ExpandAllButton({ expandAll, toggleExpandAll, marginBottom = 8 }: Props): JSX.Element {
  return (
    <div
      style={{
        fontSize: '11px',
        marginLeft: '10px',
        display: 'inline-block',
        width: '84px',
      }}
    >
      <Button
        appearance="subtle"
        spacing="compact"
        isSelected={expandAll}
        onClick={toggleExpandAll}
        shouldFitContainer
        style={{ marginBottom: `${marginBottom}px` }}
      >
        {`${expandAll ? 'Collapse' : 'Expand'} all`}
      </Button>
    </div>
  );
}

export default ExpandAllButton;
