import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { ButtonGroup } from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { colors } from '@atlaskit/theme';

import { useGetAllSkusQuery, SkuConnection, SkuSortField, Sku } from 'apollo/graphql';
import useSearch from 'hooks/use-search';
import usePagination from 'hooks/use-pagination';
import { AscOrDesc } from 'components/tables/create-table-head';
import InventoryList from 'components/reagents/inventory-list';

export interface SkuOrderBy {
  field: Exclude<keyof Sku, '__typename'>;
  order: AscOrDesc;
}

function Inventory(): JSX.Element {
  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 50,
    defaultTotalCount: 35,
    defaultFilteredCount: 35,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<SkuOrderBy[]>([
    // { field: 'default' as SkuOrderBy['field'], order: 'ASC' },
    { field: 'id', order: 'ASC' },
    { field: 'productCode', order: 'ASC' },
  ]);

  const { loading, data, previousData } = useGetAllSkusQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
      },
      orderBy: orderByList.map(orderBy => `${orderBy.field}_${orderBy.order}` as SkuSortField),
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    updatePagination<SkuConnection>(data?.skus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <RowsSelect />
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
    </div>
  );

  const skus = data ? data.skus.edges : previousData?.skus.edges || [];

  return (
    <Page>
      <Helmet title="Inventory" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Inventory
          </PageHeader>

          <InventoryList
            skus={skus}
            isLoading={loading}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Inventory;
