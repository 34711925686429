import React, { useEffect, useState } from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import { layers } from '@atlaskit/theme';

import { useGetAllSugarsQuery } from 'apollo/graphql';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props {
  setSugarIds: Function;
  setCurrentPage: Function;
}

function SugarMultiSelect({ setSugarIds, setCurrentPage }: Props): JSX.Element {
  const [sugars, setSugars] = useState<SelectOptionStringValueType[]>([]);

  useEffect(() => {
    setSugarIds(sugars.map(({ value }) => value));
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sugars]);

  const { data } = useGetAllSugarsQuery({
    ssr: false,
    fetchPolicy: 'network-only',
  });

  const allSugars = data?.sugars.edges || [];

  return (
    <div
      style={{
        zIndex: layers.card(),
        marginLeft: '16px',
        flexWrap: 'nowrap',
      }}
    >
      <CheckboxSelect
        spacing="compact"
        options={allSugars.map(({ node: { id, name } }) => ({ label: name, value: id }))}
        placeholder="All sugars"
        // @ts-ignore
        value={sugars}
        // @ts-ignore
        isMulti
        onChange={((value: any): void => {
          setSugars(value ? value as SelectOptionStringValueType[] : []);
        })}
        styles={{
          control: base => ({
            ...base,
            minWidth: '200px',
            maxWidth: '500px',
          }),
        }}
      />
    </div>
  );
}

export default SugarMultiSelect;
