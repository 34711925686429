import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { ButtonGroup } from '@atlaskit/button';

import {
  OrganismSelect,
  Rna,
  RnaConnection,
  RnaSortField,
  RnaWhereInput,
  useGetAllRnasQuery,
} from 'apollo/graphql';
import OrganismsSelect, { OrganismSelectOptionType } from 'components/genome/organism-select';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import ModelExclude from 'components/genome/model-exclude';
import RnaList from 'components/genome/rna-list';
import ExpandAllButton from 'components/tables/expand-all-button';

export interface RnaOrderBy {
  field: Exclude<keyof Rna, '__typename'>;
  order: AscOrDesc;
}

function Rnas(): JSX.Element {
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const toggleExpandAll = () => setExpandAll(prevExpandAll => !prevExpandAll);

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 20,
    defaultTotalCount: 175131,
    defaultFilteredCount: 82647,
    defaultPageCount: 4133,
  });

  // Setup filters.
  const [excludeModel, setExcludeModel] = useState<boolean>(true);
  const [organism, setOrganism] = useState<OrganismSelectOptionType>({ label: 'Human', value: OrganismSelect.Human });
  const [filters, setFilters] = useState<Omit<RnaWhereInput, 'excludeModel' | 'organism'>>({
    id: '',
    symbol: '',
    title: '',
    chrNo: '',
    chrStart: '',
    chrEnd: '',
    chrStrand: '',
    rnaLength: '',
    exonCount: '',
    mrnaLength: '',
    utr5Length: '',
    utr3Length: '',
  });

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<RnaOrderBy[]>([{ field: 'id', order: 'ASC' }]);

  const { loading, data, previousData } = useGetAllRnasQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        excludeModel,
        ...(organism.value && { organism: organism.value }),
        ...filters,
      },
      orderBy: orderByList.map(orderBy => `${orderBy.field}_${orderBy.order}` as RnaSortField),
    },
  });

  useEffect(() => {
    updatePagination<RnaConnection>(data?.rnas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <ModelExclude
        exclude={excludeModel}
        setExcludeModel={setExcludeModel}
        setCurrentPage={setCurrentPage}
      />
      <OrganismsSelect
        organism={organism}
        setOrganism={setOrganism}
        setCurrentPage={setCurrentPage}
      />
      <RowsSelect />
    </ButtonGroup>
  );

  return (
    <Page>
      <Helmet title="RefSeq RNAs" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent}>
            RefSeq RNAs
            <ExpandAllButton expandAll={expandAll} toggleExpandAll={toggleExpandAll} />
          </PageHeader>

          <RnaList
            rnas={data?.rnas.edges || previousData?.rnas.edges || []}
            isLoading={loading}
            setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
            expandAll={expandAll}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Rnas;
