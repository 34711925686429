import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { PDFViewer } from '@react-pdf/renderer';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';

import {
  GetSampleDocument,
  Oligo,
  useGetSampleQuery,
  useUpdateSampleMutation,
} from 'apollo/graphql';
import useOrders from 'hooks/use-orders';
import { useAuth } from 'contexts/auth';
import OligoSpecSheet from 'components/pdfs/oligo-spec-sheet';
import { setAlert } from 'redux/alerts';

function SampleOverview() {
  const { currentUser } = useAuth()!;
  useOrders();
  const navigate = useNavigate();
  const { sampleId } = useParams();
  const dispatch = useDispatch();

  const [amount, setAmount] = useState<number>(0);
  const [image, setImage] = useState<Blob | null>(null);
  const [imageCount, setImageCount] = useState<number>(0);
  const reset = () => {
    setImage(null);
    setImageCount(prevCount => prevCount + 1);
  };

  const { data } = useGetSampleQuery({
    variables: {
      id: sampleId!,
    },
  });

  const [update] = useUpdateSampleMutation({
    onCompleted: ({ updateSample: { success, message } }) => {
      dispatch(
        setAlert({
          title: message,
          icon: success ? (
            <SuccessIcon primaryColor={colors.G300} label="Success"/>
          ) : (
            <ErrorIcon primaryColor={colors.R400} label="Error"/>
          ),
        }),
      );
      if (success) reset();
    },
    refetchQueries: [GetSampleDocument],
  });

  const breadcrumbs = (
    <Breadcrumbs>
      <BreadcrumbsItem text="Samples" onClick={() => navigate('/samples')}/>
      <BreadcrumbsItem text={sampleId!} onClick={() => {}}/>
    </Breadcrumbs>
  );

  return (
    <Page>
      <div
        style={{
          display: 'flex',
          gap: '32px',
          alignItems: 'flex-start',
          marginRight: '16px',
          justifyContent: 'flex-start',
        }}
      >
        <PDFViewer
          style={{
            marginTop: '16px',
            width: '950px',
            height: `calc(100vh - 90px)`,
          }}
        >
          {data?.sample?.type === 'oligo' ? (
            <OligoSpecSheet
              author={currentUser?.username}
              id={sampleId}
              oligo={data?.sample?.oligo as Oligo}
              maldi={data?.sample?.maldi || undefined}
              amount={amount}
            />
          ) : (
            <>{data?.sample?.type}</>
          )}
        </PDFViewer>
        <div style={{ minWidth: 'calc(100vw - 1020px)' }}>
          <Form
            onSubmit={(values: { amount: string; }) => {
              setAmount(parseFloat(values.amount));
              if (image) {
                update({
                  variables: {
                    id: sampleId!,
                    maldi: image,
                  },
                });
              }
            }}
          >
            {({ formProps }) => (
              <form {...formProps} noValidate style={{ maxWidth: '900px' }}>
                <PageHeader breadcrumbs={breadcrumbs}>{sampleId}</PageHeader>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  <div style={{ width: '90px', flexShrink: 0 }}>
                    <Field
                      name="amount"
                      label=""
                      defaultValue={amount}
                      // validate={(value) => {
                      //   if (!value) return 'Unit missing';
                      // }}
                    >
                      {({ fieldProps, error }) => (
                        <div style={{ width: '120px' }}>
                          <Textfield
                            {...fieldProps}
                            type="number"
                            min={0}
                            autoComplete="off"
                            placeholder={'\u2212'}
                            elemAfterInput={<span style={{ width: '60px' }}>nmol</span>}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </div>
                      )}
                    </Field>
                  </div>
                  {data?.sample?.maldi ?
                    <img src={`data:image/*;base64,${data.sample.maldi}`} style={{ width: '100%' }}/> : null}
                  <input
                    key={imageCount}
                    type="file"
                    accept="image/*"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files) {
                        const file = e.target.files[0];
                        const maxSize = 512 * 1024;  // 512 kB
                        if (file && file.size < maxSize) {
                          setImage(file);
                        } else {
                          reset();
                        }
                      }
                      // const handleFileChosen = (file: File) => {
                      //   const fileReader = new FileReader();
                      //   fileReader.onloadend = () => {
                      //     console.log(fileReader.result);
                      //   };
                      //   if (file) fileReader.readAsText(file);
                      //   if (!file) {}
                      // };
                      //
                      // if (e.target.files?.length) {
                      //   handleFileChosen(e.target.files[0]);
                      //   // readFile({
                      //   //   variables: {
                      //   //     file: e.target.files[0],
                      //   //   },
                      //   // });
                      //   // console.log(e.target.files[0]);
                      // }
                    }}
                  />
                </div>
                <FormFooter>
                  <Button
                    type="submit"
                    appearance="primary"
                    // isDisabled={debouncedItems.length === 0}
                  >
                    <span style={{ fontWeight: 400 }}>Update</span>
                  </Button>
                </FormFooter>
              </form>
            )}
          </Form>
        </div>
      </div>
    </Page>
  );
}

export default SampleOverview;
