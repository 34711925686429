import Badge from '@atlaskit/badge';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { antisenseCartItemsVar, sirnaCartItemsVar } from 'apollo/cache';

import { OrganismSelect, TargetSortField, useGetAllTargetsQuery } from 'apollo/graphql';
import { DrawerType } from 'components/drawers/drawer-manager';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { openDrawer } from 'redux/drawers';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Antisense = ({ onClose }: ContentProps): JSX.Element => {
  const dispatch = useDispatch();

  useGetAllTargetsQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: {
        search: '',
        organism: OrganismSelect.Human,
      },
      orderBy: ['symbol_ASC', 'spliced_DESC'] as TargetSortField[],
    },
    fetchPolicy: 'network-only',
  });

  return (
    <MenuGroup minWidth="240px">
      <Section title="Targets">
        <ButtonLink to="/antisense/targets" onClick={onClose}>
          View all targets
        </ButtonLink>
        <ButtonItem
          onClick={() => {
            onClose();
            dispatch(openDrawer(DrawerType.CreateTargetDrawer));
          }}
        >
          Create target
        </ButtonItem>
        {/*<Section title="Oligos">*/}
        {/*  <ButtonLink to="/oligos" onClick={onClose}>*/}
        {/*    View all oligos*/}
        {/*  </ButtonLink>*/}
        {/*  <ButtonItem*/}
        {/*    onClick={() => {*/}
        {/*      onClose();*/}
        {/*      // dispatch(openDrawer(DrawerType.CreateTargetDrawer));*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Create oligo*/}
        {/*  </ButtonItem>*/}
        {/*</Section>*/}
      </Section>
      <Section hasSeparator>
        <ButtonItem
          onClick={() => {
            onClose();
            dispatch(openDrawer(DrawerType.AntisenseCartDrawer));
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>View antisense cart</span>
            <Badge appearance="added">{antisenseCartItemsVar().length}</Badge>
          </div>
        </ButtonItem>
        <ButtonItem
          onClick={() => {
            onClose();
            dispatch(openDrawer(DrawerType.SirnaCartDrawer));
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>View siRNA cart</span>
            <Badge appearance="added">{sirnaCartItemsVar().length}</Badge>
          </div>
        </ButtonItem>
      </Section>
    </MenuGroup>
  );
};

const AntisenseContent = (): JSX.Element => {
  const match1 = useMatch('/antisense/*');
  const match2 = useMatch('/targets/*');
  // const match3 = useMatch('/oligos/*');

  return (
    <PrimaryDropdown
      content={Antisense}
      text="Antisense"
      isHighlighted={!!match1 || !!match2}
    />
  );
};

export default AntisenseContent;
