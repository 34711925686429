import { DrawerType } from 'components/drawers/drawer-manager';
import { Drawer, DrawerActionType } from './types';

interface OpenDrawerAction {
  type: DrawerActionType.OPEN_DRAWER;
  payload: Omit<Drawer, 'isOpen'>;
}

export interface DrawerProps {}

export const openDrawer = (drawerType: DrawerType, drawerProps: DrawerProps = {}): OpenDrawerAction => ({
  type: DrawerActionType.OPEN_DRAWER,
  payload: { drawerType, ...drawerProps },
});

interface CloseDrawerAction {
  type: DrawerActionType.CLOSE_DRAWER;
}

export const closeDrawer = (): CloseDrawerAction => ({
  type: DrawerActionType.CLOSE_DRAWER,
});

export type DrawerAction = OpenDrawerAction | CloseDrawerAction;
