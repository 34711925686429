import Lozenge from '@atlaskit/lozenge';
import React from 'react';
// import Lozenge from '@atlaskit/lozenge';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { Sample, SampleEdge } from 'apollo/graphql';
import { SampleOrderBy } from 'pages/production/samples';
import OrderTypeLozenge from '../order/order-type-lozenge';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';
import SampleCheckbox from './sample-checkbox';

// import TableLink from '../tables/table-link';

interface Props {
  samples: SampleEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: SampleOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function ProductionList({
  samples,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      {
        isSortable: false,
        width: 1,
        shouldTruncate: false,
        key: "checkbox",
        colSpan: 1,
        content: "",
      },
      createTableHead<Sample>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 3,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<Sample>({
      //   fieldKey: 'type',
      //   fieldName: 'Type',
      //   width: 2,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<Sample>({
      //   fieldKey: 'name',
      //   fieldName: 'Name',
      //   width: 2,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'oligoId',  // TODO: Make key composite of oligoId + peptideId ...
        colSpan: 1,
        content: 'Sample name',
      },
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'productId',
        colSpan: 1,
        content: 'Product description',
      },
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={samples.map(({ node: { id, name, type, status, product, oligo, asOligo, ssOligo, isInCart } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <SampleCheckbox
                  key={id}
                  id={id}
                  isInCart={false}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <span style={{ paddingLeft: '4px' }}>
                  <TableLink to={`/samples/${id}`}>{id}</TableLink>&nbsp;&nbsp;&nbsp;
                  <OrderTypeLozenge orderType={type} />
                </span>
              )
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: name || '\u2013',
            // },
            {
              key: id,
              colSpan: 1,
              content: name ?? oligo?.name ?? (asOligo && ssOligo ? `${asOligo.name} / ${ssOligo.name}` : '\u2013')
              // content: name ?? (oligo ? oligo.name : asOligo && ssOligo ? `${asOligo.name} / ${ssOligo.name}` : '\u2013'),
            },
            {
              key: id,
              colSpan: 1,
              content: product ? product.name : '\u2013',
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No samples found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default ProductionList;
