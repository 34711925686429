import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import Button, { ButtonGroup } from '@atlaskit/button';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import SearchIcon from '@atlaskit/icon/glyph/search';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';

import { Oligo, OligoConnection, OligoSortField, useGetAllOligosQuery } from 'apollo/graphql';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import { DrawerType } from 'components/drawers/drawer-manager';
import OligoList from 'components/oligo/oligo-list';
import { PanelType } from 'components/panels/panel-manager';
import usePanel from 'hooks/use-panel';
import { openDrawer } from 'redux/drawers';

export interface OligoOrderBy {
  field: Exclude<keyof Oligo, '__typename'>;
  order: AscOrDesc;
}

function Oligos(): JSX.Element {
  const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 100,
    defaultTotalCount: 0,
    defaultFilteredCount: 0,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<OligoOrderBy[]>([{ field: 'id', order: 'ASC' }]);

  const { isPanelOpen, mountPanel, unmountPanel, togglePanel } = usePanel({
    panelType: PanelType.OligoFilter,
  });

  useEffect(() => {
    mountPanel(isPanelOpen);

    return () => {
      unmountPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, data, previousData } = useGetAllOligosQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as OligoSortField),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    updatePagination<OligoConnection>(data?.oligos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <RowsSelect />
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateOligoDrawer))}
      >
        <span style={{ fontWeight: 400 }}>
          Create oligo
        </span>
      </Button>
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 244px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
      <div style={{ flex: '0 0 164px', marginLeft: '16px', zIndex: 3 }}>
        <Select
          // value={}
          onChange={() => {}}
          options={[]}
          spacing="compact"
          placeholder="antisense / siRNA"
          aria-label="Choose an option"
        />
      </div>
      <div style={{ marginLeft: '4px' }}>
        <Button
          appearance="subtle"
          isSelected={isPanelOpen}
          iconBefore={<FilterIcon label="Open filter" />}
          onClick={togglePanel}
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Oligos" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Oligos
          </PageHeader>

          <OligoList
            oligos={data?.oligos.edges || previousData?.oligos.edges || []}
            isLoading={loading}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Oligos;
