import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NProgress from 'nprogress';

const useNprogress = () => {
  const location = useLocation();

  useEffect(() => {
    NProgress.done();

    return () => {
      NProgress.start();
    };
  }, [location]);
};

export default useNprogress;
