import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';

function Editor(): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const composer = new window.Kekule.Editor.Composer(ref.current);
    composer.setPredefinedSetting('fullFunc');
  }, []);

  return (
    <Page>
      <Helmet title="Home" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <div
            ref={ref}
            style={{
              width: '1400px',
              height: '800px',
              paddingTop: '40px',
            }}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Editor;
