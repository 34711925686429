import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@atlaskit/button';
import Form, { ErrorMessage, Field, FormFooter, HelperMessage } from '@atlaskit/form';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Drawer from '@atlaskit/drawer';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import {
  GetAllCustomersDocument,
  GetAllCustomersQuery,
  GetAllOrdersDocument, GetAllOrderTypesDocument, GetAllOrderTypesQuery, GetAllProductsDocument, GetAllProductsQuery,
  useCreateOrderMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { SelectOptionStringValueType } from 'types/select-option';

interface Props extends DrawerProps {
  isOpen: boolean;
  totalCount?: number;
}

interface OrderItemProps {
  productId?: SelectOptionStringValueType;
  // specs?: string;
  unitPrice: string;
  percDiscount: number;
  quantity: number;
  discount: string;
  discountedPrice: number;
}

function CreateOrderDrawer({ isOpen }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [count, setCount] = useState<number>(0);
  const [items, setItems] = useState<OrderItemProps[]>([{
    productId: undefined,
    // specs: '',
    unitPrice: '0.00',
    percDiscount: 0,
    quantity: 1,
    discount: '0.00',
    discountedPrice: 0,
  }]);
  const reset = () => {
    setCount(prevCount => prevCount + 1);
    setItems([]);
  };

  const [createOrder, { loading }] = useCreateOrderMutation({
    onCompleted: ({ createOrder: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllOrdersDocument],
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="extended"
    >
      <div style={{ paddingRight: '20px' }}>
        <Form
          onSubmit={({
            customer,
            orderType,
            leadTime1,
            leadTime2,
            ...values
          }: { customer: SelectOptionStringValueType; orderType: SelectOptionStringValueType; leadTime1?: string; leadTime2?: string; [key: string]: string | SelectOptionStringValueType | undefined; }) => {
            if (!leadTime1 || !parseInt(leadTime1)) {
              return { leadTime1: 'Lead time 1 must be specified' };
            }
            if (leadTime2 && (parseInt(leadTime1) > parseInt(leadTime2))) {
              return { leadTime2: 'Lead time 2 must be \u2265 lead time 1' };
            }
            const productIds = [...Array(items.length + 1).keys()].slice(1).map((i) => parseInt((values[`product${i - 1}`] as SelectOptionStringValueType).value));
            const specs = [...Array(items.length + 1).keys()].slice(1).map((i) => (values[`specs${i - 1}`] as string).replace('umol', '\u00b5mol'));
            const unitPrices = [...Array(items.length + 1).keys()].slice(1).map((i) => parseFloat(values[`unitPrice${i - 1}`] as string));
            const percDiscounts = [...Array(items.length + 1).keys()].slice(1).map((i) => parseInt(values[`percDiscount${i - 1}`] as string));
            const quantities = [...Array(items.length + 1).keys()].slice(1).map((i) => parseInt(values[`quantity${i - 1}`] as string));
            const discounts = [...Array(items.length + 1).keys()].slice(1).map((i) => parseFloat(values[`discount${i - 1}`] as string));
            const discountedPrices = [...Array(items.length + 1).keys()].slice(1).map((i) => items[i - 1].percDiscount || parseFloat(items[i - 1].discount)
              ? parseFloat(items[i - 1].unitPrice) * (1 - items[i - 1].percDiscount / 100) * items[i - 1].quantity - parseFloat(items[i - 1].discount)
              : -1);

            createOrder({
              variables: {
                customerId: customer.value,
                orderType: orderType.value,
                leadTime: [parseInt(leadTime1!), parseInt(leadTime2 || leadTime1!)],
                productIds,
                specs,
                unitPrices,
                percDiscounts,
                quantities,
                discounts,
                discountedPrices,
              },
            });
          }}
          key={count}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                <div style={{ width: '240px' }}>
                  <Field<ValueType<SelectOptionStringValueType>>
                    name="customer"
                    label="Customer"
                    isRequired
                    validate={(value) => {
                      if (!value?.value) return 'Select a customer';
                    }}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <AsyncSelect
                          {...fieldProps}
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue: string) => client.query<GetAllCustomersQuery>({
                            query: GetAllCustomersDocument,
                            variables: {
                              filter: {
                                search: inputValue,
                              },
                            },
                            fetchPolicy: 'network-only',
                          })
                            .then(({ data }) => {
                              const customers = data?.customers.edges || [];

                              return customers.map(({ node: { id, fullName } }) => ({
                                label: fullName,
                                value: id,
                              }));
                            })}
                          placeholder="Select customer"
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        {!error && <HelperMessage>&nbsp;</HelperMessage>}
                      </>
                    )}
                  </Field>
                </div>
                <div style={{ width: '180px' }}>
                  <Field<ValueType<SelectOptionStringValueType>>
                    name="orderType"
                    label="Order type"
                    isRequired
                    validate={(value) => {
                      if (!value?.value) return 'Select an order type';
                    }}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <AsyncSelect
                          {...fieldProps}
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue: string) => client.query<GetAllOrderTypesQuery>({
                            query: GetAllOrderTypesDocument,
                            variables: {
                              filter: {
                                search: inputValue,
                              },
                            },
                            fetchPolicy: 'network-only',
                          })
                            .then(({ data }) => {
                              const orderTypes = data?.orderTypes.edges || [];

                              return orderTypes.map(({ node: { name } }) => ({
                                label: name,
                                value: name,
                              }));
                            })}
                          placeholder="Select order type"
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        {!error && <HelperMessage>&nbsp;</HelperMessage>}
                      </>
                    )}
                  </Field>
                </div>
                <Field
                  name="leadTime1"
                  label="Lead time 1"
                  defaultValue=""
                  isRequired
                  validate={(value) => {
                    if (!value || !parseInt(value)) {
                      return 'Lead time must be specified';
                    }
                  }}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield
                        {...fieldProps}
                        autoComplete="off"
                        placeholder={'\u2013'}
                        type="number"
                        min={1}
                        max={90}
                        elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>days</span>}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                      {!error && <HelperMessage>&nbsp;</HelperMessage>}
                    </>
                  )}
                </Field>
                <Field
                  name="leadTime2"
                  label="Lead time 2"
                  defaultValue=""
                  // validate={(value) => {}}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <Textfield
                        {...fieldProps}
                        autoComplete="off"
                        placeholder={'\u2013'}
                        type="number"
                        min={1}
                        max={90}
                        elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>days</span>}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                      {!error && <HelperMessage>&nbsp;</HelperMessage>}
                    </>
                  )}
                </Field>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                paddingTop: '8px',
                marginTop: '10px',
                borderTop: '1px solid #6B778C',
              }}>
                <input
                  type="number"
                  max={24}
                  placeholder={items.length.toString()}
                  value={items.length}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newItemCount = parseInt(event.target.value);
                    if (newItemCount < items.length) {
                      setItems(prevItems => prevItems.slice(0, newItemCount));
                    } else if (newItemCount > items.length) {
                      setItems(prevItems => [...prevItems, {
                        productId: undefined,
                        // specs: '',
                        unitPrice: '0.00',
                        percDiscount: 0,
                        quantity: 1,
                        discount: '0.00',
                        discountedPrice: 0,
                      }]);
                    }
                  }}
                  style={{ width: '36px', margin: '4px 8px 0', padding: '4px 5px' }}
                />
                items
              </div>
              <div>
                {items.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: '12px',
                    }}
                  >
                    <div style={{ flexGrow: 1, width: '300px' }}>
                      <Field<ValueType<SelectOptionStringValueType>>
                        name={`product${i}`}
                        label={i === 0 ? 'Product' : ''}
                        isRequired
                        defaultValue={items[i].productId}
                        validate={() => {
                          if (!items[i].productId?.value) return 'Select a product';
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <AsyncSelect
                              {...fieldProps}
                              onChange={(value) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return { ...item, productId: value || undefined };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              // appearance={'subtle'}
                              cacheOptions
                              defaultOptions
                              loadOptions={(inputValue: string) => client.query<GetAllProductsQuery>({
                                query: GetAllProductsDocument,
                                variables: {
                                  filter: {
                                    search: inputValue,
                                  },
                                },
                                fetchPolicy: 'network-only',
                              })
                                .then(({ data }) => {
                                  const products = data?.products.edges || [];

                                  return products.map(({ node: { id, name } }) => ({
                                    label: name,
                                    value: id,
                                  }));
                                })}
                              placeholder="Select product"
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '160px' }}>
                      <Field
                        name={`specs${i}`}
                        label={i === 0 ? 'Specs' : ''}
                        defaultValue=""
                        // validate={(value) => {
                        //   if (!value) return 'Specs is missing';
                        // }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '128px' }}>
                      <Field
                        name={`unitPrice${i}`}
                        label={i === 0 ? 'Unit price' : ''}
                        isRequired
                        defaultValue={items[i].unitPrice}
                        validate={(value) => {
                          if (!(parseFloat(items[i].unitPrice) > 0)) return 'Unit price must be greater than 0';
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      unitPrice: event.target.value || '0.00',
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      unitPrice: parseFloat(item.unitPrice).toFixed(2),
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                              type="number"
                              min={0.00}
                              step={0.01}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '128px' }}>
                      <Field
                        name={`percDiscount${i}`}
                        label={i === 0 ? '% discount' : ''}
                        defaultValue={items[i].percDiscount}
                        // validate={(value) => {
                        //   if (parseFloat(items[i].discount)) {
                        //     return '% discount cannot be applied'
                        //   }
                        // }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              // value={items[i].percDiscount}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      percDiscount: event.target.value ? parseInt(event.target.value) : 0,
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                              type="number"
                              min={0}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '80px' }}>
                      <Field
                        name={`quantity${i}`}
                        label={i === 0 ? 'Quantity' : ''}
                        isRequired
                        defaultValue={items[i].quantity}
                        validate={(value) => {
                          if (!value) return 'Quantity is missing';
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      quantity: event.target.value ? parseInt(event.target.value) : 1,
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                              type="number"
                              min={1}
                              max={20}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '128px' }}>
                      <Field
                        name={`discount${i}`}
                        label={i === 0 ? 'Discount' : ''}
                        defaultValue={items[i].discount}
                        // validate={(value) => {
                        //   if (!value) return 'Discount is missing';
                        // }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      discount: event.target.value || '0.00',
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                setItems(items.map((item, index) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      discount: parseFloat(item.discount).toFixed(2),
                                    };
                                  } else {
                                    return item;
                                  }
                                }));
                              }}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                              type="number"
                              min={0.00}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                    <div style={{ flexGrow: 1, width: '128px' }}>
                      <Field
                        name={`discountedPrice${i}`}
                        label={i === 0 ? 'Discounted price' : ''}
                        // defaultValue=""
                        // validate={(value) => {
                        //   if (!value) return 'Discounted price is missing';
                        // }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <Textfield
                              {...fieldProps}
                              value={(parseFloat(items[i].unitPrice) * (1 - items[i].percDiscount / 100) * items[i].quantity - parseFloat(items[i].discount)).toFixed(2)}
                              // appearance={'subtle'}
                              autoComplete="off"
                              placeholder={'\u2013'}
                              type="number"
                              min={0.00}
                              step={0.01}
                            />
                            {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                ))}
              </div>
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                  isDisabled={items.length === 0}
                >
                <span style={{ fontWeight: 400 }}>
                  Submit
                </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateOrderDrawer;
