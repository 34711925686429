import React from 'react';
import { MenuGroup, Section } from '@atlaskit/menu';
import { useMatch } from 'react-router-dom';

import { SampleSortField, useGetAllSamplesLazyQuery } from 'apollo/graphql';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Production = ({ onClose }: ContentProps): JSX.Element => {
  return (
    <MenuGroup minWidth="240px">
      <Section>
        <ButtonLink to="/samples" onClick={onClose}>
          View all samples
        </ButtonLink>
        <ButtonLink to="/production" onClick={onClose}>
          View current production
        </ButtonLink>
      </Section>
      {/*<Section title="RefSeq" hasSeparator>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/genes">*/}
      {/*    Genes*/}
      {/*  </ButtonLink>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/rnas">*/}
      {/*    RNAs*/}
      {/*  </ButtonLink>*/}
      {/*  /!*<ButtonLink onClick={onClose} to="/genome/proteins">*!/*/}
      {/*  /!*  Proteins*!/*/}
      {/*  /!*</ButtonLink>*!/*/}
      {/*</Section>*/}
      {/*<Section title="Genome browser" hasSeparator>*/}
      {/*  <ButtonLink onClick={onClose} to="/genome/browser">*/}
      {/*    IGV*/}
      {/*  </ButtonLink>*/}
      {/*</Section>*/}
    </MenuGroup>
  );
};

const ProductionContent = (): JSX.Element => {
  const match1 = useMatch('/samples');
  const match2 = useMatch('/samples/*');
  const match3 = useMatch('/production');

  const [getAllSamples] = useGetAllSamplesLazyQuery({
    variables: {
      page: 1,
      perPage: 50,
      filter: {
        search: '',
      },
      orderBy: [SampleSortField.IdAsc],
    },
  });

  return (
    <span
      onMouseOver={() => {
        getAllSamples();
      }}
    >
      <PrimaryDropdown
        content={Production}
        text="Production"
        isHighlighted={!!match1 || !!match2 || !!match3}
      />
    </span>
  );
};

export default ProductionContent;
