import React from 'react';
import Select, { ValueType } from '@atlaskit/select';

import { OrderStatusSelect } from 'apollo/graphql';
import { SelectOptionEnumType } from 'types/select-option';

export type OrderStatusSelectOptionType = SelectOptionEnumType<OrderStatusSelect>;

const options: OrderStatusSelectOptionType[] = [
  { label: 'All statuses', value: OrderStatusSelect.None },
  { label: 'Active', value: OrderStatusSelect.Active },
  { label: 'Completed', value: OrderStatusSelect.Completed },
  { label: 'Pending', value: OrderStatusSelect.Pending },
  { label: 'Modified', value: OrderStatusSelect.Modified },
  { label: 'On hold', value: OrderStatusSelect.Onhold },
  { label: 'Void', value: OrderStatusSelect.Void },
];

interface Props {
  status: OrderStatusSelectOptionType;
  setStatus: (value: OrderStatusSelectOptionType) => void;
  setCurrentPage?: (num: number) => void;
}

function OrderStatusesSelect({
  status,
  setStatus,
  setCurrentPage,
}: Props): JSX.Element {
  const onOrderStatusSelectChange = (value: ValueType<OrderStatusSelectOptionType>): void => {
    if (value && value.value !== status.value) {
      setStatus(value);
      if (setCurrentPage) setCurrentPage(1);
    }
  };

  return (
    <div style={{ zIndex: 3 }}>
      <Select
        spacing="compact"
        options={options}
        value={status}
        onChange={onOrderStatusSelectChange}
        placeholder={status.label}
        aria-label={status.label}
        styles={{ control: base => ({ ...base, width: '124px' }) }}
      />
    </div>
  );
}

export default OrderStatusesSelect;
