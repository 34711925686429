import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import React, { useState } from 'react';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Button from '@atlaskit/button/standard-button';
import Popup from '@atlaskit/popup';
import { useDispatch } from 'react-redux';

import { GetAllOrdersDocument, useCreateDeliveryMutation } from 'apollo/graphql';
import { setAlert } from 'redux/alerts';

interface Props {
  orderId: number;
}

const CreateDeliveryPopup = ({ orderId }: Props) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const [createDelivery] = useCreateDeliveryMutation({
    onCompleted: ({ createDelivery: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    refetchQueries: [GetAllOrdersDocument],
  });

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="left"
      content={() => (
        <MenuGroup>
          <Section>
            <ButtonItem
              onClick={() => {
                onClose();
                createDelivery({ variables: { orderId } });
              }}
            >
              Create new delivery
            </ButtonItem>
          </Section>
        </MenuGroup>
      )}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          spacing="none"
          appearance="subtle-link"
          // isSelected={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ paddingTop: '3px' }}>
            <MoreVerticalIcon label="" />
          </div>
        </Button>
      )}
    />
  );
};

export default CreateDeliveryPopup;
