import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductHome } from '@atlaskit/atlassian-navigation';
import { JiraSoftwareIcon } from '@atlaskit/logo';

import NasBioLogo from 'components/logos/nasbio-logo';

const Brand = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div style={{ maxHeight: '32px' }}>
      <ProductHome
        onClick={() => setTimeout(() => navigate('/'))}
        icon={JiraSoftwareIcon}
        logo={NasBioLogo}
      />
    </div>
  );
};

export default Brand;
