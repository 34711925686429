import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { useAuth } from './contexts/auth';
import store from './redux/store';
import ApolloProviderWithAuth from './apollo/apollo-client';
import Loader from './layouts/loader';
import AuthenticatedApp from './layouts/authenticated-app';
import UnauthenticatedApp from './layouts/unauthenticated-app';

const App = (): JSX.Element => {
  const { checkingSession, isAuthenticated } = useAuth()!;

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | NASBio"
        defaultTitle="NASBio Web Services"
      />

      <Provider store={store}>
        <ApolloProviderWithAuth>
          {checkingSession ? <Loader /> : isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </ApolloProviderWithAuth>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
