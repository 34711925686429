import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';

import { removeAlert, selectAlerts } from '../redux/alerts';

const Alerts: React.FC = (): JSX.Element => {
  const alerts = useSelector(selectAlerts);
  const dispatch = useDispatch();

  const renderAlerts = (): JSX.Element[] => alerts.map(({
    id,
    title,
    msg = '',
    icon,
    appearance,
  }) => (
    <AutoDismissFlag
      appearance={appearance}
      icon={icon}
      id={id}
      key={id}
      title={title}
      description={msg}
    />
  ));

  return (
    <FlagGroup onDismissed={() => dispatch(removeAlert())}>
      {renderAlerts()}
    </FlagGroup>
  );
};

export default Alerts;
