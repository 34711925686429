/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { RightSidePanel } from '@atlaskit/right-side-panel';
import Button, { ButtonGroup } from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Select, { ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import Slider from 'rc-slider';

import { SelectOptionStringValueType } from 'types/select-option';
import { closePanel } from 'redux/panels';
import N5Exclude from '../antisense/n5-exclude';
import FilterInputLabel from './filter-input-label';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface Props {
  isOpen: boolean;
  setCurrentPage: (num: number) => void;
  targetIdRnaId: string;  // Id or rnaId of the target.
  custom: boolean;
  spliced: boolean;
  targetLength: number;
  length: number;
  startMin: number;
  setStartMin: Function;
  startMax: number;
  setStartMax: Function;
  exonCount: number;
  setExonId: Function;
  setIntronId: Function;
  setRegion: Function;
  setAtContent: Function;
  setGcContent: Function;
  setCpgCount: Function;
  tmMin: number;
  setTmMin: Function;
  tmMax: number;
  setTmMax: Function;
  setHairpin: Function;
  setBefore5: Function;
  setBefore10: Function;
  setBefore20: Function;
  setBefore40: Function;
  setFront8: Function;
  setMid8: Function;
  setMid16: Function;
  setBack8: Function;
  setAfter5: Function;
  setAfter10: Function;
  setAfter20: Function;
  setAfter40: Function;
  setExcludeN5: Function;
  setBlastMatches: Function;
  setBlastNextBestLength: Function;
}

function AntisenseSequenceFilter({
  isOpen,
  setCurrentPage,
  targetIdRnaId,
  custom,
  spliced,
  targetLength,
  length,
  startMin,
  setStartMin,
  startMax,
  setStartMax,
  exonCount,
  setExonId,
  setIntronId,
  setRegion,
  setAtContent,
  setGcContent,
  setCpgCount,
  tmMin,
  setTmMin,
  tmMax,
  setTmMax,
  setHairpin,
  setBefore5,
  setBefore10,
  setBefore20,
  setBefore40,
  setFront8,
  setMid8,
  // setMid16,
  setBack8,
  setAfter5,
  setAfter10,
  setAfter20,
  setAfter40,
  setExcludeN5,
  setBlastMatches,
  setBlastNextBestLength,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const defaults = {
    atValue: '70',
    gcValue: '50',
    cpgValue: '1',
    tmRange: [39, 59],
    hairpinValue: '1.1',
    before5Value: '-5.4',
    before10Value: '-4.6',
    before20Value: '-4.3',
    after5Value: '-3.6',
    after10Value: '-4.1',
    after20Value: '-3.3',
    back8Value: '-4.4',
    blastValue: '1',
  };

  const [startRange, setStartRange] = useState<[number, number]>([startMin, startMax]);
  const [exonIntronOperator, setExonIntronOperator] = useState<SelectOptionStringValueType>({ label: '=', value: '=' });
  const [exonIntronNumber, setExonIntronNumber] = useState('');
  const [regionValue, setRegionValue] = useState<SelectOptionStringValueType>({ label: '\u2212', value: '' });
  const [atOperator, setAtOperator] = useState<SelectOptionStringValueType>({ label: '<=', value: '<=' });
  const [atValue, setAtValue] = useState<string>('');
  const [gcOperator, setGcOperator] = useState<SelectOptionStringValueType>({ label: '<=', value: '<=' });
  const [gcValue, setGcValue] = useState<string>('');
  const [cpgOperator, setCpgOperator] = useState<SelectOptionStringValueType>({ label: '<=', value: '<=' });
  const [cpgValue, setCpgValue] = useState<string>('');
  const [maxCpgValue, setMaxCpgValue] = useState<number>(Math.floor(length / 2));
  const [tmRange, setTmRange] = useState<[number, number]>([tmMin, tmMax]);
  const [hairpinOperator, setHairpinOperator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [hairpinValue, setHairpinValue] = useState<string>('');
  const [before5Operator, setBefore5Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [before5Value, setBefore5Value] = useState<string>('');
  const [before10Operator, setBefore10Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [before10Value, setBefore10Value] = useState<string>('');
  const [before20Operator, setBefore20Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [before20Value, setBefore20Value] = useState<string>('');
  const [before40Operator, setBefore40Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [before40Value, setBefore40Value] = useState<string>('');
  const [front8Operator, setFront8Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [front8Value, setFront8Value] = useState<string>('');
  const [mid8Operator, setMid8Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [mid8Value, setMid8Value] = useState<string>('');
  // const [mid16Operator, setMid16Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  // const [mid16Value, setMid16Value] = useState<string>('');
  const [back8Operator, setBack8Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [back8Value, setBack8Value] = useState<string>('');
  const [after5Operator, setAfter5Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [after5Value, setAfter5Value] = useState<string>('');
  const [after10Operator, setAfter10Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [after10Value, setAfter10Value] = useState<string>('');
  const [after20Operator, setAfter20Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [after20Value, setAfter20Value] = useState<string>('');
  const [after40Operator, setAfter40Operator] = useState<SelectOptionStringValueType>({ label: '>=', value: '>=' });
  const [after40Value, setAfter40Value] = useState<string>('');
  const [excludeHomopolymer, setExcludeHomopolymer] = useState<boolean>(true);
  const [blastOperator, setBlastOperator] = useState<SelectOptionStringValueType>({ label: '=', value: '=' });
  const [blastValue, setBlastValue] = useState<string>('');
  const [nextOperator, setNextOperator] = useState<SelectOptionStringValueType>({ label: '<=', value: '<=' });
  const [nextValue, setNextValue] = useState<string>('');

  useEffect(() => {
    setStartRange([1, targetLength - (length ? length - 1 : 0)]);
  }, [targetLength, length]);

  useEffect(() => {
    const newMaxCpgValue = Math.floor(length / 2);
    setMaxCpgValue(newMaxCpgValue);
    setCpgValue(prevCpgValue => {
      return newMaxCpgValue < parseInt(prevCpgValue) ? newMaxCpgValue.toString() : prevCpgValue;
    });
    setCpgCount((prevCpgCount: string) => {
      if (prevCpgCount) {
        return cpgValue ? `${cpgOperator.value}${cpgValue}` : '';
      } else {
        return '';
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  const reset = (): void => {
    setCurrentPage(1);
    setStartRange([1, targetLength - (length ? length - 1 : 0)]);
    setStartMin(1);
    setStartMax(2304198 - (length ? length - 1 : 0));
    setExonIntronOperator({ label: '=', value: '=' });
    setExonIntronNumber('');
    setExonId('');
    setIntronId('');
    setRegionValue({ label: '\u2212', value: '' });
    setRegion('');
    setAtOperator({ label: '<=', value: '<=' });
    setAtValue(defaults.atValue);
    setAtContent('');
    setGcOperator({ label: '<=', value: '<=' });
    setGcValue(defaults.gcValue);
    setGcContent('');
    setCpgOperator({ label: '<=', value: '<=' });
    setCpgValue(defaults.cpgValue);
    setCpgCount('');
    setTmRange([defaults.tmRange[0], defaults.tmRange[1]]);
    setTmMin(0);
    setTmMax(100);
    setHairpinOperator({ label: '>=', value: '>=' });
    setHairpinValue(defaults.hairpinValue);
    setHairpin('');
    setBefore5Operator({ label: '>=', value: '>=' });
    setBefore5Value(defaults.before5Value);
    setBefore5('');
    setBefore10Operator({ label: '>=', value: '>=' });
    setBefore10Value(defaults.before10Value);
    setBefore10('');
    setBefore20Operator({ label: '>=', value: '>=' });
    setBefore20Value(defaults.before20Value);
    setBefore20('');
    setBefore40Operator({ label: '>=', value: '>=' });
    setBefore40Value('');
    setBefore40('');
    setFront8Operator({ label: '>=', value: '>=' });
    setFront8Value('');
    setFront8('');
    setMid8Operator({ label: '>=', value: '>=' });
    setMid8Value('');
    setMid8('');
    // setMid16Operator({ label: '>=', value: '>=' });
    // setMid16Value('');
    // setMid16('');
    setBack8Operator({ label: '>=', value: '>=' });
    setBack8Value(defaults.back8Value);
    setBack8('');
    setAfter5Operator({ label: '>=', value: '>=' });
    setAfter5Value(defaults.after5Value);
    setAfter5('');
    setAfter10Operator({ label: '>=', value: '>=' });
    setAfter10Value(defaults.after10Value);
    setAfter10('');
    setAfter20Operator({ label: '>=', value: '>=' });
    setAfter20Value(defaults.after20Value);
    setAfter20('');
    setAfter40Operator({ label: '>=', value: '>=' });
    setAfter40Value('');
    setAfter40('');
    setExcludeHomopolymer(true);
    setExcludeN5(false);
    setBlastOperator({ label: '=', value: '=' });
    setBlastValue(defaults.blastValue);
    setBlastMatches('');
    setNextOperator({ label: '<=', value: '<=' });
    setNextValue('');
    setBlastNextBestLength('');
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetIdRnaId, spliced]);

  return (
    <RightSidePanel isOpen={isOpen} attachPanelTo="filter-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '56px',
          borderBottom: '2px solid rgb(235, 236, 240)',
          backgroundColor: 'rgb(250, 251, 252)',
          padding: '0 16px',
        }}>
        <div style={{ lineHeight: '56px', verticalAlign: 'middle' }}>
          <span style={{ fontSize: '18px', fontWeight: 600 }}>Antisense filter</span>
        </div>
        <div style={{ padding: '12px 0' }}>
          <Button
            onClick={() => dispatch(closePanel())}
            appearance="subtle"
            iconBefore={<div style={{ width: '24px' }}><CrossIcon label="Close filter" size="small" /></div>}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
        <PerfectScrollbar
          // style={{ overflowY: 'scroll', height: '500px' }}
          css={css`
            overflow-y: scroll;
            height: calc(100vh - 210px);
            padding: 16px;
          `}
        >
          {/*<div style={{ padding: '16px', overflowX: 'clip', overflowY: 'scroll', height: 'calc(100vh - 210px)' }}>*/}
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <div style={{ fontSize: '0.8rem', marginBottom: '3px' }}>Start min</div>
                <input
                  type="number"
                  placeholder={(startRange[0] || 1).toString()}
                  onFocus={e => {
                    e.persist();
                    if (!e.target.value) {
                      e.target.value = startRange[0].toString();
                    }
                  }}
                  onBlur={e => {
                    e.target.value = '';
                  }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      if (parseInt(e.target.value) < 1) {
                        e.target.value = startRange[0].toString();
                      } else if (parseInt(e.target.value) > startRange[1] - 1) {
                        setStartRange(prevStartRange => {
                          return [prevStartRange[1] - 1, prevStartRange[1]];
                        });
                      } else {
                        setStartRange(prevStartRange => {
                          return [parseInt(e.target.value), prevStartRange[1]];
                        });
                      }
                    }
                  }}
                  style={{
                    width: '60px',
                    padding: '4px 5px',
                  }}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: '0.8rem',
                    marginBottom: '3px',
                    textAlign: 'right',
                    paddingRight: '2px',
                  }}
                >
                  Start max
                </div>
                <input
                  type="number"
                  placeholder={(startRange[1] || targetLength - (length ? length - 1 : 0)).toString()}
                  onFocus={e => {
                    e.persist();
                    if (!e.target.value) {
                      e.target.value = startRange[1].toString();
                    }
                  }}
                  onBlur={e => {
                    e.target.value = '';
                  }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      if (parseInt(e.target.value) < startRange[0] + 1) {
                        e.target.value = startRange[1].toString();
                      } else if (parseInt(e.target.value) > targetLength - (length ? length - 1 : 0)) {
                        e.target.value = (targetLength - (length ? length - 1 : 0)).toString();
                      } else {
                        setStartRange(prevStartRange => {
                          return [prevStartRange[0], parseInt(e.target.value)];
                        });
                      }
                    }
                  }}
                  style={{
                    width: '60px',
                    padding: '4px 5px',
                  }}
                />
              </div>
            </div>
            <div style={{ margin: '10px 7px' }}>
              <Range
                min={1}
                max={targetLength - (length ? length - 1 : 0)}
                value={startRange}
                pushable
                onChange={(range: any) => setStartRange(range)}
                tipProps={{ prefixCls: 'rc-slider-tooltip-hidden' }}
                // tipFormatter={value => `${value}`}
                // trackStyle={[{ backgroundColor: colors.B400 }]}
                // handleStyle={[{ backgroundColor: '#fff', borderColor: colors.B400 }, { backgroundColor: '#fff', borderColor: colors.B400 }]}
                // railStyle={{ backgroundColor: '#c4c4c4' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <FilterInputLabel>{`${spliced ? 'Exon' : 'Intron'} number`}</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '=', value: '=' }, { label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={exonIntronOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setExonIntronOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    type="number"
                    value={exonIntronNumber}
                    min={1}
                    max={spliced ? exonCount : exonCount - 1}
                    placeholder={`${spliced ? 'Exon' : 'Intron'} #`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (parseInt(e.target.value)) {
                        if (parseInt(e.target.value) < 1) {
                          setExonIntronNumber('1');
                        } else if (parseInt(e.target.value) > (spliced ? exonCount : exonCount - 1)) {
                          setExonIntronNumber((spliced ? exonCount : exonCount - 1).toString());
                        } else {
                          setExonIntronNumber(e.target.value);
                        }
                      } else {
                        setExonIntronNumber('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <FilterInputLabel>{`${spliced ? '' : 'pre-'}mRNA region`}</FilterInputLabel>
              <Select
                spacing="compact"
                options={[
                  { label: '\u2212', value: '' },
                  ...(spliced
                    ? custom
                      ? [{ label: 'Exon', value: 'Exon' }]
                      : [
                        { label: '5\'-UTR', value: '5\'-UTR' },
                        { label: 'CDS', value: 'CDS' },
                        { label: '3\'-UTR', value: '3\'-UTR' },
                      ]
                    : [
                      { label: 'Exon/intron junction', value: 'Exon/intron' },
                      { label: 'Intron', value: 'Intron' },
                      { label: 'Intron/exon junction', value: 'Intron/exon' },
                    ]),
                ]}
                value={regionValue.value ? regionValue : null}
                placeholder="Select region..."
                onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                  if (value) setRegionValue(value);
                }}
                styles={{ control: base => ({ ...base, width: '161px' }) }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel>AT content</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={atOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setAtOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="AT"
                    type="number"
                    value={atValue}
                    min={0}
                    max={100}
                    elemAfterInput={<span style={{ paddingRight: '8px' }}>%</span>}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < 0) {
                          setAtValue('0');
                        } else if (parseInt(e.target.value) > 100) {
                          setAtValue('100');
                        } else {
                          setAtValue(e.target.value);
                        }
                      } else {
                        setAtValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel>GC content</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={gcOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setGcOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="GC"
                    type="number"
                    value={gcValue}
                    min={0}
                    max={100}
                    elemAfterInput={<span style={{ paddingRight: '8px' }}>%</span>}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < 0) {
                          setGcValue('0');
                        } else if (parseInt(e.target.value) > 100) {
                          setGcValue('100');
                        } else {
                          setGcValue(e.target.value);
                        }
                      } else {
                        setGcValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <FilterInputLabel>T<sub>m</sub> min</FilterInputLabel>
                <input
                  type="number"
                  placeholder={(tmRange[0] || 0).toString()}
                  onFocus={e => {
                    e.persist();
                    if (!e.target.value) {
                      e.target.value = tmRange[0].toString();
                    }
                  }}
                  onBlur={e => {
                    e.target.value = '';
                  }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      if (parseInt(e.target.value) < 0) {
                        e.target.value = tmRange[0].toString();
                      } else if (parseInt(e.target.value) > tmRange[1] - 1) {
                        setTmRange(prevTmRange => {
                          return [prevTmRange[1] - 1, prevTmRange[1]];
                        });
                      } else {
                        setTmRange(prevTmRange => {
                          return [parseInt(e.target.value), prevTmRange[1]];
                        });
                      }
                    }
                  }}
                  style={{
                    width: '60px',
                    padding: '4px 5px',
                  }}
                />
              </div>
              <div>
                <FilterInputLabel alignRight>T<sub>m</sub> max</FilterInputLabel>
                <input
                  type="number"
                  placeholder={(tmRange[1] || 100).toString()}
                  onFocus={e => {
                    e.persist();
                    if (!e.target.value) {
                      e.target.value = tmRange[1].toString();
                    }
                  }}
                  onBlur={e => {
                    e.target.value = '';
                  }}
                  onChange={e => {
                    e.persist();
                    if (e.target.value) {
                      if (parseInt(e.target.value) < tmRange[0] + 1) {
                        e.target.value = tmRange[1].toString();
                      } else if (parseInt(e.target.value) > 100) {
                        e.target.value = '100';
                      } else {
                        setTmRange(prevTmRange => {
                          return [prevTmRange[0], parseInt(e.target.value)];
                        });
                      }
                    }
                  }}
                  style={{
                    width: '60px',
                    padding: '4px 5px',
                  }}
                />
              </div>
            </div>
            <div style={{ margin: '10px 7px' }}>
              <Range
                min={0}
                max={100}
                value={tmRange}
                pushable
                onChange={(range: any) => setTmRange(range)}
                tipProps={{ prefixCls: 'rc-slider-tooltip-hidden' }}
                // tipFormatter={value => `${value}`}
                // trackStyle={[{ backgroundColor: colors.B400 }]}
                // handleStyle={[{ backgroundColor: '#fff', borderColor: colors.B400 }, { backgroundColor: '#fff', borderColor: colors.B400 }]}
                // railStyle={{ backgroundColor: '#c4c4c4' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <FilterInputLabel>Hairpin score</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={hairpinOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setHairpinOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="Hairpin"
                    type="number"
                    value={hairpinValue}
                    min={-10}
                    max={10}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -10) {
                          setHairpinValue('-10');
                        } else if (parseInt(e.target.value) > 10) {
                          setHairpinValue('10');
                        } else {
                          setHairpinValue(e.target.value);
                        }
                      } else {
                        setHairpinValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <FilterInputLabel>CpG number</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '=', value: '=' }, { label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={cpgOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setCpgOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="CpG no."
                    type="number"
                    value={cpgValue}
                    min={0}
                    max={maxCpgValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < 0) {
                          setCpgValue('0');
                        } else if (parseInt(e.target.value) > maxCpgValue) {
                          setCpgValue(maxCpgValue.toString());
                        } else {
                          setCpgValue(e.target.value);
                        }
                      } else {
                        setCpgValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>Front-accessibility <sub>8-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={front8Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setFront8Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="front@8"
                    type="number"
                    value={front8Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setFront8Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setFront8Value('30');
                        } else {
                          setFront8Value(e.target.value);
                        }
                      } else {
                        setFront8Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>Back-accessibility <sub>8-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={back8Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setBack8Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="back@8"
                    type="number"
                    value={back8Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setBack8Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setBack8Value('30');
                        } else {
                          setBack8Value(e.target.value);
                        }
                      } else {
                        setBack8Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>Centre-accessibility <sub>8-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={mid8Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setMid8Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="mid@8"
                    type="number"
                    value={mid8Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setMid8Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setMid8Value('30');
                        } else {
                          setMid8Value(e.target.value);
                        }
                      } else {
                        setMid8Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            {/*<div style={{ marginTop: '10px' }}>*/}
            {/*  <FilterInputLabel><span>Centre-accessibility <sub>16-nt</sub></span></FilterInputLabel>*/}
            {/*  <div style={{ display: 'inline-flex' }}>*/}
            {/*    <Select*/}
            {/*      spacing="compact"*/}
            {/*      options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}*/}
            {/*      value={mid16Operator}*/}
            {/*      onChange={(value: ValueType<SelectOptionStringValueType>): void => {*/}
            {/*        if (value) setMid16Operator(value);*/}
            {/*      }}*/}
            {/*      styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}*/}
            {/*    />*/}
            {/*    <div style={{ width: '84px' }}>*/}
            {/*      <Textfield*/}
            {/*        autoComplete="off"*/}
            {/*        isCompact*/}
            {/*        placeholder="mid@16"*/}
            {/*        type="number"*/}
            {/*        value={mid16Value}*/}
            {/*        min={-30}*/}
            {/*        max={30}*/}
            {/*        step={0.1}*/}
            {/*        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
            {/*          e.persist();*/}
            {/*          if (e.target.value) {*/}
            {/*            if (parseInt(e.target.value) < -30) {*/}
            {/*              setMid16Value('-30');*/}
            {/*            } else if (parseInt(e.target.value) > 30) {*/}
            {/*              setMid16Value('30');*/}
            {/*            } else {*/}
            {/*              setMid16Value(e.target.value);*/}
            {/*            }*/}
            {/*          } else {*/}
            {/*            setMid16Value('');*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>5'-accessibility <sub>5-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={before5Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setBefore5Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="5'@5"
                    type="number"
                    value={before5Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setBefore5Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setBefore5Value('30');
                        } else {
                          setBefore5Value(e.target.value);
                        }
                      } else {
                        setBefore5Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>3'-accessibility <sub>5-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={after5Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setAfter5Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="3'@5"
                    type="number"
                    value={after5Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setAfter5Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setAfter5Value('30');
                        } else {
                          setAfter5Value(e.target.value);
                        }
                      } else {
                        setAfter5Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>5'-accessibility <sub>10-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={before10Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setBefore10Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="5'@10"
                    type="number"
                    value={before10Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setBefore10Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setBefore10Value('30');
                        } else {
                          setBefore10Value(e.target.value);
                        }
                      } else {
                        setBefore10Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>3'-accessibility <sub>10-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={after10Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setAfter10Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="3'@10"
                    type="number"
                    value={after10Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setAfter10Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setAfter10Value('30');
                        } else {
                          setAfter10Value(e.target.value);
                        }
                      } else {
                        setAfter10Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>5'-accessibility <sub>20-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={before20Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setBefore20Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="5'@20"
                    type="number"
                    value={before20Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setBefore20Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setBefore20Value('30');
                        } else {
                          setBefore20Value(e.target.value);
                        }
                      } else {
                        setBefore20Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel><span>3'-accessibility <sub>20-nt</sub></span></FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={after20Operator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setAfter20Operator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="3'@20"
                    type="number"
                    value={after20Value}
                    min={-30}
                    max={30}
                    step={0.1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < -30) {
                          setAfter20Value('-30');
                        } else if (parseInt(e.target.value) > 30) {
                          setAfter20Value('30');
                        } else {
                          setAfter20Value(e.target.value);
                        }
                      } else {
                        setAfter20Value('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel>BLAST matches</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '=', value: '=' }, { label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={blastOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setBlastOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="Match"
                    type="number"
                    value={blastValue}
                    min={0}
                    max={100}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < 0) {
                          setBlastValue('0');
                        } else if (parseInt(e.target.value) > 100) {
                          setBlastValue('100');
                        } else {
                          setBlastValue(e.target.value);
                        }
                      } else {
                        setBlastValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <FilterInputLabel>BLAST off-target</FilterInputLabel>
              <div style={{ display: 'inline-flex' }}>
                <Select
                  spacing="compact"
                  options={[{ label: '=', value: '=' }, { label: '>=', value: '>=' }, { label: '<=', value: '<=' }]}
                  value={nextOperator}
                  onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                    if (value) setNextOperator(value);
                  }}
                  styles={{ control: base => ({ ...base, width: '72px', marginRight: '5px' }) }}
                />
                <div style={{ width: '84px' }}>
                  <Textfield
                    autoComplete="off"
                    isCompact
                    placeholder="Length"
                    type="number"
                    value={nextValue}
                    min={1}
                    max={length}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (e.target.value) {
                        if (parseInt(e.target.value) < 0) {
                          setNextValue('1');
                        } else if (parseInt(e.target.value) > 100) {
                          setNextValue(`${length}`);
                        } else {
                          setNextValue(e.target.value);
                        }
                      } else {
                        setNextValue('');
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '10px' }}>
              <N5Exclude
                excludeN5={excludeHomopolymer}
                setExcludeN5={setExcludeHomopolymer}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
          {/*</div>*/}
        </PerfectScrollbar>
        <div style={{ textAlign: 'right', padding: '16px' }}>
          <ButtonGroup>
            <Button
              appearance="subtle"
              onClick={() => {
                reset();
                // setStartMax(targetLength - (length ? length - 1 : 0));
              }}
            >
              <span style={{ fontWeight: 400 }}>Reset</span>
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                setCurrentPage(1);
                setStartMin(startRange[0]);
                setStartMax(startRange[1]);
                spliced
                  ? setExonId(exonIntronNumber ? `${exonIntronOperator.value}${exonIntronNumber}` : '')
                  : setIntronId(exonIntronNumber ? `${exonIntronOperator.value}${exonIntronNumber}` : '');
                setRegion(regionValue.value ? regionValue.value : '');
                setAtContent(atValue ? `${atOperator.value}${atValue}` : '');
                setGcContent(gcValue ? `${gcOperator.value}${gcValue}` : '');
                setCpgCount(cpgValue ? `${cpgOperator.value}${cpgValue}` : '');
                setTmMin(tmRange[0]);
                setTmMax(tmRange[1]);
                setHairpin(hairpinValue ? `${hairpinOperator.value}${hairpinValue}` : '');
                setBefore5(before5Value ? `${before5Operator.value}${before5Value}` : '');
                setBefore10(before10Value ? `${before10Operator.value}${before10Value}` : '');
                setBefore20(before20Value ? `${before20Operator.value}${before20Value}` : '');
                setBefore40(before40Value ? `${before40Operator.value}${before40Value}` : '');
                setFront8(front8Value ? `${front8Operator.value}${front8Value}` : '');
                setMid8(mid8Value ? `${mid8Operator.value}${mid8Value}` : '');
                // setMid16(mid16Value ? `${mid16Operator.value}${mid16Value}` : '');
                setBack8(back8Value ? `${back8Operator.value}${back8Value}` : '');
                setAfter5(after5Value ? `${after5Operator.value}${after5Value}` : '');
                setAfter10(after10Value ? `${after10Operator.value}${after10Value}` : '');
                setAfter20(after20Value ? `${after20Operator.value}${after20Value}` : '');
                setAfter40(after40Value ? `${after40Operator.value}${after40Value}` : '');
                setExcludeN5(excludeHomopolymer);
                setBlastMatches(blastValue ? `${blastOperator.value}${blastValue}` : '');
                setBlastNextBestLength(nextValue ? `${nextOperator.value}${nextValue}` : '');
              }}
            >
              <span style={{ fontWeight: 400 }}>Apply</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </RightSidePanel>
  );
}

export default AntisenseSequenceFilter;
