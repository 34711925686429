/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useApolloClient } from '@apollo/client';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Lozenge from '@atlaskit/lozenge';
import { AsyncSelect } from '@atlaskit/select';
import { colors, fontSizeSmall } from '@atlaskit/theme';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Page from '@atlaskit/page';
import { Checkbox } from '@atlaskit/checkbox';
import PageHeader from '@atlaskit/page-header';
import { PDFViewer } from '@react-pdf/renderer';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import { parseISO as parse } from 'date-fns';

import {
  GetAllProductsDocument,
  GetAllProductsQuery,
  GetSamplesByProductIdDocument,
  GetSamplesByProductIdQuery,
  Order,
  ProductSortField,
  useGetDeliveryQuery,
  useUpdateDeliveryMutation,
} from 'apollo/graphql';
import DO, { DoItem } from 'components/pdfs/do';
import { useAuth } from 'contexts/auth';
import { setAlert } from 'redux/alerts';
import DeliveryModesSelect from 'components/order/delivery-mode-select';
import { SelectOptionStringValueType } from 'types/select-option';
import useOrders from 'hooks/use-orders';

function DeliveryOverview() {
  const { currentUser } = useAuth()!;
  useOrders();
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { state }: { state?: { orderId?: number; } } = useLocation();
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [items, setItems] = useState<DoItem[]>([]);
  const [debouncedItems, setDebouncedItems] = useState<DoItem[]>([]);
  const [deliverMode, setDeliveryMode] = useState<SelectOptionStringValueType>({ label: '\u2013', value: '' });

  const { data } = useGetDeliveryQuery({
    variables: {
      id: deliveryId!,
    },
  });

  const [updateDelivery] = useUpdateDeliveryMutation({
    onCompleted: ({ updateDelivery: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
  });

  const breadcrumbs = (
    <Breadcrumbs>
      <BreadcrumbsItem
        text="Orders"
        onClick={() => navigate('/orders')}
      />
      <BreadcrumbsItem
        text={data?.delivery?.order?.id.padStart(3, '0') || state?.orderId?.toString().padStart(3, '0') || '000'}
        onClick={() => navigate(`/orders/${data?.delivery?.order?.id || state?.orderId}`)}
      />
      <BreadcrumbsItem text="deliveries" onClick={() => navigate('/deliveries')} />
      <BreadcrumbsItem text={deliveryId!} onClick={() => {
      }} />
    </Breadcrumbs>
  );

  const actionsContent = (
    <div>
      <input
        type="number"
        max={10}
        placeholder={debouncedItems.length.toString()}
        value={debouncedItems.length}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newItemCount = parseInt(event.target.value);
          if (newItemCount < debouncedItems.length) {
            setDebouncedItems(prevItems => prevItems.slice(0, newItemCount));
          } else if (newItemCount > debouncedItems.length) {
            setDebouncedItems(prevItems => [...prevItems, {
              serialNumber: '',
              productId: `item${prevItems.length + 1}`,
              description: '',
              unitCount: 1,
            }]);
          }
        }}
        style={{ width: '36px', margin: '4px 8px 0', padding: '4px 5px' }}
      />
      rows
    </div>
  );

  return (
    <Page>
      <div style={{
        display: 'flex',
        gap: '32px',
        alignItems: 'flex-start',
        marginRight: '16px',
        justifyContent: 'flex-start',
      }}>
        <PDFViewer
          style={{
            marginTop: '16px',
            width: '950px',
            height: `calc(100vh - 90px)`,
          }}
        >
          <DO
            author={currentUser?.username}
            id={deliveryId}
            poNumber={data?.delivery?.order?.poNumber!}
            dateIssued={data?.delivery?.deliverAt}
            order={data?.delivery?.order as Order}
            items={items}
          />
        </PDFViewer>
        {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
        {/*  {data ? (*/}
        {/*    <PDFDownloadLink*/}
        {/*      document={<DO*/}
        {/*        id={deliveryId}*/}
        {/*        poNumber={data?.delivery?.order?.poNumber!}*/}
        {/*        dateIssued={data?.delivery?.deliverAt}*/}
        {/*        order={data?.delivery?.order as Order}*/}
        {/*      />}*/}
        {/*    >*/}
        {/*      Download DO*/}
        {/*    </PDFDownloadLink>*/}
        {/*  ) : 'Loading'}*/}
        {/*</div>*/}
        <div style={{ minWidth: 'calc(100vw - 1020px)' }}>
          <Form
            onSubmit={(values: { [key: string]: string }) => {
              setItems(debouncedItems.map(({ productId }, i) => ({
                serialNumber: values[`s${i + 1}_serialNumber`],
                productId,
                description: values[`s${i + 1}_description`],
                unitCount: parseInt(values[`s${i + 1}_unitCount`]),
              })));
            }}
          >
            {({ formProps }) => (
              <form {...formProps} noValidate style={{ maxWidth: '900px' }}>
                <PageHeader breadcrumbs={breadcrumbs} actions={actionsContent}>{deliveryId}</PageHeader>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '130px',
                  alignItems: 'flex-start',
                  marginBottom: '12px',
                }}>
                  <div style={{ width: '260px' }}>
                    {/*<Field name="deliverAt" label="Deliver at">*/}
                    {/*  {() => (*/}
                    <DateTimePicker
                      key={data?.delivery?.id}
                      defaultValue={data?.delivery?.deliverAt || undefined}
                      appearance={'subtle'}
                      spacing={'compact'}
                      dateFormat={'DD-MMM-YYYY'}
                      datePickerProps={{ placeholder: '\u2013' }}
                      timePickerProps={{ placeholder: '\u2013' }}
                      onChange={(value) => {
                        const updatedDeliveryAt = value ? parse(value).toISOString() : '';

                        updateDelivery({
                          variables: {
                            id: deliveryId!,
                            deliverAt: updatedDeliveryAt.substring(0, updatedDeliveryAt.length - 1),
                          },
                        });
                      }}
                    />
                    {/*  )}*/}
                    {/*</Field>*/}
                  </div>
                  {/*<Field name="mode" label="Mode">*/}
                  {/*  {() => (*/}
                  <DeliveryModesSelect
                    deliveryMode={data?.delivery?.mode
                      ? {
                        label: (
                          <Lozenge
                            isBold
                            appearance={data.delivery.mode === 'grab' ? 'success' : data.delivery.mode === 'lala' ? 'removed' : data.delivery.mode === 'in-person' ? 'inprogress' : data.delivery.mode === 'digital' ? 'new' : 'default'}
                          >
                            {data.delivery.mode}
                          </Lozenge>
                        ),
                        value: data.delivery.mode,
                      }
                      : deliverMode}
                    setDeliveryMode={(value) => {
                      setDeliveryMode(value);
                      updateDelivery({
                        variables: {
                          id: deliveryId!,
                          mode: value.value,
                        },
                      });
                    }}
                  />
                  {/*  )}*/}
                  {/*</Field>*/}
                </div>
                <PerfectScrollbar
                  // style={{ overflowY: 'scroll', height: '500px' }}
                  css={css`
                    overflow-y: scroll;
                    height: calc(100vh - 290px);
                    padding-right: 12px;
                    margin-right: -12px;
                  `}
                >
                  {data?.delivery?.order?.items?.length ?
                    data.delivery.order.items.map(({ id, description }) => (
                      <div key={id} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '16px',
                        alignItems: 'center',
                        minHeight: '32px',
                        marginBottom: '3px',
                      }}>
                        <div style={{ width: '374px' }}>
                          <Checkbox
                            value={id}
                            label={<span style={{ cursor: 'pointer' }}>{description}</span>}
                            isChecked={debouncedItems.findIndex(({ productId }) => productId === id) !== -1}
                            onChange={(e) => {
                              if (e.target.checked && debouncedItems.findIndex(({ productId }) => productId === id) === -1) {
                                setDebouncedItems(prevItems => [...prevItems, {
                                  serialNumber: '',
                                  productId: id,
                                  description,
                                  unitCount: 1,
                                }]);
                              } else {
                                setDebouncedItems(prevItems => {
                                  return prevItems.filter(({ productId }) => productId !== id).map((item, i) => {
                                    return item.productId.startsWith('item') ? {
                                      ...item,
                                      productId: `item${i + 1}`,
                                    } : item;
                                  });
                                });
                              }
                            }}
                            name={id}
                          />
                        </div>
                        {debouncedItems.findIndex(({ productId }) => productId === id) !== -1 ? (
                          <div style={{ width: '410px' }}>
                            <AsyncSelect
                              spacing={'compact'}
                              isMulti
                              cacheOptions
                              defaultOptions
                              value={debouncedItems.find(({ productId }) => productId === id)!.serialNumber
                                ? debouncedItems.find(({ productId }) => productId === id)!.serialNumber.split(', ').length > 1
                                  ? debouncedItems.find(({ productId }) => productId === id)!.serialNumber.split(', ').map(s => ({
                                    label: s,
                                    value: s,
                                  }))
                                  : {
                                    label: debouncedItems.find(({ productId }) => productId === id)!.serialNumber,
                                    value: debouncedItems.find(({ productId }) => productId === id)!.serialNumber,
                                  }
                                : undefined}
                              loadOptions={(inputValue: string) => client.query<GetSamplesByProductIdQuery>({
                                query: GetSamplesByProductIdDocument,
                                variables: {
                                  productId: parseInt(id),
                                  ...(data?.delivery?.order?.id && { orderId: parseInt(data.delivery.order.id) }),
                                  search: inputValue,
                                },
                                fetchPolicy: 'network-only',
                              })
                                .then(({ data }) => {
                                  const samples = data?.samples.edges || [];

                                  if (samples.length === 1) {
                                    const sample = samples[0].node;
                                    setDebouncedItems(debouncedItems.map((item) => {
                                      return item.productId === id
                                        ? { ...item, serialNumber: sample.id }
                                        : item;
                                    }));

                                    return [{ label: sample.id, value: sample.id }];
                                  } else {
                                    return samples.map(({ node: { id } }) => ({
                                      label: id,
                                      value: id,
                                    }));
                                  }
                                })}
                              onChange={value => {
                                setDebouncedItems(debouncedItems.map((item) => {
                                  return item.productId === id
                                    ? {
                                      ...item,
                                      serialNumber: value.length ? value.map(({ value }) => value).join(', ') : '',
                                    }
                                    : item;
                                }));
                              }}
                              isDisabled={debouncedItems.findIndex(({ productId }) => productId === id) === -1}
                              placeholder={debouncedItems.findIndex(({ productId }) => productId === id) !== -1 ? 'Select samples' : '\u2013'}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))
                    : null}
                  {debouncedItems.filter(({ productId }) => productId.startsWith('item')).length ? (<div
                    style={{
                      fontSize: fontSizeSmall(),
                      color: colors.subtleHeading(),
                      fontWeight: 500,
                      marginTop: '16px',
                      marginBottom: '4px',
                    }}
                  >
                    Add-on items
                  </div>) : null}
                  {debouncedItems.filter(({ productId }) => productId.startsWith('item')).map(({
                    productId: id,
                    description,
                  }) => (
                    <div
                      key={id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '16px',
                        alignItems: 'center',
                        minHeight: '32px',
                        marginBottom: '3px',
                      }}
                    >
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}>
                        <Checkbox
                          value={id}
                          // label={<span style={{ cursor: 'pointer' }}>{description}</span>}
                          isChecked
                          onChange={(e) => {
                            setDebouncedItems(prevItems => {
                              return prevItems.filter(({ productId }) => productId !== id).map((item, i) => {
                                return item.productId.startsWith('item') ? {
                                  ...item,
                                  productId: `item${i + 1}`,
                                } : item;
                              });
                            });
                          }}
                          name={id}
                        />
                        <div style={{ width: '410px' }}>
                          <AsyncSelect
                            value={description ? { label: description.split('__')[0], value: id } : null}
                            spacing={'compact'}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue: string) => client.query<GetAllProductsQuery>({
                              query: GetAllProductsDocument,
                              variables: {
                                filter: {
                                  search: inputValue,
                                },
                                orderBy: ProductSortField.CreatedAtDesc,
                              },
                              fetchPolicy: 'network-only',
                            })
                              .then(({ data }) => {
                                const products = data?.products.edges || [];

                                return products.map(({ node: { id, name } }) => ({
                                  label: name,
                                  value: id,
                                }));
                              })}
                            onChange={value => {
                              if (value) {
                                setDebouncedItems(debouncedItems.map((item, i) => {
                                  return parseInt(id.replace('item', '')) - 1 === i
                                    ? {
                                      ...item,
                                      serialNumber: '',
                                      description: `${value.label}__${value.value}`,
                                    }
                                    : item;
                                }));
                              }
                            }}
                            placeholder="Select product"
                          />
                        </div>
                      </div>
                      {description.split('__').length === 2 ? (
                        <div style={{ width: '410px' }}>
                          <AsyncSelect
                            key={debouncedItems.find(({ productId }) => productId === id)!.description}
                            spacing={'compact'}
                            isMulti
                            // cacheOptions
                            defaultOptions
                            value={debouncedItems.find(({ productId }) => productId === id)!.serialNumber
                              ? debouncedItems.find(({ productId }) => productId === id)!.serialNumber.split(', ').length > 1
                                ? debouncedItems.find(({ productId }) => productId === id)!.serialNumber.split(', ').map(s => ({
                                  label: s,
                                  value: s,
                                }))
                                : {
                                  label: debouncedItems.find(({ productId }) => productId === id)!.serialNumber,
                                  value: debouncedItems.find(({ productId }) => productId === id)!.serialNumber,
                                }
                              : undefined}
                            loadOptions={(inputValue: string) => client.query<GetSamplesByProductIdQuery>({
                              query: GetSamplesByProductIdDocument,
                              variables: {
                                productId: parseInt(description.split('__')[1]),
                                // ...(data?.delivery?.order?.id && { orderId: parseInt(data.delivery.order.id) }),
                                search: inputValue,
                              },
                              fetchPolicy: 'network-only',
                            })
                              .then(({ data }) => {
                                const samples = data?.samples.edges || [];

                                // return samples.map(({ node: { id } }) => ({
                                //   label: id,
                                //   value: id,
                                // }));

                                if (samples.length === 1) {
                                  const sample = samples[0].node;
                                  setDebouncedItems(debouncedItems.map((item, i) => {
                                    return parseInt(id.replace('item', '')) - 1 === i
                                      ? { ...item, serialNumber: sample.id }
                                      : item;
                                  }));

                                  return [{ label: sample.id, value: sample.id }];
                                } else {
                                  return samples.map(({ node: { id } }) => ({
                                    label: id,
                                    value: id,
                                  }));
                                }
                              })}
                            onChange={value => {
                              setDebouncedItems(debouncedItems.map((item, i) => {
                                return parseInt(id.replace('item', '')) - 1 === i
                                  ? {
                                    ...item,
                                    serialNumber: value.length ? value.map(({ value }) => value).join(', ') : '',
                                  }
                                  : item;
                              }));
                            }}
                            // isDisabled={debouncedItems.findIndex(({ productId }) => productId === id) === -1}
                            placeholder="Select samples"
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div style={{ minHeight: '48px', marginTop: '24px', marginBottom: '12px' }}>
                    {debouncedItems.map(({ serialNumber, productId, description, unitCount }, i) => (
                      <div
                        key={productId}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 8,
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ flexBasis: '240px', flexGrow: 1 }}>
                          <Field
                            name={`s${i + 1}_serialNumber`}
                            label=""
                            defaultValue={serialNumber}
                            validate={(value) => {
                              if (!value?.length) return 'Serial number missing';
                            }}
                          >
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield
                                  {...fieldProps}
                                  autoComplete="off"
                                  placeholder={`Item ${i + 1} serial number`}
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        </div>
                        <div style={{ flexBasis: '374px', flexGrow: 1 }}>
                          <Field
                            name={`s${i + 1}_description`}
                            label=""
                            defaultValue={description.split('__')[0]}
                            validate={(value) => {
                              if (!value) return 'Description missing';
                            }}
                          >
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield
                                  {...fieldProps}
                                  autoComplete="off"
                                  placeholder={`Item ${i + 1} description`}
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        </div>
                        <div style={{ flexBasis: '90px', flexShrink: 0 }}>
                          <Field
                            name={`s${i + 1}_unitCount`}
                            label=""
                            defaultValue={unitCount}
                            validate={(value) => {
                              if (!value) return 'Unit missing';
                            }}
                          >
                            {({ fieldProps, error }) => (
                              <>
                                <Textfield
                                  {...fieldProps}
                                  type="number"
                                  min={0}
                                  autoComplete="off"
                                  placeholder="Unit"
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                    ))}
                  </div>
                </PerfectScrollbar>
                {/*{debouncedItems.length ? (*/}
                <FormFooter>
                  <Button
                    type="submit"
                    appearance="primary"
                    isDisabled={debouncedItems.length === 0}
                  >
                    <span style={{ fontWeight: 400 }}>
                      Update
                    </span>
                  </Button>
                </FormFooter>
                {/*) : null}*/}
              </form>
            )}
          </Form>
        </div>
      </div>
    </Page>
  );
}

export default DeliveryOverview;
