/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface Props {
  children: ReactNode;
  withBreadcrumbs?: boolean;
  withBar?: boolean;
  alignTop?: boolean;
  rowHeight?: number;
}

function StickyHeader({ children, withBreadcrumbs = false, withBar = false, alignTop = false, rowHeight }: Props) {
  return (
    <PerfectScrollbar
      css={css`
        overflow-y: scroll;
        height: calc(100vh - ${withBreadcrumbs ? 24 : 0}px - ${withBar ? 242 : 194}px);

        & > div {
          margin-bottom: 0;
        }

        & > div > table {
          max-width: 100vw;
          margin-right: 0;
          border-collapse: separate;
          border-spacing: 0;
        }

        & > div > table > thead > tr > th {
          position: sticky;
          top: 0;
          background-color: white;
          border-bottom: 2px solid #DFE1E6;
          white-space: nowrap;
          z-index: 2;
        }
        
        & > div > table > tbody > tr {
          ${alignTop && `vertical-align: top;`}
        }
        
        & > div > table > tbody > tr > td {
          ${rowHeight && `height: ${rowHeight}px;`}
        }
      `}
    >
      {children}
    </PerfectScrollbar>
  );
}

export default StickyHeader;
