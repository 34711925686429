/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import styled from 'styled-components';
import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import { fontSize as getFontSize, gridSize as getGridSize } from '@atlaskit/theme/constants';

import { useUpdateSkuQuantityBiopMutation, useUpdateSkuQuantityNasbioMutation } from 'apollo/graphql';
import { useAuth } from 'contexts/auth';

const fontSize = getFontSize();
const gridSize = getGridSize();

const EditableField = styled.div`
  & > form > div {
    margin-top: 0;
  }

  //& > form > div > div > div > div {
  //  padding: 0;
  //}

  //& > form > div > div > div > input {  // Shift textbox up by 1px for screen size 1920 or below.
  //  margin-top: -1px;
  //  margin-bottom: 1px;
  //}
`;

interface Props {
  id: string;
  quantity: number;
  lab: 'biop' | 'nasbio';
}

const readViewContainerStyles = css({
  display: 'flex',
  maxWidth: '100%',
  minHeight: `${(gridSize * 2.5) / fontSize}em`,
  padding: `${gridSize - 4}px ${gridSize - 2}px`,
  fontSize: `${fontSize}px`,
  lineHeight: `${(gridSize * 2.5) / fontSize}`,
  wordBreak: 'break-word',
});

function SkuQuantity({ id, quantity, lab }: Props): JSX.Element {
  const { currentUser } = useAuth()!;

  const [updateSkuQuantityBiop] = useUpdateSkuQuantityBiopMutation();
  const [updateSkuQuantityNasbio] = useUpdateSkuQuantityNasbioMutation();

  return (
    <EditableField>
      <InlineEdit
        readViewFitContainerWidth
        hideActionButtons
        defaultValue={quantity.toString()}
        editView={({ errorMessage, ...fieldProps }) => (
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder={quantity.toString()}
            type="number"
            min={0}
            max={999}
            width="100%"
            autoFocus
            isCompact
            // css={{ marginTop: '-1px', marginBottom: '1px' }}
          />
        )}
        readView={() => (
          <div css={readViewContainerStyles}>
            {quantity.toString()}
          </div>
        )}
        onConfirm={value => {
          const updatedQuantity = parseInt(value);
          if (updatedQuantity === quantity || isNaN(updatedQuantity) || updatedQuantity < 0) return;
          if (lab === 'biop') {
            updateSkuQuantityBiop({
              variables: { id, quantity: parseInt(value) },
              optimisticResponse: {
                __typename: 'Mutation',
                updateSkuQuantityBiop: {
                  __typename: 'UpdateSkuQuantityResponse',
                  success: true,
                  message: 'SKU quantity updated',
                  sku: {
                    __typename: 'Sku',
                    id,
                    quantityBiop: updatedQuantity,
                    updatedAt: new Date(),
                    updatedBy: {
                      id: currentUser!.id,
                      username: currentUser!.username,
                    },
                  },
                },
              },
            });
          }
          if (lab === 'nasbio') {
            updateSkuQuantityNasbio({
              variables: { id, quantity: parseInt(value) },
              optimisticResponse: {
                __typename: 'Mutation',
                updateSkuQuantityNasbio: {
                  __typename: 'UpdateSkuQuantityResponse',
                  success: true,
                  message: 'SKU quantity updated',
                  sku: {
                    __typename: 'Sku',
                    id,
                    quantityNasbio: updatedQuantity,
                    updatedAt: new Date(),
                    updatedBy: {
                      id: currentUser!.id,
                      username: currentUser!.username,
                    },
                  },
                },
              },
            });
          }
        }}
      />
    </EditableField>
  );
}

export default SkuQuantity;
