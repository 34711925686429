import { PanelType } from 'components/panels/panel-manager';
import { PanelProps } from './actions';

export interface Panel extends PanelProps {
  panelType: PanelType | null;
  isOpen: boolean;
}

export enum PanelActionType {
  MOUNT_PANEL = 'MOUNT_PANEL',
  OPEN_PANEL = 'OPEN_PANEL',
  CLOSE_PANEL = 'CLOSE_PANEL',
}
