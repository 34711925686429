import { useEffect, useState } from 'react';

interface Props {
  defaultSearch?: string;
  timeDelay?: number;
  updateFn?: ((term: string) => string) | null;
}

const useSearch = ({
  defaultSearch = '',
  timeDelay = 300,
  updateFn = null,
}: Props = {
  defaultSearch: '',
  timeDelay: 300,
  updateFn: null,
}) => {
  const [search, setSearch] = useState<string>(defaultSearch);
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(updateFn ? updateFn(search) : search);
    }, timeDelay);

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return [setSearch, { search, debouncedSearch }] as const;
};

export default useSearch;
