import 'styles/slider.css';

import React, { useEffect, useState } from 'react';
import { codeFontFamily, colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import Lozenge from '@atlaskit/lozenge';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Slider from 'rc-slider';
import { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { AsyncSelect, ValueType } from '@atlaskit/select';

import { SelectOptionStringValueType } from 'types/select-option';
import { antisenseCartItemsVar } from 'apollo/cache';
import {
  GetAllCouplingsDocument,
  GetAllCouplingsQuery, GetAllOligosDocument, GetAllOligosQuery,
  GetAllSugarsDocument,
  GetAllSugarsQuery,
  GetBaseBySymbolDocument,
  GetBaseBySymbolQuery,
} from 'apollo/graphql';
import { MixmerProps, OligoConfigProps } from './create-mixmer-tab';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export const DeleteIcon = styled.div`
  color: ${colors.N50};
  padding-top: 3px;
  cursor: pointer;

  &:hover {
    color: ${colors.R400};
  }

  &:active {
    color: ${colors.R500};
  }
`;

interface Props {
  id: string;
  name: string;
  seq: string;
  ps: boolean;
  setAntisenseList: (v: (al: MixmerProps[]) => MixmerProps[]) => void;
  setCpIdsList: (v: (cl: OligoConfigProps[]) => OligoConfigProps[]) => void;
  rangeNodes: number[];
  segmentSugars: SelectOptionStringValueType[];
  setTotalCount: Function;
}

function MixmerItem({
  id,
  name,
  seq,
  ps,
  setAntisenseList,
  setCpIdsList,
  rangeNodes,
  segmentSugars,
  setTotalCount,
}: Props): JSX.Element {
  const client = useApolloClient();

  const [oligoName, setOligoName] = useState<string>(name);
  const [segmentLengths, setSegmentLengths] = useState<number[]>([Math.floor(seq.length / 2), seq.length - Math.floor(seq.length / 2)]);

  // Backbone
  const backboneId = ps ? '2' : '1';

  const removeItem = (id: string) => {
    setAntisenseList((prevAntisenseList) => {
      return prevAntisenseList.filter(({ antisenseId }) => antisenseId !== id);
    });
    antisenseCartItemsVar(antisenseCartItemsVar().filter(({ antisenseId }) => antisenseId !== id));
  };

  const setSegmentCount = (value: number) => {
    setAntisenseList((prevAntisenseList) => {
      return prevAntisenseList.map(({ antisenseId, rangeNodes, ...rest }) => {
        if (antisenseId === id) {
          const segmentLength = Math.floor(seq.length / value);
          if (value > rangeNodes.length - 1) {
            return {
              antisenseId,
              rangeNodes: [0, ...[...Array(value).keys()].slice(1, value).map(x => x * segmentLength), seq.length], ...rest,
            };
          } else if (value < rangeNodes.length - 1) {
            return {
              antisenseId,
              rangeNodes: [0, ...rangeNodes.slice(1, rangeNodes.length - 1).slice(0, value - 1), seq.length], ...rest,
            };
          } else {
            return { antisenseId, rangeNodes, ...rest };
          }
        } else {
          return { antisenseId, rangeNodes, ...rest };
        }
      });
    });
  };

  const setRangeNodes = (value: number[]) => {
    setAntisenseList((prevAntisenseList) => {
      return prevAntisenseList.map(({ antisenseId, ...rest }) => {
        if (antisenseId === id) {
          return { antisenseId, ...rest, rangeNodes: value };
        } else {
          return { antisenseId, ...rest };
        }
      });
    });
  };

  useEffect(() => {
    setSegmentLengths(rangeNodes.map((rangeNode, i) => {
      if (i === 0) {
        return rangeNode;
      } else {
        return rangeNode - rangeNodes[i - 1];
      }
    }).slice(1));

    setOligoName(prevOligoName => {
      return `${prevOligoName.split('_').slice(0, -1).join('_')}_${rangeNodes.slice(1).map((node, i) => i === 0 ? node : rangeNodes[i + 1] - rangeNodes[i]).join('-')}`;
    });
  }, [rangeNodes]);

  useEffect(() => {
    setAntisenseList((prevAntisenseList) => {
      return prevAntisenseList.map(({ antisenseId, segmentSugars, ...rest }) => {
        if (antisenseId === id) {
          if (rangeNodes.length - 1 > segmentSugars.length) {
            return {
              antisenseId,
              segmentSugars: [
                ...segmentSugars,
                ...[...Array(rangeNodes.length - 1 - segmentSugars.length).keys()].map(i => ({
                  label: i + segmentSugars.length + 1,
                  value: '',
                })),
              ],
              ...rest,
            };
          } else if (rangeNodes.length - 1 < segmentSugars.length) {
            return {
              antisenseId,
              segmentSugars: segmentSugars.slice(0, rangeNodes.length - 1),
              ...rest,
            };
          } else {
            return {
              antisenseId,
              segmentSugars,
              ...rest,
            };
          }
        } else {
          return { antisenseId, segmentSugars, ...rest };
        }
      });
    });
  }, [id, rangeNodes.length, setAntisenseList]);

  useEffect(() => {
    interface NtConfigProps {
      sugarId: string;
      baseId: string;
      backboneId: string;
    }

    const configuration: NtConfigProps[] = [];

    const generateNucleotideIds = async (segments: string[]) => {
      // eslint-disable-next-line
      for (const [i, segment] of segments.entries()) {
        // eslint-disable-next-line
        for (const [j, base] of segment.split('').entries()) {
          const [sugarId] = segmentSugars[i].value.split('_');
          let baseSymbol = base;
          if (base === 'C') {
            if (sugarId === '5' || sugarId === '6') baseSymbol = 'mC';  // mC for MOE/LNA
          } else if (base === 'T') {
            if (sugarId === '2') baseSymbol = 'U';  // U for RNA
          }
          await client.query<GetBaseBySymbolQuery>({
            query: GetBaseBySymbolDocument,
            variables: { symbol: baseSymbol },
          })
            .then(({ data: { baseBySymbol } }) => {
              configuration.push({
                sugarId,
                baseId: baseBySymbol!.id,
                backboneId: (i === segments.length - 1) && (j === segment.length - 1) ? '0' : (ps ? '2' : '1'),
              });
            });
        }
      }

      const ntIds: string[] = [];
      // eslint-disable-next-line
      for (const nt of configuration) {
        await client.query<GetAllCouplingsQuery>({
          query: GetAllCouplingsDocument,
          variables: {
            page: 1,
            perPage: 1,
            filter: {
              sugarIds: [nt.sugarId],
              baseIds: [nt.baseId],
              backboneIds: [nt.backboneId],
            },
          },
        })
          .then(({ data: { couplings } }) => {
            couplings.edges.length
              ? couplings.edges.map(({ node: { id } }) => ntIds.push(id))
              : ntIds.push('');
          });
      }

      return ntIds;
    };

    const segments = segmentLengths.map((segmentLength, i) => {
      if (i === 0) {
        return seq.substr(0, segmentLength);
      } else if (i === segmentLengths.length - 1) {
        return seq.substr(seq.length - segmentLength);
      } else {
        return seq.substr(segmentLengths.slice(0, i).reduce((a, b) => a + b), segmentLength);
      }
    });

    if (segmentSugars.every(({ value }) => value)) {
      generateNucleotideIds(segments)
        .then(cpIds => {
          setCpIdsList(prevCpIdsList => {
            if (prevCpIdsList.find(({ antisenseId }) => antisenseId === id)) {
              return prevCpIdsList.map(({ antisenseId, ...rest }) => {
                if (antisenseId === id) {
                  return { antisenseId, cpIds };
                } else {
                  return { antisenseId, ...rest };
                }
              });
            } else {
              return [...prevCpIdsList, { antisenseId: id, cpIds }];
            }
          });
        });
    }
  }, [id, client, backboneId, seq, ps, segmentLengths, segmentSugars, setCpIdsList]);

  const promiseOptions = (inputValue: string) => client.query<GetAllSugarsQuery>({
    query: GetAllSugarsDocument,
    variables: {
      filter: {
        search: inputValue,
        hasBase: true,
        backTerminating: false,
      },
      orderBy: ['id_ASC'],
    },
    fetchPolicy: 'network-only',
  })
    .then(({ data }) => {
      const sugars = data?.sugars.edges || [];

      return sugars.map(({ node: { id, symbol, name } }) => ({
        label: name,
        value: `${id}_${symbol.replace('d', '')}`,
      }));
    });

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <div style={{ display: 'inline-flex', marginTop: '8px' }}>
          <div style={{ paddingTop: '3px' }}>
            <Lozenge
              isBold
              appearance="default"
            >
              {id}
            </Lozenge>
          </div>
          <DeleteIcon
            onClick={() => {
              // toggle();
              removeItem(id);
            }}
          >
            <EditorCloseIcon label="close" />
          </DeleteIcon>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <Field
            name={`name${id}`}
            defaultValue={oligoName}
            validate={async (value) => {
              if (!value) {
                return 'Name is empty';
              } else {
                const { data } = await client.query<GetAllOligosQuery>({
                  query: GetAllOligosDocument,
                  variables: {
                    filter: { name: value },
                  },
                  fetchPolicy: 'network-only',
                });
                if (data && data.oligos.filteredCount) {
                  return 'Oligo already exists.';
                }

                // setOligoName(value);
                setAntisenseList((prevAntisenseList: any) => {
                  return prevAntisenseList.map(({ antisenseId, ...rest }: { antisenseId: any }) => {
                    if (antisenseId === id) {
                      return { antisenseId, ...rest, name: value };
                    } else {
                      return { antisenseId, ...rest };
                    }
                  });
                });
              }
            }}
          >
            {({ fieldProps, error }) => (
              <div style={{ display: 'flex' }}>
                <div>
                  <Textfield
                    {...fieldProps}
                    appearance={'subtle'}
                    autoComplete="off"
                    placeholder="Enter name"
                    // value={oligoName}
                    // autoFocus={autoFocus}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    //   setOligoName(event.target.value);
                    //   setAntisenseList((prevAntisenseList: any) => {
                    //     return prevAntisenseList.map(({ antisenseId, ...rest }: { antisenseId: any }) => {
                    //       if (antisenseId === id) {
                    //         return { antisenseId, ...rest, name: event.target.value };
                    //       } else {
                    //         return { antisenseId, ...rest };
                    //       }
                    //     });
                    //   });
                    // }}
                    style={{ paddingTop: '0', paddingBottom: '0', marginTop: '-6px', marginBottom: '-5px' }}
                  />
                </div>
                {error && (
                  <div style={{ marginTop: '3px', marginLeft: '4px' }}>
                    <ErrorMessage>{error}</ErrorMessage>
                  </div>
                )}
              </div>
              // <InlineEditableField
              //   {...fieldProps}
              //   fieldValue={oligoName || '\u2212'}
              //   validate={async value => {
              //     if (value) {
              //       const { data } = await client.query<GetAllOligosQuery>({
              //         query: GetAllOligosDocument,
              //         variables: {
              //           filter: { name: value },
              //         },
              //         fetchPolicy: 'network-only',
              //       });
              //       if (data && data.oligos.filteredCount) {
              //         return 'Oligo already exists.';
              //       }
              //     }
              //   }}
              //   onConfirm={value => {
              //     setOligoName(value);
              //     setAntisenseList((prevAntisenseList: any) => {
              //       return prevAntisenseList.map(({ antisenseId, ...rest }: { antisenseId: any }) => {
              //         if (antisenseId === id) {
              //           return { antisenseId, ...rest, name: value };
              //         } else {
              //           return { antisenseId, ...rest };
              //         }
              //       });
              //     });
              //   }}
              //   fitWidth
              //   hideActionButtons={false}
              // />
            )}
          </Field>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50px' }}>
            <Textfield
              autoComplete="off"
              appearance={'subtle'}
              isCompact
              placeholder="Length"
              type="number"
              min={2}
              max={seq.length}
              value={rangeNodes.length - 1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.persist();
                if (parseInt(e.target.value) < 2) {
                  setSegmentCount(2);
                } else if (parseInt(e.target.value) > seq.length) {
                  setSegmentCount(seq.length);
                } else if (parseInt(e.target.value)) {
                  setSegmentCount(parseInt(e.target.value));
                } else {
                  setSegmentCount(2);
                }
              }}
              // elemAfterInput={<span style={{ paddingRight: '8px', color: colors.N300 }}>segments</span>}
            />
          </div>
          <div style={{ paddingTop: '6px', marginLeft: '8px' }}>segments</div>
        </div>
        <div style={{ width: `${seq.length * 16}px`, paddingTop: '4px', paddingRight: '5px' }}>
          <Range
            min={0}
            max={seq.length}
            value={rangeNodes}
            tipFormatter={value => `${value}`}
            pushable={1}
            onChange={segments => {
              setRangeNodes([0, ...segments.slice(1, segments.length - 1), seq.length]);
            }}
            trackStyle={segmentLengths.map((_, i) => i % 2 === 0 ? ({}) : ({ backgroundColor: colors.Y100 }))}
            // trackStyle={[{}, { backgroundColor: colors.T200 }]}
            // handleStyle={[{ backgroundColor: '#fff', borderColor: colors.B400 }, { backgroundColor: '#fff', borderColor: colors.B400 }]}
            // railStyle={{ backgroundColor: '#c4c4c4' }}
          />
          <div
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              width: `${seq.length * 16}px`,
              paddingRight: '5px',
              fontSize: '0.75rem',
            }}
          >
            {segmentLengths
              .map((e, i) => i < rangeNodes.length - 2 ? [e, '\u2212'] : [e])
              .reduce((a, b) => [...a, ...b], [])
              .map((segmentLength, i) => (
                <span key={i}>{segmentLength}</span>
              ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          width: '502px',
          paddingTop: '10px',
          margin: '0 5px',
          fontFamily: codeFontFamily(),
          fontSize: '1.1em',
        }}
      >
        {segmentLengths.map((segmentLength, i) => {
          if (i === 0) {
            return <span key={i}>{seq.substr(0, segmentLength).split('').map(char => (segmentSugars[i].value ? segmentSugars[i].value.split('_')[1] : '') + char).join(ps ? '\u2217' : '\u22c5')}</span>;
          } else if (i === segmentLengths.length - 1) {
            return <span key={i}>{seq.substr(seq.length - segmentLength).split('').map(char => ((ps ? '\u2217' : '\u22c5') + (segmentSugars[i].value ? segmentSugars[i].value.split('_')[1] : '') + char))}</span>;
          } else {
            return <span key={i}>{seq.substr(segmentLengths.slice(0, i).reduce((a, b) => a + b), segmentLength).split('').map(char => ((ps ? '\u2217' : '\u22c5') + (segmentSugars[i].value ? segmentSugars[i].value.split('_')[1] : '') + char))}</span>;
          }
        })}
      </div>
      <div style={{ display: 'inline-block' }}>
        {[...Array(rangeNodes.length - 1).keys()].map(i => (
          <div key={i} style={{ display: 'inline-flex' }}>
            <Field<ValueType<SelectOptionStringValueType>>
              name={`segment_${id}_${i}`}
              defaultValue={segmentSugars[i]}
              validate={(value) => {
                if (!value?.value) {
                  return 'Select a sugar type';
                }
              }}
              // validate={value => validateSugar(value, i, ps ? '2' : '1')}
            >
              {({ fieldProps, error, meta: { valid } }) => (
                <>
                  <AsyncSelect
                    {...fieldProps}
                    className="compact-select inline-select"
                    classNamePrefix="react-select"
                    spacing="compact"
                    defaultOptions
                    loadOptions={promiseOptions}
                    value={segmentSugars[i]}
                    onChange={(value: ValueType<SelectOptionStringValueType>): void => {
                      setAntisenseList(prevAntisenseList => {
                        return prevAntisenseList.map(({ antisenseId, segmentSugars, ...rest }) => {
                          if (antisenseId === id) {
                            return {
                              antisenseId,
                              segmentSugars: segmentSugars.map((v, pos) => {
                                if (pos === i) {
                                  if (value) {
                                    return { label: value.label, value: value.value };
                                  } else {
                                    return { label: i + 1, value: v.value };
                                  }
                                } else {
                                  return v;
                                }
                              }),
                              ...rest,
                            };
                          } else {
                            return { antisenseId, segmentSugars, ...rest };
                          }
                        });
                      });
                    }}
                    // validationState={getValidationState(error, valid)}
                    placeholder="Select sugar..."
                    styles={{ control: base => ({ ...base, width: '101px', margin: '5px 0 0' }) }}
                  />
                  {/*{!error && <HelperMessage>&nbsp;</HelperMessage>}*/}
                  {/*{error && <ErrorMessage>{error}</ErrorMessage>}*/}
                </>
              )}
            </Field>
          </div>
        ))}
      </div>
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'space-between',
  //       marginBottom: '20px',
  //     }}
  //   >
  //     <div>
  //       <div
  //         style={{
  //           display: 'inline-flex',
  //           justifyContent: 'space-between',
  //           width: '323px',
  //         }}
  //       >
  //         <div style={{ display: 'inline-flex' }}>
  //           <div style={{ paddingTop: '4px' }}>
  //             <Lozenge
  //               isBold
  //               appearance="default"
  //             >
  //               {id}
  //             </Lozenge>
  //           </div>
  //           <DeleteIcon
  //             onClick={() => {
  //               setTotalCount((prevTotalCount: number) => prevTotalCount - 1);
  //               removeItem(id);
  //             }}
  //           >
  //             <EditorCloseIcon label="Remove item"/>
  //           </DeleteIcon>
  //         </div>
  //         <div>
  //           <InlineEditableField
  //             validate={() => {
  //               // if name exists, update oligoName to '' & antisenseCartItemsVar() to { name: '' }
  //             }}
  //             fieldValue={oligoName || '-'}
  //             // validate={async value => {
  //             //   return await validateOligoName(value, client);
  //             // }}
  //             onConfirm={value => {
  //               setOligoName(value);
  //               setAntisenseList((prevAntisenseList: GapmerProps[]) => {
  //                 return prevAntisenseList.map(({ antisenseId, ...rest }) => {
  //                   if (antisenseId === id) {
  //                     return { antisenseId, ...rest, name: value };
  //                   } else {
  //                     return { antisenseId, ...rest };
  //                   }
  //                 });
  //               });
  //             }}
  //             fitWidth
  //             hideActionButtons={false}
  //           />
  //         </div>
  //       </div>
  //       <div
  //         style={{
  //           display: 'inline-flex',
  //           justifyContent: 'space-between',
  //           width: '315px',
  //           fontFamily: codeFontFamily(),
  //           fontSize: seq.length > 18 ? '1.05em' : '1.1em',
  //         }}
  //       >
  //         <span>{[...seq.substring(0, wing5)].map(char => (char + (ps ? '\u2217' : '\u22c5')))}</span>
  //         <span>{[...seq.substring(wing5, seq.length - wing3)].map(char => (char + (ps ? '\u2217' : '\u22c5')))}</span>
  //         <span>{seq.substring(seq.length - wing3).split('').join(ps ? '\u2217' : '\u22c5')}</span>
  //       </div>
  //     </div>
  //     <div
  //       style={{
  //         width: `${seq.length * 7}px`,
  //         paddingTop: '16px',
  //       }}
  //     >
  //       <Range
  //         min={0}
  //         max={seq.length}
  //         value={[wing5, seq.length - wing3]}
  //         pushable
  //         // pushable={1}
  //         onChange={([value5, value3]: number[]) => {
  //           if (value5 < 1) {
  //             setWing5(1);
  //           } else if (value5 > seq.length - 2) {
  //             setWing5(seq.length - 2);
  //           } else {
  //             setWing5(value5);
  //           }
  //           if (seq.length - value3 < 1) {
  //             setWing3(1);
  //           } else if (value3 < 2) {
  //             setWing3(seq.length - 2);
  //           } else {
  //             setWing3(seq.length - value3);
  //           }
  //         }}
  //         trackStyle={[{ backgroundColor: colors.Y100 }]}
  //         // handleStyle={[{ backgroundColor: '#fff', borderColor: colors.B400 }, { backgroundColor: '#fff', borderColor: colors.B400 }]}
  //         railStyle={{ backgroundColor: '#abe2fb' }}
  //         // tipFormatter={(value: number) => `${value}`}
  //         tipProps={{ prefixCls: 'rc-slider-tooltip-hidden' }}
  //       />
  //       <div
  //         style={{
  //           display: 'inline-flex',
  //           justifyContent: 'space-between',
  //           width: `${seq.length * 7 - 10}px`,
  //           padding: '0 5px',
  //           fontSize: '0.75rem',
  //         }}
  //       >
  //         <span>{wing5}</span>
  //         <span>&minus;</span>
  //         <span>{seq.length - wing5 - wing3}</span>
  //         <span>&minus;</span>
  //         <span>{wing3}</span>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default MixmerItem;
