import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { RightSidePanel } from '@atlaskit/right-side-panel';

import { closePanel } from 'redux/panels';

interface Props {
  isOpen: boolean;
  setCurrentPage: (num: number) => void;
  targetIdRnaId: string;  // Id or rnaId of the target.
  custom: boolean;
  spliced: boolean;
  targetLength: number;
}

function SirnaDuplexFilter({
  isOpen,
  setCurrentPage,
  targetIdRnaId,
  custom,
  spliced,
  targetLength,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  return (
    <RightSidePanel isOpen={isOpen} attachPanelTo="filter-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '56px',
          borderBottom: '2px solid rgb(235, 236, 240)',
          backgroundColor: 'rgb(250, 251, 252)',
          padding: '0 16px',
        }}>
        <div style={{ lineHeight: '56px', verticalAlign: 'middle' }}>
          <span style={{ fontSize: '18px', fontWeight: 600 }}>siRNA filter</span>
        </div>
        <div style={{ padding: '12px 0' }}>
          <Button
            onClick={() => dispatch(closePanel())}
            appearance="subtle"
            iconBefore={<div style={{ width: '24px' }}><CrossIcon label="Close filter" size="small" /></div>}
          />
        </div>
      </div>
    </RightSidePanel>
  );
}

export default SirnaDuplexFilter;
