import React from 'react';
import Spinner from '@atlaskit/spinner';

const Loader = (): JSX.Element => {
  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner size="xlarge" />
    </div>
  );
};

export default Loader;
