import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { TriggerProps } from '@atlaskit/popup';

import { openDrawer } from 'redux/drawers';
import { DrawerType } from '../drawers/drawer-manager';
import { TargetProps } from 'types/antisense';

interface Props {
  target: TargetProps;
}

function TargetListActionsPopup({ target }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  const dispatch = useDispatch();

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-end"
      content={() => (
        <MenuGroup>
          <Section>
            <ButtonItem
              onClick={() => {
                onClose();
                dispatch(openDrawer(DrawerType.CreateAntisenseSeriesDrawer, { target }));
              }}
            >
              Generate antisense series
            </ButtonItem>
            <ButtonItem
              onClick={() => {
                onClose();
                dispatch(openDrawer(DrawerType.CreateSirnaSeriesDrawer, { target }));
              }}
              isDisabled={!target.spliced}
            >
              Generate siRNA series
            </ButtonItem>
          </Section>
          <Section hasSeparator>
            <ButtonItem onClick={onClose} isDisabled>
              Delete target
            </ButtonItem>
          </Section>
        </MenuGroup>
      )}
      trigger={(triggerProps: TriggerProps) => (
        <div style={{ width: '36px' }}>
          <Button
            {...triggerProps}
            onClick={() => setIsOpen(open => !open)}
            iconBefore={<MoreIcon label="More icon" size="medium" />}
            shouldFitContainer
            appearance="subtle"
            isSelected={isOpen}
          />
        </div>
      )}
    />
  );
}

export default TargetListActionsPopup;
