import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { Customer, CustomerEdge } from 'apollo/graphql';
import { CustomerOrderBy } from 'pages/order/customers';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';

interface Props {
  customers: CustomerEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: CustomerOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function CustomerList({
  customers,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Customer>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 6,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Customer>({
        fieldKey: 'fullName',
        fieldName: 'Full name',
        width: 7,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Customer>({
        fieldKey: 'email',
        fieldName: 'Email',
        width: 8,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Customer>({
        fieldKey: 'contactNumber',
        fieldName: 'Contact number',
        width: 6,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Customer>({
        fieldKey: 'company',
        fieldName: 'Company',
        width: 16,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={customers.map(({ node: { id,  fullName, email, contactNumber, company } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: <TableLink to={`/customers/${id}`}>{id}</TableLink>,
            },
            {
              key: id,
              colSpan: 1,
              content: fullName,
            },
            {
              key: id,
              colSpan: 1,
              content: email,
            },
            {
              key: id,
              colSpan: 1,
              content: contactNumber,
            },
            {
              key: id,
              colSpan: 1,
              content: company.name,
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No customers found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default CustomerList;
