/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors } from '@atlaskit/theme';

interface Props {
  children: ReactNode;
  state?: any;
  to: string;
  newTab?: boolean;
}

function TableLink({ children, state, to, newTab = false }: Props): JSX.Element {
  return (
    <ReactRouterLink
      to={to}
      state={state}
      target={newTab ? '_blank': '_self'}
      css={css`
        color: ${colors.B400};

        &:hover {
          color: #3572b0;
        }
      `}
    >
      {children}
    </ReactRouterLink>
  );
}

export default TableLink;
