import { useApolloClient } from '@apollo/client';
import React from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { GetAllCompaniesDocument, GetAllCompaniesQuery } from 'apollo/graphql';

interface Props {
  autoFocus?: boolean;
}

function IdField({ autoFocus = false }: Props): JSX.Element {
  const client = useApolloClient();

  return (
    <Field
      name="id"
      label="ID"
      defaultValue=""
      validate={async value => {
        if (!value) {
          return 'ID is required.';
        } else {
          const { data } = await client.query<GetAllCompaniesQuery>({
            query: GetAllCompaniesDocument,
            variables: {
              filter: {
                id: value,
              },
            },
            fetchPolicy: 'network-only',
          });
          if (data && data.companies.filteredCount) {
            return `Company <${value}> already exists.`;
          }
        }
      }}
    >
      {({ fieldProps, error }) => (
        <>
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder="Enter ID"
            autoFocus={autoFocus}
          />
          {!error && <HelperMessage>&nbsp;</HelperMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
}

export default IdField;
