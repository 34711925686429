import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ApolloClient, ApolloProvider, from } from "@apollo/client";
// import { ApolloClient, ApolloProvider, createHttpLink, from } from '@apollo/client';
import ErrorIcon from "@atlaskit/icon/glyph/error";
import { colors } from "@atlaskit/theme";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createUploadLink } from "apollo-upload-client";
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { sha256 } from 'crypto-hash';

import cache from "./cache";
import { useAuth } from "../contexts/auth";
import { setAlert } from "../redux/alerts";

const createApolloClient = (getToken: any, dispatch?: Dispatch) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        if (dispatch) {
          dispatch(
            setAlert({
              title: message,
              icon: <ErrorIcon primaryColor={colors.R400} label="Error" />,
            })
          );
        }
        // const { serviceName } = extensions;
        // console.error(`[GraphQL error]: Message: ${message}, Service: ${serviceName}, Location: ${locations}, Path: ${path}`);
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });
  // const persistedQueryLink = createPersistedQueryLink({ sha256 });
  const authLink = setContext(async (request, { headers }) => {
    const accessToken = await getToken();
    return {
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    };
  });
  // const httpLink = createHttpLink({ uri: (process.env.NODE_ENV === 'development' && process.env.REACT_APP_GRAPHQL_ENDPOINT) || 'https://nasbio.app/graphql' });
  const uploadLink = createUploadLink({ uri: (process.env.NODE_ENV === "development" && process.env.REACT_APP_GRAPHQL_ENDPOINT) || "https://nasbio.app/graphql" });

  // @ts-ignore
  return new ApolloClient({ cache, link: from([errorLink, createPersistedQueryLink(), authLink, uploadLink]) });
  // return new ApolloClient({ cache, link: from([errorLink, createPersistedQueryLink(), authLink, httpLink]) });
  // return new ApolloClient({ cache, link: from([errorLink, persistedQueryLink, authLink, httpLink]) });
};

const ApolloProviderWithAuth = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { getToken } = useAuth()!;
  const client = createApolloClient(getToken, dispatch);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { createApolloClient };
export default ApolloProviderWithAuth;
