import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { Chromosome, ChromosomeEdge } from 'apollo/graphql';
import { ChromosomeOrderBy } from 'pages/genome/chromosomes';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';

interface Props {
  chromosomes: ChromosomeEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: ChromosomeOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function ChromosomeList({
  chromosomes,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Chromosome>({
        fieldKey: 'id',
        fieldName: 'RefSeq ID',
        width: 3,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Chromosome>({
        fieldKey: 'number',
        fieldName: 'Chromosome',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      {
        isSortable: false,
        width: 6,
        shouldTruncate: false,
        key: 'title',
        colSpan: 1,
        content: 'Title',
      },
      createTableHead<Chromosome>({
        fieldKey: 'length',
        fieldName: 'Length',
        width: 3,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Chromosome>({
        fieldKey: 'organism',
        fieldName: 'Source',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
    ],
  };

  return (
    <StickyHeader>
      <DynamicTableStateless
        head={head}
        rows={chromosomes.map(({ node: { id, acc, number, title, length, organism } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: id,
            },
            {
              key: id,
              colSpan: 1,
              content: number,
            },
            {
              key: id,
              colSpan: 1,
              content: title,
            },
            {
              key: id,
              colSpan: 1,
              content: length.toLocaleString(),
            },
            {
              key: id,
              colSpan: 1,
              content: organism?.commonName || '',
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No chromosomes found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default ChromosomeList;
