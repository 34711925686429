import React from 'react';
import { useMatch } from 'react-router-dom';
import EditIcon from '@atlaskit/icon/glyph/edit';
import { MenuGroup, Section } from '@atlaskit/menu';
import {
  AmiditeSortField,
  BuildingBlockSortField,
  useGetAllAmiditesLazyQuery, useGetAllBackbonesLazyQuery,
  // useGetAllBackbonesLazyQuery,
  useGetAllBasesLazyQuery, useGetAllCouplingsLazyQuery,
  useGetAllNucleosidesLazyQuery,
  useGetAllSugarsLazyQuery,
} from 'apollo/graphql';

import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Chemistry = ({ onClose }: ContentProps): JSX.Element => {
  return (
    <MenuGroup minWidth="240px">
      <Section title="Nucleotide">
        <ButtonLink onClick={onClose} to="/chemistry/sugars">
          Sugars
        </ButtonLink>
        <ButtonLink onClick={onClose} to="/chemistry/bases">
          Bases
        </ButtonLink>
        <ButtonLink onClick={onClose} to="/chemistry/nucleosides">
          Nucleosides
        </ButtonLink>
        <ButtonLink onClick={onClose} to="/chemistry/backbones">
          Backbones
        </ButtonLink>
        <ButtonLink onClick={onClose} to="/chemistry/couplings">
          Couplings
        </ButtonLink>
        <ButtonLink onClick={onClose} to="/chemistry/amidites">
          Amidites
        </ButtonLink>
      </Section>
      <Section title="Tools">
        <ButtonLink
          onClick={onClose}
          to="/chemistry/editor"
          iconBefore={<EditIcon label="Chemistry editor" />}
        >
          Editor
        </ButtonLink>
      </Section>
    </MenuGroup>
  );
};

const ChemistryContent = (): JSX.Element => {
  const match = useMatch('/chemistry/*');

  const [getAllSugars] = useGetAllSugarsLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: { search: '' },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  const [getAllBases] = useGetAllBasesLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: { search: '' },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  const [getAllNucleosides] = useGetAllNucleosidesLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: {
        search: '',
        sugarIds: [],
        baseIds: [],
      },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  const [getAllBackbones] = useGetAllBackbonesLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: { search: '' },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  const [getAllCouplings] = useGetAllCouplingsLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: {
        search: '',
        sugarIds: [],
        baseIds: [],
        backboneIds: [],
      },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  const [getAllAmidites] = useGetAllAmiditesLazyQuery({
    variables: {
      page: 1,
      perPage: 20,
      filter: {
        search: '',
        sugarIds: [],
        baseIds: [],
      },
      orderBy: ['id_ASC' as AmiditeSortField],
    },
  });

  return (
    <span
      onMouseOver={() => {
        getAllSugars();
        getAllBases();
        getAllNucleosides();
        getAllBackbones();
        getAllCouplings();
        getAllAmidites();
      }}
    >
      <PrimaryDropdown
        content={Chemistry}
        text="Chemistry"
        isHighlighted={!!match}
      />
    </span>
  );
};

export default ChemistryContent;
