import React from 'react';
import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format, parseISO as parse } from 'date-fns';

import { Order } from 'apollo/graphql';
import Logo from './logo';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Regular.woff' },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Medium.woff', fontWeight: 500 },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff', fontWeight: 700 },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Black.woff', fontWeight: 900 },
    // { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2' },
    // { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4.woff2', fontWeight: 500 },
    // { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2', fontWeight: 700 },
    // { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4.woff2', fontWeight: 900 },
  ],
});
Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
  body: {
    flexDirection: 'column',
    paddingTop: 40,
    paddingHorizontal: 35,
    paddingBottom: 45,
    fontFamily: 'Roboto',
    fontSize: 11,
  },
  header: {
    paddingTop: 24,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    paddingTop: 24,
  },
  content: {
    flexGrow: 1,
  },
  table: {
    marginTop: 10,
    borderTop: '0.5px solid black',
    borderLeft: '0.3px solid black',
    borderRight: '0.3px solid black',
  },
  tableHeader: {
    padding: 4,
    fontWeight: 700,
    textAlign: 'center',
    borderBottom: '0.2px solid black',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '0.2px solid black',
  },
  fieldLabel: {
    fontWeight: 700,
  },
  footnote: {
    fontSize: 10,
    marginTop: 16,
  },
  acknowledgement: {
    marginTop: 30,
  },
  signatureBox: {
    flexDirection: 'row',
  },
  signature: {
    marginTop: 65,
    borderTop: '0.5px solid black',
  },
  footer: {
    fontSize: 9.5,
    paddingTop: 4,
    paddingLeft: 2,
    borderTop: '0.2px solid black',
  },
});

export interface DoItem {
  serialNumber: string;
  productId: string;
  description: string;
  unitCount: number;
}

interface Props {
  author?: string;
  id?: string;
  poNumber?: string;
  dateIssued?: string;
  order?: Order;
  items?: DoItem[];
}

const DO = ({ author, id = '', dateIssued = '', poNumber = '', order, items = [] }: Props) => {
  return (
    <Document
      title={`DO:${id}`}
      author={author}
      creator="NAS Bioscience Pte. Ltd."
      producer="NAS Bioscience Pte. Ltd."
    >
      <Page size="A4" style={styles.body}>
        <View fixed>
          <Logo />
        </View>
        <View style={styles.header}>
          <Text>Delivery order no.: {id}</Text>
          <Text>Date issued: {dateIssued ? format(parse(dateIssued), 'dd-MMM-yyyy') : '\u2013'}</Text>
          <Text>Purchase order no.: {poNumber}</Text>
        </View>
        <Text style={styles.title}>Delivery Order</Text>
        <View style={styles.content}>
          <View style={styles.table}>
            <Text style={styles.tableHeader}>PURCHASER INFORMATION</Text>
            {order ? (
              <>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4, borderRight: '0.3px solid black' }]}>Customer name</Text>
                  <Text style={{ flexBasis: '40%', padding: 4, borderRight: '0.3px solid black' }}>{order.customer?.company?.name}</Text>
                  <Text style={[styles.fieldLabel, { flexBasis: '16%', padding: 4, borderRight: '0.3px solid black' }]}>Contact person</Text>
                  <Text style={{ flexBasis: '26%', padding: 4 }}>{order.customer?.fullName}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4, borderRight: '0.3px solid black' }]}>Email</Text>
                  <Text style={{ flexBasis: '40%', padding: 4, borderRight: '0.3px solid black' }}>{order.customer?.email}</Text>
                  <Text style={[styles.fieldLabel, { flexBasis: '16%', padding: 4, borderRight: '0.3px solid black' }]}>Contact no.</Text>
                  <Text style={{ flexBasis: '26%', padding: 4 }}>{order.customer?.contactNumber}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4, borderRight: '0.3px solid black' }]}>Shipping address</Text>
                  <Text style={{ padding: 4 }}>{order.customer?.shippingAddress}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4, borderRight: '0.3px solid black' }]}>Billing address</Text>
                  <Text style={{ padding: 4 }}>{order.customer?.billingAddress}</Text>
                </View>
              </>
            ) : null}
          </View>
          <View style={[styles.table, { marginTop: 24, fontSize: 10 }]}>
            <View style={styles.tableRow}>
              <Text style={[styles.fieldLabel, { paddingHorizontal: 4, flexBasis: '37%', borderRight: '0.3px solid black' }]}>S/N</Text>
              <Text style={[styles.fieldLabel, { paddingHorizontal: 4, flexBasis: '47%', borderRight: '0.3px solid black' }]}>Description</Text>
              <Text style={[styles.fieldLabel, { paddingHorizontal: 4 }]}>Unit</Text>
            </View>
            {items?.map(({ serialNumber, description, unitCount }, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={{ flexBasis: '37%', paddingHorizontal: 4, borderRight: '0.3px solid black' }}>{serialNumber.replace('\\u2013', '\u2013')}</Text>
                <Text style={{ flexBasis: '47%', paddingHorizontal: 4, borderRight: '0.3px solid black' }}>{description.replace('\\n', '\n')}</Text>
                <Text style={{ paddingHorizontal: 4 }}>{unitCount ? unitCount : '\u2013'}</Text>
              </View>
            ))}
          </View>
          <View style={styles.footnote}>
            <Text style={{ fontWeight: 700 }}>Notes</Text>
            <Text>1. Any discrepancies must be clarified immediately, otherwise NAS Bioscience Pte. Ltd. reserves the
              right not to honour any subsequent claims.</Text>
          </View>
          <View style={styles.acknowledgement}>
            <Text>Goods/services received in good order and condition by:</Text>
            <View style={styles.signatureBox}>
              <Text style={styles.signature}>Authorized Signature and Company Stamp</Text>
            </View>
            <Text>Name:</Text>
            <Text>Date:</Text>
          </View>
        </View>
        <Text style={styles.footer}>
          NAS Bioscience Pte. Ltd. | Reg. No. 202206102K | 21 Nanyang Link, SPMS PAP-05-08, Singapore 637371
        </Text>
      </Page>
    </Document>
  );
};

export default DO;
