import React from 'react';
import { ButtonItem } from '@atlaskit/menu';

import { useGetAntisenseSequenceBlastNextBestIdsQuery } from 'apollo/graphql';

interface RnaNotFoundProps {
  id: string;
  index: number;
}

const RnaNotFound: React.FC<RnaNotFoundProps> = ({ id, index }): JSX.Element => {
  const { data } = useGetAntisenseSequenceBlastNextBestIdsQuery({
    variables: { id },
  });

  return (
    <ButtonItem key={index}>
      {data?.antisenseSequence.blastNextBestIds && data?.antisenseSequence.blastNextBestIds[index]}
    </ButtonItem>
  );
};

export default RnaNotFound;
