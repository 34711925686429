import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Checkbox } from '@atlaskit/checkbox';

import { sampleCartItemsVar } from 'apollo/cache';

interface Props {
  id: string;
  isInCart: boolean;
}

function SampleCheckbox({ id, isInCart }: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(isInCart);
  const sampleCartItems = useReactiveVar(sampleCartItemsVar);

  useEffect(() => {
    setIsChecked(
      !!sampleCartItems.find(({ id: sampleId }) => sampleId === id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sampleCartItems.length]);

  return (
    <div style={{ paddingLeft: "5px" }}>
      <Checkbox
        value={id}
        key={isChecked.toString()}
        isChecked={isChecked}
        onChange={(e) => {
          const { checked } = e.target;
          setIsChecked(checked);

          if (checked) {
            sampleCartItemsVar([...sampleCartItemsVar(), { id }]);
          } else {
            sampleCartItemsVar(sampleCartItemsVar().filter(({ id: sampleId }) => sampleId !== id));
          }
        }}
      />
    </div>
  )
}

export default SampleCheckbox;
