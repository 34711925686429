import React from "react";
import { useSelector } from "react-redux";

import { selectDrawer } from "redux/drawers";
// import CreateAmiditeDrawer from './create-amidite-drawer';
import CreateAntisenseSeriesDrawer from "./create-antisense-series-drawer";
// import CreateBackboneDrawer from './create-backbone-drawer';
// import CreateBaseDrawer from './create-base-drawer';
// import CreateNucleotideDrawer from './create-nucleotide-drawer';
import CreateSirnaSeriesDrawer from "./create-sirna-series-drawer";
// import CreateSugarDrawer from './create-sugar-drawer';
import CreateTargetDrawer from "./create-target-drawer";
import CreateUserDrawer from "./create-user-drawer";
import AntisenseCartDrawer from "./antisense-cart-drawer";
import SirnaCartDrawer from './sirna-cart-drawer';
import CreateOligoDrawer from './create-oligo-drawer';
import CreateProductDrawer from './create-product-drawer';
import CreateCompanyDrawer from './create-company-drawer';
import CreateCustomerDrawer from './create-customer-drawer';
import CreateOrderDrawer from './create-order-drawer';
import CreateSampleDrawer from './create-sample-drawer';

export enum DrawerType {
  CreateUserDrawer = "CreateUserDrawer",
  CreateTargetDrawer = "CreateTargetDrawer",
  CreateAntisenseSeriesDrawer = "CreateAntisenseSeriesDrawer",
  CreateSirnaSeriesDrawer = "CreateSirnaSeriesDrawer",
  // CreateSugarDrawer = 'CreateSugarDrawer',
  // CreateBaseDrawer = 'CreateBaseDrawer',
  // CreateBackboneDrawer = 'CreateBackboneDrawer',
  // CreateNucleotideDrawer = 'CreateNucleotideDrawer',
  // CreateAmiditeDrawer = 'CreateAmiditeDrawer',
  AntisenseCartDrawer = "AntisenseCartDrawer",
  SirnaCartDrawer = "SirnaCartDrawer",
  CreateOligoDrawer = 'CreateOligoDrawer',
  CreateProductDrawer = 'CreateProductDrawer',
  CreateCompanyDrawer = 'CreateCompanyDrawer',
  CreateCustomerDrawer = 'CreateCustomerDrawer',
  CreateOrderDrawer = 'CreateOrderDrawer',
  CreateSampleDrawer = 'CreateSampleDrawer',
}

const drawerLookup: Record<DrawerType, React.FC<any>> = {
  CreateUserDrawer,
  CreateTargetDrawer,
  CreateAntisenseSeriesDrawer,
  CreateSirnaSeriesDrawer,
  // CreateSugarDrawer,
  // CreateBaseDrawer,
  // CreateBackboneDrawer,
  // CreateNucleotideDrawer,
  // CreateAmiditeDrawer,
  AntisenseCartDrawer,
  SirnaCartDrawer,
  CreateOligoDrawer,
  CreateProductDrawer,
  CreateCompanyDrawer,
  CreateCustomerDrawer,
  CreateOrderDrawer,
  CreateSampleDrawer,
};

function DrawerManager(): JSX.Element | null {
  let renderedDrawer: JSX.Element | null = null;

  const { drawerType, isOpen, ...drawerProps } = useSelector(selectDrawer);
  if (drawerType) {
    const DrawerComponent = drawerLookup[drawerType];

    renderedDrawer = <DrawerComponent isOpen={isOpen} {...drawerProps} />;
  }

  return renderedDrawer;
}

export default DrawerManager;
