import React from 'react';
// import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { MenuGroup, Section } from '@atlaskit/menu';
// import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';

// import {
//   ChromosomeSortField,
//   GeneSortField,
//   OrganismSelect,
//   RnaSortField,
//   useGetAllChromosomesLazyQuery,
//   useGetAllGenesLazyQuery,
//   useGetAllRnasLazyQuery,
// } from 'apollo/graphql';
// import { DrawerType } from 'components/drawers/drawer-manager';
// import { openDrawer } from 'redux/drawers';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Oligo = ({ onClose }: ContentProps): JSX.Element => {
  // const dispatch = useDispatch();

  return (
    <MenuGroup minWidth="240px">
      <Section>
        <ButtonLink to="/oligos" onClick={onClose}>
          View all oligos
        </ButtonLink>
        {/*<ButtonItem*/}
        {/*  onClick={() => {*/}
        {/*    onClose();*/}
        {/*    // dispatch(openDrawer(DrawerType.CreateOligoDrawer));*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Create oligo*/}
        {/*</ButtonItem>*/}
      </Section>
    </MenuGroup>
  );
};

const OligoContent = (): JSX.Element => {
  const match = useMatch('/oligos/*');

  // const [getAllChromosomes] = useGetAllChromosomesLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 25,
  //     filter: {
  //       organism: OrganismSelect.None,
  //     },
  //     orderBy: [ChromosomeSortField.OrganismAsc],
  //   },
  // });
  //
  // const [getAllGenes] = useGetAllGenesLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 20,
  //     filter: {
  //       organism: OrganismSelect.Human,
  //     },
  //     orderBy: [GeneSortField.IdAsc],
  //   },
  // });
  //
  // const [getAllRnas] = useGetAllRnasLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 20,
  //     filter: {
  //       excludeModel: true,
  //       organism: OrganismSelect.Human,
  //       id: '',
  //       symbol: '',
  //       title: '',
  //       chrNo: '',
  //       chrStart: '',
  //       chrEnd: '',
  //       chrStrand: '',
  //       rnaLength: '',
  //       exonCount: '',
  //       mrnaLength: '',
  //       utr5Length: '',
  //       utr3Length: '',
  //     },
  //     orderBy: [RnaSortField.IdAsc],
  //   },
  // });

  return (
    <span
      onMouseOver={() => {
        // getAllChromosomes();
        // getAllGenes();
        // getAllRnas();
      }}
    >
      <PrimaryDropdown
        content={Oligo}
        text="Oligos"
        isHighlighted={!!match}
      />
    </span>
  );
};

export default OligoContent;
