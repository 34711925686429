import { RootState } from '../store';
import { PanelAction } from './actions';
import { Panel, PanelActionType } from './types';

export const selectPanel = (rootState: RootState) => rootState.panel;

const panelReducer = (
  state: Panel = { panelType: null, isOpen: false },
  action: PanelAction,
) => {
  switch (action.type) {
    case PanelActionType.MOUNT_PANEL:
      return { isOpen: false, ...action.payload };
    case PanelActionType.OPEN_PANEL:
      return { ...state, isOpen: true };
    case PanelActionType.CLOSE_PANEL:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};
export default panelReducer;
