import React, { useState } from 'react';
import { Profile } from '@atlaskit/atlassian-navigation';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import Avatar from '@atlaskit/avatar';

import { useAuth } from 'contexts/auth';
import ButtonLink from '../button-link';
import { ContentProps } from '../primary-items/primary-dropdown';

const ProfileMenu = ({ onClose }: ContentProps): JSX.Element => {
  const { currentUser, logout } = useAuth()!;

  return (
    <MenuGroup css={{ width: '200px' }}>
      <Section title={currentUser!.username}>
        <ButtonLink to="/profile/me" onClick={onClose}>
          Profile
        </ButtonLink>
        <ButtonLink to="/account/security" onClick={onClose}>
          Change password
        </ButtonLink>
      </Section>
      <Section hasSeparator>
        <ButtonItem
          onClick={() => {
            onClose();
            logout();
          }}
        >
          Log out
        </ButtonItem>
      </Section>
    </MenuGroup>
  );
};

const ProfilePopup = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => setIsOpen(open => !open);
  const onClose = () => setIsOpen(false);

  const { currentUser } = useAuth()!;

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      content={() => <ProfileMenu onClose={onClose} />}
      placement="bottom-end"
      trigger={triggerProps => (
        <Profile
          {...triggerProps}
          tooltip="Your profile and settings"
          onClick={onClick}
          isSelected={isOpen}
          icon={<Avatar src={`${currentUser!.photoUrl}`} />}
        />
      )}
    />
  );
};

export default ProfilePopup;
