import React from "react";
import Badge from "@atlaskit/badge";

import { antisenseCartItemsVar } from "apollo/cache";

function AntisenseBadge(): JSX.Element | null {
  return antisenseCartItemsVar().length > 0 ? (
    <div style={{ marginTop: "5px" }}>
      <Badge appearance="added" max={99}>
        {antisenseCartItemsVar().length}
      </Badge>{" "}
      sequence{antisenseCartItemsVar().length > 1 && "s"} selected
    </div>
  ) : null;
}

export default AntisenseBadge;
