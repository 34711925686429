import React, { useEffect, useRef } from 'react';

interface Props {
  id: string;
  structure: string;
  columns?: number;
  height?: number;
}

function Structure({ id, structure, columns = 4, height = 240 }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chemViewer = new window.Kekule.ChemWidget.Viewer(ref.current);
    chemViewer.setDimension((window.innerWidth - 138) / columns - 18, `${height}px`);
    if (structure) chemViewer.setChemObj(window.Kekule.IO.loadFormatData(structure, 'cml'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, structure]);

  return (
    <div ref={ref} style={{ height: `${height + 5}px`, textAlign: 'center' }} />
  );
}

export default Structure;
