import React from 'react';
import { TopNavigation } from '@atlaskit/page-layout';
import { AtlassianNavigation } from '@atlaskit/atlassian-navigation';

import Brand from './brand';
import primaryItems from './primary-items';
import ProfilePopup from './secondary-items/profile-popup';

const Navbar = (): JSX.Element => {
  return (
    <div style={{ zIndex: 4 }}>
      <TopNavigation id="navigation" isFixed>
        <AtlassianNavigation
          label="NASBio"
          renderProductHome={Brand}
          primaryItems={primaryItems}
          renderProfile={ProfilePopup}
        />
      </TopNavigation>
    </div>
  );
};

export default Navbar;
