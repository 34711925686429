import React from 'react';

function NasBioLogo() {
  return (
    <div style={{ width: '140px', height: '32px', paddingLeft: '1px', marginRight: '2px', marginBottom: '-4px', marginLeft: '1px' }}>
      <svg
        className={`inline pointer-events-none`}
        viewBox="0 0 116 16"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="nasbio-logo"
      >
        <title id="nasbio-logo" lang="en">
          NAS Bioscience logo
        </title>
        <g fill="#c52707">
          <path
            className="a"
            d="M89.37,486.31v-13.5h3.4c2.06,6.36,2.52,7.9,2.66,8.5h.06c-.14-1.7-.18-3.92-.18-6v-2.48h2.74v13.5H94.71c-2.14-7-2.54-8.38-2.68-9H92c.1,1.62.16,4.12.16,6.32v2.64Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="a"
            d="M102.73,483.41l-.46,2.9h-3.1l2.66-13.5h4.36l2.7,13.5h-3.22l-.48-2.9Zm2.06-2.6c-.32-2-.62-4.18-.78-5.42h-.1c-.14,1.3-.46,3.44-.78,5.42Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="a"
            d="M112.71,482.39c.08,1.12.38,1.88,1.32,1.88s1.18-.6,1.18-1.38-.46-1.48-2.06-2.3c-2.34-1.2-3.22-2.22-3.22-4.2,0-2.32,1.4-3.84,4.08-3.84,3.16,0,4.06,2,4.08,4h-3c-.06-.94-.24-1.74-1.12-1.74-.68,0-1,.44-1,1.2s.3,1.18,1.78,1.9c2.5,1.26,3.56,2.34,3.56,4.6s-1.3,4-4.34,4c-3.28,0-4.28-1.88-4.34-4.18Z"
            transform="translate(-89.37 -472.17)"
          />
        </g>
        <g fill="#19216b">
          <path
            className="b"
            d="M123.25,472.81h4.5c2.42,0,3.76,1.22,3.76,3.46,0,1.68-.76,2.48-1.6,2.78a3.16,3.16,0,0,1,2,3.26c0,2.66-1.64,4-4.24,4h-4.4Zm3.86,5.26c1.14,0,1.4-.46,1.4-1.5s-.42-1.5-1.38-1.5h-.86v3Zm-.84,6h1c1,0,1.46-.46,1.46-1.88s-.4-1.94-1.64-1.94h-.8Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M133.21,475.79h3.06v10.52h-3.06Zm0-1.14v-2.48h3v2.48Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M146.05,480.93c0,4.18-1.64,5.58-4.2,5.58s-4.12-1.36-4.12-5.52c0-4,1.82-5.38,4.22-5.38S146.05,477.15,146.05,480.93Zm-5.22,0c0,2.26.22,3.16,1.1,3.16s1-.92,1-3.16-.24-3-1-3S140.83,478.73,140.83,481Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M149.89,483.05c0,.64.24,1.28,1,1.28a.76.76,0,0,0,.86-.86c0-.6-.28-.9-1.42-1.32-2.44-.9-3.12-1.74-3.12-3.4s1-3.14,3.6-3.14c3.08,0,3.66,2,3.66,3.28h-2.76c0-.46-.06-1.2-.88-1.2a.69.69,0,0,0-.74.76c0,.56.32.82,1.52,1.26,2.24.82,3.1,1.72,3.1,3.4,0,2.12-1.22,3.38-3.88,3.38-2.88,0-3.82-1.68-3.84-3.44Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M163.59,482.57c0,1.48-.56,3.92-3.94,3.92-3.22,0-4-2.36-4-5.52s1.16-5.36,4.14-5.36c3.44,0,3.76,2.7,3.78,3.82h-3c0-.78-.06-1.54-.82-1.54s-1,.76-1,3.08.22,3.22,1,3.22.88-.76.92-1.62Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M164.89,475.79H168v10.52h-3.06Zm0-1.14v-2.48h3v2.48Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M172.45,481.79c0,1.36.14,2.42,1.1,2.42s1-.72,1-1.2h2.92c-.06.72-.38,3.5-4,3.5s-4.08-2.68-4.08-5.54c0-3.54,1.42-5.36,4.14-5.36,3.1,0,4,2.12,4,5,0,.26,0,1,0,1.16Zm2.08-1.94c0-1,0-2-1-2s-1,1-1,2Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M179,479.09c0-2.16,0-2.76,0-3.3h2.94a6.64,6.64,0,0,1,.08,1,2.58,2.58,0,0,1,2.3-1.14c1.78,0,2.64,1.12,2.64,3.14v7.56h-3.06v-7c0-.8-.14-1.26-.86-1.26s-1,.34-1,1.52v6.74H179Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M196.25,482.57c0,1.48-.56,3.92-3.94,3.92-3.22,0-4-2.36-4-5.52s1.16-5.36,4.14-5.36c3.44,0,3.76,2.7,3.78,3.82h-3c0-.78-.06-1.54-.82-1.54s-1,.76-1,3.08.22,3.22,1,3.22.88-.76.92-1.62Z"
            transform="translate(-89.37 -472.17)"
          />
          <path
            className="b"
            d="M200.39,481.79c0,1.36.14,2.42,1.1,2.42s1-.72,1-1.2h2.92c-.06.72-.38,3.5-4,3.5s-4.08-2.68-4.08-5.54c0-3.54,1.42-5.36,4.14-5.36,3.1,0,4,2.12,4,5,0,.26,0,1,0,1.16Zm2.08-1.94c0-1,0-2-1-2s-1,1-1,2Z"
            transform="translate(-89.37 -472.17)"
          />
        </g>
      </svg>
    </div>
  );
}

export default NasBioLogo;
