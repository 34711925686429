import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Checkbox } from "@atlaskit/checkbox";

import { antisenseCartItemsVar } from "apollo/cache";

interface Props {
  targetId: string;
  targetLength: number;
  symbol: string;
  id: string;
  start: number;
  isInCart: boolean;
}

function AntisenseCheckbox({
  targetId,
  targetLength,
  symbol,
  id,
  start,
  isInCart,
}: Props): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(isInCart);
  const antisenseCartItems = useReactiveVar(antisenseCartItemsVar);

  useEffect(() => {
    setIsChecked(
      !!antisenseCartItems.find(({ antisenseId }) => antisenseId === id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, antisenseCartItems.length]);

  return (
    <div style={{ paddingLeft: "5px" }}>
      <Checkbox
        value={id}
        key={isChecked.toString()}
        isChecked={isChecked}
        onChange={(e) => {
          const { checked } = e.target;
          setIsChecked(checked);

          if (checked) {
            antisenseCartItemsVar([
              ...antisenseCartItemsVar(),
              {
                targetId,
                targetLength,
                symbol,
                antisenseId: id,
                start,
              },
            ]);
          } else {
            antisenseCartItemsVar(
              antisenseCartItemsVar().filter(
                ({ antisenseId }) => antisenseId !== id
              )
            );
          }
        }}
      />
    </div>
  );
}

export default AntisenseCheckbox;
