import '@atlaskit/css-reset/dist/bundle.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'rc-slider/assets/index.css';
import './components/logos/font-awesome';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/auth';
import App from './app';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
