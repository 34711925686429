import 'styles/inline-select.css';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { Code } from '@atlaskit/code';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { AsyncSelect } from '@atlaskit/select';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Drawer from '@atlaskit/drawer';
import Form, { ErrorMessage, Field, FormFooter, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import Button, { LoadingButton } from '@atlaskit/button';

import {
  GetAllNucleosidesDocument,
  GetAllNucleosidesQuery,
  GetAllOligosDocument,
  GetAllOligosQuery,
  GetCouplingByCompositionDocument,
  GetCouplingByCompositionQuery,
  useCreateOligoMutation,
} from 'apollo/graphql';
import { closeDrawer, DrawerProps } from 'redux/drawers';
import { SelectOptionStringValueType } from 'types/select-option';
import { setAlert } from 'redux/alerts';
import BackboneToggler from '../oligo/backbone-toggler';


const LengthInput = styled.div`
  display: inline-block;
  width: 80px;

  & > div {
    margin-top: 8px;
  }

  & > div > div > input {
    padding: 6px;
  }
`;

interface Props extends DrawerProps {
  isOpen: boolean;
}

function CreateOligoDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const [count, setCount] = useState<number>(0);
  const [count2, setCount2] = useState<number>(0);
  const reset = () => setCount(prevCount => prevCount + 1);
  const resetAll = () => {
    setCount(0);
    setCount2(prevCount => prevCount + 1);
    setOligoSeq([{ label: '1', value: '' }]);
    setBbIds([]);
    setCpIds(['']);
    setIdtSymbols(['']);
    setSequence('X');
  };

  const [sequence, setSequence] = useState<string>('X');
  const [oligoSeq, setOligoSeq] = useState<SelectOptionStringValueType[]>([{ label: '1', value: '' }]);
  const [bbIds, setBbIds] = useState<('1' | '2')[]>([]);
  const [cpIds, setCpIds] = useState<string[]>(['']);
  const [idtSymbols, setIdtSymbols] = useState<string[]>(['']);
  // const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    setOligoSeq(prevOligoSeq => {
      if (sequence.length > prevOligoSeq.length) {
        return [...prevOligoSeq.map((oligoSeq, i) => sequence[i] === 'X' ? ({
          label: `${i + 1}`,
          value: '',
        }) : oligoSeq), ...[...Array(sequence.length - prevOligoSeq.length).keys()].map(i => ({
          label: (i + prevOligoSeq.length + 1).toString(),
          value: '',
        }))];
      } else if (sequence.length < prevOligoSeq.length) {
        return prevOligoSeq.slice(0, sequence.length).map((oligoSeq, i) => sequence[i] === 'X' ? ({
          label: `${i + 1}`,
          value: '',
        }) : oligoSeq);
      } else {
        return sequence.split('').map((s, i) => typeof prevOligoSeq[i]?.label === 'string' && (prevOligoSeq[i]?.label as string).includes(s)
          ? prevOligoSeq[i]
          : ({ label: i + 1, value: '' }));
      }
    });
    setBbIds(prevBbIds => {
      if (sequence.length > prevBbIds.length) {
        return [...prevBbIds, ...[...Array(sequence.length - prevBbIds.length - 1).keys()].map(i => ('1' as '1'))];
      } else if (sequence.length < prevBbIds.length) {
        return prevBbIds.slice(0, sequence.length - 1);
      } else {
        return prevBbIds;
      }
    });
    setCpIds(prevCpIds => {  // TODO: Remove last cp on extension & slicing
      if (sequence.length > prevCpIds.length) {
        return [...prevCpIds.map((cpId, i) => sequence[i] === 'X' ? '' : cpId), ...[...Array(sequence.length - prevCpIds.length).keys()].map(i => '')];
      } else if (sequence.length < prevCpIds.length) {
        return prevCpIds.slice(0, sequence.length).map((cpId, i) => sequence[i] === 'X' ? '' : cpId);
      } else {
        return prevCpIds;
      }
    });
    setIdtSymbols(prevIdtSeqs => {
      if (sequence.length > prevIdtSeqs.length) {
        return [...prevIdtSeqs.map((idtSeq, i) => sequence[i] === 'X' ? '' : idtSeq), ...[...Array(sequence.length - prevIdtSeqs.length).keys()].map(i => '')];
      } else if (sequence.length < prevIdtSeqs.length) {
        return prevIdtSeqs.slice(0, sequence.length).map((idtSeq, i) => sequence[i] === 'X' ? '' : idtSeq);
      } else {
        return [...prevIdtSeqs.map((idtSeq, i) => sequence[i] === 'X' ? '' : idtSeq)];
      }
    });
  }, [sequence]);

  const [create, { loading }] = useCreateOligoMutation({
    onCompleted: ({ createOligo: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success"/>
          : <ErrorIcon primaryColor={colors.R400} label="Error"/>,
      }));
      if (success) {
        resetAll();
      }
    },
    refetchQueries: [GetAllOligosDocument],
  });

  // useEffect(() => {
  //   setPrice(oligoSeq.every(({ value }) => value) ? 1 : 0);
  // }, [oligoSeq]);

  // const { data, previousData } = useGetOligoCostQuery({
  //   variables: { ntIds: oligoSeq.map(({ value }) => value.split('_')[0]) },
  // });

  console.log(sequence);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeDrawer());
        resetAll();
      }}
      width="full"
    >
      <div style={{ paddingRight: '16px' }}>
        <Form
          onSubmit={({ seq }: { seq: string }) => {
            if (!seq.length) {
              return { seq: 'Sequence is empty' };
            }
            if (seq !== sequence) {
              setSequence(seq);
              reset();
            }
          }}
        >
          {({ formProps }: any) => (
            <form {...formProps} noValidate>
              <Field
                key={count2}
                // label="Sequence"
                name="seq"
                defaultValue=""
                validate={(value) => {
                  if (value && !value.toUpperCase().split('').every(v => ['A', 'C', 'G', 'T', 'U', 'I', 'X'].includes(v))) {
                    return 'Only letters A/C/G/T/U/I/X are allowed.';
                  } else if (value && value.length > 80) {
                    return 'Maximum 80 characters allowed.';
                  }
                }}
              >
                {({ fieldProps, error }: any) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        gap: '8px',
                      }}
                    >
                      <TextArea
                        {...fieldProps}
                        resize={'auto'}
                        minimumRows={4}
                        isMonospaced
                        // placeholder="Enter sequence here"
                        style={{ flexBasis: '404px', flexGrow: '0' }}
                      />
                      <Button
                        type="submit"
                        appearance="default"
                      >
                        Update
                      </Button>
                    </div>
                    {!error && (
                      <HelperMessage>Enter sequence here. Only letters A/C/G/T/U/I/X are allowed.</HelperMessage>
                    )}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </form>
          )}
        </Form>
        <Form
          onSubmit={({ name, ...values }: { name: string; [key: string]: string }) => {
            const errors: { [key: string]: string } = {};

            cpIds.forEach((cpId, i) => {
              if (!cpId) {
                errors[`cpId${i}`] = 'Select a nucleoside';
              }
            });

            if (Object.keys(errors).length > 0) {
              return errors;
            }

            console.log(name);
            console.log(cpIds.map(cpId => parseInt(cpId)));

            create({
              variables: {
                name,
                cpIds,
              },
            });
          }}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '4px',
                marginTop: '20px',
                marginBottom: '20px',
              }}>
                <LengthInput>
                  <Textfield
                    autoComplete="off"
                    placeholder="Length"
                    type="number"
                    min={1}
                    max={60}
                    value={sequence.length}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.persist();
                      if (parseInt(e.target.value) < 1) {
                        setSequence('X');
                      } else if (parseInt(e.target.value) > 60) {
                        setSequence(prevSeq => prevSeq + 'X'.repeat(60 - prevSeq.length));
                      } else if (parseInt(e.target.value)) {
                        setSequence(prevSeq => {
                          if (parseInt(e.target.value) > prevSeq.length) {
                            return prevSeq + 'X'.repeat(parseInt(e.target.value) - prevSeq.length);
                          } else {
                            return prevSeq.slice(0, parseInt(e.target.value));
                          }
                        });
                      } else {
                        setSequence('X');
                      }
                    }}
                    elemAfterInput={<span style={{ paddingRight: '8px' }}>nt</span>}
                  />
                </LengthInput>
                <div style={{ width: '320px' }}>
                  <Field
                    key={count2}
                    name="name"
                    // label="Name"
                    isRequired
                    defaultValue=""
                    validate={async (value) => {
                      if (!value) {
                        return 'Name is required.';
                      } else if (value.length > 64) {
                        return 'Name should not exceed 64 characters.';
                      } else {
                        const { data } = await client.query<GetAllOligosQuery>({
                          query: GetAllOligosDocument,
                          variables: {
                            filter: {
                              name: value,
                            },
                          },
                          fetchPolicy: 'network-only',
                        });
                        if (data && data.oligos.filteredCount) {
                          return `Oligo <${value}> already exists.`;
                        }
                      }
                    }}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <Textfield
                          {...fieldProps}
                          autoComplete="off"
                          placeholder="Enter oligo name"
                          // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          //   setOligoName(e.target.value);
                          // }}
                          // autoFocus={autoFocus}
                        />
                        {!error && <HelperMessage>&nbsp;</HelperMessage>}
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </div>
                <div key={sequence} style={{ alignSelf: 'flex-start', marginTop: '18px', marginLeft: '12px' }}>
                  <Code style={{ fontSize: '16px', backgroundColor: 'transparent' }}>
                    5'-&nbsp;
                    {idtSymbols.map((idtSymbol, i) => {
                      const display = idtSymbol ? ['A', 'C', 'G', 'T', 'rA', 'rC', 'rG', 'rU', 'mA', 'mC', 'mG', 'mU', '+A', '+C', '+G', '+T'].includes(idtSymbol) ? idtSymbol : `/${i === 0 ? '5' : i === idtSymbols.length - 1 ? '3' : 'i'}${idtSymbol}/` : '-';

                      return `${display}${bbIds[i] === '2' ? '\u2217' : ''}${((i % 3 === 2) && (i !== idtSymbols.length - 1)) ? ' ' : ''}`;
                    })}
                    &nbsp;-3'
                  </Code>
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '3px', rowGap: '20px', width: '900px' }}>
                {[...Array(sequence.length).keys()].map(i => (
                  <div
                    key={`${sequence[i]}${i}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      // gap: '3px',
                      // height: '56px',
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                      <div
                        style={{
                          WebkitUserSelect: 'none',
                          MozUserSelect: 'none',
                          msUserSelect: 'none',
                        }}
                      >
                        <Code style={{ backgroundColor: 'transparent' }}>
                          {i % 10 === 0 ? (
                            <div
                              style={{
                                display: 'inline-block',
                                width: '14px',
                                textAlign: 'center',
                              }}
                            >
                              {i + 1}
                            </div>
                          ) : <span>&nbsp;</span>}
                        </Code>
                      </div>
                      {i !== sequence.length - 1 ? (
                        <BackboneToggler
                          pos={i}
                          bbId={bbIds[i]}
                          oligoSeq={oligoSeq}
                          setBbIds={setBbIds}
                          setCpIds={setCpIds}
                        />
                      ) : null}
                    </div>
                    {/*<div style={{ width: 'fit-content' }}>*/}
                    <Field name={`cpId${i}`}>
                      {({ fieldProps, error }) => (
                        <AsyncSelect
                          key={`${sequence[i]}${i}`}
                          // key={count}
                          {...fieldProps}
                          className="compact-select inline-select"
                          classNamePrefix="react-select"
                          spacing="compact"
                          defaultOptions
                          // cacheOptions
                          loadOptions={(inputValue: string) => {
                            return client.query<GetAllNucleosidesQuery>({
                              query: GetAllNucleosidesDocument,
                              variables: {
                                perPage: 50,
                                filter: {
                                  search: inputValue,
                                  ...(sequence[i] !== 'X' && { baseSymbol: sequence[i] }),
                                  ...(i !== (sequence.length - 1) && { backTerminating: false }),
                                },
                              },
                            })
                              .then(({ data: { nucleosides } }: ApolloQueryResult<GetAllNucleosidesQuery>) => {
                                const options = nucleosides.edges.map(({
                                  node: {
                                    id,
                                    symbol,
                                    idtSymbol,
                                    sugarId,
                                    baseId,
                                  },
                                }) => ({
                                  label: symbol,
                                  // label: symbol.replace(/\(|\)/g, ''),
                                  value: `${sugarId}_${baseId}_${idtSymbol || ''}`,
                                }));
                                options.unshift({ label: '\u2212', value: '' });

                                if (sequence[i] !== 'X') {
                                  setOligoSeq(prevOligoSeq => {
                                    return prevOligoSeq.map((v, pos) => {
                                      if (pos === i) {
                                        return options[1];
                                        // return options.length === 1 ? v : ((options[1].value !== v.value) && (v.value !== '')) ? v : options[1];
                                      } else {
                                        return v;
                                      }
                                    });
                                  });

                                  setIdtSymbols(prevIdtSymbols => prevIdtSymbols.map((idtSymbol, pos) => {
                                    if (pos === i) {
                                      return options[1]?.value.split('_')[2];
                                      // return options.length === 1 ? idtSymbol : ((options[1]?.value.split('_')[2] !== idtSymbol) && (idtSymbol !== '')) ? idtSymbol : options[1]?.value.split('_')[2];
                                    } else {
                                      return idtSymbol;
                                    }
                                  }));

                                  client.query<GetCouplingByCompositionQuery>({
                                    query: GetCouplingByCompositionDocument,
                                    variables: {
                                      filter: {
                                        sugarId: options[1].value.split('_')[0],
                                        baseId: options[1].value.split('_')[1],
                                        backboneId: i === sequence.length - 1 ? '0' : bbIds[i] ?? '1',
                                      },
                                    },
                                  })
                                    .then(({ data: { couplingByComposition } }) => {
                                      setCpIds(prevCpIds => prevCpIds.map((cpId, pos) => {
                                        if (pos === i) {
                                          return couplingByComposition ? couplingByComposition.id : '';
                                        } else {
                                          return cpId;
                                        }
                                      }));
                                    });
                                }

                                return options;
                              });
                          }}
                          // loadOptions={i === oligoLength - 1 ? backTerminatingCouplingOptions : backExtensibleCouplingOptions}
                          value={oligoSeq[i]}
                          onChange={(value) => {
                            console.log(value);
                            if (value) {
                              setOligoSeq(prevOligoSeq => {
                                return prevOligoSeq.map((v, pos) => {
                                  if (pos === i) {
                                    if (value) {
                                      return {
                                        label: value.label === '\u2212' ? (i + 1).toString() : value.label,
                                        value: value.value,
                                      };
                                    } else {
                                      return { label: (i + 1).toString(), value: '' };
                                    }
                                  } else {
                                    return v;
                                  }
                                });
                              });
                              if (value.value) {
                                client.query<GetCouplingByCompositionQuery>({
                                  query: GetCouplingByCompositionDocument,
                                  variables: {
                                    filter: {
                                      sugarId: value.value.split('_')[0],
                                      baseId: value.value.split('_')[1],
                                      backboneId: i === sequence.length - 1 ? '0' : bbIds[i],
                                    },
                                  },
                                })
                                  .then(({ data: { couplingByComposition } }) => {
                                    setCpIds(prevCpIds => prevCpIds.map((cpId, pos) => {
                                      if (pos === i) {
                                        return couplingByComposition ? couplingByComposition.id : '';
                                      } else {
                                        return cpId;
                                      }
                                    }));
                                    setIdtSymbols(prevIdtSeqs => prevIdtSeqs.map((idtSymbol, pos) => {
                                      if (pos === i) {
                                        return value.value.split('_')[2] || '';
                                      } else {
                                        return idtSymbol;
                                      }
                                    }));
                                  });
                              } else {
                                setCpIds(prevCpIds => prevCpIds.map((cpId, pos) => {
                                  if (pos === i) {
                                    return '';
                                  } else {
                                    return cpId;
                                  }
                                }));
                                setIdtSymbols(prevIdtSeqs => prevIdtSeqs.map((idtSymbol, pos) => {
                                  if (pos === i) {
                                    return '';
                                  } else {
                                    return idtSymbol;
                                  }
                                }));
                              }
                            }
                          }}
                          placeholder={'\u2212'}
                          // validationState={getValidationState(error, valid)}
                          styles={{
                            control: base => ({
                              ...base,
                              minWidth: '80px',
                              maxWidth: 'fit-content',
                              marginTop: '-6px',
                            }),
                          }}
                          // styles={{ control: base => ({ ...base, width: (oligoSeq[i]?.label as string)?.length > 5 ? '96px' : (oligoSeq[i]?.label as string)?.length > 3 ? '90px' : (oligoSeq[i]?.label as string)?.length > 2 ? '88px' : '78px', marginTop: '-6px' }) }}
                        />
                      )}
                    </Field>
                    {/*</div>*/}
                  </div>
                ))}
              </div>
              {/*<div>*/}
              {/*  {`$ ${data ? data.oligoCost : previousData ? previousData.oligoCost : 0}`}*/}
              {/*</div>*/}
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                  // isDisabled={!oligoName || !oligoSeq.every(({ value }) => value)}
                  style={{ marginTop: '16px', marginRight: 'auto' }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Submit
                  </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateOligoDrawer;
