import React, { useState } from 'react';
import Button, { ButtonGroup } from '@atlaskit/button';
import { FormFooter } from '@atlaskit/form';
import { GridColumn } from '@atlaskit/page';
import Popup from '@atlaskit/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStructureFile from 'hooks/use-structure-file';
// import BuildingBlockNameInlineEditableField from '../forms/nucleotide/fields/building-block-name-inline-editable-field';
// import InlineEditableField from '../forms/nucleotide/fields/inline-editable-field';
// import SymbolInlineEditableField from '../forms/nucleotide/fields/symbol-inline-editable-field';
import EditIcon from '../logos/edit-icon';
import Card from '../tables/card';
import Structure from './structure';

interface AmiditeCardProps {
  id: string;
  symbol: string;
  name: string;
  molecularWeight: number;
  formulaWeight: number;
  hasDmt: boolean;
  casNumber: string;
  structure: string;
}

function AmiditeCard({
  id,
  symbol,
  name,
  molecularWeight,
  formulaWeight,
  hasDmt,
  casNumber,
  structure,
}: AmiditeCardProps): JSX.Element {
  // const [update] = useUpdateAmiditeMutation();

  const [editDialogOpen, toggleEditDialog] = useState(false);
  const [uploadDialogOpen, toggleUploadDialog] = useState(false);

  const { cml, setCml, StructureFileInput, UploadIcon } = useStructureFile({
    toggleEditDialog,
    uploadDialogOpen,
    toggleUploadDialog,
  });

  return (
    <GridColumn medium={4}>
      <Card
        onClick={() => console.log(`${symbol} clicked`)}
        style={{ height: '414px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            style={{
              paddingRight: '2px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '530px',
            }}
          >
            {name}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '36px',
            }}
          >
            <Popup
              isOpen={editDialogOpen}
              onClose={() => toggleEditDialog(false)}
              placement="bottom-end"
              content={() => (
                <div style={{ width: '240px', padding: '16px 20px' }}>
                  {/*<BuildingBlockNameInlineEditableField*/}
                  {/*  buildingBlock="amidite"*/}
                  {/*  fieldValue={name}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (value === name) return;*/}
                  {/*  //   update({*/}
                  {/*  //     variables: { id, name: value },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateAmidite: {*/}
                  {/*  //         __typename: 'UpdateAmiditeResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         amidite: {*/}
                  {/*  //           __typename: 'Amidite',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol,*/}
                  {/*  //           name: value,*/}
                  {/*  //           molecularWeight,*/}
                  {/*  //           formulaWeight,*/}
                  {/*  //           hasDmt,*/}
                  {/*  //           casNumber,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*  originalValue={name}*/}
                  {/*/>*/}
                  {/*<SymbolInlineEditableField*/}
                  {/*  buildingBlock="nucleotide"*/}
                  {/*  fieldValue={symbol}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (value === symbol) return;*/}
                  {/*  //   update({*/}
                  {/*  //     variables: { id, symbol: value },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateAmidite: {*/}
                  {/*  //         __typename: 'UpdateAmiditeResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         amidite: {*/}
                  {/*  //           __typename: 'Amidite',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol: value,*/}
                  {/*  //           name,*/}
                  {/*  //           molecularWeight,*/}
                  {/*  //           formulaWeight,*/}
                  {/*  //           hasDmt,*/}
                  {/*  //           casNumber,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*  originalValue={symbol}*/}
                  {/*/>*/}
                  {/*<InlineEditableField*/}
                  {/*  label="Molecular weight"*/}
                  {/*  fieldValue={molecularWeight}*/}
                  {/*  validate={value => {*/}
                  {/*    if (!value || !parseFloat(value)) return 'Molecular weight cannot be empty.';*/}
                  {/*  }}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (parseFloat(value) === molecularWeight) return;*/}
                  {/*  //   return update({*/}
                  {/*  //     variables: { id, molecularWeight: parseFloat(value) },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateAmidite: {*/}
                  {/*  //         __typename: 'UpdateAmiditeResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         amidite: {*/}
                  {/*  //           __typename: 'Amidite',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol,*/}
                  {/*  //           name,*/}
                  {/*  //           molecularWeight: parseFloat(value),*/}
                  {/*  //           formulaWeight,*/}
                  {/*  //           hasDmt,*/}
                  {/*  //           casNumber,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*/>*/}
                  {/*<InlineEditableField*/}
                  {/*  label="Formula weight"*/}
                  {/*  fieldValue={formulaWeight}*/}
                  {/*  validate={value => {*/}
                  {/*    if (!value || !parseFloat(value)) return 'Formula weight cannot be empty.';*/}
                  {/*  }}*/}
                  {/*  onConfirm={() => {}}*/}
                  {/*  // onConfirm={value => {*/}
                  {/*  //   if (parseFloat(value) === formulaWeight) return;*/}
                  {/*  //   return update({*/}
                  {/*  //     variables: { id, formulaWeight: parseFloat(value) },*/}
                  {/*  //     optimisticResponse: {*/}
                  {/*  //       __typename: 'Mutation',*/}
                  {/*  //       updateAmidite: {*/}
                  {/*  //         __typename: 'UpdateAmiditeResponse',*/}
                  {/*  //         success: true,*/}
                  {/*  //         message: 'Building block successfully updated',*/}
                  {/*  //         amidite: {*/}
                  {/*  //           __typename: 'Amidite',*/}
                  {/*  //           id,*/}
                  {/*  //           symbol,*/}
                  {/*  //           name,*/}
                  {/*  //           molecularWeight,*/}
                  {/*  //           formulaWeight: parseFloat(value),*/}
                  {/*  //           hasDmt,*/}
                  {/*  //           casNumber,*/}
                  {/*  //           structure,*/}
                  {/*  //         },*/}
                  {/*  //       },*/}
                  {/*  //     },*/}
                  {/*  //   });*/}
                  {/*  // }}*/}
                  {/*  fitWidth*/}
                  {/*/>*/}
                </div>
              )}
              trigger={triggerProps => (
                <EditIcon
                  {...triggerProps}
                  editDialogOpen={editDialogOpen}
                  onClick={() => {
                    toggleUploadDialog(false);
                    toggleEditDialog(open => !open);
                  }}
                >
                  <FontAwesomeIcon icon={editDialogOpen ? ['far', 'edit'] : ['fas', 'edit']} />
                </EditIcon>
              )}
            />
            <Popup
              isOpen={uploadDialogOpen}
              onClose={() => toggleUploadDialog(false)}
              placement="bottom-end"
              content={() => (
                <div style={{ padding: '20px' }}>
                  <StructureFileInput />
                  <FormFooter>
                    <ButtonGroup>
                      <Button
                        appearance="danger"
                        // onClick={() => {
                        //   update({
                        //     variables: { id, structure: '' },
                        //     optimisticResponse: {
                        //       __typename: 'Mutation',
                        //       updateAmidite: {
                        //         __typename: 'UpdateAmiditeResponse',
                        //         success: true,
                        //         message: 'Building block successfully updated',
                        //         amidite: {
                        //           __typename: 'Amidite',
                        //           id,
                        //           symbol,
                        //           name,
                        //           molecularWeight,
                        //           formulaWeight,
                        //           hasDmt,
                        //           casNumber,
                        //           structure: '',
                        //         },
                        //       },
                        //     },
                        //   });
                        // }}
                        isDisabled={!structure}
                      >
                        Remove
                      </Button>
                      <Button
                        appearance="primary"
                        onClick={() => {
                          if (cml === structure) return;
                          if (cml) {
                            // update({
                            //   variables: { id, structure: cml as string },
                            //   optimisticResponse: {
                            //     __typename: 'Mutation',
                            //     updateAmidite: {
                            //       __typename: 'UpdateAmiditeResponse',
                            //       success: true,
                            //       message: 'Building block successfully updated',
                            //       amidite: {
                            //         __typename: 'Amidite',
                            //         id,
                            //         symbol,
                            //         name,
                            //         molecularWeight,
                            //         formulaWeight,
                            //         hasDmt,
                            //         casNumber,
                            //         structure: cml as string,
                            //       },
                            //     },
                            //   },
                            // })
                            //   .then(() => setCml(null));
                            setCml(null);
                          }
                        }}
                        isDisabled={!cml}
                        style={{ marginLeft: '5px' }}
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  </FormFooter>
                </div>
              )}
              trigger={UploadIcon}
            />
          </div>
        </div>
        <div style={{ height: '346px' }}>
          <Structure
            id={id}
            structure={structure}
            columns={3}
            height={340}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: 500 }}>{symbol}</span>
          <span>M.W.&nbsp;{molecularWeight.toFixed(2)}&nbsp;(F.W.&nbsp;{formulaWeight.toFixed(2)})</span>
        </div>
      </Card>
    </GridColumn>
  );
}

export default AmiditeCard;
