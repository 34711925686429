import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import Select, { ValueType } from '@atlaskit/select';
import Toggle from '@atlaskit/toggle';
import Form, { FormFooter } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { codeFontFamily, colors } from '@atlaskit/theme';

import { SelectOptionStringValueType } from 'types/select-option';
import { antisenseCartItemsVar, sirnaCartItemsVar } from 'apollo/cache';
import { GetSirnaDuplexDocument, GetSirnaDuplexQuery, useCreateAsosMutation } from 'apollo/graphql';
import GapmerItem from './gapmer-item';
import { setAlert } from 'redux/alerts';
import StandardSirnaItem from './standard-sirna-item';

export interface SirnaProps {
  sirnaId: string;
  name: string;
  seqAs: string;
  startAs: number;
  endAs: number;
  seqS: string;
  startS: number;
  endS: number;
  symbol: string;
}

export interface OligoConfigProps {
  sirnaId: string;
  cpIds?: string[];
}

interface Props {
  flipDuplex: boolean;
  setTotalCount: Function;
}

function CreateModifiedSirnaTab({ flipDuplex, setTotalCount }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const sirnaCartItems = useReactiveVar(sirnaCartItemsVar);

  // Payload for creation of siRNAs.
  const [sirnaList, setSirnaList] = useState<SirnaProps[]>(sirnaCartItems.map(({
    sirnaId,
    targetLength,
    symbol,
  }) => {
    const sirna = client.readQuery<GetSirnaDuplexQuery>({
      query: GetSirnaDuplexDocument,
      variables: { id: sirnaId },
    });

    const { seqAs, startAs, endAs, seqS, startS, endS } = sirna!.sirnaDuplex;

    return {
      sirnaId,
      name: `${symbol}_${startAs.toString().padStart(targetLength.toString().length, '0')}${(seqAs.length === 23 && seqS.length === 21 && endAs === endS) ? '' : '_' + seqAs.length.toString() + '/' + seqS.length.toString() + (endAs === endS ? '' : '/' + (endAs - endS).toString())}`,
      seqAs: seqAs.replaceAll('T', 'U'),
      startAs,
      endAs,
      seqS: seqS.replaceAll('T', 'U'),
      startS,
      endS,
      symbol,
    };
  }));
  // const [cpIdsList, setCpIdsList] = useState<OligoConfigProps[]>(antisenseList.map(({ antisenseId }) => ({ antisenseId })));

  useEffect(() => {
    setSirnaList(prevSirnaList => {
      return prevSirnaList.map(sirna => {
        return { ...sirna };
      });
    });
  }, [sirnaCartItems]);

  // useEffect(() => {
  //   setCpIdsList(prevCpIdsList => {
  //     if (antisenseList.length > prevCpIdsList.length) {
  //       return [...prevCpIdsList, { antisenseId: antisenseList[antisenseList.length - 1].antisenseId }];
  //     } else if (antisenseList.length < prevCpIdsList.length) {
  //       return prevCpIdsList.filter(({ antisenseId }) => {
  //         return antisenseList.find(({ antisenseId: id }) => id === antisenseId);
  //       });
  //     } else {
  //       return prevCpIdsList || [];
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [antisenseList]);

  // const [create, { loading }] = useCreateAsosMutation({
  //   onCompleted: ({ createAsos: { success, message } }) => {
  //     dispatch(setAlert({
  //       title: message,
  //       icon: success
  //         ? <SuccessIcon primaryColor={colors.G300} label="Success"/>
  //         : <ErrorIcon primaryColor={colors.R400} label="Error"/>,
  //     }));
  //     if (success) {
  //       setTotalCount(0);
  //       // TODO: Filter cart items based on name of returned asos.
  //       antisenseCartItemsVar([]);
  //     }
  //   },
  // });

  return (
    <div style={{ width: '100%' }}>
      {/*<div*/}
      {/*  style={{*/}
      {/*    display: 'flex',*/}
      {/*    justifyContent: 'space-between',*/}
      {/*    margin: '10px 0 20px 0',*/}
      {/*  }}>*/}
      {/*  <Select*/}
      {/*    spacing="compact"*/}
      {/*    label="5'-wing"*/}
      {/*    options={[{ label: 'LNA', value: '3' }, { label: 'MOE', value: '4' }]}*/}
      {/*    value={chem5}*/}
      {/*    onChange={(value: ValueType<SelectOptionStringValueType>): void => {*/}
      {/*      if (value) setChem5(value);*/}
      {/*    }}*/}
      {/*    placeholder="Choose 5'-wing..."*/}
      {/*    styles={{ control: base => ({ ...base, width: '84px' }) }}*/}
      {/*  />*/}
      {/*  <div*/}
      {/*    onClick={() => setPs(prevPs => !prevPs)}*/}
      {/*    style={{*/}
      {/*      marginTop: '6px',*/}
      {/*      cursor: 'pointer',*/}
      {/*      WebkitUserSelect: 'none',*/}
      {/*      MozUserSelect: 'none',*/}
      {/*      msUserSelect: 'none',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Toggle*/}
      {/*      value={ps.toString()}*/}
      {/*      isChecked={ps}*/}
      {/*      onChange={() => setPs(prevPs => !prevPs)}*/}
      {/*    />*/}
      {/*    <span>{' '} PS backbone</span>*/}
      {/*  </div>*/}
      {/*  <Select*/}
      {/*    spacing="compact"*/}
      {/*    label="3'-wing"*/}
      {/*    options={[{ label: 'LNA', value: '3' }, { label: 'MOE', value: '4' }]}*/}
      {/*    value={chem3}*/}
      {/*    onChange={(value: ValueType<SelectOptionStringValueType>): void => {*/}
      {/*      if (value) setChem3(value);*/}
      {/*    }}*/}
      {/*    placeholder="Choose 3'-wing..."*/}
      {/*    styles={{ control: base => ({ ...base, width: '84px' }) }}*/}
      {/*  />*/}
      {/*</div>*/}
      <Form
        onSubmit={(values) => {
          const sirnas = sirnaCartItems.map(({ sirnaId, targetId }, i) => ({
            name: sirnaList.find(sirna => sirna.sirnaId === sirnaId)!.name,
            // cpIds: cpIdsList.find(cpIds => cpIds.antisenseId === antisenseId)!.cpIds! || [],
            targetId: parseInt(targetId),
            sirnaId: parseInt(sirnaId),
            seqAs: sirnaList.find(sirna => sirna.sirnaId === sirnaId)!.seqAs,
            seqS: sirnaList.find(sirna => sirna.sirnaId === sirnaId)!.seqS,
          }));
          console.log(sirnas);
          // create({ variables: { sirnas } });
        }}
      >
        {({ formProps }: any) => (
          <form {...formProps} noValidate>
            {sirnaList.map(({ sirnaId, name, seqAs: defaultSeqAs, startAs, endAs, seqS: defaultSeqS, startS, endS }) => {
              const seqAs = defaultSeqAs.replaceAll('T', 'U');
              const seqS = defaultSeqS.replaceAll('T', 'U');

              return (
                <StandardSirnaItem
                  id={sirnaId}
                  name={name}
                  seqTop={!flipDuplex ? ('5\'-' + (endS - endAs > 0 ? '\u00a0'.repeat(endS - endAs) : '') + seqAs + (startAs - startS > 0 ? '\u00a0'.repeat(Math.abs(startAs - startS)) : '') + '-3\'') : ('3\'-' + (startAs - startS > 0 ? '\u00a0'.repeat(startAs - startS) : '') + seqAs.split('').reverse().join('') + (endS - endAs > 0 ? '\u00a0'.repeat(Math.abs(endS - endAs)) : '') + '-5\'')}
                  basePairing={'\u00a0'.repeat(3) + (!flipDuplex ? '\u00a0'.repeat(Math.abs(endAs - endS)) : '\u00a0'.repeat(Math.abs(startS - startAs))) + '|'.repeat(endAs - (startS > startAs ? startS : startAs) - (endAs - endS > 0 ? endAs - endS : 0) + 1)}
                  seqBtm={!flipDuplex ? ('3\'-' + (endAs - endS > 0 ? '\u00a0'.repeat(endAs - endS) : '') + seqS.split('').reverse().join('') + (startS - startAs > 0 ? '\u00a0'.repeat(Math.abs(startS - startAs)) : '') + '-5\'') : ('5\'-' + (startS - startAs > 0 ? '\u00a0'.repeat(startS - startAs) : '') + seqS + (endAs - endS > 0 ? '\u00a0'.repeat(Math.abs(endAs - endS)) : '') + '-3\'')}
                  setSirnaList={setSirnaList}
                  setTotalCount={setTotalCount}
                  key={sirnaId}
                />
              );
            })}
            <FormFooter>
              <LoadingButton
                type="submit"
                appearance="primary"
                // isDisabled={!cpIdsList.length || !cpIdsList[cpIdsList.length - 1].cpIds} // TODO: Add conditional checks to make sure no names are ''
                // isLoading={loading}
                // onClick={() => {
                //   const asos = antisenseCartItems.map(({ antisenseId, targetId }, i) => ({
                //     name: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.name,
                //     cpIds: cpIdsList.find(cpIds => cpIds.antisenseId === antisenseId)!.cpIds! || [],
                //     targetId: parseInt(targetId),
                //     antisenseId: parseInt(antisenseId),
                //     antisenseSeq: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.antisenseSeq,
                //   }));
                //   // console.log(asos);
                //   create({ variables: { asos } });
                // }}
                style={{ marginBottom: '20px' }}
              >
                Submit
              </LoadingButton>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
}

export default CreateModifiedSirnaTab;
