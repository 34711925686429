import React, { Fragment, useState } from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import TextField from '@atlaskit/textfield';

const validatePassword = (value?: string): string | void => {
  if (!value) {
    return 'Password is required.';
  } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
    return 'Passwords must include at least 1 capital letter and 1 number.';
  } else if (value.length > 20) {
    return 'Password should not exceed 20 characters.';
  }
};

const validateEmptyPassword = (value?: string): string | void => {
  if (!value) {
    return;
  } else if (!value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
    return 'Passwords must include at least 1 capital letter and 1 number.';
  } else if (value.length > 20) {
    return 'Password should not exceed 20 characters.';
  }
};

interface Props {
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  label?: string | null;
  showPassword?: boolean;
  allowEmpty?: boolean;
}

function PasswordField({
  name = 'password',
  placeholder,
  autoFocus = false,
  label = null,
  showPassword = false,
  allowEmpty = false,
}: Props): JSX.Element {
  const [passwordVisible, togglePasswordVisibility] = useState(false);

  return (
    <Field
      name={name}
      label={label}
      defaultValue=""
      validate={allowEmpty ? validateEmptyPassword : validatePassword}
    >
      {({ fieldProps, error }) => (
        <Fragment>
          <TextField
            {...fieldProps}
            type={passwordVisible || showPassword ? 'text' : 'password'}
            autoComplete="off"
            placeholder={placeholder || 'Enter password'}
            autoFocus={autoFocus}
            elemAfterInput={
              !showPassword ? (
                <span
                  style={{ padding: '4px 4px 0 0', cursor: 'default' }}
                  onClick={() => togglePasswordVisibility(visible => !visible)}
                >
                {passwordVisible
                  ? <WatchFilledIcon label="Show password" size="medium" />
                  : <WatchIcon label="Hide password" size="medium" />
                }
              </span>
              ) : null
            }
          />
          {(!error || error === 'Invalid credentials.' || error === 'Account has been disabled.') &&
          <HelperMessage>&nbsp;</HelperMessage>}
          {error && error !== 'Invalid credentials.' && error !== 'Account has been disabled.' &&
          <ErrorMessage>{error}</ErrorMessage>}
        </Fragment>
      )}
    </Field>
  );
}

export default PasswordField;
