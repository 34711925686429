import { PanelType } from 'components/panels/panel-manager';
import { Panel, PanelActionType } from './types';

interface MountPanelAction {
  type: PanelActionType.MOUNT_PANEL;
  payload: Omit<Panel, 'isOpen'>;
}

export interface PanelProps {}

export const mountPanel = (panelType: PanelType, panelProps: PanelProps = {}): MountPanelAction => ({
  type: PanelActionType.MOUNT_PANEL,
  payload: { panelType, ...panelProps },
});

interface OpenPanelAction {
  type: PanelActionType.OPEN_PANEL;
}

export const openPanel = (): OpenPanelAction => ({
  type: PanelActionType.OPEN_PANEL,
});

interface ClosePanelAction {
  type: PanelActionType.CLOSE_PANEL;
}

export const closePanel = (): ClosePanelAction => ({
  type: PanelActionType.CLOSE_PANEL,
});

export type PanelAction = MountPanelAction | OpenPanelAction | ClosePanelAction;
