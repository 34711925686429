import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Form, { FormFooter } from '@atlaskit/form';
// import { Field } from '@atlaskit/form';
// import Toggle from '@atlaskit/toggle';
import { LoadingButton } from '@atlaskit/button';
import Drawer from '@atlaskit/drawer';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';

import { CreateUserInput, GetAllUsersDocument, useCreateUserMutation } from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { closeDrawer, DrawerProps } from 'redux/drawers';
import { EmailField, UsernameField, PasswordField } from '../forms/auth/fields';

interface Props extends DrawerProps {
  isOpen: boolean;
}

function CreateSampleDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();

  const [count, setCount] = useState<number>(0);
  const reset = () => setCount(prevCount => prevCount + 1);

  // const [emailNotify, setEmailNotify] = useState<boolean>(true);

  const [createUser, { loading }] = useCreateUserMutation({
    onCompleted: ({ createUser: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllUsersDocument],
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <Form
          onSubmit={({ username, email, password }: CreateUserInput) => {
            createUser({
              variables: { username, email, ...(password && { password }) },
            });
          }}
          key={count}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div>
                <UsernameField />
                <EmailField />
                <PasswordField label="Password" showPassword allowEmpty />
                {/*<Field name="emailNotify">*/}
                {/*  {({ fieldProps }) => (*/}
                {/*    <span style={{ cursor: 'pointer' }}>*/}
                {/*      <Toggle*/}
                {/*        {...fieldProps}*/}
                {/*        isChecked={emailNotify}*/}
                {/*        onChange={() => setEmailNotify(!emailNotify)}*/}
                {/*      />*/}
                {/*      <span onClick={() => setEmailNotify(!emailNotify)}> Send email notification?</span>*/}
                {/*    </span>*/}
                {/*  )}*/}
                {/*</Field>*/}
              </div>
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                >
                <span style={{ fontWeight: 400 }}>
                  Submit
                </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateSampleDrawer;
