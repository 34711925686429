import React from 'react';
import { useApolloClient } from '@apollo/client';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import { useLocation, useNavigate } from 'react-router-dom';

import { SelectOptionStringValueType } from 'types/select-option';
import { SearchTargetsDocument, SearchTargetsQuery, useGetTargetCountQuery } from 'apollo/graphql';
import createTargetOptions from '../antisense/create-target-options';

interface Props {
  target: any;
  setTarget: Function;
}

const TargetSwitcher: React.FC<Props> = ({ target, setTarget }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const promiseOptions = (inputValue: string) => client.query<SearchTargetsQuery>({
    query: SearchTargetsDocument,
    variables: {
      filter: { search: inputValue },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
  })
    .then(res => createTargetOptions(res, 'switcher'));

  const { data } = useGetTargetCountQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
  });

  return (
    <AsyncSelect
      key={data?.targets.totalCount || 0}
      defaultOptions
      loadOptions={promiseOptions}
      filterOption={({ value }) => location.pathname.endsWith('/sirna') ? value.endsWith('__true') : true}
      placeholder="Search for target..."
      value={target}
      onChange={(value: ValueType<SelectOptionStringValueType>): void => {
        if (value) {
          setTarget(value);
          navigate(
            location.pathname.endsWith('/antisense')
              ? `/targets/${value.value.split('__')[0]}/antisense`
              : location.pathname.endsWith('/sirna')
                ? `/targets/${value.value.split('__')[0]}/sirna`
                : `/targets/${value.value.split('__')[0]}`,
            {
              state: {
                spliced: value.value.split('__')[1] === 'true',
              },
            },
          );
        }
      }}
      styles={{ control: base => ({ ...base, height: '50px' }) }}
    />
  );
};

export default TargetSwitcher;
