import React from 'react';
// import Button from '@atlaskit/button';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
// import CopyIcon from '@atlaskit/icon/glyph/copy';
// import MoreIcon from '@atlaskit/icon/glyph/more';
// import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
// import { GridColumn } from '@atlaskit/page';
// import { PanelStateless } from '@atlaskit/panel';
// import { codeFontFamily, colors } from '@atlaskit/theme';
// import Tooltip from '@atlaskit/tooltip';
// import { useState } from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import styled from 'styled-components';

import { OrderItem } from 'apollo/graphql';
import SampleLozengeList from '../production/sample-lozenge-list';
import StickyHeader from '../tables/sticky-header';
import OrderItemListActionsPopup from './order-item-list-actions-popup';

// const ExpandableInfo = styled.div`
//   & > div {
//     margin: 0 0 0 0;
//   }
//
//   & > div > div {
//     padding-top: 2px;
//     padding-bottom: 0;
//     margin: 2px 0 0 0;
//   }
// `;

// interface ExpandableSeqInfoProps {
//   displaySeq: string;
//   primarySeq: string;
// }

// const ExpandableSeqInfo = ({ displaySeq, primarySeq }: ExpandableSeqInfoProps) => {
//   const [isExpanded, toggleExpanded] = useState<boolean>(false);
//
//   return (
//     <GridColumn>
//       <ExpandableInfo>
//         <PanelStateless
//           header={(
//             <div
//               style={{
//                 fontFamily: codeFontFamily(),
//                 fontSize: '1.1em',
//                 whiteSpace: isExpanded ? 'normal' : 'inherit',
//                 marginBottom: '2px',
//                 WebkitUserSelect: 'none',
//                 MozUserSelect: 'none',
//                 msUserSelect: 'none',
//               }}
//             >
//               {!isExpanded && displaySeq.length > 63 ? (
//                 <span>
//                   {displaySeq.substring(0, 63)}<br />
//                 </span>
//               ) : displaySeq}
//             </div>
//           )}
//           isExpanded={isExpanded}
//           onChange={() => {
//             toggleExpanded(isExpanded => !isExpanded);
//           }}
//         >
//           <div style={{ whiteSpace: 'normal' }}>
//             <span style={{ fontSize: '0.85em', color: colors.N300 }}>Primary seq:&nbsp;&nbsp;</span>
//             <span style={{ fontFamily: codeFontFamily(), fontSize: '1.1em', color: colors.N300 }}>{primarySeq}</span>
//           </div>
//         </PanelStateless>
//       </ExpandableInfo>
//     </GridColumn>
//   );
// };

export interface OrderItemProps extends OrderItem {
  orderType?: string;
  companyId?: string;
}

interface Props {
  items: OrderItemProps[];
  isLoading: boolean;
}

function OrderItemList({
  items,
  isLoading,
}: Props) {
  const head = {
    cells: [
      // createTableHead<Oligo>({
      //   fieldKey: 'id',
      //   fieldName: 'ID',
      //   width: 2,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      {
        isSortable: false,
        width: 1,
        shouldTruncate: false,
        key: 'id',
        colSpan: 1,
        content: 'Items',
      },
      {
        isSortable: false,
        width: 6,
        shouldTruncate: false,
        key: 'description',
        colSpan: 1,
        content: 'Description',
      },
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'productId',
        colSpan: 1,
        content: 'Product ID',
      },
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'quantity',
        colSpan: 1,
        content: 'Quantity',
      },
      {
        isSortable: false,
        width: 6,
        shouldTruncate: false,
        key: 'samples',
        colSpan: 1,
        content: 'Samples',
      },
      {
        isSortable: false,
        width: 1,
        shouldTruncate: false,
        key: 'actions',
        colSpan: 1,
        content: '',
      },
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={items.map(({
          productId,
          orderId,
          companyId,
          id,
          description,
          product,
          quantity,
          samples,
        }, i) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: i + 1,
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: (
            //     <Tooltip
            //       content={(
            //         <>
            //           {name}
            //           {aliases.length > 0 && <div>[ {aliases.join(', ')} ]</div>}
            //         </>
            //       )}
            //       position="right"
            //       delay={200}
            //     >
            //       <span style={{ textOverflow: 'ellipsis', cursor: 'pointer' }}>{name}</span>
            //     </Tooltip>
            //   ),
            // },
            {
              key: id,
              colSpan: 1,
              content: description,
            },
            {
              key: id,
              colSpan: 1,
              content: productId,
            },
            {
              key: id,
              colSpan: 1,
              content: quantity,
            },
            {
              key: id,
              colSpan: 1,
              content: <SampleLozengeList samples={samples || []}/>,
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
                  <OrderItemListActionsPopup
                    sample={{
                      orderItemId: id,
                      productId,
                      orderId,
                      companyId,
                      typeName: product.type,
                      ...(product.oligoId && { oligoId: product.oligoId }),
                      // mass: product.oligo?.mass || (product.asOligo && product.ssOligo ? (product.asOligo.mass + product.ssOligo.mass) : undefined),
                    }}
                  />
                </div>
              ),
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No items found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default OrderItemList;
