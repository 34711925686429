import React, { useState } from 'react';
import { TriggerProps } from '@atlaskit/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UploadIcon from '../components/logos/upload-icon';

interface Props {
  toggleEditDialog: Function;
  uploadDialogOpen: boolean;
  toggleUploadDialog: (f: (open: boolean) => boolean) => void;
}

const useStructureFile = ({ toggleEditDialog, uploadDialogOpen, toggleUploadDialog }: Props) => {
  const [cml, setCml] = useState<string | ArrayBuffer | null>(null);

  const handleFileChosen = (file: File | null) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setCml(fileReader.result ? fileReader.result : null);
    };
    if (file) fileReader.readAsText(file);
    if (!file) setCml(null);
  };

  return {
    cml,
    setCml,
    StructureFileInput: () => (
      <input
        type="file"
        accept=".cml"
        onChange={e => handleFileChosen((e.target.files && e.target.files.length > 0) ? e.target.files[0] : null)}
      />
    ),
    UploadIcon: (triggerProps: TriggerProps) => (
      <UploadIcon
        {...triggerProps}
        uploadDialogOpen={uploadDialogOpen}
        onClick={() => {
          toggleEditDialog(false);
          toggleUploadDialog(open => !open);
        }}
      >
        <FontAwesomeIcon icon={uploadDialogOpen ? ['fas', 'minus-square'] : ['fas', 'plus-square']} />
      </UploadIcon>
    ),
  };
};

export default useStructureFile;
