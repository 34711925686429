import React from 'react';
import { useMatch } from 'react-router-dom';
import { MenuGroup, Section } from '@atlaskit/menu';

import { useGetAllUsersQuery, UserSortField } from 'apollo/graphql';
import { useAuth } from 'contexts/auth';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Admin = ({ onClose }: ContentProps): JSX.Element => {
  const usersMatch = useMatch('/admin/users');

  useGetAllUsersQuery({
    variables: {
      page: 1,
      perPage: 25,
      filter: { search: '' },
      orderBy: ['username_ASC' as UserSortField],
    },
    ...(usersMatch ? {} : { fetchPolicy: 'network-only' }),
  });

  return (
    <MenuGroup minWidth="240px">
      <Section>
        <ButtonLink to="/admin/users" onClick={onClose}>
          View all users
        </ButtonLink>
        {/*<ButtonLink to="/admin/employees" onClick={onClose}>*/}
        {/*  View all employees*/}
        {/*</ButtonLink>*/}
      </Section>
    </MenuGroup>
  );
};

const AdminContent = (): JSX.Element | null => {
  const { currentUser } = useAuth()!;
  const match = useMatch('/admin/*');

  return currentUser?.isAdmin
    ? (
      <span>
        <PrimaryDropdown
          content={Admin}
          text="Admin"
          isHighlighted={!!match}
        />
      </span>
    ) : null;
};

export default AdminContent;
