import React from 'react';
import Select, { ValueType } from '@atlaskit/select';

import { OrganismSelect } from 'apollo/graphql';
import { SelectOptionEnumType } from 'types/select-option';

export type OrganismSelectOptionType = SelectOptionEnumType<OrganismSelect>;

const options: OrganismSelectOptionType[] = [
  { label: 'Human', value: OrganismSelect.Human },
  { label: 'Mouse', value: OrganismSelect.Mouse },
  { label: 'Rat', value: OrganismSelect.Rat },
  // { label: 'COVID', value: OrganismSelect.Covid },
  { label: 'All', value: OrganismSelect.None },
];

interface Props {
  organism: OrganismSelectOptionType;
  setOrganism: (value: OrganismSelectOptionType) => void;
  setCurrentPage?: (num: number) => void;
}

function OrganismsSelect({
  organism,
  setOrganism,
  setCurrentPage,
}: Props): JSX.Element {
  const onOrganismSelectChange = (value: ValueType<OrganismSelectOptionType>): void => {
    if (value && value.value !== organism.value) {
      setOrganism(value);
      if (setCurrentPage) setCurrentPage(1);
    }
  };

  return (
    <div style={{ zIndex: 3 }}>
      <Select
        spacing="compact"
        options={options}
        value={organism}
        onChange={onOrganismSelectChange}
        placeholder={organism.label}
        aria-label={organism.label}
        styles={{ control: base => ({ ...base, width: '98px' }) }}
      />
    </div>
  );
}

export default OrganismsSelect;
