import React from 'react';
import Toggle from '@atlaskit/toggle';

interface Props {
  flipDuplex: boolean;
  setFlipDuplex: (f: (exclude: boolean) => boolean) => void;
  setCurrentPage: (num: number) => void;
}

function FlipDuplex({
  flipDuplex,
  setFlipDuplex,
  setCurrentPage,
}: Props): JSX.Element {
  const toggleFlip = () => {
    setFlipDuplex((prevFlip: boolean) => !prevFlip);
    setCurrentPage(1);
  };

  return (
    <div
      onClick={toggleFlip}
      style={{
        cursor: 'pointer',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        paddingTop: '3px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Toggle
        isChecked={flipDuplex}
        onChange={toggleFlip}
      />
      <div style={{ paddingTop: '3px' }}>Flip duplex</div>
    </div>
  );
}

export default FlipDuplex;
