import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';

interface Props {
  excludeN5: boolean;
  setExcludeN5: (f: (exclude: boolean) => boolean) => void;
  setCurrentPage: (num: number) => void;
}

function N5Exclude({
  excludeN5,
  setExcludeN5,
  setCurrentPage,
}: Props): JSX.Element {
  const toggleExclude = () => {
    setExcludeN5((prevExclude: boolean) => !prevExclude);
    setCurrentPage(1);
  };

  return (
    <div
      onClick={toggleExclude}
      style={{
        cursor: 'pointer',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        paddingTop: '2px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Checkbox
        isChecked={excludeN5}
        onChange={toggleExclude}
      />
      <div style={{ display: 'block', paddingLeft: '2px', paddingTop: '2px' }}>
        Exclude homopolymers (N<sub>5+</sub>)
      </div>
    </div>
  );
}

export default N5Exclude;
