import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _FieldSet: any;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
  /** Decimal (fixed-point) */
  Decimal: any;
  Upload: any;
  datetime: any;
};

/** An amidite. */
export type Amidite = {
  __typename?: 'Amidite';
  /** The CAS number of the amidite. */
  casNumber?: Maybe<Scalars['String']>;
  /** The structure of the amidite in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The formula weight of the amidite. */
  formulaWeight: Scalars['Float'];
  /** Whether the amidite has a DMT group. */
  hasDmt: Scalars['Boolean'];
  /** The unique ID associated with the amidite. */
  id: Scalars['ID'];
  /** The molecular weight of the amidite. */
  molecularWeight: Scalars['Float'];
  /** The name of the amidite (must be unique). */
  name: Scalars['String'];
  /** The nucleoside that the amidite will be converted into. */
  nucleoside?: Maybe<Nucleoside>;
  nucleosideId: Scalars['ID'];
  /** The symbol of the amidite (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type AmiditeConnection = {
  __typename?: 'AmiditeConnection';
  currentPage: Scalars['Int'];
  edges: Array<AmiditeEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type AmiditeEdge = {
  __typename?: 'AmiditeEdge';
  cursor: Scalars['String'];
  node: Amidite;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum AmiditeSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FormulaWeightAsc = 'formulaWeight_ASC',
  FormulaWeightDesc = 'formulaWeight_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MolecularWeightAsc = 'molecularWeight_ASC',
  MolecularWeightDesc = 'molecularWeight_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SymbolAsc = 'symbol_ASC',
  SymbolDesc = 'symbol_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for amidites. */
export type AmiditeWhereInput = {
  baseIds?: Maybe<Array<Scalars['ID']>>;
  casNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sugarIds?: Maybe<Array<Scalars['ID']>>;
  symbol?: Maybe<Scalars['String']>;
};

export type AntisenseCartItem = {
  __typename?: 'AntisenseCartItem';
  antisenseId: Scalars['ID'];
  id: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** An antisense sequence. */
export type AntisenseSequence = {
  __typename?: 'AntisenseSequence';
  /** Openness of target RNA over 5 nt after the antisense site. */
  after5?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 10 nt after the antisense site. */
  after10?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 20 nt after the antisense site. */
  after20?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 40 nt after the antisense site. */
  after40?: Maybe<Scalars['Float']>;
  /** AT content of the antisense sequence. */
  atContent: Scalars['Float'];
  /** Openness of target RNA over 8 nt at the back of the antisense site. */
  back8?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 5 nt before the antisense site. */
  before5?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 10 nt before the antisense site. */
  before10?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 20 nt before the antisense site. */
  before20?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 40 nt before the antisense site. */
  before40?: Maybe<Scalars['Float']>;
  /** Number of matching transcripts from BLAST search. */
  blastMatches?: Maybe<Scalars['Int']>;
  /** Length (and count) of the next best BLAST hit to the antisense sequence. */
  blastNextBest?: Maybe<Scalars['String']>;
  /** Count of the next best BLAST hit to the antisense sequence. */
  blastNextBestCount?: Maybe<Scalars['Int']>;
  /** RefSeq IDs of off-target RNAs. */
  blastNextBestIds?: Maybe<Array<Scalars['ID']>>;
  /** Length of the next best BLAST hit to the antisense sequence. */
  blastNextBestLength?: Maybe<Scalars['Int']>;
  /** CpG count of the antisense sequence. */
  cpgCount: Scalars['Int'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** End position of the antisense sequence. */
  end: Scalars['Int'];
  /** Exon placement of the antisense sequence. Applicable for mRNA targets. */
  exonId?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 8 nt at the front of the antisense site. */
  front8?: Maybe<Scalars['Float']>;
  /** GC content of the antisense sequence. */
  gcContent: Scalars['Float'];
  /** Free energy of hairpin formation for the antisense sequence. */
  hairpin: Scalars['Float'];
  /** Whether the antisense sequence contains homopolymers of N5 and beyond. */
  homopolymer: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Intron placement of the antisense sequence. Applicable for pre-mRNA targets. */
  intronId?: Maybe<Scalars['Float']>;
  isInCart: Scalars['Boolean'];
  /** Length of the antisense sequence. */
  length: Scalars['Int'];
  /** Openness of target RNA over 8 nt in the middle of the antisense site. */
  mid8?: Maybe<Scalars['Float']>;
  /** Openness of target RNA over 16 nt in the middle of the antisense site. */
  mid16?: Maybe<Scalars['Float']>;
  /** Off-target RNAs containing homologous sequence. */
  offTargets?: Maybe<Array<Maybe<Rna>>>;
  /** Placement of the antisense sequence on the target RNA. */
  region: Scalars['String'];
  /** Sequence of the antisense sequence. */
  seq: Scalars['String'];
  /** The antisense series that the sequence belongs to. */
  series?: Maybe<AntisenseSeries>;
  /** Start position of the antisense sequence. */
  start: Scalars['Int'];
  /** The target of the antisense sequence. */
  target?: Maybe<Target>;
  /** Sequence of the antisense target. */
  targetSeq: Scalars['String'];
  /** Melting temperature of the antisense sequence at 0.25 µM [strand] & 150 mM [Na+]. */
  tm: Scalars['Float'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type AntisenseSequenceConnection = {
  __typename?: 'AntisenseSequenceConnection';
  currentPage: Scalars['Int'];
  edges: Array<AntisenseSequenceEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type AntisenseSequenceEdge = {
  __typename?: 'AntisenseSequenceEdge';
  cursor: Scalars['String'];
  node: AntisenseSequence;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum AntisenseSequenceSortField {
  After5Asc = 'after5_ASC',
  After5Desc = 'after5_DESC',
  After10Asc = 'after10_ASC',
  After10Desc = 'after10_DESC',
  After20Asc = 'after20_ASC',
  After20Desc = 'after20_DESC',
  After40Asc = 'after40_ASC',
  After40Desc = 'after40_DESC',
  AtContentAsc = 'atContent_ASC',
  AtContentDesc = 'atContent_DESC',
  Back8Asc = 'back8_ASC',
  Back8Desc = 'back8_DESC',
  Before5Asc = 'before5_ASC',
  Before5Desc = 'before5_DESC',
  Before10Asc = 'before10_ASC',
  Before10Desc = 'before10_DESC',
  Before20Asc = 'before20_ASC',
  Before20Desc = 'before20_DESC',
  Before40Asc = 'before40_ASC',
  Before40Desc = 'before40_DESC',
  BlastMatchesAsc = 'blastMatches_ASC',
  BlastMatchesDesc = 'blastMatches_DESC',
  BlastNextBestAsc = 'blastNextBest_ASC',
  BlastNextBestDesc = 'blastNextBest_DESC',
  CpgCountAsc = 'cpgCount_ASC',
  CpgCountDesc = 'cpgCount_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  ExonIdAsc = 'exonId_ASC',
  ExonIdDesc = 'exonId_DESC',
  Front8Asc = 'front8_ASC',
  Front8Desc = 'front8_DESC',
  GcContentAsc = 'gcContent_ASC',
  GcContentDesc = 'gcContent_DESC',
  HairpinAsc = 'hairpin_ASC',
  HairpinDesc = 'hairpin_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntronIdAsc = 'intronId_ASC',
  IntronIdDesc = 'intronId_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  Mid8Asc = 'mid8_ASC',
  Mid8Desc = 'mid8_DESC',
  Mid16Asc = 'mid16_ASC',
  Mid16Desc = 'mid16_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SeqAsc = 'seq_ASC',
  SeqDesc = 'seq_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  TmAsc = 'tm_ASC',
  TmDesc = 'tm_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for antisense sequence. */
export type AntisenseSequenceWhereInput = {
  after5?: Maybe<Scalars['String']>;
  after10?: Maybe<Scalars['String']>;
  after20?: Maybe<Scalars['String']>;
  after40?: Maybe<Scalars['String']>;
  atContent?: Maybe<Scalars['String']>;
  back8?: Maybe<Scalars['String']>;
  before5?: Maybe<Scalars['String']>;
  before10?: Maybe<Scalars['String']>;
  before20?: Maybe<Scalars['String']>;
  before40?: Maybe<Scalars['String']>;
  blastMatches?: Maybe<Scalars['String']>;
  blastNextBest?: Maybe<Scalars['String']>;
  blastNextBestLength?: Maybe<Scalars['String']>;
  cpgCount?: Maybe<Scalars['String']>;
  excludeN5?: Maybe<Scalars['Boolean']>;
  exonId?: Maybe<Scalars['String']>;
  front8?: Maybe<Scalars['String']>;
  gcContent?: Maybe<Scalars['String']>;
  hairpin?: Maybe<Scalars['String']>;
  intronId?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  mid8?: Maybe<Scalars['String']>;
  mid16?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rnaId?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['String']>;
  spliced?: Maybe<Scalars['Boolean']>;
  startRange?: Maybe<Array<Scalars['Int']>>;
  targetId?: Maybe<Scalars['Int']>;
  tmRange?: Maybe<Array<Scalars['Int']>>;
};

/** An antisense series. */
export type AntisenseSeries = {
  __typename?: 'AntisenseSeries';
  /** Sequences of the antisense series. */
  antisenseSequences?: Maybe<Array<AntisenseSequence>>;
  createdAt?: Maybe<Scalars['datetime']>;
  id: Scalars['ID'];
  /** Length of the antisense series. */
  length: Scalars['Int'];
  /** The target of the antisense series. */
  target?: Maybe<Target>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A genome assembly. */
export type Assembly = {
  __typename?: 'Assembly';
  acc: Scalars['String'];
  /** The chromosomes of the assembly. */
  chromosomes?: Maybe<Array<Chromosome>>;
  createdAt?: Maybe<Scalars['datetime']>;
  id: Scalars['ID'];
  /** The name of the assembly. */
  name: Scalars['String'];
  /** The organism of the assembly. */
  organism?: Maybe<Organism>;
  /** The synonym of the assembly. */
  synonym: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type AssemblyConnection = {
  __typename?: 'AssemblyConnection';
  currentPage: Scalars['Int'];
  edges: Array<AssemblyEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type AssemblyEdge = {
  __typename?: 'AssemblyEdge';
  cursor: Scalars['String'];
  node: Assembly;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum AssemblySortField {
  AccAsc = 'acc_ASC',
  AccDesc = 'acc_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrganismAsc = 'organism_ASC',
  OrganismDesc = 'organism_DESC',
  SynonymAsc = 'synonym_ASC',
  SynonymDesc = 'synonym_DESC'
}

/** Filtering inputs for assemblies. */
export type AssemblyWhereInput = {
  name?: Maybe<Scalars['String']>;
  organism?: Maybe<OrganismSelect>;
};

/** A backbone. */
export type Backbone = {
  __typename?: 'Backbone';
  /** Whether the backbone terminates at the 3'-end. */
  backTerminating: Scalars['Boolean'];
  /** The structure of the backbone in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the backbone. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID associated with the backbone. */
  id: Scalars['ID'];
  /** The mass of the backbone. */
  mass: Scalars['Float'];
  /** The name of the backbone (must be unique). */
  name: Scalars['String'];
  /** The symbol of the backbone (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type BackboneConnection = {
  __typename?: 'BackboneConnection';
  currentPage: Scalars['Int'];
  edges: Array<BackboneEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type BackboneEdge = {
  __typename?: 'BackboneEdge';
  cursor: Scalars['String'];
  node: Backbone;
};

/** Filtering inputs for backbones. */
export type BackboneWhereInput = {
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** A base. */
export type Base = {
  __typename?: 'Base';
  /** The structure of the base in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** Derivatives of the base. */
  derivatives?: Maybe<Array<Base>>;
  /** The description of the base. */
  description?: Maybe<Scalars['String']>;
  /** Correction to EC260 of the base. */
  ec260Correction?: Maybe<Scalars['Int']>;
  /** The unique ID associated with the base. */
  id: Scalars['ID'];
  /** If the base is an aromatic ring. */
  isAromatic: Scalars['Boolean'];
  /** The mass of the base. */
  mass: Scalars['Float'];
  /** The name of the base (must be unique). */
  name: Scalars['String'];
  /** The parent of the base. */
  parent?: Maybe<Base>;
  /** The ID of the parent of the base. */
  parentId?: Maybe<Scalars['Int']>;
  /** The symbol of the base (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type BaseConnection = {
  __typename?: 'BaseConnection';
  currentPage: Scalars['Int'];
  edges: Array<BaseEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type BaseEdge = {
  __typename?: 'BaseEdge';
  cursor: Scalars['String'];
  node: Base;
};

/** Filtering inputs for bases. */
export type BaseWhereInput = {
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum BuildingBlockSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MassAsc = 'mass_ASC',
  MassDesc = 'mass_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SymbolAsc = 'symbol_ASC',
  SymbolDesc = 'symbol_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** A chemical reagent. */
export type ChemicalReagent = {
  __typename?: 'ChemicalReagent';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the chemical reagent. */
  id: Scalars['ID'];
  /** The title of the chemical reagent (must be unique). */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A chromosome. */
export type Chromosome = {
  __typename?: 'Chromosome';
  acc: Scalars['String'];
  /** The assembly of the chromosome. */
  assembly?: Maybe<Assembly>;
  createdAt?: Maybe<Scalars['datetime']>;
  id: Scalars['ID'];
  /** The length of the chromosome. */
  length: Scalars['Int'];
  /** The number of the chromosome. */
  number: Scalars['String'];
  /** The organism of the chromosome. */
  organism?: Maybe<Organism>;
  /** The title of the chromosome. */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type ChromosomeConnection = {
  __typename?: 'ChromosomeConnection';
  currentPage: Scalars['Int'];
  edges: Array<ChromosomeEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type ChromosomeEdge = {
  __typename?: 'ChromosomeEdge';
  cursor: Scalars['String'];
  node: Chromosome;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum ChromosomeSortField {
  AccAsc = 'acc_ASC',
  AccDesc = 'acc_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  OrganismAsc = 'organism_ASC',
  OrganismDesc = 'organism_DESC'
}

/** Filtering inputs for chromosomes. */
export type ChromosomeWhereInput = {
  number?: Maybe<Scalars['String']>;
  organism?: Maybe<OrganismSelect>;
  refseqId?: Maybe<Scalars['String']>;
};

/** A company. */
export type Company = {
  __typename?: 'Company';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The customers from the company. */
  customers?: Maybe<Array<Customer>>;
  /** The unique ID associated with the company. */
  id: Scalars['ID'];
  /** The name of the company. */
  name: Scalars['String'];
  /** The short name of the company. */
  shortName: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  currentPage: Scalars['Int'];
  edges: Array<CompanyEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String'];
  node: Company;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum CompanySortField {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Filtering inputs for companies. */
export type CompanyWhereInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** A coupling. */
export type Coupling = {
  __typename?: 'Coupling';
  /** Whether the coupling terminates at the 3'-end. */
  backTerminating: Scalars['Boolean'];
  /** The backbone component of the coupling. */
  backbone?: Maybe<Backbone>;
  backboneId: Scalars['ID'];
  /** The base component of the coupling. */
  base?: Maybe<Base>;
  baseId: Scalars['ID'];
  /** The structure of the coupling in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the coupling. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID associated with the coupling. */
  id: Scalars['ID'];
  /** The mass of the coupling. */
  mass: Scalars['Float'];
  /** The name of the coupling (must be unique). */
  name: Scalars['String'];
  /** The sugar component of the coupling. */
  sugar?: Maybe<Sugar>;
  sugarId: Scalars['ID'];
  /** The symbol of the coupling (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** Inputs for coupling composition of sugar, base & backbone IDs. */
export type CouplingCompositionInput = {
  backboneId: Scalars['ID'];
  baseId: Scalars['ID'];
  sugarId: Scalars['ID'];
};

/** A list of edges with pagination information. */
export type CouplingConnection = {
  __typename?: 'CouplingConnection';
  currentPage: Scalars['Int'];
  edges: Array<CouplingEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type CouplingEdge = {
  __typename?: 'CouplingEdge';
  cursor: Scalars['String'];
  node: Coupling;
};

/** Filtering inputs for couplings. */
export type CouplingWhereInput = {
  backTerminating?: Maybe<Scalars['Boolean']>;
  backboneIds?: Maybe<Array<Scalars['ID']>>;
  backboneSymbol?: Maybe<Scalars['String']>;
  baseId?: Maybe<Scalars['ID']>;
  baseIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sugarId?: Maybe<Scalars['ID']>;
  sugarIds?: Maybe<Array<Scalars['ID']>>;
  symbol?: Maybe<Scalars['String']>;
};

/** Inputs for antisense series creation. */
export type CreateAntisenseSeriesInput = {
  length: Scalars['Int'];
  targetId: Scalars['ID'];
};

/** Mutation response for antisense series creation. */
export type CreateAntisenseSeriesResponse = MutationResponse & {
  __typename?: 'CreateAntisenseSeriesResponse';
  antisenseSeries?: Maybe<AntisenseSeries>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Inputs for ASO creation. */
export type CreateAsoInput = {
  antisenseId?: Maybe<Scalars['Int']>;
  antisenseSeq?: Maybe<Scalars['String']>;
  cpIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  targetId?: Maybe<Scalars['Int']>;
};

/** Mutation response for creation of multiple ASOs. */
export type CreateAsosResponse = MutationResponse & {
  __typename?: 'CreateAsosResponse';
  asos: Array<Oligo>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Inputs for company creation. */
export type CreateCompanyInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Mutation response for company creation. */
export type CreateCompanyResponse = MutationResponse & {
  __typename?: 'CreateCompanyResponse';
  company?: Maybe<Company>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Inputs for customer creation. */
export type CreateCustomerInput = {
  companyId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Mutation response for customer creation. */
export type CreateCustomerResponse = MutationResponse & {
  __typename?: 'CreateCustomerResponse';
  customer?: Maybe<Customer>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Mutation response for delivery creation. */
export type CreateDeliveryResponse = MutationResponse & {
  __typename?: 'CreateDeliveryResponse';
  delivery?: Maybe<Delivery>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Inputs for oligo creation. */
export type CreateOligoInput = {
  cpIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

/** Mutation response for oligo creation. */
export type CreateOligoResponse = MutationResponse & {
  __typename?: 'CreateOligoResponse';
  message: Scalars['String'];
  oligo?: Maybe<Oligo>;
  success: Scalars['Boolean'];
};

/** Inputs for order creation. */
export type CreateOrderInput = {
  customerId: Scalars['String'];
  discountedPrices: Array<Scalars['Float']>;
  discounts: Array<Scalars['Float']>;
  leadTime: Array<Scalars['Int']>;
  orderType: Scalars['String'];
  percDiscounts: Array<Scalars['Int']>;
  poNumber?: Maybe<Scalars['String']>;
  productIds: Array<Scalars['Int']>;
  quantities: Array<Scalars['Int']>;
  specs: Array<Scalars['String']>;
  unitPrices: Array<Scalars['Float']>;
};

/** Mutation response for order creation. */
export type CreateOrderResponse = MutationResponse & {
  __typename?: 'CreateOrderResponse';
  message: Scalars['String'];
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

/** Inputs for product creation. */
export type CreateProductInput = {
  name: Scalars['String'];
  oligoId?: Maybe<Scalars['Int']>;
  peptideId?: Maybe<Scalars['Int']>;
  productType: Scalars['String'];
};

/** Mutation response for product creation. */
export type CreateProductResponse = MutationResponse & {
  __typename?: 'CreateProductResponse';
  message: Scalars['String'];
  product?: Maybe<Product>;
  success: Scalars['Boolean'];
};

/** Inputs for sample creation. */
export type CreateSampleInput = {
  companyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oligoId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  typeName: Scalars['String'];
};

/** Mutation response for sample creation. */
export type CreateSampleResponse = MutationResponse & {
  __typename?: 'CreateSampleResponse';
  message: Scalars['String'];
  sample?: Maybe<Sample>;
  success: Scalars['Boolean'];
};

/** Inputs for siRNA series creation. */
export type CreateSirnaSeriesInput = {
  config: Array<Scalars['Int']>;
  targetId: Scalars['ID'];
};

/** Mutation response for siRNA series creation. */
export type CreateSirnaSeriesResponse = MutationResponse & {
  __typename?: 'CreateSirnaSeriesResponse';
  message: Scalars['String'];
  sirnaSeries?: Maybe<SirnaSeries>;
  success: Scalars['Boolean'];
};

/** Inputs for target creation. */
export type CreateTargetInput = {
  rnaId: Scalars['ID'];
  spliced: Scalars['Boolean'];
};

/** Mutation response for target creation. */
export type CreateTargetResponse = MutationResponse & {
  __typename?: 'CreateTargetResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  target?: Maybe<Target>;
};

/** Provides data to create a new user. */
export type CreateUserInput = {
  /** The new user's email (must be unique). */
  email: Scalars['String'];
  /** The new user's password. A random password will be generated if not provided. */
  password?: Maybe<Scalars['String']>;
  /** The new user's username (must be unique). */
  username: Scalars['String'];
};

/** Mutation response when creating a new user. */
export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  /** The message on the outcome of the mutation. */
  message: Scalars['String'];
  /** Whether the mutation succeeded. */
  success: Scalars['Boolean'];
  /** The newly created user. */
  user?: Maybe<User>;
};

/** A customer. */
export type Customer = {
  __typename?: 'Customer';
  /** The billing address of the customer. */
  billingAddress: Scalars['String'];
  /** The company from which the customer is from. */
  company: Company;
  /** The contact number of the customer. */
  contactNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The email of the customer. */
  email: Scalars['String'];
  /** The first name of the customer. */
  firstName: Scalars['String'];
  /** The full name of the customer. */
  fullName: Scalars['String'];
  /** The unique ID associated with the customer. */
  id: Scalars['ID'];
  /** The last name of the customer. */
  lastName: Scalars['String'];
  /** The orders of the customer. */
  orders?: Maybe<Array<Order>>;
  /** The shipping address of the customer. */
  shippingAddress: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  currentPage: Scalars['Int'];
  edges: Array<CustomerEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  cursor: Scalars['String'];
  node: Customer;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum CustomerSortField {
  CompanyAsc = 'company_ASC',
  CompanyDesc = 'company_DESC',
  ContactNumberAsc = 'contactNumber_ASC',
  ContactNumberDesc = 'contactNumber_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC'
}

/** Filtering inputs for customers. */
export type CustomerWhereInput = {
  companies?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** A delivery. */
export type Delivery = {
  __typename?: 'Delivery';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The date and time of the delivery. */
  deliverAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the delivery. */
  id: Scalars['String'];
  /** The mode of the delivery. */
  mode?: Maybe<Scalars['String']>;
  /** The order of the delivery. */
  order?: Maybe<Order>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type DeliveryConnection = {
  __typename?: 'DeliveryConnection';
  currentPage: Scalars['Int'];
  edges: Array<DeliveryEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type DeliveryEdge = {
  __typename?: 'DeliveryEdge';
  cursor: Scalars['String'];
  node: Delivery;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum DeliverySortField {
  DeliverAtAsc = 'deliverAt_ASC',
  DeliverAtDesc = 'deliverAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ModeAsc = 'mode_ASC',
  ModeDesc = 'mode_DESC'
}

/** Filtering inputs for deliveries. */
export type DeliveryWhereInput = {
  id?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** A gene. */
export type Gene = {
  __typename?: 'Gene';
  /** The aliases of the gene. */
  aliases: Array<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the gene. */
  desc?: Maybe<Scalars['String']>;
  /** The Ensembl ID of the gene. */
  ensgId?: Maybe<Scalars['String']>;
  /** The NCBI GeneID of the gene. */
  id: Scalars['ID'];
  /** The chromosomal loci of the gene. */
  loci?: Maybe<Array<GeneMapping>>;
  /** The name of the gene. */
  name: Scalars['String'];
  /** The organism of the gene. */
  organism?: Maybe<Organism>;
  /** The proteins of the gene. */
  proteins?: Maybe<Array<Protein>>;
  /** The RNAs of the gene. */
  rnas?: Maybe<Array<Rna>>;
  /** The RefSeqGene ID of the gene. */
  rsgId?: Maybe<Scalars['String']>;
  /** The symbol of the gene. */
  symbol: Scalars['String'];
  /** The synonyms of the gene. */
  synonyms: Array<Scalars['String']>;
  /** The type of the gene. */
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** The chromosomal locus of a gene. */
export type GeneMapping = {
  __typename?: 'GeneMapping';
  /** The chromosomal band of the locus. */
  band: Scalars['String'];
  /** The chromosome number of the locus. */
  chrNo: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The end position of the locus on the chromosome. */
  end: Scalars['Int'];
  /** The gene at the locus. */
  gene: Gene;
  /** The unique ID associated with the gene mapping. */
  id: Scalars['ID'];
  /** The length of the locus. */
  length: Scalars['Int'];
  /** The chromosomal locus of the gene. */
  locus: Scalars['String'];
  /** The start position of the locus on the chromosome. */
  start: Scalars['Int'];
  /** The chromosomal strand of the locus. */
  strand: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A hardware reagent. */
export type HardwareReagent = {
  __typename?: 'HardwareReagent';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the hardware reagent. */
  id: Scalars['ID'];
  /** The title of the hardware reagent (must be unique). */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A modification. */
export type Modification = {
  __typename?: 'Modification';
  /** The structure of the modification in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the modification. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID associated with the modification. */
  id: Scalars['ID'];
  /** The symbol of the modification in IDT format. */
  idtSymbol?: Maybe<Scalars['String']>;
  /** The mass of the modification. */
  mass: Scalars['Float'];
  /** The name of the modification (must be unique). */
  name: Scalars['String'];
  /** Whether the modification can be placed at the 3'-end. */
  placement3: Scalars['Boolean'];
  /** Whether the modification can be placed at the 5'-end. */
  placement5: Scalars['Boolean'];
  /** The symbol of the modification (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a single antisense series. */
  createAntisenseSeries: CreateAntisenseSeriesResponse;
  /** Create multiple ASOs. */
  createAsos: CreateAsosResponse;
  /** Create a single company. */
  createCompany: CreateCompanyResponse;
  /** Create a single customer. */
  createCustomer: CreateCustomerResponse;
  /** Create a single delivery. */
  createDelivery: CreateDeliveryResponse;
  /** Create a single oligo. */
  createOligo: CreateOligoResponse;
  /** Create a single order. */
  createOrder: CreateOrderResponse;
  /** Create a single product. */
  createProduct: CreateProductResponse;
  /** Create a single sample. */
  createSample: CreateSampleResponse;
  /** Create a single siRNA series. */
  createSirnaSeries: CreateSirnaSeriesResponse;
  /** Create a single target. */
  createTarget: CreateTargetResponse;
  /** Creates a new user. */
  createUser: CreateUserResponse;
  /** Deletes a user. */
  deleteUser: Scalars['Boolean'];
  /** Update a single delivery. */
  updateDelivery: UpdateDeliveryResponse;
  /** Update a single sample. */
  updateSample: UpdateSampleResponse;
  /** Update the quantity of a SKU. */
  updateSkuQuantityBiop: UpdateSkuQuantityResponse;
  /** Update the quantity of a SKU. */
  updateSkuQuantityNasbio: UpdateSkuQuantityResponse;
  /** Escalates or deescalates admin role permissions. An admin cannot deescalate ownself. */
  updateUserAdmin: UpdateUserResponse;
  /** Blocks or unblocks a user. An admin cannot block ownself. */
  updateUserBlocked: UpdateUserResponse;
  /** Updates a user's email. */
  updateUserEmail: UpdateUserResponse;
  /** Updates a user's password. */
  updateUserPassword: UpdateUserPasswordResponse;
  /** Updates a user's username. */
  updateUserUsername: UpdateUserResponse;
};


export type MutationCreateAntisenseSeriesArgs = {
  input: CreateAntisenseSeriesInput;
};


export type MutationCreateAsosArgs = {
  asos: Array<CreateAsoInput>;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateDeliveryArgs = {
  orderId: Scalars['Int'];
};


export type MutationCreateOligoArgs = {
  input: CreateOligoInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateSampleArgs = {
  input: CreateSampleInput;
};


export type MutationCreateSirnaSeriesArgs = {
  input: CreateSirnaSeriesInput;
};


export type MutationCreateTargetArgs = {
  input: CreateTargetInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDeliveryArgs = {
  id: Scalars['ID'];
  input: UpdateDeliveryInput;
};


export type MutationUpdateSampleArgs = {
  id: Scalars['ID'];
  input: UpdateSampleInput;
};


export type MutationUpdateSkuQuantityBiopArgs = {
  input: UpdateSkuQuantityInput;
};


export type MutationUpdateSkuQuantityNasbioArgs = {
  input: UpdateSkuQuantityInput;
};


export type MutationUpdateUserAdminArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserBlockedArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


export type MutationUpdateUserUsernameArgs = {
  input: UpdateUserUsernameInput;
};

export type MutationResponse = {
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** A nucleoside. */
export type Nucleoside = {
  __typename?: 'Nucleoside';
  /** Whether the nucleoside terminates at the 3'-end. */
  backTerminating: Scalars['Boolean'];
  /** The base component of the nucleoside. */
  base?: Maybe<Base>;
  baseId: Scalars['ID'];
  /** The structure of the nucleoside in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the nucleoside. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID associated with the nucleoside. */
  id: Scalars['ID'];
  /** The symbol of the nucleoside in IDT format. */
  idtSymbol?: Maybe<Scalars['String']>;
  /** The mass of the nucleoside. */
  mass: Scalars['Float'];
  /** The name of the nucleoside (must be unique). */
  name: Scalars['String'];
  /** The sugar component of the nucleoside. */
  sugar?: Maybe<Sugar>;
  sugarId: Scalars['ID'];
  /** The structure of the nucleoside in svg format. */
  svg?: Maybe<Scalars['String']>;
  /** The symbol of the nucleoside (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type NucleosideConnection = {
  __typename?: 'NucleosideConnection';
  currentPage: Scalars['Int'];
  edges: Array<NucleosideEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type NucleosideEdge = {
  __typename?: 'NucleosideEdge';
  cursor: Scalars['String'];
  node: Nucleoside;
};

/** Filtering inputs for nucleosides. */
export type NucleosideWhereInput = {
  backTerminating?: Maybe<Scalars['Boolean']>;
  baseId?: Maybe<Scalars['ID']>;
  baseIds?: Maybe<Array<Scalars['ID']>>;
  baseSymbol?: Maybe<Scalars['String']>;
  frontExtensible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sugarId?: Maybe<Scalars['ID']>;
  sugarIds?: Maybe<Array<Scalars['ID']>>;
  symbol?: Maybe<Scalars['String']>;
};

/** An oligo. */
export type Oligo = {
  __typename?: 'Oligo';
  /** Aliases of the oligo. */
  aliases: Array<Scalars['String']>;
  /** ID of the antisense component of the oligo. */
  antisenseId?: Maybe<Scalars['Int']>;
  /** IDs of backbones making up the oligo. */
  bbIds: Array<Scalars['ID']>;
  /** Sequence of the core component of the oligo. */
  coreSeq?: Maybe<Scalars['String']>;
  /** IDs of couplings making up the oligo. */
  cpIds: Array<Scalars['ID']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** EC260 of the oligo. */
  ec260: Scalars['Int'];
  /** The unique ID associated with the oligo. */
  id: Scalars['ID'];
  /** Sequence of the oligo in IDT format. */
  idtSeq?: Maybe<Array<Scalars['String']>>;
  /** Whether the oligo is the antisense strand of an siRNA duplex. */
  isSirnaAntisense?: Maybe<Scalars['Boolean']>;
  /** Length of the oligo. */
  length: Scalars['Int'];
  /** Mass of the oligo. */
  mass: Scalars['Float'];
  /** The 3'-modification of the oligo. */
  mod3?: Maybe<Modification>;
  /** The 5'-modification of the oligo. */
  mod5?: Maybe<Modification>;
  /** List of modifications to the oligo. */
  mods?: Maybe<Array<Modification>>;
  /** List of modified positions on the oligo. */
  modsPos?: Maybe<Array<Scalars['Int']>>;
  /** Name of the oligo. */
  name: Scalars['String'];
  /** The products of the oligo. */
  products?: Maybe<Array<Product>>;
  /** The samples of the oligo. */
  samples?: Maybe<Array<Sample>>;
  /** ID of the siRNA duplex component of the oligo. */
  sirnaDuplexId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type OligoConnection = {
  __typename?: 'OligoConnection';
  currentPage: Scalars['Int'];
  edges: Array<OligoEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type OligoEdge = {
  __typename?: 'OligoEdge';
  cursor: Scalars['String'];
  node: Oligo;
};

/** A reagent for oligo synthesis. */
export type OligoReagent = {
  __typename?: 'OligoReagent';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the oligo reagent. */
  id: Scalars['ID'];
  /** The title of the oligo reagent (must be unique). */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type OligoReagentConnection = {
  __typename?: 'OligoReagentConnection';
  currentPage: Scalars['Int'];
  edges: Array<OligoReagentEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type OligoReagentEdge = {
  __typename?: 'OligoReagentEdge';
  cursor: Scalars['String'];
  node: OligoReagent;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum OligoReagentSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for oligo reagents. */
export type OligoReagentWhereInput = {
  title?: Maybe<Scalars['String']>;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum OligoSortField {
  CoreSeqAsc = 'coreSeq_ASC',
  CoreSeqDesc = 'coreSeq_DESC',
  Ec260Asc = 'ec260_ASC',
  Ec260Desc = 'ec260_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  MassAsc = 'mass_ASC',
  MassDesc = 'mass_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Filtering inputs for oligos. */
export type OligoWhereInput = {
  ec260?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['Int']>;
};

/** An order. */
export type Order = {
  __typename?: 'Order';
  /** Users assigned to the order. */
  assignedUsers?: Maybe<Array<User>>;
  /** Date on which the order was completed. */
  completedOn?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The customer who placed the order. */
  customer?: Maybe<Customer>;
  /** The company ID of the customer who placed the order. */
  customerCompany?: Maybe<Scalars['String']>;
  /** The ID of the customer who placed the order. */
  customerId?: Maybe<Scalars['String']>;
  /** The name of the customer who placed the order. */
  customerName?: Maybe<Scalars['String']>;
  /** The deliveries for the order. */
  deliveries?: Maybe<Array<Delivery>>;
  /** First due date for the order. */
  dueOn1?: Maybe<Scalars['Date']>;
  /** Second due date for the order. */
  dueOn2?: Maybe<Scalars['Date']>;
  /** The unique ID associated with the order. */
  id: Scalars['ID'];
  /** User-in-charge for the order. */
  inCharge?: Maybe<User>;
  /** The number of items in the order. */
  itemCount?: Maybe<Scalars['Int']>;
  /** The items for the order. */
  items?: Maybe<Array<OrderItem>>;
  /** Lead time for the order. */
  leadTime: Array<Scalars['ID']>;
  /** The date on which the PO was issued. */
  poIssuedOn?: Maybe<Scalars['Date']>;
  /** The PO number for the order. */
  poNumber?: Maybe<Scalars['String']>;
  /** The date on which the PO was received. */
  poReceivedOn?: Maybe<Scalars['Date']>;
  /** The PO for the order. */
  purchaseOrder?: Maybe<PurchaseOrder>;
  /** The remarks for the order. */
  remarks?: Maybe<Scalars['String']>;
  /** The samples of the order. */
  samples?: Maybe<Array<Sample>>;
  /** Status of the order. One of "pending", "active", "completed", "modified", "on hold" or "void". */
  status: Scalars['String'];
  /** The type of the order. */
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  currentPage: Scalars['Int'];
  edges: Array<OrderEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

/** An order item. */
export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the order item. */
  description: Scalars['String'];
  /** The discounted price of the order item. */
  discountedPrice?: Maybe<Scalars['Float']>;
  /** The ID associated with the order item. */
  id: Scalars['ID'];
  /** The ID associated with the order of the order item. */
  orderId: Scalars['ID'];
  /** The product of the order item. */
  product: Product;
  /** The ID associated with the product of the order item. */
  productId: Scalars['ID'];
  /** The quantity of the order item. */
  quantity: Scalars['Int'];
  /** The samples of the order item. */
  samples?: Maybe<Array<Sample>>;
  /** The specifications of the order item. */
  specs?: Maybe<Scalars['String']>;
  /** The unit price of the order item. */
  unitPrice: Scalars['Float'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum OrderSortField {
  CompletedOnAsc = 'completedOn_ASC',
  CompletedOnDesc = 'completedOn_DESC',
  CustomerNameAsc = 'customerName_ASC',
  CustomerNameDesc = 'customerName_DESC',
  CustomerAsc = 'customer_ASC',
  CustomerDesc = 'customer_DESC',
  DueOn1Asc = 'dueOn1_ASC',
  DueOn1Desc = 'dueOn1_DESC',
  DueOn2Asc = 'dueOn2_ASC',
  DueOn2Desc = 'dueOn2_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LeadTimeAsc = 'leadTime_ASC',
  LeadTimeDesc = 'leadTime_DESC',
  PoIssuedOnAsc = 'poIssuedOn_ASC',
  PoIssuedOnDesc = 'poIssuedOn_DESC',
  PoNumberAsc = 'poNumber_ASC',
  PoNumberDesc = 'poNumber_DESC',
  PoReceivedOnAsc = 'poReceivedOn_ASC',
  PoReceivedOnDesc = 'poReceivedOn_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

/** Selection of order status. */
export enum OrderStatusSelect {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Modified = 'MODIFIED',
  None = 'NONE',
  Onhold = 'ONHOLD',
  Pending = 'PENDING',
  Void = 'VOID'
}

/** An order type. */
export type OrderType = {
  __typename?: 'OrderType';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The name of the order type. */
  name: Scalars['String'];
  /** The orders under the order type. */
  orders: Array<Order>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type OrderTypeConnection = {
  __typename?: 'OrderTypeConnection';
  currentPage: Scalars['Int'];
  edges: Array<OrderTypeEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type OrderTypeEdge = {
  __typename?: 'OrderTypeEdge';
  cursor: Scalars['String'];
  node: OrderType;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum OrderTypeSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Filtering inputs for order types. */
export type OrderTypeWhereInput = {
  search?: Maybe<Scalars['String']>;
};

/** Filtering inputs for orders. */
export type OrderWhereInput = {
  companies?: Maybe<Array<Scalars['String']>>;
  customers?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['Int']>;
  orderTypes?: Maybe<Array<Scalars['String']>>;
  po?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatusSelect>;
  type?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** An organism. */
export type Organism = {
  __typename?: 'Organism';
  /** The assemblies of the organism. */
  assemblies?: Maybe<Array<Assembly>>;
  /** The chromosomes of the organism. */
  chromosomes?: Maybe<Array<Chromosome>>;
  /** The common name of the organism. */
  commonName: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The NCBI TaxId of the organism. */
  id: Scalars['ID'];
  /** The scientific name of the organism. */
  scientificName: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type OrganismConnection = {
  __typename?: 'OrganismConnection';
  currentPage: Scalars['Int'];
  edges: Array<OrganismEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type OrganismEdge = {
  __typename?: 'OrganismEdge';
  cursor: Scalars['String'];
  node: Organism;
};

/** Selection of organisms. */
export enum OrganismSelect {
  Human = 'HUMAN',
  Mouse = 'MOUSE',
  None = 'NONE',
  Rat = 'RAT'
}

/** List of sorting parameters made up of field name and sort order pairs. */
export enum OrganismSortField {
  CommonNameAsc = 'commonName_ASC',
  CommonNameDesc = 'commonName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ScientificNameAsc = 'scientificName_ASC',
  ScientificNameDesc = 'scientificName_DESC'
}

/** Filtering inputs for organisms. */
export type OrganismWhereInput = {
  name?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor to continue from when paginating forward. */
  endCursor?: Maybe<Scalars['String']>;
  /** Whether there are more items when paginating forward. */
  hasNextPage: Scalars['Boolean'];
  /** Whether there are more items when paginating backward. */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue from when paginating backward. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A reagent for peptide synthesis. */
export type PeptideReagent = {
  __typename?: 'PeptideReagent';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the peptide reagent. */
  id: Scalars['ID'];
  /** The title of the peptide reagent (must be unique). */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A product. */
export type Product = {
  __typename?: 'Product';
  /** The oligo ID of the antisense strand of the product. */
  asId?: Maybe<Scalars['Int']>;
  /** The siRNA antisense strand of the product. */
  asOligo?: Maybe<Oligo>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the product. */
  id: Scalars['ID'];
  /** The name of the product. */
  name: Scalars['String'];
  /** The oligo of the product. */
  oligo?: Maybe<Oligo>;
  /** The oligo ID of the product. */
  oligoId?: Maybe<Scalars['Int']>;
  /** The samples of the product. */
  samples?: Maybe<Array<Sample>>;
  /** The oligo ID of the sense strand of the product. */
  ssId?: Maybe<Scalars['Int']>;
  /** The siRNA sense strand of the product. */
  ssOligo?: Maybe<Oligo>;
  /** The type of the product. */
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  currentPage: Scalars['Int'];
  edges: Array<ProductEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String'];
  node: Product;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum ProductSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** A product type. */
export type ProductType = {
  __typename?: 'ProductType';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The name of the product type. */
  name: Scalars['String'];
  /** The products under the product type. */
  products: Array<Order>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  currentPage: Scalars['Int'];
  edges: Array<ProductTypeEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  cursor: Scalars['String'];
  node: ProductType;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum ProductTypeSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Filtering inputs for product types. */
export type ProductTypeWhereInput = {
  search?: Maybe<Scalars['String']>;
};

/** Filtering inputs for products. */
export type ProductWhereInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** A RefSeq protein. */
export type Protein = {
  __typename?: 'Protein';
  acc: Scalars['String'];
  /** The assembly of the protein. */
  assembly?: Maybe<Assembly>;
  /** The CDS length of the protein. */
  cdsLength: Scalars['Int'];
  /** The CDS segment count of the protein. */
  cdsSegmentCount: Scalars['Int'];
  /** The start and end positions of CDS segments on the protein. */
  cdsSegments?: Maybe<Array<Array<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The gene of the protein. */
  gene?: Maybe<Gene>;
  id: Scalars['ID'];
  /** The length of the protein. */
  length: Scalars['Int'];
  /** The chromosomal loci of the protein. */
  loci?: Maybe<Array<ProteinMapping>>;
  /** The organism of the protein. */
  organism?: Maybe<Organism>;
  /** The sequence of the protein. */
  seq: Scalars['String'];
  /** The title of the protein. */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** The chromosomal locus of a protein. */
export type ProteinMapping = {
  __typename?: 'ProteinMapping';
  /** The chromosome number of the locus. */
  chrNo: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The end positions of the CDS segments on the chromosome. */
  ends: Array<Scalars['Int']>;
  id: Scalars['String'];
  /** The length of the CDS. */
  length: Scalars['Int'];
  /** The chromosomal locus of the CDS. */
  locus: Scalars['String'];
  /** The protein at the locus. */
  protein: Protein;
  /** The start positions of the CDS segments on the chromosome. */
  starts: Array<Scalars['Int']>;
  /** The chromosomal strand of the CDS. */
  strand: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A purchase order (PO). */
export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The date on which PO was issued. */
  issuedOn?: Maybe<Scalars['Date']>;
  /** The PO number. */
  number: Scalars['String'];
  /** The order for the PO. */
  order?: Maybe<Order>;
  /** The date on which PO was received. */
  receivedOn?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a list of amidites. */
  amidites: AmiditeConnection;
  antisenseCart: Array<AntisenseCartItem>;
  /** A ping statement for health check. */
  antisensePing: Scalars['String'];
  /** Retrieve a single antisense sequence by ID. */
  antisenseSequence: AntisenseSequence;
  /** Retrieve a list of antisense sequences. */
  antisenseSequences: AntisenseSequenceConnection;
  /** Retrieve a list of assemblies. */
  assemblies: AssemblyConnection;
  /** Retrieve a single assembly by name. */
  assembly?: Maybe<Assembly>;
  /** Retrieve a list of backbones. */
  backbones: BackboneConnection;
  /** Retrieve a single base by ID. */
  base?: Maybe<Base>;
  /** Retrieve a single base by symbol. */
  baseBySymbol?: Maybe<Base>;
  /** Retrieve a list of bases. */
  bases: BaseConnection;
  /** Retrieve a single chromosome by RefSeq ID. */
  chromosome?: Maybe<Chromosome>;
  /** Retrieve a list of chromosomes. */
  chromosomes: ChromosomeConnection;
  /** Retrieve a list of companies. */
  companies: CompanyConnection;
  /** Retrieve a single company by ID. */
  company?: Maybe<Company>;
  /** Retrieve a single coupling by composition of sugar, base & backbone. */
  couplingByComposition?: Maybe<Coupling>;
  /** Retrieve a list of couplings. */
  couplings: CouplingConnection;
  /** Retrieves record of the currently logged-in user. */
  currentUser?: Maybe<User>;
  /** Retrieve a single customer by ID. */
  customer?: Maybe<Customer>;
  /** Retrieve a list of customers. */
  customers: CustomerConnection;
  /** Retrieve a list of deliveries. */
  deliveries: DeliveryConnection;
  /** Retrieve a single delivery by ID. */
  delivery?: Maybe<Delivery>;
  /** A ping statement for health check. */
  genomePing: Scalars['String'];
  /** Retrieve a list of nucleosides. */
  nucleosides: NucleosideConnection;
  /** Retrieve a list of reagents for oligo synthesis. */
  oligoReagents: OligoReagentConnection;
  /** Retrieve a list of oligos. */
  oligos: OligoConnection;
  /** A ping statement for health check. */
  oligosPing: Scalars['String'];
  /** Retrieve a single order by ID. */
  order?: Maybe<Order>;
  /** Retrieve a list of order types. */
  orderTypes: OrderTypeConnection;
  /** Retrieve a list of orders. */
  orders: OrderConnection;
  /** A ping statement for health check. */
  ordersPing: Scalars['String'];
  /** Retrieve a single organism by ID. */
  organism?: Maybe<Organism>;
  /** Retrieve a list of organisms. */
  organisms: OrganismConnection;
  /** Retrieve a single product by ID. */
  product?: Maybe<Product>;
  /** Retrieve a list of product types. */
  productTypes: ProductTypeConnection;
  /** Retrieve a list of products. */
  products: ProductConnection;
  /** A ping statement for health check. */
  proteinsPing: Scalars['String'];
  /** A ping statement for health check. */
  reagentsPing: Scalars['String'];
  /** Retrieve a single RNA by ID. */
  rna?: Maybe<Rna>;
  /** Retrieve a list of RNAs. */
  rnas: RnaConnection;
  /** A ping statement for health check. */
  rnasPing: Scalars['String'];
  /** Retrieve a single sample by ID. */
  sample?: Maybe<Sample>;
  sampleCart: Array<SampleCartItem>;
  /** Retrieve a list of companies. */
  samples: SampleConnection;
  /** A ping statement for health check. */
  samplesPing: Scalars['String'];
  /** Search for RNAs. */
  searchRnas: RnaConnection;
  /** Search for targets. */
  searchTargets: TargetConnection;
  sirnaCart: Array<SirnaCartItem>;
  /** Retrieve a single siRNA duplex by ID. */
  sirnaDuplex: SirnaDuplex;
  /** Retrieve a list of siRNA duplexes. */
  sirnaDuplexes: SirnaDuplexConnection;
  /** Retrieve a list of SKUs. */
  skus: SkuConnection;
  /** A ping statement for health check. */
  structuresPing: Scalars['String'];
  /** Retrieve a single sugar by ID. */
  sugar?: Maybe<Sugar>;
  /** Retrieve a list of sugars. */
  sugars: SugarConnection;
  /** Retrieve a single target by ID, or a combination of RefSeq ID & spliced status. */
  target?: Maybe<Target>;
  /** Retrieve a list of targets. */
  targets: TargetConnection;
  /** Retrieves a single user by ID. */
  user: User;
  /** Retrieves a list of users. */
  users: UserConnection;
  /** A ping statement for health check. */
  usersPing?: Maybe<Scalars['String']>;
};


export type QueryAmiditesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AmiditeWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AmiditeSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryAntisenseSequenceArgs = {
  id: Scalars['ID'];
};


export type QueryAntisenseSequencesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AntisenseSequenceWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AntisenseSequenceSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryAssembliesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssemblyWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AssemblySortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryAssemblyArgs = {
  name: Scalars['String'];
};


export type QueryBackbonesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<BackboneWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BuildingBlockSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryBaseArgs = {
  id: Scalars['ID'];
};


export type QueryBaseBySymbolArgs = {
  symbol: Scalars['String'];
};


export type QueryBasesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<BaseWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BuildingBlockSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryChromosomeArgs = {
  id: Scalars['ID'];
};


export type QueryChromosomesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ChromosomeWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ChromosomeSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CompanyWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CompanySortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCouplingByCompositionArgs = {
  filter: CouplingCompositionInput;
};


export type QueryCouplingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CouplingWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BuildingBlockSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CustomerWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CustomerSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryDeliveriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DeliveryWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DeliverySortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryArgs = {
  id: Scalars['ID'];
};


export type QueryNucleosidesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NucleosideWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BuildingBlockSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryOligoReagentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OligoReagentWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OligoReagentSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryOligosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OligoWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OligoSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderTypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OrderTypeWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrderTypeSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OrderWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrderSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryOrganismArgs = {
  id: Scalars['ID'];
};


export type QueryOrganismsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<OrganismWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganismSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductTypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductTypeWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductTypeSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryProductsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ProductWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProductSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryRnaArgs = {
  id: Scalars['ID'];
};


export type QueryRnasArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RnaWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<RnaSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySampleArgs = {
  id: Scalars['ID'];
};


export type QuerySamplesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SampleWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SampleSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySearchRnasArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RnaSearchInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<RnaSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySearchTargetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TargetSearchInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TargetSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySirnaDuplexArgs = {
  id: Scalars['ID'];
};


export type QuerySirnaDuplexesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SirnaDuplexWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SirnaDuplexSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySkusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SkuWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SkuSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QuerySugarArgs = {
  id: Scalars['ID'];
};


export type QuerySugarsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SugarWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BuildingBlockSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryTargetArgs = {
  filter: SingleTargetInput;
};


export type QueryTargetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TargetWhereInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TargetSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserSortField>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

/** A RefSeq RNA. */
export type Rna = {
  __typename?: 'Rna';
  acc: Scalars['String'];
  /** The aliases of the gene of the RNA. */
  aliases?: Maybe<Array<Scalars['String']>>;
  /** The assembly of the RNA. */
  assembly?: Maybe<Assembly>;
  /** The CDS end position on the RNA. */
  cdsEnd?: Maybe<Scalars['Int']>;
  /** The CDS start position on the RNA. */
  cdsStart?: Maybe<Scalars['Int']>;
  /** The end position of the RNA locus on the chromosomes. */
  chrEnd: Scalars['Int'];
  /** The chromosome number of the RNA. */
  chrNo: Scalars['String'];
  /** The start position of the RNA locus on the chromosome. */
  chrStart: Scalars['Int'];
  /** The chromosomal strand of the RNA. */
  chrStrand: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The description of the RNA. */
  desc?: Maybe<Scalars['String']>;
  /** The Ensembl transcript ID of the RNA. */
  enstId?: Maybe<Scalars['String']>;
  /** The sequence of the RNA at the provided exon number. */
  exon?: Maybe<Scalars['String']>;
  /** The exon count of the RNA. */
  exonCount: Scalars['Int'];
  /** The start and end positions of exons on the RNA. */
  exons?: Maybe<Array<Array<Scalars['Int']>>>;
  /** The gene of the RNA. */
  gene?: Maybe<Gene>;
  /** The description of the gene of the RNA. */
  geneDesc?: Maybe<Scalars['String']>;
  /** The gene ID of the RNA. */
  geneId?: Maybe<Scalars['Int']>;
  /** The name of the gene of the RNA. */
  geneName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Whether the mRNA is an existing target. */
  isMrnaTarget?: Maybe<Scalars['Boolean']>;
  /** Whether the pre-mRNA is an existing target. */
  isRnaTarget?: Maybe<Scalars['Boolean']>;
  /** The chromosomal loci of the RNA. */
  loci?: Maybe<Array<RnaMapping>>;
  /** The length of the mRNA. */
  mrnaLength: Scalars['Int'];
  /** The organism of the RNA. */
  organism?: Maybe<Organism>;
  /** The organism ID of the RNA. */
  organismId: Scalars['Int'];
  /** The sequence of the primary transcript of the RNA. */
  priSeq?: Maybe<Scalars['String']>;
  /** The RefSeq ID of the protein the RNA encodes for. */
  protId?: Maybe<Scalars['String']>;
  /** The length of the protein the RNA encodes for. */
  protLength?: Maybe<Scalars['Int']>;
  /** The title of the protein the RNA encodes for. */
  protTitle?: Maybe<Scalars['String']>;
  protein?: Maybe<Protein>;
  /** The length of the primary transcript of the RNA. */
  rnaLength: Scalars['Int'];
  /** The sequence of the RNA. */
  seq?: Maybe<Scalars['String']>;
  /** The status of the RefSeq record. */
  status?: Maybe<Scalars['String']>;
  /** The symbol of the RNA. */
  symbol: Scalars['String'];
  /** The synonyms of the gene of the RNA. */
  synonyms?: Maybe<Array<Scalars['String']>>;
  /** The mRNA and pre-mRNA targets of the RNA. */
  targets?: Maybe<Array<Target>>;
  /** The title of the RNA. */
  title: Scalars['String'];
  /** The type of the RNA. */
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['datetime']>;
  /** The 3'-UTR sequence of the RNA. */
  utr3?: Maybe<Scalars['String']>;
  /** The length of the 3'-UTR of the RNA. */
  utr3Length: Scalars['Int'];
  /** The 5'-UTR sequence of the RNA. */
  utr5?: Maybe<Scalars['String']>;
  /** The length of the 5'-UTR of the RNA. */
  utr5Length: Scalars['Int'];
};


/** A RefSeq RNA. */
export type RnaExonArgs = {
  exonId: Scalars['Int'];
};

/** A list of edges with pagination information. */
export type RnaConnection = {
  __typename?: 'RnaConnection';
  currentPage: Scalars['Int'];
  edges: Array<RnaEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type RnaEdge = {
  __typename?: 'RnaEdge';
  cursor: Scalars['String'];
  node: Rna;
};

/** The chromosomal locus of a RNA. */
export type RnaMapping = {
  __typename?: 'RnaMapping';
  /** The chromosome number of the locus. */
  chrNo: Scalars['String'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** The end positions of the exons on the chromosome. */
  ends: Array<Scalars['Int']>;
  id: Scalars['String'];
  /** The length of the pre-mRNA. */
  length: Scalars['Int'];
  /** The chromosomal locus of the pre-mRNA. */
  locus: Scalars['String'];
  /** The start positions of the exons on the chromosome. */
  starts: Array<Scalars['Int']>;
  /** The chromosomal strand of the pre-mRNA. */
  strand: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** Search input for RNAs. */
export type RnaSearchInput = {
  excludeModel?: Maybe<Scalars['Boolean']>;
  organism?: Maybe<OrganismSelect>;
  search?: Maybe<Scalars['String']>;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum RnaSortField {
  AccAsc = 'acc_ASC',
  AccDesc = 'acc_DESC',
  ChrEndAsc = 'chrEnd_ASC',
  ChrEndDesc = 'chrEnd_DESC',
  ChrNoAsc = 'chrNo_ASC',
  ChrNoDesc = 'chrNo_DESC',
  ChrStartAsc = 'chrStart_ASC',
  ChrStartDesc = 'chrStart_DESC',
  ChrStrandAsc = 'chrStrand_ASC',
  ChrStrandDesc = 'chrStrand_DESC',
  ExonCountAsc = 'exonCount_ASC',
  ExonCountDesc = 'exonCount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MrnaLengthAsc = 'mrnaLength_ASC',
  MrnaLengthDesc = 'mrnaLength_DESC',
  RnaLengthAsc = 'rnaLength_ASC',
  RnaLengthDesc = 'rnaLength_DESC',
  SymbolAsc = 'symbol_ASC',
  SymbolDesc = 'symbol_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  Utr3LengthAsc = 'utr3Length_ASC',
  Utr3LengthDesc = 'utr3Length_DESC',
  Utr5LengthAsc = 'utr5Length_ASC',
  Utr5LengthDesc = 'utr5Length_DESC'
}

/** Filtering inputs for RNAs. */
export type RnaWhereInput = {
  acc?: Maybe<Scalars['String']>;
  chrEnd?: Maybe<Scalars['String']>;
  chrNo?: Maybe<Scalars['String']>;
  chrStart?: Maybe<Scalars['String']>;
  chrStrand?: Maybe<Scalars['String']>;
  excludeModel?: Maybe<Scalars['Boolean']>;
  exonCount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mrnaLength?: Maybe<Scalars['String']>;
  organism?: Maybe<OrganismSelect>;
  rnaLength?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  utr3Length?: Maybe<Scalars['String']>;
  utr5Length?: Maybe<Scalars['String']>;
};

/** A sample. */
export type Sample = {
  __typename?: 'Sample';
  /** The oligo ID of the antisense strand of the sample. */
  asId?: Maybe<Scalars['Int']>;
  /** The siRNA antisense strand of the sample. */
  asOligo?: Maybe<Oligo>;
  /** The company which ordered the sample. */
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The unique ID associated with the sample. */
  id: Scalars['ID'];
  isInCart: Scalars['Boolean'];
  /** MALDI spectrum of the sample. */
  maldi?: Maybe<Scalars['String']>;
  /** The name of the sample if specified. */
  name?: Maybe<Scalars['String']>;
  /** The oligo of the sample. */
  oligo?: Maybe<Oligo>;
  /** The oligo ID of the sample. */
  oligoId?: Maybe<Scalars['Int']>;
  /** The order for which the sample belongs. */
  order?: Maybe<Order>;
  /** The order ID for which the sample belongs. */
  orderId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  /** The product ID of the sample. */
  productId?: Maybe<Scalars['Int']>;
  /** The oligo ID of the sense strand of the sample. */
  ssId?: Maybe<Scalars['Int']>;
  /** The siRNA sense strand of the sample. */
  ssOligo?: Maybe<Oligo>;
  /** Status of the sample. One of "active", "completed", or "void". */
  status: Scalars['String'];
  /** The type of the sample. */
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SampleCartItem = {
  __typename?: 'SampleCartItem';
  ec260?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mass?: Maybe<Scalars['Float']>;
};

/** A list of edges with pagination information. */
export type SampleConnection = {
  __typename?: 'SampleConnection';
  currentPage: Scalars['Int'];
  edges: Array<SampleEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type SampleEdge = {
  __typename?: 'SampleEdge';
  cursor: Scalars['String'];
  node: Sample;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum SampleSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderIdAsc = 'orderId_ASC',
  OrderIdDesc = 'orderId_DESC'
}

/** Selection of sample status. */
export enum SampleStatusSelect {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  None = 'NONE',
  Void = 'VOID'
}

/** An order type. */
export type SampleType = {
  __typename?: 'SampleType';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The name of the sample type. */
  name: Scalars['String'];
  /** The orders under the sample type. */
  orders: Array<Sample>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Filtering inputs for samples. */
export type SampleWhereInput = {
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<SampleStatusSelect>;
};

/** Filtering inputs to retrieve a single target. */
export type SingleTargetInput = {
  id?: Maybe<Scalars['ID']>;
  rnaId?: Maybe<Scalars['ID']>;
  spliced?: Maybe<Scalars['Boolean']>;
};

export type SirnaCartItem = {
  __typename?: 'SirnaCartItem';
  id: Scalars['ID'];
  sirnaId: Scalars['ID'];
  targetId: Scalars['ID'];
};

/** An siRNA duplex. */
export type SirnaDuplex = {
  __typename?: 'SirnaDuplex';
  /** AT content of the siRNA seed region. */
  atContentSeed: Scalars['Float'];
  /** Number of matching transcripts for siRNA antisense strand from BLAST search. */
  blastMatchesAs?: Maybe<Scalars['Int']>;
  /** Number of matching transcripts for siRNA sense strand from BLAST search. */
  blastMatchesS?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** End position of the siRNA antisense strand. */
  endAs: Scalars['Int'];
  /** End position of the siRNA sense strand. */
  endS: Scalars['Int'];
  /** Exon placement of the siRNA target. */
  exonId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isInCart: Scalars['Boolean'];
  /** Length of the siRNA antisense strand. */
  lengthAs: Scalars['Int'];
  /** Length of the siRNA sense strand. */
  lengthS: Scalars['Int'];
  /** Placement of the siRNA on the target RNA. */
  region?: Maybe<Scalars['String']>;
  /** Sequence of the siRNA antisense strand. */
  seqAs: Scalars['String'];
  /** Sequence of the siRNA sense strand. */
  seqS: Scalars['String'];
  /** The siRNA series that the duplex belongs to. */
  series?: Maybe<SirnaSeries>;
  /** Start position of the siRNA antisense strand. */
  startAs: Scalars['Int'];
  /** Start position of the siRNA sense strand. */
  startS: Scalars['Int'];
  /** The target of the siRNA duplex. */
  target?: Maybe<Target>;
  /** Sequence of the siRNA target. */
  targetSeq: Scalars['String'];
  /** Tm of the full siRNA at 0.25 µM [strand] & 150 mM [Na+]. */
  tm: Scalars['Float'];
  /** Tm of the siRNA seed region at 0.25 µM [strand] & 150 mM [Na+]. */
  tmSeed: Scalars['Float'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type SirnaDuplexConnection = {
  __typename?: 'SirnaDuplexConnection';
  currentPage: Scalars['Int'];
  edges: Array<SirnaDuplexEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type SirnaDuplexEdge = {
  __typename?: 'SirnaDuplexEdge';
  cursor: Scalars['String'];
  node: SirnaDuplex;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum SirnaDuplexSortField {
  AtContentSeedAsc = 'atContentSeed_ASC',
  AtContentSeedDesc = 'atContentSeed_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndAsAsc = 'endAs_ASC',
  EndAsDesc = 'endAs_DESC',
  EndSAsc = 'endS_ASC',
  EndSDesc = 'endS_DESC',
  ExonIdAsc = 'exonId_ASC',
  ExonIdDesc = 'exonId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LengthAsAsc = 'lengthAs_ASC',
  LengthAsDesc = 'lengthAs_DESC',
  LengthSAsc = 'lengthS_ASC',
  LengthSDesc = 'lengthS_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  SeqAsAsc = 'seqAs_ASC',
  SeqAsDesc = 'seqAs_DESC',
  SeqSAsc = 'seqS_ASC',
  SeqSDesc = 'seqS_DESC',
  StartAsAsc = 'startAs_ASC',
  StartAsDesc = 'startAs_DESC',
  StartSAsc = 'startS_ASC',
  StartSDesc = 'startS_DESC',
  TmSeedAsc = 'tmSeed_ASC',
  TmSeedDesc = 'tmSeed_DESC',
  TmAsc = 'tm_ASC',
  TmDesc = 'tm_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for siRNA duplex. */
export type SirnaDuplexWhereInput = {
  atContentSeed?: Maybe<Scalars['String']>;
  exonId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rnaId?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['Int']>;
  tm?: Maybe<Scalars['String']>;
  tmSeed?: Maybe<Scalars['String']>;
};

/** An siRNA series. */
export type SirnaSeries = {
  __typename?: 'SirnaSeries';
  /** Configuration of the siRNA series. */
  config: Array<Scalars['Int']>;
  createdAt?: Maybe<Scalars['datetime']>;
  id: Scalars['ID'];
  /** The target of the siRNA series. */
  target?: Maybe<Target>;
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A stock keeping unit (SKU) for reagent. */
export type Sku = {
  __typename?: 'Sku';
  /** The SKU corresponding to a chemical reagent. */
  chemicalReagent?: Maybe<ChemicalReagent>;
  chemicalReagentId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** The user who created the SKU. */
  createdBy?: Maybe<User>;
  /** The discount percentage applied to the SKU. */
  discount: Scalars['Decimal'];
  /** The SKU corresponding to a hardware reagent. */
  hardwareReagent?: Maybe<HardwareReagent>;
  hardwareReagentId?: Maybe<Scalars['Int']>;
  /** The unique ID associated with the SKU. */
  id: Scalars['ID'];
  /** The list price of the SKU. */
  listPrice: Scalars['Decimal'];
  /** The price of the SKU normalized for pack size & unit size. */
  normalizedPrice: Scalars['Decimal'];
  /** The SKU corresponding to a reagent for oligo synthesis. */
  oligoReagent?: Maybe<OligoReagent>;
  oligoReagentId?: Maybe<Scalars['Int']>;
  /** The packing size of the SKU. */
  packSize: Scalars['Int'];
  /** The SKU corresponding to a reagent for peptide synthesis. */
  peptideReagent?: Maybe<PeptideReagent>;
  peptideReagentId?: Maybe<Scalars['Int']>;
  /** The product code of the SKU from the vendor. */
  productCode: Scalars['String'];
  /** The quantity of the SKU [Biop lab] in stock. */
  quantityBiop: Scalars['Int'];
  /** The quantity of the SKU [NAS Bio] in stock. */
  quantityNasbio: Scalars['Int'];
  /** The SKU corresponding to a solvent reagent. */
  solventReagent?: Maybe<SolventReagent>;
  solventReagentId?: Maybe<Scalars['Int']>;
  /** Title of the Sku. */
  title: Scalars['String'];
  /** The unit of measure of the SKU. */
  unitOfMeasure: Scalars['String'];
  /** The unit price of the SKU. */
  unitPrice: Scalars['Decimal'];
  /** The unit size of the SKU. */
  unitSize: Scalars['Decimal'];
  updatedAt?: Maybe<Scalars['datetime']>;
  /** The user who last updated the SKU. */
  updatedBy?: Maybe<User>;
};

/** A list of edges with pagination information. */
export type SkuConnection = {
  __typename?: 'SkuConnection';
  currentPage: Scalars['Int'];
  edges: Array<SkuEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type SkuEdge = {
  __typename?: 'SkuEdge';
  cursor: Scalars['String'];
  node: Sku;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum SkuSortField {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  DiscountAsc = 'discount_ASC',
  DiscountDesc = 'discount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ListPriceAsc = 'listPrice_ASC',
  ListPriceDesc = 'listPrice_DESC',
  NormalizedPriceAsc = 'normalizedPrice_ASC',
  NormalizedPriceDesc = 'normalizedPrice_DESC',
  PackSizeAsc = 'packSize_ASC',
  PackSizeDesc = 'packSize_DESC',
  ProductCodeAsc = 'productCode_ASC',
  ProductCodeDesc = 'productCode_DESC',
  QuantityBiopAsc = 'quantityBiop_ASC',
  QuantityBiopDesc = 'quantityBiop_DESC',
  QuantityNasbioAsc = 'quantityNasbio_ASC',
  QuantityNasbioDesc = 'quantityNasbio_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UnitOfMeasureAsc = 'unitOfMeasure_ASC',
  UnitOfMeasureDesc = 'unitOfMeasure_DESC',
  UnitPriceAsc = 'unitPrice_ASC',
  UnitPriceDesc = 'unitPrice_DESC',
  UnitSizeAsc = 'unitSize_ASC',
  UnitSizeDesc = 'unitSize_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for SKUs. */
export type SkuWhereInput = {
  search?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** A solvent reagent. */
export type SolventReagent = {
  __typename?: 'SolventReagent';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the solvent reagent. */
  id: Scalars['ID'];
  /** The title of the solvent reagent (must be unique). */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A structure. */
export type Structure = {
  __typename?: 'Structure';
  createdAt?: Maybe<Scalars['datetime']>;
  /** The unique ID associated with the structure. */
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A sugar. */
export type Sugar = {
  __typename?: 'Sugar';
  /** Whether the sugar terminates at the 3'-end. */
  backTerminating: Scalars['Boolean'];
  /** The structure of the sugar in cml format. */
  cml?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['datetime']>;
  /** Derivatives of the sugar. */
  derivatives?: Maybe<Array<Sugar>>;
  /** The description of the sugar. */
  description?: Maybe<Scalars['String']>;
  /** Whether the sugar has an associated base. */
  hasBase: Scalars['Boolean'];
  /** The unique ID associated with the sugar. */
  id: Scalars['ID'];
  /** The mass of the sugar. */
  mass: Scalars['Float'];
  /** The name of the sugar (must be unique). */
  name: Scalars['String'];
  /** The parent of the sugar. */
  parent?: Maybe<Sugar>;
  /** The ID of the parent of the sugar. */
  parentId?: Maybe<Scalars['Int']>;
  /** The symbol of the sugar (must be unique). */
  symbol: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type SugarConnection = {
  __typename?: 'SugarConnection';
  currentPage: Scalars['Int'];
  edges: Array<SugarEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type SugarEdge = {
  __typename?: 'SugarEdge';
  cursor: Scalars['String'];
  node: Sugar;
};

/** Filtering inputs for sugars. */
export type SugarWhereInput = {
  backTerminating?: Maybe<Scalars['Boolean']>;
  hasBase?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** A RNA target. */
export type Target = {
  __typename?: 'Target';
  /** The antisense series of the target. */
  antisenseSeries?: Maybe<Array<AntisenseSeries>>;
  /** The number of antisense series for the target. */
  antisenseSeriesLength?: Maybe<Scalars['Int']>;
  /** The CDS end position for the target. */
  cdsEnd: Scalars['Int'];
  /** The CDS start position for the target. */
  cdsStart: Scalars['Int'];
  createdAt?: Maybe<Scalars['datetime']>;
  /** Whether the target is a custom sequence. Default to False. */
  custom: Scalars['Boolean'];
  /** The number of exons on the target. */
  exonCount: Scalars['Int'];
  /** The start and end positions of exons on the target. */
  exons?: Maybe<Array<Array<Scalars['Int']>>>;
  /** The gene ID of the target. */
  geneId?: Maybe<Scalars['Int']>;
  /** The gene name of the target. */
  geneName: Scalars['String'];
  /** The unique ID associated with the target. */
  id: Scalars['ID'];
  /** The length of the target. */
  length: Scalars['Int'];
  /** The organism ID of the target. */
  organismId: Scalars['Int'];
  rna?: Maybe<Rna>;
  /** The RefSeq ID of the target. */
  rnaId?: Maybe<Scalars['String']>;
  /** The sequence of the target. */
  seq?: Maybe<Scalars['String']>;
  /** The siRNA series of the target. */
  sirnaSeries?: Maybe<Array<SirnaSeries>>;
  /** The number of siRNA series for the target. */
  sirnaSeriesLength?: Maybe<Scalars['Int']>;
  /** Whether the target transcript is spliced. Default to true. */
  spliced: Scalars['Boolean'];
  /** The gene symbol of the target. */
  symbol: Scalars['String'];
  /** The title of the target. */
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
};

/** A list of edges with pagination information. */
export type TargetConnection = {
  __typename?: 'TargetConnection';
  currentPage: Scalars['Int'];
  edges: Array<TargetEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A list of edges. */
export type TargetEdge = {
  __typename?: 'TargetEdge';
  cursor: Scalars['String'];
  node: Target;
};

/** Search input for target. */
export type TargetSearchInput = {
  rnaId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  spliced?: Maybe<Scalars['Boolean']>;
};

/** List of sorting parameters made up of field name and sort order pairs. */
export enum TargetSortField {
  AntisenseSeriesLengthAsc = 'antisenseSeriesLength_ASC',
  AntisenseSeriesLengthDesc = 'antisenseSeriesLength_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExonCountAsc = 'exonCount_ASC',
  ExonCountDesc = 'exonCount_DESC',
  GeneNameAsc = 'geneName_ASC',
  GeneNameDesc = 'geneName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  RnaIdAsc = 'rnaId_ASC',
  RnaIdDesc = 'rnaId_DESC',
  SirnaSeriesLengthAsc = 'sirnaSeriesLength_ASC',
  SirnaSeriesLengthDesc = 'sirnaSeriesLength_DESC',
  SplicedAsc = 'spliced_ASC',
  SplicedDesc = 'spliced_DESC',
  SymbolAsc = 'symbol_ASC',
  SymbolDesc = 'symbol_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** Filtering inputs for targets. */
export type TargetWhereInput = {
  custom?: Maybe<Scalars['Boolean']>;
  geneName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  organism?: Maybe<OrganismSelect>;
  rnaId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  spliced?: Maybe<Scalars['Boolean']>;
  symbol?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Inputs for delivery updates. */
export type UpdateDeliveryInput = {
  deliverAt?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
};

/** Mutation response for delivery updates. */
export type UpdateDeliveryResponse = MutationResponse & {
  __typename?: 'UpdateDeliveryResponse';
  delivery?: Maybe<Delivery>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Inputs for sample updates. */
export type UpdateSampleInput = {
  maldi?: Maybe<Scalars['Upload']>;
};

/** Mutation response for sample updates. */
export type UpdateSampleResponse = MutationResponse & {
  __typename?: 'UpdateSampleResponse';
  message: Scalars['String'];
  sample?: Maybe<Sample>;
  success: Scalars['Boolean'];
};

/** Inputs to update the quantity of a SKU. */
export type UpdateSkuQuantityInput = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

/** Mutation response for update to SKU quantity. */
export type UpdateSkuQuantityResponse = MutationResponse & {
  __typename?: 'UpdateSkuQuantityResponse';
  message: Scalars['String'];
  sku?: Maybe<Sku>;
  success: Scalars['Boolean'];
};

/** Provides data to update an existing user's email. */
export type UpdateUserEmailInput = {
  /** The new email to be updated. */
  email: Scalars['String'];
  /** The unique ID associated with the user. */
  id: Scalars['ID'];
};

/**
 * Provides data to update an existing user's password.
 * A current password and new password are required to update a password.
 */
export type UpdateUserPasswordInput = {
  /** The unique ID associated with the user. */
  id: Scalars['ID'];
  /** The updated user password. */
  newPassword: Scalars['String'];
  /** The existing user password. */
  password: Scalars['String'];
};

/** Mutation response when updating the user password. */
export type UpdateUserPasswordResponse = {
  __typename?: 'UpdateUserPasswordResponse';
  /** The message on the outcome of the mutation. */
  message: Scalars['String'];
  /** Whether the mutation succeeded. */
  success: Scalars['Boolean'];
};

/**
 * "
 * Mutation response when updating an existing user.
 */
export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  /** The message on the outcome of the mutation. */
  message: Scalars['String'];
  /** Whether the mutation succeeded. */
  success: Scalars['Boolean'];
  /** The updated existing user. */
  user: User;
};

/** Provides data to update an existing user's username. */
export type UpdateUserUsernameInput = {
  /** The unique ID associated with the user. */
  id: Scalars['ID'];
  /** The new username to be updated. */
  username: Scalars['String'];
};

/** A unique Auth0 user. */
export type User = {
  __typename?: 'User';
  /** The date and time the user was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The email associated with the user (must be unique). */
  email?: Maybe<Scalars['String']>;
  /** Whether the user's email has been verified. */
  emailVerified?: Maybe<Scalars['Boolean']>;
  /** The unique ID associated with the user. */
  id: Scalars['ID'];
  /** Whether the user has an admin role. */
  isAdmin?: Maybe<Scalars['Boolean']>;
  /** Whether the user is blocked. */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** Whether the user belongs to the orders group. */
  isOrders?: Maybe<Scalars['Boolean']>;
  /** The last time the user has login to the service. */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** The IP address when the user last login to the service. */
  lastLoginIp?: Maybe<Scalars['String']>;
  /** The number of times the user has login to the service. */
  loginsCount?: Maybe<Scalars['Int']>;
  /** URL of the profile photo of the user. */
  photoUrl?: Maybe<Scalars['String']>;
  /** The date and time the user was updated. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The username associated with the user (must be unique). */
  username: Scalars['String'];
  usernameUpper?: Maybe<Scalars['String']>;
};

/** A list of user edges with pagination information. */
export type UserConnection = {
  __typename?: 'UserConnection';
  currentPage: Scalars['Int'];
  /** A list of user edges. */
  edges: Array<UserEdge>;
  filteredCount: Scalars['Int'];
  pageCount: Scalars['Int'];
  /** Information to assist with pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A single user node with its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['ID'];
  /** A user at the end of an edge. */
  node: User;
};

/** Sorting options for user connections. */
export enum UserSortField {
  /** Order users ascending by creation time. */
  CreatedAtAsc = 'createdAt_ASC',
  /** Order users descending by creation time. */
  CreatedAtDesc = 'createdAt_DESC',
  /** Order users ascending by email. */
  EmailAsc = 'email_ASC',
  /** Order users descending by email. */
  EmailDesc = 'email_DESC',
  /** Order users ascending by logins count. */
  LoginsCountAsc = 'loginsCount_ASC',
  /** Order users descending by logins count. */
  LoginsCountDesc = 'loginsCount_DESC',
  /** Order users ascending by last updated time. */
  UpdatedAtAsc = 'updatedAt_ASC',
  /** Order users descending by last updated time. */
  UpdatedAtDesc = 'updatedAt_DESC',
  /** Order users ascending by username. */
  UsernameAsc = 'username_ASC',
  /** Order users descending by username. */
  UsernameDesc = 'username_DESC'
}

/** Filtering inputs for users. */
export type UserWhereInput = {
  adminOnly?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserProfileFragment = { __typename?: 'User', id: string, username: string, email?: string | null | undefined };

export type UserActivityFragment = { __typename?: 'User', lastLogin?: any | null | undefined, lastLoginIp?: string | null | undefined, loginsCount?: number | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined };

export type GetAllUsersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserSortField> | UserSortField>;
}>;


export type GetAllUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', emailVerified?: boolean | null | undefined, isAdmin?: boolean | null | undefined, isBlocked?: boolean | null | undefined, isOrders?: boolean | null | undefined, id: string, username: string, email?: string | null | undefined, lastLogin?: any | null | undefined, lastLoginIp?: string | null | undefined, loginsCount?: number | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CreateUserMutationVariables = Exact<{
  username: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateUserResponse', success: boolean, message: string, user?: { __typename?: 'User', id: string, username: string, email?: string | null | undefined } | null | undefined } };

export type UpdateUserAdminMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateUserAdminMutation = { __typename?: 'Mutation', updateUserAdmin: { __typename?: 'UpdateUserResponse', success: boolean, message: string, user: { __typename?: 'User', isAdmin?: boolean | null | undefined, id: string, username: string, email?: string | null | undefined } } };

export type UpdateUserBlockedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateUserBlockedMutation = { __typename?: 'Mutation', updateUserBlocked: { __typename?: 'UpdateUserResponse', success: boolean, message: string, user: { __typename?: 'User', isBlocked?: boolean | null | undefined, id: string, username: string, email?: string | null | undefined } } };

export type TargetPropsFragment = { __typename?: 'Target', organismId: number, id: string, custom: boolean, rnaId?: string | null | undefined, spliced: boolean, geneId?: number | null | undefined, symbol: string, geneName: string, title: string, length: number, exonCount: number, cdsStart: number, cdsEnd: number, antisenseSeriesLength?: number | null | undefined, sirnaSeriesLength?: number | null | undefined };

export type GetAllTargetsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<TargetWhereInput>;
  orderBy?: Maybe<Array<TargetSortField> | TargetSortField>;
}>;


export type GetAllTargetsQuery = { __typename?: 'Query', targets: { __typename?: 'TargetConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'TargetEdge', cursor: string, node: { __typename?: 'Target', organismId: number, id: string, custom: boolean, rnaId?: string | null | undefined, spliced: boolean, geneId?: number | null | undefined, symbol: string, geneName: string, title: string, length: number, exonCount: number, cdsStart: number, cdsEnd: number, antisenseSeriesLength?: number | null | undefined, sirnaSeriesLength?: number | null | undefined, antisenseSeries?: Array<{ __typename?: 'AntisenseSeries', id: string, length: number }> | null | undefined, sirnaSeries?: Array<{ __typename?: 'SirnaSeries', id: string, config: Array<number> }> | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetTargetCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTargetCountQuery = { __typename?: 'Query', targets: { __typename?: 'TargetConnection', totalCount: number, pageCount: number } };

export type GetTargetByIdRnaIdQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  rnaId?: Maybe<Scalars['ID']>;
  spliced?: Maybe<Scalars['Boolean']>;
}>;


export type GetTargetByIdRnaIdQuery = { __typename?: 'Query', target?: { __typename?: 'Target', organismId: number, id: string, rnaId?: string | null | undefined, spliced: boolean, custom: boolean, symbol: string, geneName: string, title: string, length: number, exonCount: number, rna?: { __typename?: 'Rna', rnaLength: number, mrnaLength: number, targets?: Array<{ __typename?: 'Target', id: string, spliced: boolean }> | null | undefined } | null | undefined, antisenseSeries?: Array<{ __typename?: 'AntisenseSeries', id: string, length: number }> | null | undefined } | null | undefined };

export type SearchTargetsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<TargetSearchInput>;
}>;


export type SearchTargetsQuery = { __typename?: 'Query', searchTargets: { __typename?: 'TargetConnection', edges: Array<{ __typename?: 'TargetEdge', node: { __typename?: 'Target', organismId: number, id: string, custom: boolean, rnaId?: string | null | undefined, spliced: boolean, geneId?: number | null | undefined, symbol: string, geneName: string, title: string, length: number, exonCount: number, cdsStart: number, cdsEnd: number, antisenseSeriesLength?: number | null | undefined, sirnaSeriesLength?: number | null | undefined } }> } };

export type CreateTargetMutationVariables = Exact<{
  rnaId: Scalars['ID'];
  spliced: Scalars['Boolean'];
}>;


export type CreateTargetMutation = { __typename?: 'Mutation', createTarget: { __typename?: 'CreateTargetResponse', success: boolean, message: string, target?: { __typename?: 'Target', organismId: number, id: string, custom: boolean, rnaId?: string | null | undefined, spliced: boolean, geneId?: number | null | undefined, symbol: string, geneName: string, title: string, length: number, exonCount: number, cdsStart: number, cdsEnd: number, antisenseSeriesLength?: number | null | undefined, sirnaSeriesLength?: number | null | undefined, rna?: { __typename?: 'Rna', id: string, isMrnaTarget?: boolean | null | undefined, isRnaTarget?: boolean | null | undefined } | null | undefined } | null | undefined } };

export type GetTargetAntisenseSeriesQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  rnaId?: Maybe<Scalars['ID']>;
  spliced?: Maybe<Scalars['Boolean']>;
}>;


export type GetTargetAntisenseSeriesQuery = { __typename?: 'Query', target?: { __typename?: 'Target', organismId: number, id: string, rnaId?: string | null | undefined, spliced: boolean, custom: boolean, symbol: string, geneName: string, title: string, length: number, exonCount: number, antisenseSeries?: Array<{ __typename?: 'AntisenseSeries', id: string, length: number }> | null | undefined } | null | undefined };

export type GetTargetRnasQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  rnaId?: Maybe<Scalars['ID']>;
  spliced?: Maybe<Scalars['Boolean']>;
}>;


export type GetTargetRnasQuery = { __typename?: 'Query', target?: { __typename?: 'Target', id: string, length: number, rna?: { __typename?: 'Rna', rnaLength: number, mrnaLength: number } | null | undefined } | null | undefined };

export type CreateAntisenseSeriesMutationVariables = Exact<{
  targetId: Scalars['ID'];
  length: Scalars['Int'];
}>;


export type CreateAntisenseSeriesMutation = { __typename?: 'Mutation', createAntisenseSeries: { __typename?: 'CreateAntisenseSeriesResponse', success: boolean, message: string, antisenseSeries?: { __typename?: 'AntisenseSeries', id: string, length: number, target?: { __typename?: 'Target', id: string, rnaId?: string | null | undefined } | null | undefined } | null | undefined } };

export type AntisenseSequencePropsFragment = { __typename?: 'AntisenseSequence', id: string, start: number, end: number, seq: string, targetSeq: string, length: number, atContent: number, gcContent: number, cpgCount: number, tm: number, hairpin: number, homopolymer: boolean };

export type GetTargetAntisenseSequencesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<AntisenseSequenceWhereInput>;
  orderBy?: Maybe<Array<AntisenseSequenceSortField> | AntisenseSequenceSortField>;
}>;


export type GetTargetAntisenseSequencesQuery = { __typename?: 'Query', antisenseSequences: { __typename?: 'AntisenseSequenceConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'AntisenseSequenceEdge', cursor: string, node: { __typename?: 'AntisenseSequence', exonId?: number | null | undefined, intronId?: number | null | undefined, region: string, before40?: number | null | undefined, before20?: number | null | undefined, before10?: number | null | undefined, before5?: number | null | undefined, front8?: number | null | undefined, mid8?: number | null | undefined, mid16?: number | null | undefined, back8?: number | null | undefined, after5?: number | null | undefined, after10?: number | null | undefined, after20?: number | null | undefined, after40?: number | null | undefined, blastMatches?: number | null | undefined, blastNextBestLength?: number | null | undefined, blastNextBestCount?: number | null | undefined, isInCart: boolean, id: string, start: number, end: number, seq: string, targetSeq: string, length: number, atContent: number, gcContent: number, cpgCount: number, tm: number, hairpin: number, homopolymer: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetAntisenseSequenceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAntisenseSequenceQuery = { __typename?: 'Query', antisenseSequence: { __typename?: 'AntisenseSequence', id: string, seq: string, start: number, isInCart: boolean } };

export type GetAntisenseSequenceOffTargetsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAntisenseSequenceOffTargetsQuery = { __typename?: 'Query', antisenseSequence: { __typename?: 'AntisenseSequence', id: string, offTargets?: Array<{ __typename?: 'Rna', id: string, symbol: string } | null | undefined> | null | undefined } };

export type GetAntisenseSequenceBlastNextBestIdsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAntisenseSequenceBlastNextBestIdsQuery = { __typename?: 'Query', antisenseSequence: { __typename?: 'AntisenseSequence', id: string, blastNextBestIds?: Array<string> | null | undefined } };

export type CreateSirnaSeriesMutationVariables = Exact<{
  targetId: Scalars['ID'];
  config: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateSirnaSeriesMutation = { __typename?: 'Mutation', createSirnaSeries: { __typename?: 'CreateSirnaSeriesResponse', success: boolean, message: string, sirnaSeries?: { __typename?: 'SirnaSeries', id: string, config: Array<number>, target?: { __typename?: 'Target', id: string, rnaId?: string | null | undefined } | null | undefined } | null | undefined } };

export type SirnaDuplexPropsFragment = { __typename?: 'SirnaDuplex', id: string, startAs: number, endAs: number, seqAs: string, targetSeq: string, lengthAs: number, startS: number, endS: number, seqS: string, lengthS: number, atContentSeed: number, tmSeed: number, tm: number };

export type GetTargetSirnaDuplexesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<SirnaDuplexWhereInput>;
  orderBy?: Maybe<Array<SirnaDuplexSortField> | SirnaDuplexSortField>;
}>;


export type GetTargetSirnaDuplexesQuery = { __typename?: 'Query', sirnaDuplexes: { __typename?: 'SirnaDuplexConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'SirnaDuplexEdge', cursor: string, node: { __typename?: 'SirnaDuplex', exonId?: number | null | undefined, region?: string | null | undefined, blastMatchesAs?: number | null | undefined, blastMatchesS?: number | null | undefined, isInCart: boolean, id: string, startAs: number, endAs: number, seqAs: string, targetSeq: string, lengthAs: number, startS: number, endS: number, seqS: string, lengthS: number, atContentSeed: number, tmSeed: number, tm: number } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetSirnaDuplexQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSirnaDuplexQuery = { __typename?: 'Query', sirnaDuplex: { __typename?: 'SirnaDuplex', id: string, startAs: number, endAs: number, seqAs: string, startS: number, endS: number, seqS: string, isInCart: boolean } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, username: string, email?: string | null | undefined, emailVerified?: boolean | null | undefined, isAdmin?: boolean | null | undefined, isOrders?: boolean | null | undefined, photoUrl?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined } | null | undefined };

export type UpdateUserPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: { __typename?: 'UpdateUserPasswordResponse', success: boolean, message: string } };

export type SugarPropsFragment = { __typename?: 'Sugar', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, hasBase: boolean, backTerminating: boolean };

export type GetAllSugarsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<SugarWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllSugarsQuery = { __typename?: 'Query', sugars: { __typename?: 'SugarConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'SugarEdge', cursor: string, node: { __typename?: 'Sugar', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, hasBase: boolean, backTerminating: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetSugarByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSugarByIdQuery = { __typename?: 'Query', sugar?: { __typename?: 'Sugar', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, hasBase: boolean, backTerminating: boolean } | null | undefined };

export type BasePropsFragment = { __typename?: 'Base', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, isAromatic: boolean };

export type GetAllBasesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<BaseWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllBasesQuery = { __typename?: 'Query', bases: { __typename?: 'BaseConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'BaseEdge', cursor: string, node: { __typename?: 'Base', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, isAromatic: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetBaseByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBaseByIdQuery = { __typename?: 'Query', base?: { __typename?: 'Base', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, isAromatic: boolean } | null | undefined };

export type GetBaseBySymbolQueryVariables = Exact<{
  symbol: Scalars['String'];
}>;


export type GetBaseBySymbolQuery = { __typename?: 'Query', baseBySymbol?: { __typename?: 'Base', id: string } | null | undefined };

export type NucleosidePropsFragment = { __typename?: 'Nucleoside', sugarId: string, baseId: string, id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean };

export type GetAllNucleosidesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<NucleosideWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllNucleosidesQuery = { __typename?: 'Query', nucleosides: { __typename?: 'NucleosideConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'NucleosideEdge', cursor: string, node: { __typename?: 'Nucleoside', sugarId: string, baseId: string, id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type BackbonePropsFragment = { __typename?: 'Backbone', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean };

export type GetAllBackbonesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<BackboneWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllBackbonesQuery = { __typename?: 'Query', backbones: { __typename?: 'BackboneConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'BackboneEdge', cursor: string, node: { __typename?: 'Backbone', id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CouplingPropsFragment = { __typename?: 'Coupling', sugarId: string, baseId: string, backboneId: string, id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean };

export type GetAllCouplingsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CouplingWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllCouplingsQuery = { __typename?: 'Query', couplings: { __typename?: 'CouplingConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'CouplingEdge', cursor: string, node: { __typename?: 'Coupling', sugarId: string, baseId: string, backboneId: string, id: string, symbol: string, name: string, mass: number, description?: string | null | undefined, cml?: string | null | undefined, backTerminating: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetAllCouplingsNoStructureQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CouplingWhereInput>;
  orderBy?: Maybe<Array<BuildingBlockSortField> | BuildingBlockSortField>;
}>;


export type GetAllCouplingsNoStructureQuery = { __typename?: 'Query', couplings: { __typename?: 'CouplingConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'CouplingEdge', cursor: string, node: { __typename?: 'Coupling', sugarId: string, baseId: string, backboneId: string, id: string, symbol: string, name: string, mass: number, description?: string | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetCouplingByCompositionQueryVariables = Exact<{
  filter: CouplingCompositionInput;
}>;


export type GetCouplingByCompositionQuery = { __typename?: 'Query', couplingByComposition?: { __typename?: 'Coupling', id: string, symbol: string } | null | undefined };

export type AmiditePropsFragment = { __typename?: 'Amidite', nucleosideId: string, id: string, symbol: string, name: string, molecularWeight: number, formulaWeight: number, hasDmt: boolean, casNumber?: string | null | undefined, cml?: string | null | undefined };

export type GetAllAmiditesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<AmiditeWhereInput>;
  orderBy?: Maybe<Array<AmiditeSortField> | AmiditeSortField>;
}>;


export type GetAllAmiditesQuery = { __typename?: 'Query', amidites: { __typename?: 'AmiditeConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'AmiditeEdge', cursor: string, node: { __typename?: 'Amidite', nucleosideId: string, id: string, symbol: string, name: string, molecularWeight: number, formulaWeight: number, hasDmt: boolean, casNumber?: string | null | undefined, cml?: string | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type PageInfoPropsFragment = { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean };

export type OrganismPropsFragment = { __typename?: 'Organism', id: string, commonName: string, scientificName: string };

export type ChromosomePropsFragment = { __typename?: 'Chromosome', id: string, acc: string, number: string, title: string, length: number };

export type GetAllChromosomesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ChromosomeWhereInput>;
  orderBy?: Maybe<Array<ChromosomeSortField> | ChromosomeSortField>;
}>;


export type GetAllChromosomesQuery = { __typename?: 'Query', chromosomes: { __typename?: 'ChromosomeConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'ChromosomeEdge', cursor: string, node: { __typename?: 'Chromosome', id: string, acc: string, number: string, title: string, length: number, organism?: { __typename?: 'Organism', id: string, commonName: string, scientificName: string } | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type RnaPropsFragment = { __typename?: 'Rna', organismId: number, id: string, acc: string, symbol: string, synonyms?: Array<string> | null | undefined, geneName?: string | null | undefined, title: string, desc?: string | null | undefined, chrNo: string, chrStart: number, chrEnd: number, chrStrand: string, exonCount: number, rnaLength: number, mrnaLength: number, utr5Length: number, utr3Length: number, enstId?: string | null | undefined, type?: string | null | undefined, protId?: string | null | undefined, protTitle?: string | null | undefined, protLength?: number | null | undefined, cdsStart?: number | null | undefined, cdsEnd?: number | null | undefined };

export type IsMrnaRnaTargetFragment = { __typename?: 'Rna', id: string, isMrnaTarget?: boolean | null | undefined, isRnaTarget?: boolean | null | undefined };

export type GetAllRnasQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<RnaWhereInput>;
  orderBy?: Maybe<Array<RnaSortField> | RnaSortField>;
}>;


export type GetAllRnasQuery = { __typename?: 'Query', rnas: { __typename?: 'RnaConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'RnaEdge', cursor: string, node: { __typename?: 'Rna', status?: string | null | undefined, isMrnaTarget?: boolean | null | undefined, isRnaTarget?: boolean | null | undefined, organismId: number, id: string, acc: string, symbol: string, synonyms?: Array<string> | null | undefined, geneName?: string | null | undefined, title: string, desc?: string | null | undefined, chrNo: string, chrStart: number, chrEnd: number, chrStrand: string, exonCount: number, rnaLength: number, mrnaLength: number, utr5Length: number, utr3Length: number, enstId?: string | null | undefined, type?: string | null | undefined, protId?: string | null | undefined, protTitle?: string | null | undefined, protLength?: number | null | undefined, cdsStart?: number | null | undefined, cdsEnd?: number | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type SearchRnasQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<RnaSearchInput>;
  orderBy?: Maybe<Array<RnaSortField> | RnaSortField>;
}>;


export type SearchRnasQuery = { __typename?: 'Query', searchRnas: { __typename?: 'RnaConnection', edges: Array<{ __typename?: 'RnaEdge', node: { __typename?: 'Rna', organismId: number, id: string, acc: string, symbol: string, synonyms?: Array<string> | null | undefined, geneName?: string | null | undefined, title: string, desc?: string | null | undefined, chrNo: string, chrStart: number, chrEnd: number, chrStrand: string, exonCount: number, rnaLength: number, mrnaLength: number, utr5Length: number, utr3Length: number, enstId?: string | null | undefined, type?: string | null | undefined, protId?: string | null | undefined, protTitle?: string | null | undefined, protLength?: number | null | undefined, cdsStart?: number | null | undefined, cdsEnd?: number | null | undefined } }> } };

export type GetRnaByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRnaByIdQuery = { __typename?: 'Query', rna?: { __typename?: 'Rna', organismId: number, id: string, acc: string, symbol: string, synonyms?: Array<string> | null | undefined, geneName?: string | null | undefined, title: string, desc?: string | null | undefined, chrNo: string, chrStart: number, chrEnd: number, chrStrand: string, exonCount: number, rnaLength: number, mrnaLength: number, utr5Length: number, utr3Length: number, enstId?: string | null | undefined, type?: string | null | undefined, protId?: string | null | undefined, protTitle?: string | null | undefined, protLength?: number | null | undefined, cdsStart?: number | null | undefined, cdsEnd?: number | null | undefined, isMrnaTarget?: boolean | null | undefined, isRnaTarget?: boolean | null | undefined } | null | undefined };

export type GetAllPingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPingsQuery = { __typename?: 'Query', usersPing?: string | null | undefined, genomePing: string, rnasPing: string, proteinsPing: string, antisensePing: string, structuresPing: string, oligosPing: string, reagentsPing: string, ordersPing: string, samplesPing: string };

export type GetUsersPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersPingQuery = { __typename?: 'Query', usersPing?: string | null | undefined };

export type GetGenomePingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenomePingQuery = { __typename?: 'Query', genomePing: string };

export type GetRnasPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRnasPingQuery = { __typename?: 'Query', rnasPing: string };

export type GetProteinsPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProteinsPingQuery = { __typename?: 'Query', proteinsPing: string };

export type GetAntisensePingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAntisensePingQuery = { __typename?: 'Query', antisensePing: string };

export type GetStructuresPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStructuresPingQuery = { __typename?: 'Query', structuresPing: string };

export type GetOligosPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOligosPingQuery = { __typename?: 'Query', oligosPing: string };

export type GetReagentsPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReagentsPingQuery = { __typename?: 'Query', reagentsPing: string };

export type GetOrdersPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersPingQuery = { __typename?: 'Query', ordersPing: string };

export type GetSamplesPingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSamplesPingQuery = { __typename?: 'Query', samplesPing: string };

export type ModificationPropsFragment = { __typename?: 'Modification', id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, placement5: boolean, placement3: boolean };

export type OligoPropsFragment = { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number };

export type GetAllOligosQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<OligoWhereInput>;
  orderBy?: Maybe<Array<OligoSortField> | OligoSortField>;
}>;


export type GetAllOligosQuery = { __typename?: 'Query', oligos: { __typename?: 'OligoConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'OligoEdge', cursor: string, node: { __typename?: 'Oligo', modsPos?: Array<number> | null | undefined, id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number, mod5?: { __typename?: 'Modification', id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, placement5: boolean, placement3: boolean } | null | undefined, mod3?: { __typename?: 'Modification', id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, placement5: boolean, placement3: boolean } | null | undefined, mods?: Array<{ __typename?: 'Modification', id: string, symbol: string, idtSymbol?: string | null | undefined, name: string, mass: number, placement5: boolean, placement3: boolean }> | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type CreateOligoMutationVariables = Exact<{
  name: Scalars['String'];
  cpIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateOligoMutation = { __typename?: 'Mutation', createOligo: { __typename?: 'CreateOligoResponse', success: boolean, message: string, oligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined } };

export type CreateAsosMutationVariables = Exact<{
  asos: Array<CreateAsoInput> | CreateAsoInput;
}>;


export type CreateAsosMutation = { __typename?: 'Mutation', createAsos: { __typename?: 'CreateAsosResponse', success: boolean, message: string, asos: Array<{ __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number }> } };

export type GetAllProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductTypesQuery = { __typename?: 'Query', productTypes: { __typename?: 'ProductTypeConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'ProductTypeEdge', cursor: string, node: { __typename?: 'ProductType', name: string } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type ProductPropsFragment = { __typename?: 'Product', id: string, type: string, name: string };

export type GetAllProductsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<Array<ProductSortField> | ProductSortField>;
}>;


export type GetAllProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'ProductEdge', cursor: string, node: { __typename?: 'Product', id: string, type: string, name: string, oligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: string, type: string, name: string } | null | undefined };

export type CreateProductMutationVariables = Exact<{
  name: Scalars['String'];
  productType: Scalars['String'];
  oligoId?: Maybe<Scalars['Int']>;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'CreateProductResponse', success: boolean, message: string, product?: { __typename?: 'Product', id: string, type: string, name: string } | null | undefined } };

export type CompanyPropsFragment = { __typename?: 'Company', id: string, name: string, shortName: string };

export type GetAllCompaniesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanySortField> | CompanySortField>;
}>;


export type GetAllCompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'CompanyEdge', cursor: string, node: { __typename?: 'Company', id: string, name: string, shortName: string } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, name: string, shortName: string } | null | undefined };

export type CreateCompanyMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CreateCompanyResponse', success: boolean, message: string, company?: { __typename?: 'Company', id: string, name: string, shortName: string } | null | undefined } };

export type CustomerPropsFragment = { __typename?: 'Customer', id: string, firstName: string, lastName: string, fullName: string, email: string, contactNumber: string, shippingAddress: string, billingAddress: string };

export type GetAllCustomersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CustomerWhereInput>;
  orderBy?: Maybe<Array<CustomerSortField> | CustomerSortField>;
}>;


export type GetAllCustomersQuery = { __typename?: 'Query', customers: { __typename?: 'CustomerConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'CustomerEdge', cursor: string, node: { __typename?: 'Customer', id: string, firstName: string, lastName: string, fullName: string, email: string, contactNumber: string, shippingAddress: string, billingAddress: string, company: { __typename?: 'Company', id: string, name: string, shortName: string } } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id: string, firstName: string, lastName: string, fullName: string, email: string, contactNumber: string, shippingAddress: string, billingAddress: string, company: { __typename?: 'Company', id: string, name: string } } | null | undefined };

export type CreateCustomerMutationVariables = Exact<{
  companyId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'CreateCustomerResponse', success: boolean, message: string, customer?: { __typename?: 'Customer', id: string, firstName: string, lastName: string, fullName: string, email: string, contactNumber: string, shippingAddress: string, billingAddress: string } | null | undefined } };

export type GetAllOrderTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOrderTypesQuery = { __typename?: 'Query', orderTypes: { __typename?: 'OrderTypeConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'OrderTypeEdge', cursor: string, node: { __typename?: 'OrderType', name: string } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type OrderPropsFragment = { __typename?: 'Order', id: string, customerId?: string | null | undefined, customerName?: string | null | undefined, customerCompany?: string | null | undefined, poNumber?: string | null | undefined, poIssuedOn?: any | null | undefined, poReceivedOn?: any | null | undefined, type: string, status: string, leadTime: Array<string>, dueOn1?: any | null | undefined, dueOn2?: any | null | undefined, completedOn?: any | null | undefined };

export type GetAllOrdersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<OrderSortField> | OrderSortField>;
}>;


export type GetAllOrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'OrderEdge', cursor: string, node: { __typename?: 'Order', remarks?: string | null | undefined, id: string, customerId?: string | null | undefined, customerName?: string | null | undefined, customerCompany?: string | null | undefined, poNumber?: string | null | undefined, poIssuedOn?: any | null | undefined, poReceivedOn?: any | null | undefined, type: string, status: string, leadTime: Array<string>, dueOn1?: any | null | undefined, dueOn2?: any | null | undefined, completedOn?: any | null | undefined, inCharge?: { __typename?: 'User', id: string, username: string, photoUrl?: string | null | undefined } | null | undefined, deliveries?: Array<{ __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined }> | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type OrderItemPropsFragment = { __typename?: 'OrderItem', orderId: string, productId: string, specs?: string | null | undefined, id: string, description: string, quantity: number, unitPrice: number };

export type GetOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', remarks?: string | null | undefined, id: string, customerId?: string | null | undefined, customerName?: string | null | undefined, customerCompany?: string | null | undefined, poNumber?: string | null | undefined, poIssuedOn?: any | null | undefined, poReceivedOn?: any | null | undefined, type: string, status: string, leadTime: Array<string>, dueOn1?: any | null | undefined, dueOn2?: any | null | undefined, completedOn?: any | null | undefined, customer?: { __typename?: 'Customer', company: { __typename?: 'Company', id: string } } | null | undefined, items?: Array<{ __typename?: 'OrderItem', orderId: string, productId: string, specs?: string | null | undefined, id: string, description: string, quantity: number, unitPrice: number, product: { __typename?: 'Product', oligoId?: number | null | undefined, asId?: number | null | undefined, ssId?: number | null | undefined, id: string, type: string, name: string, samples?: Array<{ __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean }> | null | undefined }, samples?: Array<{ __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean }> | null | undefined }> | null | undefined, inCharge?: { __typename?: 'User', id: string, username: string, photoUrl?: string | null | undefined } | null | undefined, deliveries?: Array<{ __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined }> | null | undefined } | null | undefined };

export type CreateOrderMutationVariables = Exact<{
  customerId: Scalars['String'];
  orderType: Scalars['String'];
  leadTime: Array<Scalars['Int']> | Scalars['Int'];
  productIds: Array<Scalars['Int']> | Scalars['Int'];
  specs: Array<Scalars['String']> | Scalars['String'];
  unitPrices: Array<Scalars['Float']> | Scalars['Float'];
  quantities: Array<Scalars['Int']> | Scalars['Int'];
  discounts: Array<Scalars['Float']> | Scalars['Float'];
  percDiscounts: Array<Scalars['Int']> | Scalars['Int'];
  discountedPrices: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderResponse', success: boolean, message: string, order?: { __typename?: 'Order', id: string, customerId?: string | null | undefined, customerName?: string | null | undefined, customerCompany?: string | null | undefined, poNumber?: string | null | undefined, poIssuedOn?: any | null | undefined, poReceivedOn?: any | null | undefined, type: string, status: string, leadTime: Array<string>, dueOn1?: any | null | undefined, dueOn2?: any | null | undefined, completedOn?: any | null | undefined } | null | undefined } };

export type DeliveryPropsFragment = { __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined, mode?: string | null | undefined };

export type GetAllDeliveriesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<DeliveryWhereInput>;
  orderBy?: Maybe<Array<DeliverySortField> | DeliverySortField>;
}>;


export type GetAllDeliveriesQuery = { __typename?: 'Query', deliveries: { __typename?: 'DeliveryConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'DeliveryEdge', cursor: string, node: { __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined, mode?: string | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetDeliveryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDeliveryQuery = { __typename?: 'Query', delivery?: { __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined, mode?: string | null | undefined, order?: { __typename?: 'Order', id: string, poNumber?: string | null | undefined, customer?: { __typename?: 'Customer', fullName: string, email: string, contactNumber: string, shippingAddress: string, billingAddress: string, company: { __typename?: 'Company', name: string } } | null | undefined, items?: Array<{ __typename?: 'OrderItem', id: string, description: string, unitPrice: number, quantity: number }> | null | undefined } | null | undefined } | null | undefined };

export type CreateDeliveryMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CreateDeliveryMutation = { __typename?: 'Mutation', createDelivery: { __typename?: 'CreateDeliveryResponse', success: boolean, message: string, delivery?: { __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined, mode?: string | null | undefined } | null | undefined } };

export type UpdateDeliveryMutationVariables = Exact<{
  id: Scalars['ID'];
  deliverAt?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
}>;


export type UpdateDeliveryMutation = { __typename?: 'Mutation', updateDelivery: { __typename?: 'UpdateDeliveryResponse', success: boolean, message: string, delivery?: { __typename?: 'Delivery', id: string, deliverAt?: any | null | undefined, mode?: string | null | undefined } | null | undefined } };

export type SamplePropsFragment = { __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean };

export type GetAllSamplesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<SampleWhereInput>;
  orderBy?: Maybe<Array<SampleSortField> | SampleSortField>;
}>;


export type GetAllSamplesQuery = { __typename?: 'Query', samples: { __typename?: 'SampleConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'SampleEdge', cursor: string, node: { __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean, product?: { __typename?: 'Product', id: string, type: string, name: string } | null | undefined, oligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined, asOligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined, ssOligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetSamplesByProductIdQueryVariables = Exact<{
  productId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetSamplesByProductIdQuery = { __typename?: 'Query', samples: { __typename?: 'SampleConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'SampleEdge', cursor: string, node: { __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetSampleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSampleQuery = { __typename?: 'Query', sample?: { __typename?: 'Sample', maldi?: string | null | undefined, id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean, product?: { __typename?: 'Product', id: string, type: string, name: string } | null | undefined, oligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined, asOligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined, ssOligo?: { __typename?: 'Oligo', id: string, coreSeq?: string | null | undefined, name: string, aliases: Array<string>, cpIds: Array<string>, bbIds: Array<string>, idtSeq?: Array<string> | null | undefined, length: number, mass: number, ec260: number } | null | undefined } | null | undefined };

export type CreateSampleMutationVariables = Exact<{
  orderItemId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  typeName: Scalars['String'];
  oligoId?: Maybe<Scalars['Int']>;
}>;


export type CreateSampleMutation = { __typename?: 'Mutation', createSample: { __typename?: 'CreateSampleResponse', success: boolean, message: string, sample?: { __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean } | null | undefined } };

export type UpdateSampleMutationVariables = Exact<{
  id: Scalars['ID'];
  maldi?: Maybe<Scalars['Upload']>;
}>;


export type UpdateSampleMutation = { __typename?: 'Mutation', updateSample: { __typename?: 'UpdateSampleResponse', success: boolean, message: string, sample?: { __typename?: 'Sample', id: string, name?: string | null | undefined, type: string, status: string, productId?: number | null | undefined, isInCart: boolean } | null | undefined } };

export type SkuPropsFragment = { __typename?: 'Sku', oligoReagentId?: number | null | undefined, peptideReagentId?: number | null | undefined, id: string, title: string, productCode: string, packSize: number, unitSize: any, unitOfMeasure: string, listPrice: any, discount: any, unitPrice: any, normalizedPrice: any, quantityBiop: number, quantityNasbio: number };

export type GetAllSkusQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<SkuWhereInput>;
  orderBy?: Maybe<Array<SkuSortField> | SkuSortField>;
}>;


export type GetAllSkusQuery = { __typename?: 'Query', skus: { __typename?: 'SkuConnection', totalCount: number, filteredCount: number, pageCount: number, currentPage: number, edges: Array<{ __typename?: 'SkuEdge', cursor: string, node: { __typename?: 'Sku', createdAt?: any | null | undefined, updatedAt?: any | null | undefined, oligoReagentId?: number | null | undefined, peptideReagentId?: number | null | undefined, id: string, title: string, productCode: string, packSize: number, unitSize: any, unitOfMeasure: string, listPrice: any, discount: any, unitPrice: any, normalizedPrice: any, quantityBiop: number, quantityNasbio: number } }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null | undefined, endCursor?: string | null | undefined, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type UpdateSkuQuantityBiopMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity: Scalars['Int'];
}>;


export type UpdateSkuQuantityBiopMutation = { __typename?: 'Mutation', updateSkuQuantityBiop: { __typename?: 'UpdateSkuQuantityResponse', success: boolean, message: string, sku?: { __typename?: 'Sku', id: string, quantityBiop: number, updatedAt?: any | null | undefined, updatedBy?: { __typename?: 'User', id: string, username: string } | null | undefined } | null | undefined } };

export type UpdateSkuQuantityNasbioMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity: Scalars['Int'];
}>;


export type UpdateSkuQuantityNasbioMutation = { __typename?: 'Mutation', updateSkuQuantityNasbio: { __typename?: 'UpdateSkuQuantityResponse', success: boolean, message: string, sku?: { __typename?: 'Sku', id: string, quantityNasbio: number, updatedAt?: any | null | undefined, updatedBy?: { __typename?: 'User', id: string, username: string } | null | undefined } | null | undefined } };

export const UserProfileFragmentDoc = gql`
    fragment userProfile on User {
  id
  username
  email
}
    `;
export const UserActivityFragmentDoc = gql`
    fragment userActivity on User {
  lastLogin
  lastLoginIp
  loginsCount
  createdAt
  updatedAt
}
    `;
export const TargetPropsFragmentDoc = gql`
    fragment targetProps on Target {
  organismId
  id
  custom
  rnaId
  spliced
  geneId
  symbol
  geneName
  title
  length
  exonCount
  cdsStart
  cdsEnd
  antisenseSeriesLength
  sirnaSeriesLength
}
    `;
export const AntisenseSequencePropsFragmentDoc = gql`
    fragment antisenseSequenceProps on AntisenseSequence {
  id
  start
  end
  seq
  targetSeq
  length
  atContent
  gcContent
  cpgCount
  tm
  hairpin
  homopolymer
}
    `;
export const SirnaDuplexPropsFragmentDoc = gql`
    fragment sirnaDuplexProps on SirnaDuplex {
  id
  startAs
  endAs
  seqAs
  targetSeq
  lengthAs
  startS
  endS
  seqS
  lengthS
  atContentSeed
  tmSeed
  tm
}
    `;
export const SugarPropsFragmentDoc = gql`
    fragment sugarProps on Sugar {
  id
  symbol
  name
  mass
  description
  cml
  hasBase
  backTerminating
}
    `;
export const BasePropsFragmentDoc = gql`
    fragment baseProps on Base {
  id
  symbol
  name
  mass
  description
  cml
  isAromatic
}
    `;
export const NucleosidePropsFragmentDoc = gql`
    fragment nucleosideProps on Nucleoside {
  sugarId
  baseId
  id
  symbol
  idtSymbol
  name
  mass
  description
  cml
  backTerminating
}
    `;
export const BackbonePropsFragmentDoc = gql`
    fragment backboneProps on Backbone {
  id
  symbol
  name
  mass
  description
  cml
  backTerminating
}
    `;
export const CouplingPropsFragmentDoc = gql`
    fragment couplingProps on Coupling {
  sugarId
  baseId
  backboneId
  id
  symbol
  name
  mass
  description
  cml
  backTerminating
}
    `;
export const AmiditePropsFragmentDoc = gql`
    fragment amiditeProps on Amidite {
  nucleosideId
  id
  symbol
  name
  molecularWeight
  formulaWeight
  hasDmt
  casNumber
  cml
}
    `;
export const PageInfoPropsFragmentDoc = gql`
    fragment pageInfoProps on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const OrganismPropsFragmentDoc = gql`
    fragment organismProps on Organism {
  id
  commonName
  scientificName
}
    `;
export const ChromosomePropsFragmentDoc = gql`
    fragment chromosomeProps on Chromosome {
  id
  acc
  number
  title
  length
}
    `;
export const RnaPropsFragmentDoc = gql`
    fragment rnaProps on Rna {
  organismId
  id
  acc
  symbol
  synonyms
  geneName
  title
  desc
  chrNo
  chrStart
  chrEnd
  chrStrand
  exonCount
  rnaLength
  mrnaLength
  utr5Length
  utr3Length
  enstId
  type
  protId
  protTitle
  protLength
  cdsStart
  cdsEnd
}
    `;
export const IsMrnaRnaTargetFragmentDoc = gql`
    fragment isMrnaRnaTarget on Rna {
  id
  isMrnaTarget
  isRnaTarget
}
    `;
export const ModificationPropsFragmentDoc = gql`
    fragment modificationProps on Modification {
  id
  symbol
  idtSymbol
  name
  mass
  placement5
  placement3
}
    `;
export const OligoPropsFragmentDoc = gql`
    fragment oligoProps on Oligo {
  id
  coreSeq
  name
  aliases
  cpIds
  bbIds
  idtSeq
  length
  mass
  ec260
}
    `;
export const ProductPropsFragmentDoc = gql`
    fragment productProps on Product {
  id
  type
  name
}
    `;
export const CompanyPropsFragmentDoc = gql`
    fragment companyProps on Company {
  id
  name
  shortName
}
    `;
export const CustomerPropsFragmentDoc = gql`
    fragment customerProps on Customer {
  id
  firstName
  lastName
  fullName
  email
  contactNumber
  shippingAddress
  billingAddress
}
    `;
export const OrderPropsFragmentDoc = gql`
    fragment orderProps on Order {
  id
  customerId
  customerName
  customerCompany
  poNumber
  poIssuedOn
  poReceivedOn
  type
  status
  leadTime
  dueOn1
  dueOn2
  completedOn
}
    `;
export const OrderItemPropsFragmentDoc = gql`
    fragment orderItemProps on OrderItem {
  orderId
  productId
  specs
  id
  description
  quantity
  unitPrice
}
    `;
export const DeliveryPropsFragmentDoc = gql`
    fragment deliveryProps on Delivery {
  id
  deliverAt
  mode
}
    `;
export const SamplePropsFragmentDoc = gql`
    fragment sampleProps on Sample {
  id
  name
  type
  status
  productId
  isInCart @client
}
    `;
export const SkuPropsFragmentDoc = gql`
    fragment skuProps on Sku {
  oligoReagentId
  peptideReagentId
  id
  title
  productCode
  packSize
  unitSize
  unitOfMeasure
  listPrice
  discount
  unitPrice
  normalizedPrice
  quantityBiop
  quantityNasbio
}
    `;
export const GetAllUsersDocument = gql`
    query getAllUsers($page: Int, $perPage: Int, $filter: UserWhereInput = {}, $orderBy: [UserSortField!]) {
  users(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...userProfile
        emailVerified
        isAdmin
        isBlocked
        isOrders
        ...userActivity
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${UserProfileFragmentDoc}
${UserActivityFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($username: String!, $email: String!, $password: String) {
  createUser(input: {username: $username, email: $email, password: $password}) {
    success
    message
    user {
      ...userProfile
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserAdminDocument = gql`
    mutation updateUserAdmin($id: ID!) {
  updateUserAdmin(id: $id) {
    success
    message
    user {
      ...userProfile
      isAdmin
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserAdminMutationFn = Apollo.MutationFunction<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>;

/**
 * __useUpdateUserAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAdminMutation, { data, loading, error }] = useUpdateUserAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>(UpdateUserAdminDocument, options);
      }
export type UpdateUserAdminMutationHookResult = ReturnType<typeof useUpdateUserAdminMutation>;
export type UpdateUserAdminMutationResult = Apollo.MutationResult<UpdateUserAdminMutation>;
export type UpdateUserAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>;
export const UpdateUserBlockedDocument = gql`
    mutation updateUserBlocked($id: ID!) {
  updateUserBlocked(id: $id) {
    success
    message
    user {
      ...userProfile
      isBlocked
    }
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserBlockedMutationFn = Apollo.MutationFunction<UpdateUserBlockedMutation, UpdateUserBlockedMutationVariables>;

/**
 * __useUpdateUserBlockedMutation__
 *
 * To run a mutation, you first call `useUpdateUserBlockedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBlockedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBlockedMutation, { data, loading, error }] = useUpdateUserBlockedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserBlockedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBlockedMutation, UpdateUserBlockedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBlockedMutation, UpdateUserBlockedMutationVariables>(UpdateUserBlockedDocument, options);
      }
export type UpdateUserBlockedMutationHookResult = ReturnType<typeof useUpdateUserBlockedMutation>;
export type UpdateUserBlockedMutationResult = Apollo.MutationResult<UpdateUserBlockedMutation>;
export type UpdateUserBlockedMutationOptions = Apollo.BaseMutationOptions<UpdateUserBlockedMutation, UpdateUserBlockedMutationVariables>;
export const GetAllTargetsDocument = gql`
    query getAllTargets($page: Int, $perPage: Int, $filter: TargetWhereInput, $orderBy: [TargetSortField!]) {
  targets(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...targetProps
        antisenseSeries {
          id
          length
        }
        sirnaSeries {
          id
          config
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${TargetPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllTargetsQuery__
 *
 * To run a query within a React component, call `useGetAllTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTargetsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllTargetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTargetsQuery, GetAllTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTargetsQuery, GetAllTargetsQueryVariables>(GetAllTargetsDocument, options);
      }
export function useGetAllTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTargetsQuery, GetAllTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTargetsQuery, GetAllTargetsQueryVariables>(GetAllTargetsDocument, options);
        }
export type GetAllTargetsQueryHookResult = ReturnType<typeof useGetAllTargetsQuery>;
export type GetAllTargetsLazyQueryHookResult = ReturnType<typeof useGetAllTargetsLazyQuery>;
export type GetAllTargetsQueryResult = Apollo.QueryResult<GetAllTargetsQuery, GetAllTargetsQueryVariables>;
export const GetTargetCountDocument = gql`
    query getTargetCount {
  targets {
    totalCount
    pageCount
  }
}
    `;

/**
 * __useGetTargetCountQuery__
 *
 * To run a query within a React component, call `useGetTargetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTargetCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetCountQuery, GetTargetCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetCountQuery, GetTargetCountQueryVariables>(GetTargetCountDocument, options);
      }
export function useGetTargetCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetCountQuery, GetTargetCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetCountQuery, GetTargetCountQueryVariables>(GetTargetCountDocument, options);
        }
export type GetTargetCountQueryHookResult = ReturnType<typeof useGetTargetCountQuery>;
export type GetTargetCountLazyQueryHookResult = ReturnType<typeof useGetTargetCountLazyQuery>;
export type GetTargetCountQueryResult = Apollo.QueryResult<GetTargetCountQuery, GetTargetCountQueryVariables>;
export const GetTargetByIdRnaIdDocument = gql`
    query getTargetByIdRnaId($id: ID, $rnaId: ID, $spliced: Boolean) {
  target(filter: {id: $id, rnaId: $rnaId, spliced: $spliced}) {
    organismId
    id
    rnaId
    rna {
      rnaLength
      mrnaLength
      targets {
        id
        spliced
      }
    }
    spliced
    custom
    symbol
    geneName
    title
    length
    exonCount
    antisenseSeries {
      id
      length
    }
  }
}
    `;

/**
 * __useGetTargetByIdRnaIdQuery__
 *
 * To run a query within a React component, call `useGetTargetByIdRnaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetByIdRnaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetByIdRnaIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rnaId: // value for 'rnaId'
 *      spliced: // value for 'spliced'
 *   },
 * });
 */
export function useGetTargetByIdRnaIdQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetByIdRnaIdQuery, GetTargetByIdRnaIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetByIdRnaIdQuery, GetTargetByIdRnaIdQueryVariables>(GetTargetByIdRnaIdDocument, options);
      }
export function useGetTargetByIdRnaIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetByIdRnaIdQuery, GetTargetByIdRnaIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetByIdRnaIdQuery, GetTargetByIdRnaIdQueryVariables>(GetTargetByIdRnaIdDocument, options);
        }
export type GetTargetByIdRnaIdQueryHookResult = ReturnType<typeof useGetTargetByIdRnaIdQuery>;
export type GetTargetByIdRnaIdLazyQueryHookResult = ReturnType<typeof useGetTargetByIdRnaIdLazyQuery>;
export type GetTargetByIdRnaIdQueryResult = Apollo.QueryResult<GetTargetByIdRnaIdQuery, GetTargetByIdRnaIdQueryVariables>;
export const SearchTargetsDocument = gql`
    query searchTargets($page: Int, $perPage: Int, $filter: TargetSearchInput) {
  searchTargets(page: $page, perPage: $perPage, filter: $filter) {
    edges {
      node {
        ...targetProps
      }
    }
  }
}
    ${TargetPropsFragmentDoc}`;

/**
 * __useSearchTargetsQuery__
 *
 * To run a query within a React component, call `useSearchTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTargetsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchTargetsQuery(baseOptions?: Apollo.QueryHookOptions<SearchTargetsQuery, SearchTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTargetsQuery, SearchTargetsQueryVariables>(SearchTargetsDocument, options);
      }
export function useSearchTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTargetsQuery, SearchTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTargetsQuery, SearchTargetsQueryVariables>(SearchTargetsDocument, options);
        }
export type SearchTargetsQueryHookResult = ReturnType<typeof useSearchTargetsQuery>;
export type SearchTargetsLazyQueryHookResult = ReturnType<typeof useSearchTargetsLazyQuery>;
export type SearchTargetsQueryResult = Apollo.QueryResult<SearchTargetsQuery, SearchTargetsQueryVariables>;
export const CreateTargetDocument = gql`
    mutation createTarget($rnaId: ID!, $spliced: Boolean!) {
  createTarget(input: {rnaId: $rnaId, spliced: $spliced}) {
    success
    message
    target {
      ...targetProps
      rna {
        id
        ...isMrnaRnaTarget
      }
    }
  }
}
    ${TargetPropsFragmentDoc}
${IsMrnaRnaTargetFragmentDoc}`;
export type CreateTargetMutationFn = Apollo.MutationFunction<CreateTargetMutation, CreateTargetMutationVariables>;

/**
 * __useCreateTargetMutation__
 *
 * To run a mutation, you first call `useCreateTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetMutation, { data, loading, error }] = useCreateTargetMutation({
 *   variables: {
 *      rnaId: // value for 'rnaId'
 *      spliced: // value for 'spliced'
 *   },
 * });
 */
export function useCreateTargetMutation(baseOptions?: Apollo.MutationHookOptions<CreateTargetMutation, CreateTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTargetMutation, CreateTargetMutationVariables>(CreateTargetDocument, options);
      }
export type CreateTargetMutationHookResult = ReturnType<typeof useCreateTargetMutation>;
export type CreateTargetMutationResult = Apollo.MutationResult<CreateTargetMutation>;
export type CreateTargetMutationOptions = Apollo.BaseMutationOptions<CreateTargetMutation, CreateTargetMutationVariables>;
export const GetTargetAntisenseSeriesDocument = gql`
    query getTargetAntisenseSeries($id: ID, $rnaId: ID, $spliced: Boolean) {
  target(filter: {id: $id, rnaId: $rnaId, spliced: $spliced}) {
    organismId
    id
    rnaId
    spliced
    custom
    symbol
    geneName
    title
    length
    exonCount
    antisenseSeries {
      id
      length
    }
  }
}
    `;

/**
 * __useGetTargetAntisenseSeriesQuery__
 *
 * To run a query within a React component, call `useGetTargetAntisenseSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetAntisenseSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetAntisenseSeriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rnaId: // value for 'rnaId'
 *      spliced: // value for 'spliced'
 *   },
 * });
 */
export function useGetTargetAntisenseSeriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetAntisenseSeriesQuery, GetTargetAntisenseSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetAntisenseSeriesQuery, GetTargetAntisenseSeriesQueryVariables>(GetTargetAntisenseSeriesDocument, options);
      }
export function useGetTargetAntisenseSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetAntisenseSeriesQuery, GetTargetAntisenseSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetAntisenseSeriesQuery, GetTargetAntisenseSeriesQueryVariables>(GetTargetAntisenseSeriesDocument, options);
        }
export type GetTargetAntisenseSeriesQueryHookResult = ReturnType<typeof useGetTargetAntisenseSeriesQuery>;
export type GetTargetAntisenseSeriesLazyQueryHookResult = ReturnType<typeof useGetTargetAntisenseSeriesLazyQuery>;
export type GetTargetAntisenseSeriesQueryResult = Apollo.QueryResult<GetTargetAntisenseSeriesQuery, GetTargetAntisenseSeriesQueryVariables>;
export const GetTargetRnasDocument = gql`
    query getTargetRnas($id: ID, $rnaId: ID, $spliced: Boolean) {
  target(filter: {id: $id, rnaId: $rnaId, spliced: $spliced}) {
    id
    length
    rna {
      rnaLength
      mrnaLength
    }
  }
}
    `;

/**
 * __useGetTargetRnasQuery__
 *
 * To run a query within a React component, call `useGetTargetRnasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetRnasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetRnasQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rnaId: // value for 'rnaId'
 *      spliced: // value for 'spliced'
 *   },
 * });
 */
export function useGetTargetRnasQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetRnasQuery, GetTargetRnasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetRnasQuery, GetTargetRnasQueryVariables>(GetTargetRnasDocument, options);
      }
export function useGetTargetRnasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetRnasQuery, GetTargetRnasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetRnasQuery, GetTargetRnasQueryVariables>(GetTargetRnasDocument, options);
        }
export type GetTargetRnasQueryHookResult = ReturnType<typeof useGetTargetRnasQuery>;
export type GetTargetRnasLazyQueryHookResult = ReturnType<typeof useGetTargetRnasLazyQuery>;
export type GetTargetRnasQueryResult = Apollo.QueryResult<GetTargetRnasQuery, GetTargetRnasQueryVariables>;
export const CreateAntisenseSeriesDocument = gql`
    mutation createAntisenseSeries($targetId: ID!, $length: Int!) {
  createAntisenseSeries(input: {targetId: $targetId, length: $length}) {
    success
    message
    antisenseSeries {
      id
      target {
        id
        rnaId
      }
      length
    }
  }
}
    `;
export type CreateAntisenseSeriesMutationFn = Apollo.MutationFunction<CreateAntisenseSeriesMutation, CreateAntisenseSeriesMutationVariables>;

/**
 * __useCreateAntisenseSeriesMutation__
 *
 * To run a mutation, you first call `useCreateAntisenseSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAntisenseSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAntisenseSeriesMutation, { data, loading, error }] = useCreateAntisenseSeriesMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useCreateAntisenseSeriesMutation(baseOptions?: Apollo.MutationHookOptions<CreateAntisenseSeriesMutation, CreateAntisenseSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAntisenseSeriesMutation, CreateAntisenseSeriesMutationVariables>(CreateAntisenseSeriesDocument, options);
      }
export type CreateAntisenseSeriesMutationHookResult = ReturnType<typeof useCreateAntisenseSeriesMutation>;
export type CreateAntisenseSeriesMutationResult = Apollo.MutationResult<CreateAntisenseSeriesMutation>;
export type CreateAntisenseSeriesMutationOptions = Apollo.BaseMutationOptions<CreateAntisenseSeriesMutation, CreateAntisenseSeriesMutationVariables>;
export const GetTargetAntisenseSequencesDocument = gql`
    query getTargetAntisenseSequences($page: Int, $perPage: Int, $filter: AntisenseSequenceWhereInput = {}, $orderBy: [AntisenseSequenceSortField!]) {
  antisenseSequences(
    page: $page
    perPage: $perPage
    filter: $filter
    orderBy: $orderBy
  ) {
    edges {
      node {
        ...antisenseSequenceProps
        exonId
        intronId
        region
        before40
        before20
        before10
        before5
        front8
        mid8
        mid16
        back8
        after5
        after10
        after20
        after40
        blastMatches
        blastNextBestLength
        blastNextBestCount
        isInCart @client
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${AntisenseSequencePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetTargetAntisenseSequencesQuery__
 *
 * To run a query within a React component, call `useGetTargetAntisenseSequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetAntisenseSequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetAntisenseSequencesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTargetAntisenseSequencesQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetAntisenseSequencesQuery, GetTargetAntisenseSequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetAntisenseSequencesQuery, GetTargetAntisenseSequencesQueryVariables>(GetTargetAntisenseSequencesDocument, options);
      }
export function useGetTargetAntisenseSequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetAntisenseSequencesQuery, GetTargetAntisenseSequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetAntisenseSequencesQuery, GetTargetAntisenseSequencesQueryVariables>(GetTargetAntisenseSequencesDocument, options);
        }
export type GetTargetAntisenseSequencesQueryHookResult = ReturnType<typeof useGetTargetAntisenseSequencesQuery>;
export type GetTargetAntisenseSequencesLazyQueryHookResult = ReturnType<typeof useGetTargetAntisenseSequencesLazyQuery>;
export type GetTargetAntisenseSequencesQueryResult = Apollo.QueryResult<GetTargetAntisenseSequencesQuery, GetTargetAntisenseSequencesQueryVariables>;
export const GetAntisenseSequenceDocument = gql`
    query getAntisenseSequence($id: ID!) {
  antisenseSequence(id: $id) {
    id
    seq
    start
    isInCart @client
  }
}
    `;

/**
 * __useGetAntisenseSequenceQuery__
 *
 * To run a query within a React component, call `useGetAntisenseSequenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAntisenseSequenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAntisenseSequenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAntisenseSequenceQuery(baseOptions: Apollo.QueryHookOptions<GetAntisenseSequenceQuery, GetAntisenseSequenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAntisenseSequenceQuery, GetAntisenseSequenceQueryVariables>(GetAntisenseSequenceDocument, options);
      }
export function useGetAntisenseSequenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAntisenseSequenceQuery, GetAntisenseSequenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAntisenseSequenceQuery, GetAntisenseSequenceQueryVariables>(GetAntisenseSequenceDocument, options);
        }
export type GetAntisenseSequenceQueryHookResult = ReturnType<typeof useGetAntisenseSequenceQuery>;
export type GetAntisenseSequenceLazyQueryHookResult = ReturnType<typeof useGetAntisenseSequenceLazyQuery>;
export type GetAntisenseSequenceQueryResult = Apollo.QueryResult<GetAntisenseSequenceQuery, GetAntisenseSequenceQueryVariables>;
export const GetAntisenseSequenceOffTargetsDocument = gql`
    query getAntisenseSequenceOffTargets($id: ID!) {
  antisenseSequence(id: $id) {
    id
    offTargets {
      id
      symbol
    }
  }
}
    `;

/**
 * __useGetAntisenseSequenceOffTargetsQuery__
 *
 * To run a query within a React component, call `useGetAntisenseSequenceOffTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAntisenseSequenceOffTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAntisenseSequenceOffTargetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAntisenseSequenceOffTargetsQuery(baseOptions: Apollo.QueryHookOptions<GetAntisenseSequenceOffTargetsQuery, GetAntisenseSequenceOffTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAntisenseSequenceOffTargetsQuery, GetAntisenseSequenceOffTargetsQueryVariables>(GetAntisenseSequenceOffTargetsDocument, options);
      }
export function useGetAntisenseSequenceOffTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAntisenseSequenceOffTargetsQuery, GetAntisenseSequenceOffTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAntisenseSequenceOffTargetsQuery, GetAntisenseSequenceOffTargetsQueryVariables>(GetAntisenseSequenceOffTargetsDocument, options);
        }
export type GetAntisenseSequenceOffTargetsQueryHookResult = ReturnType<typeof useGetAntisenseSequenceOffTargetsQuery>;
export type GetAntisenseSequenceOffTargetsLazyQueryHookResult = ReturnType<typeof useGetAntisenseSequenceOffTargetsLazyQuery>;
export type GetAntisenseSequenceOffTargetsQueryResult = Apollo.QueryResult<GetAntisenseSequenceOffTargetsQuery, GetAntisenseSequenceOffTargetsQueryVariables>;
export const GetAntisenseSequenceBlastNextBestIdsDocument = gql`
    query getAntisenseSequenceBlastNextBestIds($id: ID!) {
  antisenseSequence(id: $id) {
    id
    blastNextBestIds
  }
}
    `;

/**
 * __useGetAntisenseSequenceBlastNextBestIdsQuery__
 *
 * To run a query within a React component, call `useGetAntisenseSequenceBlastNextBestIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAntisenseSequenceBlastNextBestIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAntisenseSequenceBlastNextBestIdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAntisenseSequenceBlastNextBestIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAntisenseSequenceBlastNextBestIdsQuery, GetAntisenseSequenceBlastNextBestIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAntisenseSequenceBlastNextBestIdsQuery, GetAntisenseSequenceBlastNextBestIdsQueryVariables>(GetAntisenseSequenceBlastNextBestIdsDocument, options);
      }
export function useGetAntisenseSequenceBlastNextBestIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAntisenseSequenceBlastNextBestIdsQuery, GetAntisenseSequenceBlastNextBestIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAntisenseSequenceBlastNextBestIdsQuery, GetAntisenseSequenceBlastNextBestIdsQueryVariables>(GetAntisenseSequenceBlastNextBestIdsDocument, options);
        }
export type GetAntisenseSequenceBlastNextBestIdsQueryHookResult = ReturnType<typeof useGetAntisenseSequenceBlastNextBestIdsQuery>;
export type GetAntisenseSequenceBlastNextBestIdsLazyQueryHookResult = ReturnType<typeof useGetAntisenseSequenceBlastNextBestIdsLazyQuery>;
export type GetAntisenseSequenceBlastNextBestIdsQueryResult = Apollo.QueryResult<GetAntisenseSequenceBlastNextBestIdsQuery, GetAntisenseSequenceBlastNextBestIdsQueryVariables>;
export const CreateSirnaSeriesDocument = gql`
    mutation createSirnaSeries($targetId: ID!, $config: [Int!]!) {
  createSirnaSeries(input: {targetId: $targetId, config: $config}) {
    success
    message
    sirnaSeries {
      id
      target {
        id
        rnaId
      }
      config
    }
  }
}
    `;
export type CreateSirnaSeriesMutationFn = Apollo.MutationFunction<CreateSirnaSeriesMutation, CreateSirnaSeriesMutationVariables>;

/**
 * __useCreateSirnaSeriesMutation__
 *
 * To run a mutation, you first call `useCreateSirnaSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSirnaSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSirnaSeriesMutation, { data, loading, error }] = useCreateSirnaSeriesMutation({
 *   variables: {
 *      targetId: // value for 'targetId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateSirnaSeriesMutation(baseOptions?: Apollo.MutationHookOptions<CreateSirnaSeriesMutation, CreateSirnaSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSirnaSeriesMutation, CreateSirnaSeriesMutationVariables>(CreateSirnaSeriesDocument, options);
      }
export type CreateSirnaSeriesMutationHookResult = ReturnType<typeof useCreateSirnaSeriesMutation>;
export type CreateSirnaSeriesMutationResult = Apollo.MutationResult<CreateSirnaSeriesMutation>;
export type CreateSirnaSeriesMutationOptions = Apollo.BaseMutationOptions<CreateSirnaSeriesMutation, CreateSirnaSeriesMutationVariables>;
export const GetTargetSirnaDuplexesDocument = gql`
    query getTargetSirnaDuplexes($page: Int, $perPage: Int, $filter: SirnaDuplexWhereInput = {}, $orderBy: [SirnaDuplexSortField!]) {
  sirnaDuplexes(
    page: $page
    perPage: $perPage
    filter: $filter
    orderBy: $orderBy
  ) {
    edges {
      node {
        ...sirnaDuplexProps
        exonId
        region
        blastMatchesAs
        blastMatchesS
        isInCart @client
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${SirnaDuplexPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetTargetSirnaDuplexesQuery__
 *
 * To run a query within a React component, call `useGetTargetSirnaDuplexesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetSirnaDuplexesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetSirnaDuplexesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetTargetSirnaDuplexesQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetSirnaDuplexesQuery, GetTargetSirnaDuplexesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetSirnaDuplexesQuery, GetTargetSirnaDuplexesQueryVariables>(GetTargetSirnaDuplexesDocument, options);
      }
export function useGetTargetSirnaDuplexesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetSirnaDuplexesQuery, GetTargetSirnaDuplexesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetSirnaDuplexesQuery, GetTargetSirnaDuplexesQueryVariables>(GetTargetSirnaDuplexesDocument, options);
        }
export type GetTargetSirnaDuplexesQueryHookResult = ReturnType<typeof useGetTargetSirnaDuplexesQuery>;
export type GetTargetSirnaDuplexesLazyQueryHookResult = ReturnType<typeof useGetTargetSirnaDuplexesLazyQuery>;
export type GetTargetSirnaDuplexesQueryResult = Apollo.QueryResult<GetTargetSirnaDuplexesQuery, GetTargetSirnaDuplexesQueryVariables>;
export const GetSirnaDuplexDocument = gql`
    query getSirnaDuplex($id: ID!) {
  sirnaDuplex(id: $id) {
    id
    startAs
    endAs
    seqAs
    startS
    endS
    seqS
    isInCart @client
  }
}
    `;

/**
 * __useGetSirnaDuplexQuery__
 *
 * To run a query within a React component, call `useGetSirnaDuplexQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSirnaDuplexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSirnaDuplexQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSirnaDuplexQuery(baseOptions: Apollo.QueryHookOptions<GetSirnaDuplexQuery, GetSirnaDuplexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSirnaDuplexQuery, GetSirnaDuplexQueryVariables>(GetSirnaDuplexDocument, options);
      }
export function useGetSirnaDuplexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSirnaDuplexQuery, GetSirnaDuplexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSirnaDuplexQuery, GetSirnaDuplexQueryVariables>(GetSirnaDuplexDocument, options);
        }
export type GetSirnaDuplexQueryHookResult = ReturnType<typeof useGetSirnaDuplexQuery>;
export type GetSirnaDuplexLazyQueryHookResult = ReturnType<typeof useGetSirnaDuplexLazyQuery>;
export type GetSirnaDuplexQueryResult = Apollo.QueryResult<GetSirnaDuplexQuery, GetSirnaDuplexQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    username
    email
    emailVerified
    isAdmin
    isOrders
    photoUrl
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation updateUserPassword($id: ID!, $password: String!, $newPassword: String!) {
  updateUserPassword(
    input: {id: $id, password: $password, newPassword: $newPassword}
  ) {
    success
    message
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const GetAllSugarsDocument = gql`
    query getAllSugars($page: Int, $perPage: Int, $filter: SugarWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  sugars(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...sugarProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${SugarPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllSugarsQuery__
 *
 * To run a query within a React component, call `useGetAllSugarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSugarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSugarsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllSugarsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSugarsQuery, GetAllSugarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSugarsQuery, GetAllSugarsQueryVariables>(GetAllSugarsDocument, options);
      }
export function useGetAllSugarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSugarsQuery, GetAllSugarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSugarsQuery, GetAllSugarsQueryVariables>(GetAllSugarsDocument, options);
        }
export type GetAllSugarsQueryHookResult = ReturnType<typeof useGetAllSugarsQuery>;
export type GetAllSugarsLazyQueryHookResult = ReturnType<typeof useGetAllSugarsLazyQuery>;
export type GetAllSugarsQueryResult = Apollo.QueryResult<GetAllSugarsQuery, GetAllSugarsQueryVariables>;
export const GetSugarByIdDocument = gql`
    query getSugarById($id: ID!) {
  sugar(id: $id) {
    ...sugarProps
  }
}
    ${SugarPropsFragmentDoc}`;

/**
 * __useGetSugarByIdQuery__
 *
 * To run a query within a React component, call `useGetSugarByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSugarByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSugarByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSugarByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSugarByIdQuery, GetSugarByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSugarByIdQuery, GetSugarByIdQueryVariables>(GetSugarByIdDocument, options);
      }
export function useGetSugarByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSugarByIdQuery, GetSugarByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSugarByIdQuery, GetSugarByIdQueryVariables>(GetSugarByIdDocument, options);
        }
export type GetSugarByIdQueryHookResult = ReturnType<typeof useGetSugarByIdQuery>;
export type GetSugarByIdLazyQueryHookResult = ReturnType<typeof useGetSugarByIdLazyQuery>;
export type GetSugarByIdQueryResult = Apollo.QueryResult<GetSugarByIdQuery, GetSugarByIdQueryVariables>;
export const GetAllBasesDocument = gql`
    query getAllBases($page: Int, $perPage: Int, $filter: BaseWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  bases(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...baseProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${BasePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllBasesQuery__
 *
 * To run a query within a React component, call `useGetAllBasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBasesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllBasesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBasesQuery, GetAllBasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBasesQuery, GetAllBasesQueryVariables>(GetAllBasesDocument, options);
      }
export function useGetAllBasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBasesQuery, GetAllBasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBasesQuery, GetAllBasesQueryVariables>(GetAllBasesDocument, options);
        }
export type GetAllBasesQueryHookResult = ReturnType<typeof useGetAllBasesQuery>;
export type GetAllBasesLazyQueryHookResult = ReturnType<typeof useGetAllBasesLazyQuery>;
export type GetAllBasesQueryResult = Apollo.QueryResult<GetAllBasesQuery, GetAllBasesQueryVariables>;
export const GetBaseByIdDocument = gql`
    query getBaseById($id: ID!) {
  base(id: $id) {
    ...baseProps
  }
}
    ${BasePropsFragmentDoc}`;

/**
 * __useGetBaseByIdQuery__
 *
 * To run a query within a React component, call `useGetBaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBaseByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBaseByIdQuery, GetBaseByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseByIdQuery, GetBaseByIdQueryVariables>(GetBaseByIdDocument, options);
      }
export function useGetBaseByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseByIdQuery, GetBaseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseByIdQuery, GetBaseByIdQueryVariables>(GetBaseByIdDocument, options);
        }
export type GetBaseByIdQueryHookResult = ReturnType<typeof useGetBaseByIdQuery>;
export type GetBaseByIdLazyQueryHookResult = ReturnType<typeof useGetBaseByIdLazyQuery>;
export type GetBaseByIdQueryResult = Apollo.QueryResult<GetBaseByIdQuery, GetBaseByIdQueryVariables>;
export const GetBaseBySymbolDocument = gql`
    query getBaseBySymbol($symbol: String!) {
  baseBySymbol(symbol: $symbol) {
    id
  }
}
    `;

/**
 * __useGetBaseBySymbolQuery__
 *
 * To run a query within a React component, call `useGetBaseBySymbolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseBySymbolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseBySymbolQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetBaseBySymbolQuery(baseOptions: Apollo.QueryHookOptions<GetBaseBySymbolQuery, GetBaseBySymbolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseBySymbolQuery, GetBaseBySymbolQueryVariables>(GetBaseBySymbolDocument, options);
      }
export function useGetBaseBySymbolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseBySymbolQuery, GetBaseBySymbolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseBySymbolQuery, GetBaseBySymbolQueryVariables>(GetBaseBySymbolDocument, options);
        }
export type GetBaseBySymbolQueryHookResult = ReturnType<typeof useGetBaseBySymbolQuery>;
export type GetBaseBySymbolLazyQueryHookResult = ReturnType<typeof useGetBaseBySymbolLazyQuery>;
export type GetBaseBySymbolQueryResult = Apollo.QueryResult<GetBaseBySymbolQuery, GetBaseBySymbolQueryVariables>;
export const GetAllNucleosidesDocument = gql`
    query getAllNucleosides($page: Int, $perPage: Int, $filter: NucleosideWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  nucleosides(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...nucleosideProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${NucleosidePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllNucleosidesQuery__
 *
 * To run a query within a React component, call `useGetAllNucleosidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNucleosidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNucleosidesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllNucleosidesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNucleosidesQuery, GetAllNucleosidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNucleosidesQuery, GetAllNucleosidesQueryVariables>(GetAllNucleosidesDocument, options);
      }
export function useGetAllNucleosidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNucleosidesQuery, GetAllNucleosidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNucleosidesQuery, GetAllNucleosidesQueryVariables>(GetAllNucleosidesDocument, options);
        }
export type GetAllNucleosidesQueryHookResult = ReturnType<typeof useGetAllNucleosidesQuery>;
export type GetAllNucleosidesLazyQueryHookResult = ReturnType<typeof useGetAllNucleosidesLazyQuery>;
export type GetAllNucleosidesQueryResult = Apollo.QueryResult<GetAllNucleosidesQuery, GetAllNucleosidesQueryVariables>;
export const GetAllBackbonesDocument = gql`
    query getAllBackbones($page: Int, $perPage: Int, $filter: BackboneWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  backbones(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...backboneProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${BackbonePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllBackbonesQuery__
 *
 * To run a query within a React component, call `useGetAllBackbonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBackbonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBackbonesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllBackbonesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBackbonesQuery, GetAllBackbonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBackbonesQuery, GetAllBackbonesQueryVariables>(GetAllBackbonesDocument, options);
      }
export function useGetAllBackbonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBackbonesQuery, GetAllBackbonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBackbonesQuery, GetAllBackbonesQueryVariables>(GetAllBackbonesDocument, options);
        }
export type GetAllBackbonesQueryHookResult = ReturnType<typeof useGetAllBackbonesQuery>;
export type GetAllBackbonesLazyQueryHookResult = ReturnType<typeof useGetAllBackbonesLazyQuery>;
export type GetAllBackbonesQueryResult = Apollo.QueryResult<GetAllBackbonesQuery, GetAllBackbonesQueryVariables>;
export const GetAllCouplingsDocument = gql`
    query getAllCouplings($page: Int, $perPage: Int, $filter: CouplingWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  couplings(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...couplingProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${CouplingPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllCouplingsQuery__
 *
 * To run a query within a React component, call `useGetAllCouplingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCouplingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCouplingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCouplingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCouplingsQuery, GetAllCouplingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCouplingsQuery, GetAllCouplingsQueryVariables>(GetAllCouplingsDocument, options);
      }
export function useGetAllCouplingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCouplingsQuery, GetAllCouplingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCouplingsQuery, GetAllCouplingsQueryVariables>(GetAllCouplingsDocument, options);
        }
export type GetAllCouplingsQueryHookResult = ReturnType<typeof useGetAllCouplingsQuery>;
export type GetAllCouplingsLazyQueryHookResult = ReturnType<typeof useGetAllCouplingsLazyQuery>;
export type GetAllCouplingsQueryResult = Apollo.QueryResult<GetAllCouplingsQuery, GetAllCouplingsQueryVariables>;
export const GetAllCouplingsNoStructureDocument = gql`
    query getAllCouplingsNoStructure($page: Int, $perPage: Int, $filter: CouplingWhereInput = {}, $orderBy: [BuildingBlockSortField!]) {
  couplings(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        sugarId
        baseId
        backboneId
        id
        symbol
        name
        mass
        description
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllCouplingsNoStructureQuery__
 *
 * To run a query within a React component, call `useGetAllCouplingsNoStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCouplingsNoStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCouplingsNoStructureQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCouplingsNoStructureQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCouplingsNoStructureQuery, GetAllCouplingsNoStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCouplingsNoStructureQuery, GetAllCouplingsNoStructureQueryVariables>(GetAllCouplingsNoStructureDocument, options);
      }
export function useGetAllCouplingsNoStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCouplingsNoStructureQuery, GetAllCouplingsNoStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCouplingsNoStructureQuery, GetAllCouplingsNoStructureQueryVariables>(GetAllCouplingsNoStructureDocument, options);
        }
export type GetAllCouplingsNoStructureQueryHookResult = ReturnType<typeof useGetAllCouplingsNoStructureQuery>;
export type GetAllCouplingsNoStructureLazyQueryHookResult = ReturnType<typeof useGetAllCouplingsNoStructureLazyQuery>;
export type GetAllCouplingsNoStructureQueryResult = Apollo.QueryResult<GetAllCouplingsNoStructureQuery, GetAllCouplingsNoStructureQueryVariables>;
export const GetCouplingByCompositionDocument = gql`
    query getCouplingByComposition($filter: CouplingCompositionInput!) {
  couplingByComposition(filter: $filter) {
    id
    symbol
  }
}
    `;

/**
 * __useGetCouplingByCompositionQuery__
 *
 * To run a query within a React component, call `useGetCouplingByCompositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouplingByCompositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouplingByCompositionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCouplingByCompositionQuery(baseOptions: Apollo.QueryHookOptions<GetCouplingByCompositionQuery, GetCouplingByCompositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouplingByCompositionQuery, GetCouplingByCompositionQueryVariables>(GetCouplingByCompositionDocument, options);
      }
export function useGetCouplingByCompositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouplingByCompositionQuery, GetCouplingByCompositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouplingByCompositionQuery, GetCouplingByCompositionQueryVariables>(GetCouplingByCompositionDocument, options);
        }
export type GetCouplingByCompositionQueryHookResult = ReturnType<typeof useGetCouplingByCompositionQuery>;
export type GetCouplingByCompositionLazyQueryHookResult = ReturnType<typeof useGetCouplingByCompositionLazyQuery>;
export type GetCouplingByCompositionQueryResult = Apollo.QueryResult<GetCouplingByCompositionQuery, GetCouplingByCompositionQueryVariables>;
export const GetAllAmiditesDocument = gql`
    query getAllAmidites($page: Int, $perPage: Int, $filter: AmiditeWhereInput = {}, $orderBy: [AmiditeSortField!]) {
  amidites(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...amiditeProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${AmiditePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllAmiditesQuery__
 *
 * To run a query within a React component, call `useGetAllAmiditesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAmiditesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAmiditesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllAmiditesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAmiditesQuery, GetAllAmiditesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAmiditesQuery, GetAllAmiditesQueryVariables>(GetAllAmiditesDocument, options);
      }
export function useGetAllAmiditesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAmiditesQuery, GetAllAmiditesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAmiditesQuery, GetAllAmiditesQueryVariables>(GetAllAmiditesDocument, options);
        }
export type GetAllAmiditesQueryHookResult = ReturnType<typeof useGetAllAmiditesQuery>;
export type GetAllAmiditesLazyQueryHookResult = ReturnType<typeof useGetAllAmiditesLazyQuery>;
export type GetAllAmiditesQueryResult = Apollo.QueryResult<GetAllAmiditesQuery, GetAllAmiditesQueryVariables>;
export const GetAllChromosomesDocument = gql`
    query getAllChromosomes($page: Int, $perPage: Int, $filter: ChromosomeWhereInput = {}, $orderBy: [ChromosomeSortField!]) {
  chromosomes(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...chromosomeProps
        organism {
          ...organismProps
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${ChromosomePropsFragmentDoc}
${OrganismPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllChromosomesQuery__
 *
 * To run a query within a React component, call `useGetAllChromosomesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChromosomesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChromosomesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllChromosomesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChromosomesQuery, GetAllChromosomesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChromosomesQuery, GetAllChromosomesQueryVariables>(GetAllChromosomesDocument, options);
      }
export function useGetAllChromosomesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChromosomesQuery, GetAllChromosomesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChromosomesQuery, GetAllChromosomesQueryVariables>(GetAllChromosomesDocument, options);
        }
export type GetAllChromosomesQueryHookResult = ReturnType<typeof useGetAllChromosomesQuery>;
export type GetAllChromosomesLazyQueryHookResult = ReturnType<typeof useGetAllChromosomesLazyQuery>;
export type GetAllChromosomesQueryResult = Apollo.QueryResult<GetAllChromosomesQuery, GetAllChromosomesQueryVariables>;
export const GetAllRnasDocument = gql`
    query getAllRnas($page: Int, $perPage: Int, $filter: RnaWhereInput = {}, $orderBy: [RnaSortField!]) {
  rnas(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...rnaProps
        status
        isMrnaTarget
        isRnaTarget
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${RnaPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllRnasQuery__
 *
 * To run a query within a React component, call `useGetAllRnasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRnasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRnasQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllRnasQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRnasQuery, GetAllRnasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRnasQuery, GetAllRnasQueryVariables>(GetAllRnasDocument, options);
      }
export function useGetAllRnasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRnasQuery, GetAllRnasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRnasQuery, GetAllRnasQueryVariables>(GetAllRnasDocument, options);
        }
export type GetAllRnasQueryHookResult = ReturnType<typeof useGetAllRnasQuery>;
export type GetAllRnasLazyQueryHookResult = ReturnType<typeof useGetAllRnasLazyQuery>;
export type GetAllRnasQueryResult = Apollo.QueryResult<GetAllRnasQuery, GetAllRnasQueryVariables>;
export const SearchRnasDocument = gql`
    query searchRnas($page: Int, $perPage: Int, $filter: RnaSearchInput = {}, $orderBy: [RnaSortField!]) {
  searchRnas(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...rnaProps
      }
    }
  }
}
    ${RnaPropsFragmentDoc}`;

/**
 * __useSearchRnasQuery__
 *
 * To run a query within a React component, call `useSearchRnasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRnasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRnasQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchRnasQuery(baseOptions?: Apollo.QueryHookOptions<SearchRnasQuery, SearchRnasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRnasQuery, SearchRnasQueryVariables>(SearchRnasDocument, options);
      }
export function useSearchRnasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRnasQuery, SearchRnasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRnasQuery, SearchRnasQueryVariables>(SearchRnasDocument, options);
        }
export type SearchRnasQueryHookResult = ReturnType<typeof useSearchRnasQuery>;
export type SearchRnasLazyQueryHookResult = ReturnType<typeof useSearchRnasLazyQuery>;
export type SearchRnasQueryResult = Apollo.QueryResult<SearchRnasQuery, SearchRnasQueryVariables>;
export const GetRnaByIdDocument = gql`
    query getRnaById($id: ID!) {
  rna(id: $id) {
    ...rnaProps
    ...isMrnaRnaTarget
  }
}
    ${RnaPropsFragmentDoc}
${IsMrnaRnaTargetFragmentDoc}`;

/**
 * __useGetRnaByIdQuery__
 *
 * To run a query within a React component, call `useGetRnaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRnaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRnaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRnaByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRnaByIdQuery, GetRnaByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRnaByIdQuery, GetRnaByIdQueryVariables>(GetRnaByIdDocument, options);
      }
export function useGetRnaByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRnaByIdQuery, GetRnaByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRnaByIdQuery, GetRnaByIdQueryVariables>(GetRnaByIdDocument, options);
        }
export type GetRnaByIdQueryHookResult = ReturnType<typeof useGetRnaByIdQuery>;
export type GetRnaByIdLazyQueryHookResult = ReturnType<typeof useGetRnaByIdLazyQuery>;
export type GetRnaByIdQueryResult = Apollo.QueryResult<GetRnaByIdQuery, GetRnaByIdQueryVariables>;
export const GetAllPingsDocument = gql`
    query getAllPings {
  usersPing
  genomePing
  rnasPing
  proteinsPing
  antisensePing
  structuresPing
  oligosPing
  reagentsPing
  ordersPing
  samplesPing
}
    `;

/**
 * __useGetAllPingsQuery__
 *
 * To run a query within a React component, call `useGetAllPingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPingsQuery, GetAllPingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPingsQuery, GetAllPingsQueryVariables>(GetAllPingsDocument, options);
      }
export function useGetAllPingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPingsQuery, GetAllPingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPingsQuery, GetAllPingsQueryVariables>(GetAllPingsDocument, options);
        }
export type GetAllPingsQueryHookResult = ReturnType<typeof useGetAllPingsQuery>;
export type GetAllPingsLazyQueryHookResult = ReturnType<typeof useGetAllPingsLazyQuery>;
export type GetAllPingsQueryResult = Apollo.QueryResult<GetAllPingsQuery, GetAllPingsQueryVariables>;
export const GetUsersPingDocument = gql`
    query getUsersPing {
  usersPing
}
    `;

/**
 * __useGetUsersPingQuery__
 *
 * To run a query within a React component, call `useGetUsersPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersPingQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersPingQuery, GetUsersPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPingQuery, GetUsersPingQueryVariables>(GetUsersPingDocument, options);
      }
export function useGetUsersPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPingQuery, GetUsersPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPingQuery, GetUsersPingQueryVariables>(GetUsersPingDocument, options);
        }
export type GetUsersPingQueryHookResult = ReturnType<typeof useGetUsersPingQuery>;
export type GetUsersPingLazyQueryHookResult = ReturnType<typeof useGetUsersPingLazyQuery>;
export type GetUsersPingQueryResult = Apollo.QueryResult<GetUsersPingQuery, GetUsersPingQueryVariables>;
export const GetGenomePingDocument = gql`
    query getGenomePing {
  genomePing
}
    `;

/**
 * __useGetGenomePingQuery__
 *
 * To run a query within a React component, call `useGetGenomePingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenomePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenomePingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenomePingQuery(baseOptions?: Apollo.QueryHookOptions<GetGenomePingQuery, GetGenomePingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenomePingQuery, GetGenomePingQueryVariables>(GetGenomePingDocument, options);
      }
export function useGetGenomePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenomePingQuery, GetGenomePingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenomePingQuery, GetGenomePingQueryVariables>(GetGenomePingDocument, options);
        }
export type GetGenomePingQueryHookResult = ReturnType<typeof useGetGenomePingQuery>;
export type GetGenomePingLazyQueryHookResult = ReturnType<typeof useGetGenomePingLazyQuery>;
export type GetGenomePingQueryResult = Apollo.QueryResult<GetGenomePingQuery, GetGenomePingQueryVariables>;
export const GetRnasPingDocument = gql`
    query getRnasPing {
  rnasPing
}
    `;

/**
 * __useGetRnasPingQuery__
 *
 * To run a query within a React component, call `useGetRnasPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRnasPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRnasPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRnasPingQuery(baseOptions?: Apollo.QueryHookOptions<GetRnasPingQuery, GetRnasPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRnasPingQuery, GetRnasPingQueryVariables>(GetRnasPingDocument, options);
      }
export function useGetRnasPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRnasPingQuery, GetRnasPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRnasPingQuery, GetRnasPingQueryVariables>(GetRnasPingDocument, options);
        }
export type GetRnasPingQueryHookResult = ReturnType<typeof useGetRnasPingQuery>;
export type GetRnasPingLazyQueryHookResult = ReturnType<typeof useGetRnasPingLazyQuery>;
export type GetRnasPingQueryResult = Apollo.QueryResult<GetRnasPingQuery, GetRnasPingQueryVariables>;
export const GetProteinsPingDocument = gql`
    query getProteinsPing {
  proteinsPing
}
    `;

/**
 * __useGetProteinsPingQuery__
 *
 * To run a query within a React component, call `useGetProteinsPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProteinsPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProteinsPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProteinsPingQuery(baseOptions?: Apollo.QueryHookOptions<GetProteinsPingQuery, GetProteinsPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProteinsPingQuery, GetProteinsPingQueryVariables>(GetProteinsPingDocument, options);
      }
export function useGetProteinsPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProteinsPingQuery, GetProteinsPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProteinsPingQuery, GetProteinsPingQueryVariables>(GetProteinsPingDocument, options);
        }
export type GetProteinsPingQueryHookResult = ReturnType<typeof useGetProteinsPingQuery>;
export type GetProteinsPingLazyQueryHookResult = ReturnType<typeof useGetProteinsPingLazyQuery>;
export type GetProteinsPingQueryResult = Apollo.QueryResult<GetProteinsPingQuery, GetProteinsPingQueryVariables>;
export const GetAntisensePingDocument = gql`
    query getAntisensePing {
  antisensePing
}
    `;

/**
 * __useGetAntisensePingQuery__
 *
 * To run a query within a React component, call `useGetAntisensePingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAntisensePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAntisensePingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAntisensePingQuery(baseOptions?: Apollo.QueryHookOptions<GetAntisensePingQuery, GetAntisensePingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAntisensePingQuery, GetAntisensePingQueryVariables>(GetAntisensePingDocument, options);
      }
export function useGetAntisensePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAntisensePingQuery, GetAntisensePingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAntisensePingQuery, GetAntisensePingQueryVariables>(GetAntisensePingDocument, options);
        }
export type GetAntisensePingQueryHookResult = ReturnType<typeof useGetAntisensePingQuery>;
export type GetAntisensePingLazyQueryHookResult = ReturnType<typeof useGetAntisensePingLazyQuery>;
export type GetAntisensePingQueryResult = Apollo.QueryResult<GetAntisensePingQuery, GetAntisensePingQueryVariables>;
export const GetStructuresPingDocument = gql`
    query getStructuresPing {
  structuresPing
}
    `;

/**
 * __useGetStructuresPingQuery__
 *
 * To run a query within a React component, call `useGetStructuresPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStructuresPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStructuresPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStructuresPingQuery(baseOptions?: Apollo.QueryHookOptions<GetStructuresPingQuery, GetStructuresPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStructuresPingQuery, GetStructuresPingQueryVariables>(GetStructuresPingDocument, options);
      }
export function useGetStructuresPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStructuresPingQuery, GetStructuresPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStructuresPingQuery, GetStructuresPingQueryVariables>(GetStructuresPingDocument, options);
        }
export type GetStructuresPingQueryHookResult = ReturnType<typeof useGetStructuresPingQuery>;
export type GetStructuresPingLazyQueryHookResult = ReturnType<typeof useGetStructuresPingLazyQuery>;
export type GetStructuresPingQueryResult = Apollo.QueryResult<GetStructuresPingQuery, GetStructuresPingQueryVariables>;
export const GetOligosPingDocument = gql`
    query getOligosPing {
  oligosPing
}
    `;

/**
 * __useGetOligosPingQuery__
 *
 * To run a query within a React component, call `useGetOligosPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOligosPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOligosPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOligosPingQuery(baseOptions?: Apollo.QueryHookOptions<GetOligosPingQuery, GetOligosPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOligosPingQuery, GetOligosPingQueryVariables>(GetOligosPingDocument, options);
      }
export function useGetOligosPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOligosPingQuery, GetOligosPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOligosPingQuery, GetOligosPingQueryVariables>(GetOligosPingDocument, options);
        }
export type GetOligosPingQueryHookResult = ReturnType<typeof useGetOligosPingQuery>;
export type GetOligosPingLazyQueryHookResult = ReturnType<typeof useGetOligosPingLazyQuery>;
export type GetOligosPingQueryResult = Apollo.QueryResult<GetOligosPingQuery, GetOligosPingQueryVariables>;
export const GetReagentsPingDocument = gql`
    query getReagentsPing {
  reagentsPing
}
    `;

/**
 * __useGetReagentsPingQuery__
 *
 * To run a query within a React component, call `useGetReagentsPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReagentsPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReagentsPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReagentsPingQuery(baseOptions?: Apollo.QueryHookOptions<GetReagentsPingQuery, GetReagentsPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReagentsPingQuery, GetReagentsPingQueryVariables>(GetReagentsPingDocument, options);
      }
export function useGetReagentsPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReagentsPingQuery, GetReagentsPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReagentsPingQuery, GetReagentsPingQueryVariables>(GetReagentsPingDocument, options);
        }
export type GetReagentsPingQueryHookResult = ReturnType<typeof useGetReagentsPingQuery>;
export type GetReagentsPingLazyQueryHookResult = ReturnType<typeof useGetReagentsPingLazyQuery>;
export type GetReagentsPingQueryResult = Apollo.QueryResult<GetReagentsPingQuery, GetReagentsPingQueryVariables>;
export const GetOrdersPingDocument = gql`
    query getOrdersPing {
  ordersPing
}
    `;

/**
 * __useGetOrdersPingQuery__
 *
 * To run a query within a React component, call `useGetOrdersPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersPingQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersPingQuery, GetOrdersPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersPingQuery, GetOrdersPingQueryVariables>(GetOrdersPingDocument, options);
      }
export function useGetOrdersPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersPingQuery, GetOrdersPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersPingQuery, GetOrdersPingQueryVariables>(GetOrdersPingDocument, options);
        }
export type GetOrdersPingQueryHookResult = ReturnType<typeof useGetOrdersPingQuery>;
export type GetOrdersPingLazyQueryHookResult = ReturnType<typeof useGetOrdersPingLazyQuery>;
export type GetOrdersPingQueryResult = Apollo.QueryResult<GetOrdersPingQuery, GetOrdersPingQueryVariables>;
export const GetSamplesPingDocument = gql`
    query getSamplesPing {
  samplesPing
}
    `;

/**
 * __useGetSamplesPingQuery__
 *
 * To run a query within a React component, call `useGetSamplesPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamplesPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamplesPingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSamplesPingQuery(baseOptions?: Apollo.QueryHookOptions<GetSamplesPingQuery, GetSamplesPingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSamplesPingQuery, GetSamplesPingQueryVariables>(GetSamplesPingDocument, options);
      }
export function useGetSamplesPingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSamplesPingQuery, GetSamplesPingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSamplesPingQuery, GetSamplesPingQueryVariables>(GetSamplesPingDocument, options);
        }
export type GetSamplesPingQueryHookResult = ReturnType<typeof useGetSamplesPingQuery>;
export type GetSamplesPingLazyQueryHookResult = ReturnType<typeof useGetSamplesPingLazyQuery>;
export type GetSamplesPingQueryResult = Apollo.QueryResult<GetSamplesPingQuery, GetSamplesPingQueryVariables>;
export const GetAllOligosDocument = gql`
    query getAllOligos($page: Int, $perPage: Int, $filter: OligoWhereInput, $orderBy: [OligoSortField!]) {
  oligos(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...oligoProps
        mod5 {
          ...modificationProps
        }
        mod3 {
          ...modificationProps
        }
        mods {
          ...modificationProps
        }
        modsPos
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${OligoPropsFragmentDoc}
${ModificationPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllOligosQuery__
 *
 * To run a query within a React component, call `useGetAllOligosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOligosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOligosQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllOligosQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOligosQuery, GetAllOligosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOligosQuery, GetAllOligosQueryVariables>(GetAllOligosDocument, options);
      }
export function useGetAllOligosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOligosQuery, GetAllOligosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOligosQuery, GetAllOligosQueryVariables>(GetAllOligosDocument, options);
        }
export type GetAllOligosQueryHookResult = ReturnType<typeof useGetAllOligosQuery>;
export type GetAllOligosLazyQueryHookResult = ReturnType<typeof useGetAllOligosLazyQuery>;
export type GetAllOligosQueryResult = Apollo.QueryResult<GetAllOligosQuery, GetAllOligosQueryVariables>;
export const CreateOligoDocument = gql`
    mutation createOligo($name: String!, $cpIds: [ID!]!) {
  createOligo(input: {name: $name, cpIds: $cpIds}) {
    success
    message
    oligo {
      ...oligoProps
    }
  }
}
    ${OligoPropsFragmentDoc}`;
export type CreateOligoMutationFn = Apollo.MutationFunction<CreateOligoMutation, CreateOligoMutationVariables>;

/**
 * __useCreateOligoMutation__
 *
 * To run a mutation, you first call `useCreateOligoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOligoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOligoMutation, { data, loading, error }] = useCreateOligoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      cpIds: // value for 'cpIds'
 *   },
 * });
 */
export function useCreateOligoMutation(baseOptions?: Apollo.MutationHookOptions<CreateOligoMutation, CreateOligoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOligoMutation, CreateOligoMutationVariables>(CreateOligoDocument, options);
      }
export type CreateOligoMutationHookResult = ReturnType<typeof useCreateOligoMutation>;
export type CreateOligoMutationResult = Apollo.MutationResult<CreateOligoMutation>;
export type CreateOligoMutationOptions = Apollo.BaseMutationOptions<CreateOligoMutation, CreateOligoMutationVariables>;
export const CreateAsosDocument = gql`
    mutation createAsos($asos: [CreateAsoInput!]!) {
  createAsos(asos: $asos) {
    success
    message
    asos {
      ...oligoProps
    }
  }
}
    ${OligoPropsFragmentDoc}`;
export type CreateAsosMutationFn = Apollo.MutationFunction<CreateAsosMutation, CreateAsosMutationVariables>;

/**
 * __useCreateAsosMutation__
 *
 * To run a mutation, you first call `useCreateAsosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAsosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAsosMutation, { data, loading, error }] = useCreateAsosMutation({
 *   variables: {
 *      asos: // value for 'asos'
 *   },
 * });
 */
export function useCreateAsosMutation(baseOptions?: Apollo.MutationHookOptions<CreateAsosMutation, CreateAsosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAsosMutation, CreateAsosMutationVariables>(CreateAsosDocument, options);
      }
export type CreateAsosMutationHookResult = ReturnType<typeof useCreateAsosMutation>;
export type CreateAsosMutationResult = Apollo.MutationResult<CreateAsosMutation>;
export type CreateAsosMutationOptions = Apollo.BaseMutationOptions<CreateAsosMutation, CreateAsosMutationVariables>;
export const GetAllProductTypesDocument = gql`
    query getAllProductTypes {
  productTypes {
    edges {
      node {
        name
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllProductTypesQuery__
 *
 * To run a query within a React component, call `useGetAllProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductTypesQuery, GetAllProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductTypesQuery, GetAllProductTypesQueryVariables>(GetAllProductTypesDocument, options);
      }
export function useGetAllProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductTypesQuery, GetAllProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductTypesQuery, GetAllProductTypesQueryVariables>(GetAllProductTypesDocument, options);
        }
export type GetAllProductTypesQueryHookResult = ReturnType<typeof useGetAllProductTypesQuery>;
export type GetAllProductTypesLazyQueryHookResult = ReturnType<typeof useGetAllProductTypesLazyQuery>;
export type GetAllProductTypesQueryResult = Apollo.QueryResult<GetAllProductTypesQuery, GetAllProductTypesQueryVariables>;
export const GetAllProductsDocument = gql`
    query getAllProducts($page: Int, $perPage: Int, $filter: ProductWhereInput = {}, $orderBy: [ProductSortField!]) {
  products(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...productProps
        oligo {
          ...oligoProps
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${ProductPropsFragmentDoc}
${OligoPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
      }
export function useGetAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: ID!) {
  product(id: $id) {
    ...productProps
  }
}
    ${ProductPropsFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($name: String!, $productType: String!, $oligoId: Int) {
  createProduct(
    input: {name: $name, productType: $productType, oligoId: $oligoId}
  ) {
    success
    message
    product {
      ...productProps
    }
  }
}
    ${ProductPropsFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      productType: // value for 'productType'
 *      oligoId: // value for 'oligoId'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const GetAllCompaniesDocument = gql`
    query getAllCompanies($page: Int, $perPage: Int, $filter: CompanyWhereInput = {}, $orderBy: [CompanySortField!]) {
  companies(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...companyProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${CompanyPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, options);
      }
export function useGetAllCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>(GetAllCompaniesDocument, options);
        }
export type GetAllCompaniesQueryHookResult = ReturnType<typeof useGetAllCompaniesQuery>;
export type GetAllCompaniesLazyQueryHookResult = ReturnType<typeof useGetAllCompaniesLazyQuery>;
export type GetAllCompaniesQueryResult = Apollo.QueryResult<GetAllCompaniesQuery, GetAllCompaniesQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($id: ID!) {
  company(id: $id) {
    ...companyProps
  }
}
    ${CompanyPropsFragmentDoc}`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($id: String!, $name: String!) {
  createCompany(input: {id: $id, name: $name}) {
    success
    message
    company {
      ...companyProps
    }
  }
}
    ${CompanyPropsFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const GetAllCustomersDocument = gql`
    query getAllCustomers($page: Int, $perPage: Int, $filter: CustomerWhereInput = {}, $orderBy: [CustomerSortField!]) {
  customers(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...customerProps
        company {
          ...companyProps
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${CustomerPropsFragmentDoc}
${CompanyPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllCustomersQuery__
 *
 * To run a query within a React component, call `useGetAllCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCustomersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCustomersQuery, GetAllCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCustomersQuery, GetAllCustomersQueryVariables>(GetAllCustomersDocument, options);
      }
export function useGetAllCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCustomersQuery, GetAllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCustomersQuery, GetAllCustomersQueryVariables>(GetAllCustomersDocument, options);
        }
export type GetAllCustomersQueryHookResult = ReturnType<typeof useGetAllCustomersQuery>;
export type GetAllCustomersLazyQueryHookResult = ReturnType<typeof useGetAllCustomersLazyQuery>;
export type GetAllCustomersQueryResult = Apollo.QueryResult<GetAllCustomersQuery, GetAllCustomersQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: ID!) {
  customer(id: $id) {
    ...customerProps
    company {
      id
      name
    }
  }
}
    ${CustomerPropsFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($companyId: String!, $firstName: String!, $lastName: String!) {
  createCustomer(
    input: {companyId: $companyId, firstName: $firstName, lastName: $lastName}
  ) {
    success
    message
    customer {
      ...customerProps
    }
  }
}
    ${CustomerPropsFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetAllOrderTypesDocument = gql`
    query getAllOrderTypes {
  orderTypes {
    edges {
      node {
        name
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllOrderTypesQuery__
 *
 * To run a query within a React component, call `useGetAllOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrderTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrderTypesQuery, GetAllOrderTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrderTypesQuery, GetAllOrderTypesQueryVariables>(GetAllOrderTypesDocument, options);
      }
export function useGetAllOrderTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrderTypesQuery, GetAllOrderTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrderTypesQuery, GetAllOrderTypesQueryVariables>(GetAllOrderTypesDocument, options);
        }
export type GetAllOrderTypesQueryHookResult = ReturnType<typeof useGetAllOrderTypesQuery>;
export type GetAllOrderTypesLazyQueryHookResult = ReturnType<typeof useGetAllOrderTypesLazyQuery>;
export type GetAllOrderTypesQueryResult = Apollo.QueryResult<GetAllOrderTypesQuery, GetAllOrderTypesQueryVariables>;
export const GetAllOrdersDocument = gql`
    query getAllOrders($page: Int, $perPage: Int, $filter: OrderWhereInput = {}, $orderBy: [OrderSortField!]) {
  orders(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...orderProps
        inCharge {
          id
          username
          photoUrl
        }
        remarks
        deliveries {
          id
          deliverAt
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${OrderPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllOrdersQuery__
 *
 * To run a query within a React component, call `useGetAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrdersQuery, GetAllOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrdersQuery, GetAllOrdersQueryVariables>(GetAllOrdersDocument, options);
      }
export function useGetAllOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrdersQuery, GetAllOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrdersQuery, GetAllOrdersQueryVariables>(GetAllOrdersDocument, options);
        }
export type GetAllOrdersQueryHookResult = ReturnType<typeof useGetAllOrdersQuery>;
export type GetAllOrdersLazyQueryHookResult = ReturnType<typeof useGetAllOrdersLazyQuery>;
export type GetAllOrdersQueryResult = Apollo.QueryResult<GetAllOrdersQuery, GetAllOrdersQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($id: ID!) {
  order(id: $id) {
    ...orderProps
    customer {
      company {
        id
      }
    }
    items {
      ...orderItemProps
      product {
        ...productProps
        oligoId
        asId
        ssId
        samples {
          ...sampleProps
        }
      }
      samples {
        ...sampleProps
      }
    }
    inCharge {
      id
      username
      photoUrl
    }
    remarks
    deliveries {
      id
      deliverAt
    }
  }
}
    ${OrderPropsFragmentDoc}
${OrderItemPropsFragmentDoc}
${ProductPropsFragmentDoc}
${SamplePropsFragmentDoc}`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($customerId: String!, $orderType: String!, $leadTime: [Int!]!, $productIds: [Int!]!, $specs: [String!]!, $unitPrices: [Float!]!, $quantities: [Int!]!, $discounts: [Float!]!, $percDiscounts: [Int!]!, $discountedPrices: [Float!]!) {
  createOrder(
    input: {customerId: $customerId, orderType: $orderType, leadTime: $leadTime, productIds: $productIds, specs: $specs, unitPrices: $unitPrices, quantities: $quantities, discounts: $discounts, percDiscounts: $percDiscounts, discountedPrices: $discountedPrices}
  ) {
    success
    message
    order {
      ...orderProps
    }
  }
}
    ${OrderPropsFragmentDoc}`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      orderType: // value for 'orderType'
 *      leadTime: // value for 'leadTime'
 *      productIds: // value for 'productIds'
 *      specs: // value for 'specs'
 *      unitPrices: // value for 'unitPrices'
 *      quantities: // value for 'quantities'
 *      discounts: // value for 'discounts'
 *      percDiscounts: // value for 'percDiscounts'
 *      discountedPrices: // value for 'discountedPrices'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetAllDeliveriesDocument = gql`
    query getAllDeliveries($page: Int, $perPage: Int, $filter: DeliveryWhereInput = {}, $orderBy: [DeliverySortField!]) {
  deliveries(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...deliveryProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${DeliveryPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllDeliveriesQuery__
 *
 * To run a query within a React component, call `useGetAllDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDeliveriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllDeliveriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables>(GetAllDeliveriesDocument, options);
      }
export function useGetAllDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables>(GetAllDeliveriesDocument, options);
        }
export type GetAllDeliveriesQueryHookResult = ReturnType<typeof useGetAllDeliveriesQuery>;
export type GetAllDeliveriesLazyQueryHookResult = ReturnType<typeof useGetAllDeliveriesLazyQuery>;
export type GetAllDeliveriesQueryResult = Apollo.QueryResult<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables>;
export const GetDeliveryDocument = gql`
    query getDelivery($id: ID!) {
  delivery(id: $id) {
    ...deliveryProps
    order {
      id
      poNumber
      customer {
        fullName
        email
        contactNumber
        shippingAddress
        billingAddress
        company {
          name
        }
      }
      items {
        id
        description
        unitPrice
        quantity
      }
    }
  }
}
    ${DeliveryPropsFragmentDoc}`;

/**
 * __useGetDeliveryQuery__
 *
 * To run a query within a React component, call `useGetDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeliveryQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryQuery, GetDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryQuery, GetDeliveryQueryVariables>(GetDeliveryDocument, options);
      }
export function useGetDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryQuery, GetDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryQuery, GetDeliveryQueryVariables>(GetDeliveryDocument, options);
        }
export type GetDeliveryQueryHookResult = ReturnType<typeof useGetDeliveryQuery>;
export type GetDeliveryLazyQueryHookResult = ReturnType<typeof useGetDeliveryLazyQuery>;
export type GetDeliveryQueryResult = Apollo.QueryResult<GetDeliveryQuery, GetDeliveryQueryVariables>;
export const CreateDeliveryDocument = gql`
    mutation createDelivery($orderId: Int!) {
  createDelivery(orderId: $orderId) {
    success
    message
    delivery {
      ...deliveryProps
    }
  }
}
    ${DeliveryPropsFragmentDoc}`;
export type CreateDeliveryMutationFn = Apollo.MutationFunction<CreateDeliveryMutation, CreateDeliveryMutationVariables>;

/**
 * __useCreateDeliveryMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryMutation, { data, loading, error }] = useCreateDeliveryMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCreateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryMutation, CreateDeliveryMutationVariables>(CreateDeliveryDocument, options);
      }
export type CreateDeliveryMutationHookResult = ReturnType<typeof useCreateDeliveryMutation>;
export type CreateDeliveryMutationResult = Apollo.MutationResult<CreateDeliveryMutation>;
export type CreateDeliveryMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryMutation, CreateDeliveryMutationVariables>;
export const UpdateDeliveryDocument = gql`
    mutation updateDelivery($id: ID!, $deliverAt: String, $mode: String) {
  updateDelivery(id: $id, input: {deliverAt: $deliverAt, mode: $mode}) {
    success
    message
    delivery {
      ...deliveryProps
    }
  }
}
    ${DeliveryPropsFragmentDoc}`;
export type UpdateDeliveryMutationFn = Apollo.MutationFunction<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;

/**
 * __useUpdateDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryMutation, { data, loading, error }] = useUpdateDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deliverAt: // value for 'deliverAt'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useUpdateDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>(UpdateDeliveryDocument, options);
      }
export type UpdateDeliveryMutationHookResult = ReturnType<typeof useUpdateDeliveryMutation>;
export type UpdateDeliveryMutationResult = Apollo.MutationResult<UpdateDeliveryMutation>;
export type UpdateDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryMutation, UpdateDeliveryMutationVariables>;
export const GetAllSamplesDocument = gql`
    query getAllSamples($page: Int, $perPage: Int, $filter: SampleWhereInput = {}, $orderBy: [SampleSortField!]) {
  samples(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...sampleProps
        product {
          ...productProps
        }
        oligo {
          ...oligoProps
        }
        asOligo {
          ...oligoProps
        }
        ssOligo {
          ...oligoProps
        }
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${SamplePropsFragmentDoc}
${ProductPropsFragmentDoc}
${OligoPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllSamplesQuery__
 *
 * To run a query within a React component, call `useGetAllSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSamplesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllSamplesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSamplesQuery, GetAllSamplesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSamplesQuery, GetAllSamplesQueryVariables>(GetAllSamplesDocument, options);
      }
export function useGetAllSamplesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSamplesQuery, GetAllSamplesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSamplesQuery, GetAllSamplesQueryVariables>(GetAllSamplesDocument, options);
        }
export type GetAllSamplesQueryHookResult = ReturnType<typeof useGetAllSamplesQuery>;
export type GetAllSamplesLazyQueryHookResult = ReturnType<typeof useGetAllSamplesLazyQuery>;
export type GetAllSamplesQueryResult = Apollo.QueryResult<GetAllSamplesQuery, GetAllSamplesQueryVariables>;
export const GetSamplesByProductIdDocument = gql`
    query getSamplesByProductId($productId: Int, $orderId: Int, $search: String) {
  samples(
    filter: {productId: $productId, orderId: $orderId, search: $search}
    orderBy: [createdAt_DESC]
  ) {
    edges {
      node {
        ...sampleProps
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${SamplePropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetSamplesByProductIdQuery__
 *
 * To run a query within a React component, call `useGetSamplesByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSamplesByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSamplesByProductIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      orderId: // value for 'orderId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetSamplesByProductIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSamplesByProductIdQuery, GetSamplesByProductIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSamplesByProductIdQuery, GetSamplesByProductIdQueryVariables>(GetSamplesByProductIdDocument, options);
      }
export function useGetSamplesByProductIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSamplesByProductIdQuery, GetSamplesByProductIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSamplesByProductIdQuery, GetSamplesByProductIdQueryVariables>(GetSamplesByProductIdDocument, options);
        }
export type GetSamplesByProductIdQueryHookResult = ReturnType<typeof useGetSamplesByProductIdQuery>;
export type GetSamplesByProductIdLazyQueryHookResult = ReturnType<typeof useGetSamplesByProductIdLazyQuery>;
export type GetSamplesByProductIdQueryResult = Apollo.QueryResult<GetSamplesByProductIdQuery, GetSamplesByProductIdQueryVariables>;
export const GetSampleDocument = gql`
    query getSample($id: ID!) {
  sample(id: $id) {
    ...sampleProps
    maldi
    product {
      ...productProps
    }
    oligo {
      ...oligoProps
    }
    asOligo {
      ...oligoProps
    }
    ssOligo {
      ...oligoProps
    }
  }
}
    ${SamplePropsFragmentDoc}
${ProductPropsFragmentDoc}
${OligoPropsFragmentDoc}`;

/**
 * __useGetSampleQuery__
 *
 * To run a query within a React component, call `useGetSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSampleQuery(baseOptions: Apollo.QueryHookOptions<GetSampleQuery, GetSampleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSampleQuery, GetSampleQueryVariables>(GetSampleDocument, options);
      }
export function useGetSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSampleQuery, GetSampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSampleQuery, GetSampleQueryVariables>(GetSampleDocument, options);
        }
export type GetSampleQueryHookResult = ReturnType<typeof useGetSampleQuery>;
export type GetSampleLazyQueryHookResult = ReturnType<typeof useGetSampleLazyQuery>;
export type GetSampleQueryResult = Apollo.QueryResult<GetSampleQuery, GetSampleQueryVariables>;
export const CreateSampleDocument = gql`
    mutation createSample($orderItemId: String, $productId: Int, $orderId: Int, $companyId: String, $name: String, $typeName: String!, $oligoId: Int) {
  createSample(
    input: {orderItemId: $orderItemId, productId: $productId, orderId: $orderId, companyId: $companyId, name: $name, typeName: $typeName, oligoId: $oligoId}
  ) {
    success
    message
    sample {
      ...sampleProps
    }
  }
}
    ${SamplePropsFragmentDoc}`;
export type CreateSampleMutationFn = Apollo.MutationFunction<CreateSampleMutation, CreateSampleMutationVariables>;

/**
 * __useCreateSampleMutation__
 *
 * To run a mutation, you first call `useCreateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSampleMutation, { data, loading, error }] = useCreateSampleMutation({
 *   variables: {
 *      orderItemId: // value for 'orderItemId'
 *      productId: // value for 'productId'
 *      orderId: // value for 'orderId'
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      typeName: // value for 'typeName'
 *      oligoId: // value for 'oligoId'
 *   },
 * });
 */
export function useCreateSampleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSampleMutation, CreateSampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSampleMutation, CreateSampleMutationVariables>(CreateSampleDocument, options);
      }
export type CreateSampleMutationHookResult = ReturnType<typeof useCreateSampleMutation>;
export type CreateSampleMutationResult = Apollo.MutationResult<CreateSampleMutation>;
export type CreateSampleMutationOptions = Apollo.BaseMutationOptions<CreateSampleMutation, CreateSampleMutationVariables>;
export const UpdateSampleDocument = gql`
    mutation updateSample($id: ID!, $maldi: Upload) {
  updateSample(id: $id, input: {maldi: $maldi}) {
    success
    message
    sample {
      ...sampleProps
    }
  }
}
    ${SamplePropsFragmentDoc}`;
export type UpdateSampleMutationFn = Apollo.MutationFunction<UpdateSampleMutation, UpdateSampleMutationVariables>;

/**
 * __useUpdateSampleMutation__
 *
 * To run a mutation, you first call `useUpdateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSampleMutation, { data, loading, error }] = useUpdateSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      maldi: // value for 'maldi'
 *   },
 * });
 */
export function useUpdateSampleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSampleMutation, UpdateSampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSampleMutation, UpdateSampleMutationVariables>(UpdateSampleDocument, options);
      }
export type UpdateSampleMutationHookResult = ReturnType<typeof useUpdateSampleMutation>;
export type UpdateSampleMutationResult = Apollo.MutationResult<UpdateSampleMutation>;
export type UpdateSampleMutationOptions = Apollo.BaseMutationOptions<UpdateSampleMutation, UpdateSampleMutationVariables>;
export const GetAllSkusDocument = gql`
    query getAllSkus($page: Int, $perPage: Int, $filter: SkuWhereInput = {}, $orderBy: [SkuSortField!]) {
  skus(page: $page, perPage: $perPage, filter: $filter, orderBy: $orderBy) {
    edges {
      node {
        ...skuProps
        createdAt
        updatedAt
      }
      cursor
    }
    pageInfo {
      ...pageInfoProps
    }
    totalCount
    filteredCount
    pageCount
    currentPage
  }
}
    ${SkuPropsFragmentDoc}
${PageInfoPropsFragmentDoc}`;

/**
 * __useGetAllSkusQuery__
 *
 * To run a query within a React component, call `useGetAllSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSkusQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllSkusQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSkusQuery, GetAllSkusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSkusQuery, GetAllSkusQueryVariables>(GetAllSkusDocument, options);
      }
export function useGetAllSkusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSkusQuery, GetAllSkusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSkusQuery, GetAllSkusQueryVariables>(GetAllSkusDocument, options);
        }
export type GetAllSkusQueryHookResult = ReturnType<typeof useGetAllSkusQuery>;
export type GetAllSkusLazyQueryHookResult = ReturnType<typeof useGetAllSkusLazyQuery>;
export type GetAllSkusQueryResult = Apollo.QueryResult<GetAllSkusQuery, GetAllSkusQueryVariables>;
export const UpdateSkuQuantityBiopDocument = gql`
    mutation updateSkuQuantityBiop($id: ID!, $quantity: Int!) {
  updateSkuQuantityBiop(input: {id: $id, quantity: $quantity}) {
    success
    message
    sku {
      id
      quantityBiop
      updatedAt
      updatedBy {
        id
        username
      }
    }
  }
}
    `;
export type UpdateSkuQuantityBiopMutationFn = Apollo.MutationFunction<UpdateSkuQuantityBiopMutation, UpdateSkuQuantityBiopMutationVariables>;

/**
 * __useUpdateSkuQuantityBiopMutation__
 *
 * To run a mutation, you first call `useUpdateSkuQuantityBiopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuQuantityBiopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuQuantityBiopMutation, { data, loading, error }] = useUpdateSkuQuantityBiopMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateSkuQuantityBiopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkuQuantityBiopMutation, UpdateSkuQuantityBiopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkuQuantityBiopMutation, UpdateSkuQuantityBiopMutationVariables>(UpdateSkuQuantityBiopDocument, options);
      }
export type UpdateSkuQuantityBiopMutationHookResult = ReturnType<typeof useUpdateSkuQuantityBiopMutation>;
export type UpdateSkuQuantityBiopMutationResult = Apollo.MutationResult<UpdateSkuQuantityBiopMutation>;
export type UpdateSkuQuantityBiopMutationOptions = Apollo.BaseMutationOptions<UpdateSkuQuantityBiopMutation, UpdateSkuQuantityBiopMutationVariables>;
export const UpdateSkuQuantityNasbioDocument = gql`
    mutation updateSkuQuantityNasbio($id: ID!, $quantity: Int!) {
  updateSkuQuantityNasbio(input: {id: $id, quantity: $quantity}) {
    success
    message
    sku {
      id
      quantityNasbio
      updatedAt
      updatedBy {
        id
        username
      }
    }
  }
}
    `;
export type UpdateSkuQuantityNasbioMutationFn = Apollo.MutationFunction<UpdateSkuQuantityNasbioMutation, UpdateSkuQuantityNasbioMutationVariables>;

/**
 * __useUpdateSkuQuantityNasbioMutation__
 *
 * To run a mutation, you first call `useUpdateSkuQuantityNasbioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuQuantityNasbioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuQuantityNasbioMutation, { data, loading, error }] = useUpdateSkuQuantityNasbioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateSkuQuantityNasbioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkuQuantityNasbioMutation, UpdateSkuQuantityNasbioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkuQuantityNasbioMutation, UpdateSkuQuantityNasbioMutationVariables>(UpdateSkuQuantityNasbioDocument, options);
      }
export type UpdateSkuQuantityNasbioMutationHookResult = ReturnType<typeof useUpdateSkuQuantityNasbioMutation>;
export type UpdateSkuQuantityNasbioMutationResult = Apollo.MutationResult<UpdateSkuQuantityNasbioMutation>;
export type UpdateSkuQuantityNasbioMutationOptions = Apollo.BaseMutationOptions<UpdateSkuQuantityNasbioMutation, UpdateSkuQuantityNasbioMutationVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Amidite: ResolverTypeWrapper<Amidite>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  AmiditeConnection: ResolverTypeWrapper<AmiditeConnection>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AmiditeEdge: ResolverTypeWrapper<AmiditeEdge>;
  AmiditeSortField: AmiditeSortField;
  AmiditeWhereInput: AmiditeWhereInput;
  AntisenseCartItem: ResolverTypeWrapper<AntisenseCartItem>;
  AntisenseSequence: ResolverTypeWrapper<AntisenseSequence>;
  AntisenseSequenceConnection: ResolverTypeWrapper<AntisenseSequenceConnection>;
  AntisenseSequenceEdge: ResolverTypeWrapper<AntisenseSequenceEdge>;
  AntisenseSequenceSortField: AntisenseSequenceSortField;
  AntisenseSequenceWhereInput: AntisenseSequenceWhereInput;
  AntisenseSeries: ResolverTypeWrapper<AntisenseSeries>;
  Assembly: ResolverTypeWrapper<Assembly>;
  AssemblyConnection: ResolverTypeWrapper<AssemblyConnection>;
  AssemblyEdge: ResolverTypeWrapper<AssemblyEdge>;
  AssemblySortField: AssemblySortField;
  AssemblyWhereInput: AssemblyWhereInput;
  Backbone: ResolverTypeWrapper<Backbone>;
  BackboneConnection: ResolverTypeWrapper<BackboneConnection>;
  BackboneEdge: ResolverTypeWrapper<BackboneEdge>;
  BackboneWhereInput: BackboneWhereInput;
  Base: ResolverTypeWrapper<Base>;
  BaseConnection: ResolverTypeWrapper<BaseConnection>;
  BaseEdge: ResolverTypeWrapper<BaseEdge>;
  BaseWhereInput: BaseWhereInput;
  BuildingBlockSortField: BuildingBlockSortField;
  ChemicalReagent: ResolverTypeWrapper<ChemicalReagent>;
  Chromosome: ResolverTypeWrapper<Chromosome>;
  ChromosomeConnection: ResolverTypeWrapper<ChromosomeConnection>;
  ChromosomeEdge: ResolverTypeWrapper<ChromosomeEdge>;
  ChromosomeSortField: ChromosomeSortField;
  ChromosomeWhereInput: ChromosomeWhereInput;
  Company: ResolverTypeWrapper<Company>;
  CompanyConnection: ResolverTypeWrapper<CompanyConnection>;
  CompanyEdge: ResolverTypeWrapper<CompanyEdge>;
  CompanySortField: CompanySortField;
  CompanyWhereInput: CompanyWhereInput;
  Coupling: ResolverTypeWrapper<Coupling>;
  CouplingCompositionInput: CouplingCompositionInput;
  CouplingConnection: ResolverTypeWrapper<CouplingConnection>;
  CouplingEdge: ResolverTypeWrapper<CouplingEdge>;
  CouplingWhereInput: CouplingWhereInput;
  CreateAntisenseSeriesInput: CreateAntisenseSeriesInput;
  CreateAntisenseSeriesResponse: ResolverTypeWrapper<CreateAntisenseSeriesResponse>;
  CreateAsoInput: CreateAsoInput;
  CreateAsosResponse: ResolverTypeWrapper<CreateAsosResponse>;
  CreateCompanyInput: CreateCompanyInput;
  CreateCompanyResponse: ResolverTypeWrapper<CreateCompanyResponse>;
  CreateCustomerInput: CreateCustomerInput;
  CreateCustomerResponse: ResolverTypeWrapper<CreateCustomerResponse>;
  CreateDeliveryResponse: ResolverTypeWrapper<CreateDeliveryResponse>;
  CreateOligoInput: CreateOligoInput;
  CreateOligoResponse: ResolverTypeWrapper<CreateOligoResponse>;
  CreateOrderInput: CreateOrderInput;
  CreateOrderResponse: ResolverTypeWrapper<CreateOrderResponse>;
  CreateProductInput: CreateProductInput;
  CreateProductResponse: ResolverTypeWrapper<CreateProductResponse>;
  CreateSampleInput: CreateSampleInput;
  CreateSampleResponse: ResolverTypeWrapper<CreateSampleResponse>;
  CreateSirnaSeriesInput: CreateSirnaSeriesInput;
  CreateSirnaSeriesResponse: ResolverTypeWrapper<CreateSirnaSeriesResponse>;
  CreateTargetInput: CreateTargetInput;
  CreateTargetResponse: ResolverTypeWrapper<CreateTargetResponse>;
  CreateUserInput: CreateUserInput;
  CreateUserResponse: ResolverTypeWrapper<CreateUserResponse>;
  Customer: ResolverTypeWrapper<Customer>;
  CustomerConnection: ResolverTypeWrapper<CustomerConnection>;
  CustomerEdge: ResolverTypeWrapper<CustomerEdge>;
  CustomerSortField: CustomerSortField;
  CustomerWhereInput: CustomerWhereInput;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Decimal: ResolverTypeWrapper<Scalars['Decimal']>;
  Delivery: ResolverTypeWrapper<Delivery>;
  DeliveryConnection: ResolverTypeWrapper<DeliveryConnection>;
  DeliveryEdge: ResolverTypeWrapper<DeliveryEdge>;
  DeliverySortField: DeliverySortField;
  DeliveryWhereInput: DeliveryWhereInput;
  Gene: ResolverTypeWrapper<Gene>;
  GeneMapping: ResolverTypeWrapper<GeneMapping>;
  HardwareReagent: ResolverTypeWrapper<HardwareReagent>;
  Modification: ResolverTypeWrapper<Modification>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationResponse: ResolversTypes['CreateAntisenseSeriesResponse'] | ResolversTypes['CreateAsosResponse'] | ResolversTypes['CreateCompanyResponse'] | ResolversTypes['CreateCustomerResponse'] | ResolversTypes['CreateDeliveryResponse'] | ResolversTypes['CreateOligoResponse'] | ResolversTypes['CreateOrderResponse'] | ResolversTypes['CreateProductResponse'] | ResolversTypes['CreateSampleResponse'] | ResolversTypes['CreateSirnaSeriesResponse'] | ResolversTypes['CreateTargetResponse'] | ResolversTypes['UpdateDeliveryResponse'] | ResolversTypes['UpdateSampleResponse'] | ResolversTypes['UpdateSkuQuantityResponse'];
  Nucleoside: ResolverTypeWrapper<Nucleoside>;
  NucleosideConnection: ResolverTypeWrapper<NucleosideConnection>;
  NucleosideEdge: ResolverTypeWrapper<NucleosideEdge>;
  NucleosideWhereInput: NucleosideWhereInput;
  Oligo: ResolverTypeWrapper<Oligo>;
  OligoConnection: ResolverTypeWrapper<OligoConnection>;
  OligoEdge: ResolverTypeWrapper<OligoEdge>;
  OligoReagent: ResolverTypeWrapper<OligoReagent>;
  OligoReagentConnection: ResolverTypeWrapper<OligoReagentConnection>;
  OligoReagentEdge: ResolverTypeWrapper<OligoReagentEdge>;
  OligoReagentSortField: OligoReagentSortField;
  OligoReagentWhereInput: OligoReagentWhereInput;
  OligoSortField: OligoSortField;
  OligoWhereInput: OligoWhereInput;
  Order: ResolverTypeWrapper<Order>;
  OrderConnection: ResolverTypeWrapper<OrderConnection>;
  OrderEdge: ResolverTypeWrapper<OrderEdge>;
  OrderItem: ResolverTypeWrapper<OrderItem>;
  OrderSortField: OrderSortField;
  OrderStatusSelect: OrderStatusSelect;
  OrderType: ResolverTypeWrapper<OrderType>;
  OrderTypeConnection: ResolverTypeWrapper<OrderTypeConnection>;
  OrderTypeEdge: ResolverTypeWrapper<OrderTypeEdge>;
  OrderTypeSortField: OrderTypeSortField;
  OrderTypeWhereInput: OrderTypeWhereInput;
  OrderWhereInput: OrderWhereInput;
  Organism: ResolverTypeWrapper<Organism>;
  OrganismConnection: ResolverTypeWrapper<OrganismConnection>;
  OrganismEdge: ResolverTypeWrapper<OrganismEdge>;
  OrganismSelect: OrganismSelect;
  OrganismSortField: OrganismSortField;
  OrganismWhereInput: OrganismWhereInput;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PeptideReagent: ResolverTypeWrapper<PeptideReagent>;
  Product: ResolverTypeWrapper<Product>;
  ProductConnection: ResolverTypeWrapper<ProductConnection>;
  ProductEdge: ResolverTypeWrapper<ProductEdge>;
  ProductSortField: ProductSortField;
  ProductType: ResolverTypeWrapper<ProductType>;
  ProductTypeConnection: ResolverTypeWrapper<ProductTypeConnection>;
  ProductTypeEdge: ResolverTypeWrapper<ProductTypeEdge>;
  ProductTypeSortField: ProductTypeSortField;
  ProductTypeWhereInput: ProductTypeWhereInput;
  ProductWhereInput: ProductWhereInput;
  Protein: ResolverTypeWrapper<Protein>;
  ProteinMapping: ResolverTypeWrapper<ProteinMapping>;
  PurchaseOrder: ResolverTypeWrapper<PurchaseOrder>;
  Query: ResolverTypeWrapper<{}>;
  Rna: ResolverTypeWrapper<Rna>;
  RnaConnection: ResolverTypeWrapper<RnaConnection>;
  RnaEdge: ResolverTypeWrapper<RnaEdge>;
  RnaMapping: ResolverTypeWrapper<RnaMapping>;
  RnaSearchInput: RnaSearchInput;
  RnaSortField: RnaSortField;
  RnaWhereInput: RnaWhereInput;
  Sample: ResolverTypeWrapper<Sample>;
  SampleCartItem: ResolverTypeWrapper<SampleCartItem>;
  SampleConnection: ResolverTypeWrapper<SampleConnection>;
  SampleEdge: ResolverTypeWrapper<SampleEdge>;
  SampleSortField: SampleSortField;
  SampleStatusSelect: SampleStatusSelect;
  SampleType: ResolverTypeWrapper<SampleType>;
  SampleWhereInput: SampleWhereInput;
  SingleTargetInput: SingleTargetInput;
  SirnaCartItem: ResolverTypeWrapper<SirnaCartItem>;
  SirnaDuplex: ResolverTypeWrapper<SirnaDuplex>;
  SirnaDuplexConnection: ResolverTypeWrapper<SirnaDuplexConnection>;
  SirnaDuplexEdge: ResolverTypeWrapper<SirnaDuplexEdge>;
  SirnaDuplexSortField: SirnaDuplexSortField;
  SirnaDuplexWhereInput: SirnaDuplexWhereInput;
  SirnaSeries: ResolverTypeWrapper<SirnaSeries>;
  Sku: ResolverTypeWrapper<Sku>;
  SkuConnection: ResolverTypeWrapper<SkuConnection>;
  SkuEdge: ResolverTypeWrapper<SkuEdge>;
  SkuSortField: SkuSortField;
  SkuWhereInput: SkuWhereInput;
  SolventReagent: ResolverTypeWrapper<SolventReagent>;
  Structure: ResolverTypeWrapper<Structure>;
  Sugar: ResolverTypeWrapper<Sugar>;
  SugarConnection: ResolverTypeWrapper<SugarConnection>;
  SugarEdge: ResolverTypeWrapper<SugarEdge>;
  SugarWhereInput: SugarWhereInput;
  Target: ResolverTypeWrapper<Target>;
  TargetConnection: ResolverTypeWrapper<TargetConnection>;
  TargetEdge: ResolverTypeWrapper<TargetEdge>;
  TargetSearchInput: TargetSearchInput;
  TargetSortField: TargetSortField;
  TargetWhereInput: TargetWhereInput;
  UpdateDeliveryInput: UpdateDeliveryInput;
  UpdateDeliveryResponse: ResolverTypeWrapper<UpdateDeliveryResponse>;
  UpdateSampleInput: UpdateSampleInput;
  UpdateSampleResponse: ResolverTypeWrapper<UpdateSampleResponse>;
  UpdateSkuQuantityInput: UpdateSkuQuantityInput;
  UpdateSkuQuantityResponse: ResolverTypeWrapper<UpdateSkuQuantityResponse>;
  UpdateUserEmailInput: UpdateUserEmailInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  UpdateUserPasswordResponse: ResolverTypeWrapper<UpdateUserPasswordResponse>;
  UpdateUserResponse: ResolverTypeWrapper<UpdateUserResponse>;
  UpdateUserUsernameInput: UpdateUserUsernameInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  User: ResolverTypeWrapper<User>;
  UserConnection: ResolverTypeWrapper<UserConnection>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserSortField: UserSortField;
  UserWhereInput: UserWhereInput;
  datetime: ResolverTypeWrapper<Scalars['datetime']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Amidite: Amidite;
  String: Scalars['String'];
  Float: Scalars['Float'];
  Boolean: Scalars['Boolean'];
  ID: Scalars['ID'];
  AmiditeConnection: AmiditeConnection;
  Int: Scalars['Int'];
  AmiditeEdge: AmiditeEdge;
  AmiditeWhereInput: AmiditeWhereInput;
  AntisenseCartItem: AntisenseCartItem;
  AntisenseSequence: AntisenseSequence;
  AntisenseSequenceConnection: AntisenseSequenceConnection;
  AntisenseSequenceEdge: AntisenseSequenceEdge;
  AntisenseSequenceWhereInput: AntisenseSequenceWhereInput;
  AntisenseSeries: AntisenseSeries;
  Assembly: Assembly;
  AssemblyConnection: AssemblyConnection;
  AssemblyEdge: AssemblyEdge;
  AssemblyWhereInput: AssemblyWhereInput;
  Backbone: Backbone;
  BackboneConnection: BackboneConnection;
  BackboneEdge: BackboneEdge;
  BackboneWhereInput: BackboneWhereInput;
  Base: Base;
  BaseConnection: BaseConnection;
  BaseEdge: BaseEdge;
  BaseWhereInput: BaseWhereInput;
  ChemicalReagent: ChemicalReagent;
  Chromosome: Chromosome;
  ChromosomeConnection: ChromosomeConnection;
  ChromosomeEdge: ChromosomeEdge;
  ChromosomeWhereInput: ChromosomeWhereInput;
  Company: Company;
  CompanyConnection: CompanyConnection;
  CompanyEdge: CompanyEdge;
  CompanyWhereInput: CompanyWhereInput;
  Coupling: Coupling;
  CouplingCompositionInput: CouplingCompositionInput;
  CouplingConnection: CouplingConnection;
  CouplingEdge: CouplingEdge;
  CouplingWhereInput: CouplingWhereInput;
  CreateAntisenseSeriesInput: CreateAntisenseSeriesInput;
  CreateAntisenseSeriesResponse: CreateAntisenseSeriesResponse;
  CreateAsoInput: CreateAsoInput;
  CreateAsosResponse: CreateAsosResponse;
  CreateCompanyInput: CreateCompanyInput;
  CreateCompanyResponse: CreateCompanyResponse;
  CreateCustomerInput: CreateCustomerInput;
  CreateCustomerResponse: CreateCustomerResponse;
  CreateDeliveryResponse: CreateDeliveryResponse;
  CreateOligoInput: CreateOligoInput;
  CreateOligoResponse: CreateOligoResponse;
  CreateOrderInput: CreateOrderInput;
  CreateOrderResponse: CreateOrderResponse;
  CreateProductInput: CreateProductInput;
  CreateProductResponse: CreateProductResponse;
  CreateSampleInput: CreateSampleInput;
  CreateSampleResponse: CreateSampleResponse;
  CreateSirnaSeriesInput: CreateSirnaSeriesInput;
  CreateSirnaSeriesResponse: CreateSirnaSeriesResponse;
  CreateTargetInput: CreateTargetInput;
  CreateTargetResponse: CreateTargetResponse;
  CreateUserInput: CreateUserInput;
  CreateUserResponse: CreateUserResponse;
  Customer: Customer;
  CustomerConnection: CustomerConnection;
  CustomerEdge: CustomerEdge;
  CustomerWhereInput: CustomerWhereInput;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  Decimal: Scalars['Decimal'];
  Delivery: Delivery;
  DeliveryConnection: DeliveryConnection;
  DeliveryEdge: DeliveryEdge;
  DeliveryWhereInput: DeliveryWhereInput;
  Gene: Gene;
  GeneMapping: GeneMapping;
  HardwareReagent: HardwareReagent;
  Modification: Modification;
  Mutation: {};
  MutationResponse: ResolversParentTypes['CreateAntisenseSeriesResponse'] | ResolversParentTypes['CreateAsosResponse'] | ResolversParentTypes['CreateCompanyResponse'] | ResolversParentTypes['CreateCustomerResponse'] | ResolversParentTypes['CreateDeliveryResponse'] | ResolversParentTypes['CreateOligoResponse'] | ResolversParentTypes['CreateOrderResponse'] | ResolversParentTypes['CreateProductResponse'] | ResolversParentTypes['CreateSampleResponse'] | ResolversParentTypes['CreateSirnaSeriesResponse'] | ResolversParentTypes['CreateTargetResponse'] | ResolversParentTypes['UpdateDeliveryResponse'] | ResolversParentTypes['UpdateSampleResponse'] | ResolversParentTypes['UpdateSkuQuantityResponse'];
  Nucleoside: Nucleoside;
  NucleosideConnection: NucleosideConnection;
  NucleosideEdge: NucleosideEdge;
  NucleosideWhereInput: NucleosideWhereInput;
  Oligo: Oligo;
  OligoConnection: OligoConnection;
  OligoEdge: OligoEdge;
  OligoReagent: OligoReagent;
  OligoReagentConnection: OligoReagentConnection;
  OligoReagentEdge: OligoReagentEdge;
  OligoReagentWhereInput: OligoReagentWhereInput;
  OligoWhereInput: OligoWhereInput;
  Order: Order;
  OrderConnection: OrderConnection;
  OrderEdge: OrderEdge;
  OrderItem: OrderItem;
  OrderType: OrderType;
  OrderTypeConnection: OrderTypeConnection;
  OrderTypeEdge: OrderTypeEdge;
  OrderTypeWhereInput: OrderTypeWhereInput;
  OrderWhereInput: OrderWhereInput;
  Organism: Organism;
  OrganismConnection: OrganismConnection;
  OrganismEdge: OrganismEdge;
  OrganismWhereInput: OrganismWhereInput;
  PageInfo: PageInfo;
  PeptideReagent: PeptideReagent;
  Product: Product;
  ProductConnection: ProductConnection;
  ProductEdge: ProductEdge;
  ProductType: ProductType;
  ProductTypeConnection: ProductTypeConnection;
  ProductTypeEdge: ProductTypeEdge;
  ProductTypeWhereInput: ProductTypeWhereInput;
  ProductWhereInput: ProductWhereInput;
  Protein: Protein;
  ProteinMapping: ProteinMapping;
  PurchaseOrder: PurchaseOrder;
  Query: {};
  Rna: Rna;
  RnaConnection: RnaConnection;
  RnaEdge: RnaEdge;
  RnaMapping: RnaMapping;
  RnaSearchInput: RnaSearchInput;
  RnaWhereInput: RnaWhereInput;
  Sample: Sample;
  SampleCartItem: SampleCartItem;
  SampleConnection: SampleConnection;
  SampleEdge: SampleEdge;
  SampleType: SampleType;
  SampleWhereInput: SampleWhereInput;
  SingleTargetInput: SingleTargetInput;
  SirnaCartItem: SirnaCartItem;
  SirnaDuplex: SirnaDuplex;
  SirnaDuplexConnection: SirnaDuplexConnection;
  SirnaDuplexEdge: SirnaDuplexEdge;
  SirnaDuplexWhereInput: SirnaDuplexWhereInput;
  SirnaSeries: SirnaSeries;
  Sku: Sku;
  SkuConnection: SkuConnection;
  SkuEdge: SkuEdge;
  SkuWhereInput: SkuWhereInput;
  SolventReagent: SolventReagent;
  Structure: Structure;
  Sugar: Sugar;
  SugarConnection: SugarConnection;
  SugarEdge: SugarEdge;
  SugarWhereInput: SugarWhereInput;
  Target: Target;
  TargetConnection: TargetConnection;
  TargetEdge: TargetEdge;
  TargetSearchInput: TargetSearchInput;
  TargetWhereInput: TargetWhereInput;
  UpdateDeliveryInput: UpdateDeliveryInput;
  UpdateDeliveryResponse: UpdateDeliveryResponse;
  UpdateSampleInput: UpdateSampleInput;
  UpdateSampleResponse: UpdateSampleResponse;
  UpdateSkuQuantityInput: UpdateSkuQuantityInput;
  UpdateSkuQuantityResponse: UpdateSkuQuantityResponse;
  UpdateUserEmailInput: UpdateUserEmailInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  UpdateUserPasswordResponse: UpdateUserPasswordResponse;
  UpdateUserResponse: UpdateUserResponse;
  UpdateUserUsernameInput: UpdateUserUsernameInput;
  Upload: Scalars['Upload'];
  User: User;
  UserConnection: UserConnection;
  UserEdge: UserEdge;
  UserWhereInput: UserWhereInput;
  datetime: Scalars['datetime'];
};

export type AmiditeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Amidite'] = ResolversParentTypes['Amidite']> = {
  casNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  formulaWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hasDmt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  molecularWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nucleoside?: Resolver<Maybe<ResolversTypes['Nucleoside']>, ParentType, ContextType>;
  nucleosideId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmiditeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmiditeConnection'] = ResolversParentTypes['AmiditeConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AmiditeEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmiditeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmiditeEdge'] = ResolversParentTypes['AmiditeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Amidite'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntisenseCartItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AntisenseCartItem'] = ResolversParentTypes['AntisenseCartItem']> = {
  antisenseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntisenseSequenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AntisenseSequence'] = ResolversParentTypes['AntisenseSequence']> = {
  after5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  after10?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  after20?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  after40?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  atContent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  back8?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  before5?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  before10?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  before20?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  before40?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blastMatches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blastNextBest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blastNextBestCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blastNextBestIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  blastNextBestLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cpgCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exonId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  front8?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gcContent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hairpin?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  homopolymer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intronId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isInCart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mid8?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mid16?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  offTargets?: Resolver<Maybe<Array<Maybe<ResolversTypes['Rna']>>>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seq?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['AntisenseSeries']>, ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType>;
  targetSeq?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tm?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntisenseSequenceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AntisenseSequenceConnection'] = ResolversParentTypes['AntisenseSequenceConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AntisenseSequenceEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntisenseSequenceEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AntisenseSequenceEdge'] = ResolversParentTypes['AntisenseSequenceEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AntisenseSequence'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntisenseSeriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AntisenseSeries'] = ResolversParentTypes['AntisenseSeries']> = {
  antisenseSequences?: Resolver<Maybe<Array<ResolversTypes['AntisenseSequence']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssemblyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Assembly'] = ResolversParentTypes['Assembly']> = {
  acc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chromosomes?: Resolver<Maybe<Array<ResolversTypes['Chromosome']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType>;
  synonym?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssemblyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssemblyConnection'] = ResolversParentTypes['AssemblyConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AssemblyEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssemblyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssemblyEdge'] = ResolversParentTypes['AssemblyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Assembly'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackboneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Backbone'] = ResolversParentTypes['Backbone']> = {
  backTerminating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackboneConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BackboneConnection'] = ResolversParentTypes['BackboneConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BackboneEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BackboneEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BackboneEdge'] = ResolversParentTypes['BackboneEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Backbone'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Base'] = ResolversParentTypes['Base']> = {
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  derivatives?: Resolver<Maybe<Array<ResolversTypes['Base']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ec260Correction?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAromatic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseConnection'] = ResolversParentTypes['BaseConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BaseEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseEdge'] = ResolversParentTypes['BaseEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Base'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChemicalReagentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChemicalReagent'] = ResolversParentTypes['ChemicalReagent']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromosomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Chromosome'] = ResolversParentTypes['Chromosome']> = {
  acc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assembly?: Resolver<Maybe<ResolversTypes['Assembly']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromosomeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromosomeConnection'] = ResolversParentTypes['ChromosomeConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ChromosomeEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChromosomeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChromosomeEdge'] = ResolversParentTypes['ChromosomeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Chromosome'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  customers?: Resolver<Maybe<Array<ResolversTypes['Customer']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyConnection'] = ResolversParentTypes['CompanyConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CompanyEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyEdge'] = ResolversParentTypes['CompanyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Company'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouplingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coupling'] = ResolversParentTypes['Coupling']> = {
  backTerminating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  backbone?: Resolver<Maybe<ResolversTypes['Backbone']>, ParentType, ContextType>;
  backboneId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType>;
  baseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sugar?: Resolver<Maybe<ResolversTypes['Sugar']>, ParentType, ContextType>;
  sugarId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouplingConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouplingConnection'] = ResolversParentTypes['CouplingConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CouplingEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouplingEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouplingEdge'] = ResolversParentTypes['CouplingEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Coupling'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAntisenseSeriesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAntisenseSeriesResponse'] = ResolversParentTypes['CreateAntisenseSeriesResponse']> = {
  antisenseSeries?: Resolver<Maybe<ResolversTypes['AntisenseSeries']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAsosResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAsosResponse'] = ResolversParentTypes['CreateAsosResponse']> = {
  asos?: Resolver<Array<ResolversTypes['Oligo']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCompanyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCompanyResponse'] = ResolversParentTypes['CreateCompanyResponse']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCustomerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCustomerResponse'] = ResolversParentTypes['CreateCustomerResponse']> = {
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDeliveryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateDeliveryResponse'] = ResolversParentTypes['CreateDeliveryResponse']> = {
  delivery?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOligoResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOligoResponse'] = ResolversParentTypes['CreateOligoResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOrderResponse'] = ResolversParentTypes['CreateOrderResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProductResponse'] = ResolversParentTypes['CreateProductResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSampleResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSampleResponse'] = ResolversParentTypes['CreateSampleResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sample?: Resolver<Maybe<ResolversTypes['Sample']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSirnaSeriesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSirnaSeriesResponse'] = ResolversParentTypes['CreateSirnaSeriesResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sirnaSeries?: Resolver<Maybe<ResolversTypes['SirnaSeries']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTargetResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTargetResponse'] = ResolversParentTypes['CreateTargetResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUserResponse'] = ResolversParentTypes['CreateUserResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  billingAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>;
  contactNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orders?: Resolver<Maybe<Array<ResolversTypes['Order']>>, ParentType, ContextType>;
  shippingAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConnection'] = ResolversParentTypes['CustomerConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CustomerEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerEdge'] = ResolversParentTypes['CustomerEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal';
}

export type DeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Delivery'] = ResolversParentTypes['Delivery']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  deliverAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryConnection'] = ResolversParentTypes['DeliveryConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DeliveryEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeliveryEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeliveryEdge'] = ResolversParentTypes['DeliveryEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Delivery'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gene'] = ResolversParentTypes['Gene']> = {
  aliases?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ensgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loci?: Resolver<Maybe<Array<ResolversTypes['GeneMapping']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType>;
  proteins?: Resolver<Maybe<Array<ResolversTypes['Protein']>>, ParentType, ContextType>;
  rnas?: Resolver<Maybe<Array<ResolversTypes['Rna']>>, ParentType, ContextType>;
  rsgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  synonyms?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneMapping'] = ResolversParentTypes['GeneMapping']> = {
  band?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chrNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gene?: Resolver<ResolversTypes['Gene'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  strand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HardwareReagentResolvers<ContextType = any, ParentType extends ResolversParentTypes['HardwareReagent'] = ResolversParentTypes['HardwareReagent']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Modification'] = ResolversParentTypes['Modification']> = {
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idtSymbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placement3?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  placement5?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createAntisenseSeries?: Resolver<ResolversTypes['CreateAntisenseSeriesResponse'], ParentType, ContextType, RequireFields<MutationCreateAntisenseSeriesArgs, 'input'>>;
  createAsos?: Resolver<ResolversTypes['CreateAsosResponse'], ParentType, ContextType, RequireFields<MutationCreateAsosArgs, 'asos'>>;
  createCompany?: Resolver<ResolversTypes['CreateCompanyResponse'], ParentType, ContextType, RequireFields<MutationCreateCompanyArgs, 'input'>>;
  createCustomer?: Resolver<ResolversTypes['CreateCustomerResponse'], ParentType, ContextType, RequireFields<MutationCreateCustomerArgs, 'input'>>;
  createDelivery?: Resolver<ResolversTypes['CreateDeliveryResponse'], ParentType, ContextType, RequireFields<MutationCreateDeliveryArgs, 'orderId'>>;
  createOligo?: Resolver<ResolversTypes['CreateOligoResponse'], ParentType, ContextType, RequireFields<MutationCreateOligoArgs, 'input'>>;
  createOrder?: Resolver<ResolversTypes['CreateOrderResponse'], ParentType, ContextType, RequireFields<MutationCreateOrderArgs, 'input'>>;
  createProduct?: Resolver<ResolversTypes['CreateProductResponse'], ParentType, ContextType, RequireFields<MutationCreateProductArgs, 'input'>>;
  createSample?: Resolver<ResolversTypes['CreateSampleResponse'], ParentType, ContextType, RequireFields<MutationCreateSampleArgs, 'input'>>;
  createSirnaSeries?: Resolver<ResolversTypes['CreateSirnaSeriesResponse'], ParentType, ContextType, RequireFields<MutationCreateSirnaSeriesArgs, 'input'>>;
  createTarget?: Resolver<ResolversTypes['CreateTargetResponse'], ParentType, ContextType, RequireFields<MutationCreateTargetArgs, 'input'>>;
  createUser?: Resolver<ResolversTypes['CreateUserResponse'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'id'>>;
  updateDelivery?: Resolver<ResolversTypes['UpdateDeliveryResponse'], ParentType, ContextType, RequireFields<MutationUpdateDeliveryArgs, 'id' | 'input'>>;
  updateSample?: Resolver<ResolversTypes['UpdateSampleResponse'], ParentType, ContextType, RequireFields<MutationUpdateSampleArgs, 'id' | 'input'>>;
  updateSkuQuantityBiop?: Resolver<ResolversTypes['UpdateSkuQuantityResponse'], ParentType, ContextType, RequireFields<MutationUpdateSkuQuantityBiopArgs, 'input'>>;
  updateSkuQuantityNasbio?: Resolver<ResolversTypes['UpdateSkuQuantityResponse'], ParentType, ContextType, RequireFields<MutationUpdateSkuQuantityNasbioArgs, 'input'>>;
  updateUserAdmin?: Resolver<ResolversTypes['UpdateUserResponse'], ParentType, ContextType, RequireFields<MutationUpdateUserAdminArgs, 'id'>>;
  updateUserBlocked?: Resolver<ResolversTypes['UpdateUserResponse'], ParentType, ContextType, RequireFields<MutationUpdateUserBlockedArgs, 'id'>>;
  updateUserEmail?: Resolver<ResolversTypes['UpdateUserResponse'], ParentType, ContextType, RequireFields<MutationUpdateUserEmailArgs, 'input'>>;
  updateUserPassword?: Resolver<ResolversTypes['UpdateUserPasswordResponse'], ParentType, ContextType, RequireFields<MutationUpdateUserPasswordArgs, 'input'>>;
  updateUserUsername?: Resolver<ResolversTypes['UpdateUserResponse'], ParentType, ContextType, RequireFields<MutationUpdateUserUsernameArgs, 'input'>>;
};

export type MutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationResponse'] = ResolversParentTypes['MutationResponse']> = {
  __resolveType: TypeResolveFn<'CreateAntisenseSeriesResponse' | 'CreateAsosResponse' | 'CreateCompanyResponse' | 'CreateCustomerResponse' | 'CreateDeliveryResponse' | 'CreateOligoResponse' | 'CreateOrderResponse' | 'CreateProductResponse' | 'CreateSampleResponse' | 'CreateSirnaSeriesResponse' | 'CreateTargetResponse' | 'UpdateDeliveryResponse' | 'UpdateSampleResponse' | 'UpdateSkuQuantityResponse', ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type NucleosideResolvers<ContextType = any, ParentType extends ResolversParentTypes['Nucleoside'] = ResolversParentTypes['Nucleoside']> = {
  backTerminating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType>;
  baseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idtSymbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sugar?: Resolver<Maybe<ResolversTypes['Sugar']>, ParentType, ContextType>;
  sugarId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  svg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NucleosideConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['NucleosideConnection'] = ResolversParentTypes['NucleosideConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['NucleosideEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NucleosideEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NucleosideEdge'] = ResolversParentTypes['NucleosideEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Nucleoside'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Oligo'] = ResolversParentTypes['Oligo']> = {
  aliases?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  antisenseId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bbIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  coreSeq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cpIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  ec260?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  idtSeq?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  isSirnaAntisense?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mod3?: Resolver<Maybe<ResolversTypes['Modification']>, ParentType, ContextType>;
  mod5?: Resolver<Maybe<ResolversTypes['Modification']>, ParentType, ContextType>;
  mods?: Resolver<Maybe<Array<ResolversTypes['Modification']>>, ParentType, ContextType>;
  modsPos?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<Maybe<Array<ResolversTypes['Product']>>, ParentType, ContextType>;
  samples?: Resolver<Maybe<Array<ResolversTypes['Sample']>>, ParentType, ContextType>;
  sirnaDuplexId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OligoConnection'] = ResolversParentTypes['OligoConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OligoEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OligoEdge'] = ResolversParentTypes['OligoEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Oligo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoReagentResolvers<ContextType = any, ParentType extends ResolversParentTypes['OligoReagent'] = ResolversParentTypes['OligoReagent']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoReagentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OligoReagentConnection'] = ResolversParentTypes['OligoReagentConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OligoReagentEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OligoReagentEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OligoReagentEdge'] = ResolversParentTypes['OligoReagentEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OligoReagent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  assignedUsers?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  completedOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customerCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveries?: Resolver<Maybe<Array<ResolversTypes['Delivery']>>, ParentType, ContextType>;
  dueOn1?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  dueOn2?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inCharge?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  itemCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['OrderItem']>>, ParentType, ContextType>;
  leadTime?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  poIssuedOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  poReceivedOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  purchaseOrder?: Resolver<Maybe<ResolversTypes['PurchaseOrder']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  samples?: Resolver<Maybe<Array<ResolversTypes['Sample']>>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderConnection'] = ResolversParentTypes['OrderConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OrderEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderEdge'] = ResolversParentTypes['OrderEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderItem'] = ResolversParentTypes['OrderItem']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discountedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  samples?: Resolver<Maybe<Array<ResolversTypes['Sample']>>, ParentType, ContextType>;
  specs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderType'] = ResolversParentTypes['OrderType']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orders?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTypeConnection'] = ResolversParentTypes['OrderTypeConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OrderTypeEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTypeEdge'] = ResolversParentTypes['OrderTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OrderType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganismResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organism'] = ResolversParentTypes['Organism']> = {
  assemblies?: Resolver<Maybe<Array<ResolversTypes['Assembly']>>, ParentType, ContextType>;
  chromosomes?: Resolver<Maybe<Array<ResolversTypes['Chromosome']>>, ParentType, ContextType>;
  commonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  scientificName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganismConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganismConnection'] = ResolversParentTypes['OrganismConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['OrganismEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganismEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganismEdge'] = ResolversParentTypes['OrganismEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Organism'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PeptideReagentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PeptideReagent'] = ResolversParentTypes['PeptideReagent']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  asId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  asOligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  oligoId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  samples?: Resolver<Maybe<Array<ResolversTypes['Sample']>>, ParentType, ContextType>;
  ssId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ssOligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductConnection'] = ResolversParentTypes['ProductConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductEdge'] = ResolversParentTypes['ProductEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductType'] = ResolversParentTypes['ProductType']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductTypeConnection'] = ResolversParentTypes['ProductTypeConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductTypeEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductTypeEdge'] = ResolversParentTypes['ProductTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProteinResolvers<ContextType = any, ParentType extends ResolversParentTypes['Protein'] = ResolversParentTypes['Protein']> = {
  acc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assembly?: Resolver<Maybe<ResolversTypes['Assembly']>, ParentType, ContextType>;
  cdsLength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cdsSegmentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cdsSegments?: Resolver<Maybe<Array<Array<ResolversTypes['Int']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  gene?: Resolver<Maybe<ResolversTypes['Gene']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loci?: Resolver<Maybe<Array<ResolversTypes['ProteinMapping']>>, ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType>;
  seq?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProteinMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProteinMapping'] = ResolversParentTypes['ProteinMapping']> = {
  chrNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  ends?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  protein?: Resolver<ResolversTypes['Protein'], ParentType, ContextType>;
  starts?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  strand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchaseOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PurchaseOrder'] = ResolversParentTypes['PurchaseOrder']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  issuedOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  receivedOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  amidites?: Resolver<ResolversTypes['AmiditeConnection'], ParentType, ContextType, RequireFields<QueryAmiditesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  antisenseCart?: Resolver<Array<ResolversTypes['AntisenseCartItem']>, ParentType, ContextType>;
  antisensePing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  antisenseSequence?: Resolver<ResolversTypes['AntisenseSequence'], ParentType, ContextType, RequireFields<QueryAntisenseSequenceArgs, 'id'>>;
  antisenseSequences?: Resolver<ResolversTypes['AntisenseSequenceConnection'], ParentType, ContextType, RequireFields<QueryAntisenseSequencesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  assemblies?: Resolver<ResolversTypes['AssemblyConnection'], ParentType, ContextType, RequireFields<QueryAssembliesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  assembly?: Resolver<Maybe<ResolversTypes['Assembly']>, ParentType, ContextType, RequireFields<QueryAssemblyArgs, 'name'>>;
  backbones?: Resolver<ResolversTypes['BackboneConnection'], ParentType, ContextType, RequireFields<QueryBackbonesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType, RequireFields<QueryBaseArgs, 'id'>>;
  baseBySymbol?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType, RequireFields<QueryBaseBySymbolArgs, 'symbol'>>;
  bases?: Resolver<ResolversTypes['BaseConnection'], ParentType, ContextType, RequireFields<QueryBasesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  chromosome?: Resolver<Maybe<ResolversTypes['Chromosome']>, ParentType, ContextType, RequireFields<QueryChromosomeArgs, 'id'>>;
  chromosomes?: Resolver<ResolversTypes['ChromosomeConnection'], ParentType, ContextType, RequireFields<QueryChromosomesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  companies?: Resolver<ResolversTypes['CompanyConnection'], ParentType, ContextType, RequireFields<QueryCompaniesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryCompanyArgs, 'id'>>;
  couplingByComposition?: Resolver<Maybe<ResolversTypes['Coupling']>, ParentType, ContextType, RequireFields<QueryCouplingByCompositionArgs, 'filter'>>;
  couplings?: Resolver<ResolversTypes['CouplingConnection'], ParentType, ContextType, RequireFields<QueryCouplingsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  currentUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryCustomerArgs, 'id'>>;
  customers?: Resolver<ResolversTypes['CustomerConnection'], ParentType, ContextType, RequireFields<QueryCustomersArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  deliveries?: Resolver<ResolversTypes['DeliveryConnection'], ParentType, ContextType, RequireFields<QueryDeliveriesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  delivery?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType, RequireFields<QueryDeliveryArgs, 'id'>>;
  genomePing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nucleosides?: Resolver<ResolversTypes['NucleosideConnection'], ParentType, ContextType, RequireFields<QueryNucleosidesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  oligoReagents?: Resolver<ResolversTypes['OligoReagentConnection'], ParentType, ContextType, RequireFields<QueryOligoReagentsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  oligos?: Resolver<ResolversTypes['OligoConnection'], ParentType, ContextType, RequireFields<QueryOligosArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  oligosPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryOrderArgs, 'id'>>;
  orderTypes?: Resolver<ResolversTypes['OrderTypeConnection'], ParentType, ContextType, RequireFields<QueryOrderTypesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  orders?: Resolver<ResolversTypes['OrderConnection'], ParentType, ContextType, RequireFields<QueryOrdersArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  ordersPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType, RequireFields<QueryOrganismArgs, 'id'>>;
  organisms?: Resolver<ResolversTypes['OrganismConnection'], ParentType, ContextType, RequireFields<QueryOrganismsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductArgs, 'id'>>;
  productTypes?: Resolver<ResolversTypes['ProductTypeConnection'], ParentType, ContextType, RequireFields<QueryProductTypesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  products?: Resolver<ResolversTypes['ProductConnection'], ParentType, ContextType, RequireFields<QueryProductsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  proteinsPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reagentsPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rna?: Resolver<Maybe<ResolversTypes['Rna']>, ParentType, ContextType, RequireFields<QueryRnaArgs, 'id'>>;
  rnas?: Resolver<ResolversTypes['RnaConnection'], ParentType, ContextType, RequireFields<QueryRnasArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  rnasPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sample?: Resolver<Maybe<ResolversTypes['Sample']>, ParentType, ContextType, RequireFields<QuerySampleArgs, 'id'>>;
  sampleCart?: Resolver<Array<ResolversTypes['SampleCartItem']>, ParentType, ContextType>;
  samples?: Resolver<ResolversTypes['SampleConnection'], ParentType, ContextType, RequireFields<QuerySamplesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  samplesPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  searchRnas?: Resolver<ResolversTypes['RnaConnection'], ParentType, ContextType, RequireFields<QuerySearchRnasArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  searchTargets?: Resolver<ResolversTypes['TargetConnection'], ParentType, ContextType, RequireFields<QuerySearchTargetsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  sirnaCart?: Resolver<Array<ResolversTypes['SirnaCartItem']>, ParentType, ContextType>;
  sirnaDuplex?: Resolver<ResolversTypes['SirnaDuplex'], ParentType, ContextType, RequireFields<QuerySirnaDuplexArgs, 'id'>>;
  sirnaDuplexes?: Resolver<ResolversTypes['SirnaDuplexConnection'], ParentType, ContextType, RequireFields<QuerySirnaDuplexesArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  skus?: Resolver<ResolversTypes['SkuConnection'], ParentType, ContextType, RequireFields<QuerySkusArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  structuresPing?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sugar?: Resolver<Maybe<ResolversTypes['Sugar']>, ParentType, ContextType, RequireFields<QuerySugarArgs, 'id'>>;
  sugars?: Resolver<ResolversTypes['SugarConnection'], ParentType, ContextType, RequireFields<QuerySugarsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType, RequireFields<QueryTargetArgs, 'filter'>>;
  targets?: Resolver<ResolversTypes['TargetConnection'], ParentType, ContextType, RequireFields<QueryTargetsArgs, 'after' | 'before' | 'filter' | 'first' | 'last' | 'orderBy' | 'page' | 'perPage'>>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  users?: Resolver<ResolversTypes['UserConnection'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'filter' | 'orderBy'>>;
  usersPing?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type RnaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rna'] = ResolversParentTypes['Rna']> = {
  acc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  aliases?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  assembly?: Resolver<Maybe<ResolversTypes['Assembly']>, ParentType, ContextType>;
  cdsEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cdsStart?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chrEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chrNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chrStart?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  chrStrand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enstId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<RnaExonArgs, 'exonId'>>;
  exonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exons?: Resolver<Maybe<Array<Array<ResolversTypes['Int']>>>, ParentType, ContextType>;
  gene?: Resolver<Maybe<ResolversTypes['Gene']>, ParentType, ContextType>;
  geneDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geneId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geneName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isMrnaTarget?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRnaTarget?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  loci?: Resolver<Maybe<Array<ResolversTypes['RnaMapping']>>, ParentType, ContextType>;
  mrnaLength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organism?: Resolver<Maybe<ResolversTypes['Organism']>, ParentType, ContextType>;
  organismId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priSeq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  protId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  protLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  protTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  protein?: Resolver<Maybe<ResolversTypes['Protein']>, ParentType, ContextType>;
  rnaLength?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  synonyms?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  targets?: Resolver<Maybe<Array<ResolversTypes['Target']>>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  utr3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utr3Length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  utr5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utr5Length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RnaConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RnaConnection'] = ResolversParentTypes['RnaConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['RnaEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RnaEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RnaEdge'] = ResolversParentTypes['RnaEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Rna'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RnaMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['RnaMapping'] = ResolversParentTypes['RnaMapping']> = {
  chrNo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  ends?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  starts?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  strand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sample'] = ResolversParentTypes['Sample']> = {
  asId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  asOligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInCart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maldi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  oligoId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ssId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ssOligo?: Resolver<Maybe<ResolversTypes['Oligo']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleCartItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleCartItem'] = ResolversParentTypes['SampleCartItem']> = {
  ec260?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleConnection'] = ResolversParentTypes['SampleConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SampleEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleEdge'] = ResolversParentTypes['SampleEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Sample'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SampleType'] = ResolversParentTypes['SampleType']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orders?: Resolver<Array<ResolversTypes['Sample']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SirnaCartItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SirnaCartItem'] = ResolversParentTypes['SirnaCartItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sirnaId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SirnaDuplexResolvers<ContextType = any, ParentType extends ResolversParentTypes['SirnaDuplex'] = ResolversParentTypes['SirnaDuplex']> = {
  atContentSeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  blastMatchesAs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blastMatchesS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  endAs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endS?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exonId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInCart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lengthAs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lengthS?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seqAs?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seqS?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['SirnaSeries']>, ParentType, ContextType>;
  startAs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startS?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType>;
  targetSeq?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tm?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tmSeed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SirnaDuplexConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SirnaDuplexConnection'] = ResolversParentTypes['SirnaDuplexConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SirnaDuplexEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SirnaDuplexEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SirnaDuplexEdge'] = ResolversParentTypes['SirnaDuplexEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SirnaDuplex'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SirnaSeriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SirnaSeries'] = ResolversParentTypes['SirnaSeries']> = {
  config?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['Target']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sku'] = ResolversParentTypes['Sku']> = {
  chemicalReagent?: Resolver<Maybe<ResolversTypes['ChemicalReagent']>, ParentType, ContextType>;
  chemicalReagentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  hardwareReagent?: Resolver<Maybe<ResolversTypes['HardwareReagent']>, ParentType, ContextType>;
  hardwareReagentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  listPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  normalizedPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  oligoReagent?: Resolver<Maybe<ResolversTypes['OligoReagent']>, ParentType, ContextType>;
  oligoReagentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  packSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  peptideReagent?: Resolver<Maybe<ResolversTypes['PeptideReagent']>, ParentType, ContextType>;
  peptideReagentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantityBiop?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantityNasbio?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  solventReagent?: Resolver<Maybe<ResolversTypes['SolventReagent']>, ParentType, ContextType>;
  solventReagentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitOfMeasure?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  unitSize?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkuConnection'] = ResolversParentTypes['SkuConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SkuEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkuEdge'] = ResolversParentTypes['SkuEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Sku'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SolventReagentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SolventReagent'] = ResolversParentTypes['SolventReagent']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Structure'] = ResolversParentTypes['Structure']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SugarResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sugar'] = ResolversParentTypes['Sugar']> = {
  backTerminating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  derivatives?: Resolver<Maybe<Array<ResolversTypes['Sugar']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasBase?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mass?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Sugar']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SugarConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SugarConnection'] = ResolversParentTypes['SugarConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SugarEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SugarEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SugarEdge'] = ResolversParentTypes['SugarEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Sugar'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Target'] = ResolversParentTypes['Target']> = {
  antisenseSeries?: Resolver<Maybe<Array<ResolversTypes['AntisenseSeries']>>, ParentType, ContextType>;
  antisenseSeriesLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cdsEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cdsStart?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  custom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  exonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exons?: Resolver<Maybe<Array<Array<ResolversTypes['Int']>>>, ParentType, ContextType>;
  geneId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  geneName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organismId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rna?: Resolver<Maybe<ResolversTypes['Rna']>, ParentType, ContextType>;
  rnaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sirnaSeries?: Resolver<Maybe<Array<ResolversTypes['SirnaSeries']>>, ParentType, ContextType>;
  sirnaSeriesLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  spliced?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['datetime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetConnection'] = ResolversParentTypes['TargetConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TargetEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TargetEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetEdge'] = ResolversParentTypes['TargetEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Target'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDeliveryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateDeliveryResponse'] = ResolversParentTypes['UpdateDeliveryResponse']> = {
  delivery?: Resolver<Maybe<ResolversTypes['Delivery']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateSampleResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSampleResponse'] = ResolversParentTypes['UpdateSampleResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sample?: Resolver<Maybe<ResolversTypes['Sample']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateSkuQuantityResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSkuQuantityResponse'] = ResolversParentTypes['UpdateSkuQuantityResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['Sku']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserPasswordResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserPasswordResponse'] = ResolversParentTypes['UpdateUserPasswordResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserResponse'] = ResolversParentTypes['UpdateUserResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOrders?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastLogin?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastLoginIp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loginsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usernameUpper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']> = {
  cursor?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DatetimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['datetime'], any> {
  name: 'datetime';
}

export type Resolvers<ContextType = any> = {
  Amidite?: AmiditeResolvers<ContextType>;
  AmiditeConnection?: AmiditeConnectionResolvers<ContextType>;
  AmiditeEdge?: AmiditeEdgeResolvers<ContextType>;
  AntisenseCartItem?: AntisenseCartItemResolvers<ContextType>;
  AntisenseSequence?: AntisenseSequenceResolvers<ContextType>;
  AntisenseSequenceConnection?: AntisenseSequenceConnectionResolvers<ContextType>;
  AntisenseSequenceEdge?: AntisenseSequenceEdgeResolvers<ContextType>;
  AntisenseSeries?: AntisenseSeriesResolvers<ContextType>;
  Assembly?: AssemblyResolvers<ContextType>;
  AssemblyConnection?: AssemblyConnectionResolvers<ContextType>;
  AssemblyEdge?: AssemblyEdgeResolvers<ContextType>;
  Backbone?: BackboneResolvers<ContextType>;
  BackboneConnection?: BackboneConnectionResolvers<ContextType>;
  BackboneEdge?: BackboneEdgeResolvers<ContextType>;
  Base?: BaseResolvers<ContextType>;
  BaseConnection?: BaseConnectionResolvers<ContextType>;
  BaseEdge?: BaseEdgeResolvers<ContextType>;
  ChemicalReagent?: ChemicalReagentResolvers<ContextType>;
  Chromosome?: ChromosomeResolvers<ContextType>;
  ChromosomeConnection?: ChromosomeConnectionResolvers<ContextType>;
  ChromosomeEdge?: ChromosomeEdgeResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  CompanyConnection?: CompanyConnectionResolvers<ContextType>;
  CompanyEdge?: CompanyEdgeResolvers<ContextType>;
  Coupling?: CouplingResolvers<ContextType>;
  CouplingConnection?: CouplingConnectionResolvers<ContextType>;
  CouplingEdge?: CouplingEdgeResolvers<ContextType>;
  CreateAntisenseSeriesResponse?: CreateAntisenseSeriesResponseResolvers<ContextType>;
  CreateAsosResponse?: CreateAsosResponseResolvers<ContextType>;
  CreateCompanyResponse?: CreateCompanyResponseResolvers<ContextType>;
  CreateCustomerResponse?: CreateCustomerResponseResolvers<ContextType>;
  CreateDeliveryResponse?: CreateDeliveryResponseResolvers<ContextType>;
  CreateOligoResponse?: CreateOligoResponseResolvers<ContextType>;
  CreateOrderResponse?: CreateOrderResponseResolvers<ContextType>;
  CreateProductResponse?: CreateProductResponseResolvers<ContextType>;
  CreateSampleResponse?: CreateSampleResponseResolvers<ContextType>;
  CreateSirnaSeriesResponse?: CreateSirnaSeriesResponseResolvers<ContextType>;
  CreateTargetResponse?: CreateTargetResponseResolvers<ContextType>;
  CreateUserResponse?: CreateUserResponseResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerConnection?: CustomerConnectionResolvers<ContextType>;
  CustomerEdge?: CustomerEdgeResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Decimal?: GraphQLScalarType;
  Delivery?: DeliveryResolvers<ContextType>;
  DeliveryConnection?: DeliveryConnectionResolvers<ContextType>;
  DeliveryEdge?: DeliveryEdgeResolvers<ContextType>;
  Gene?: GeneResolvers<ContextType>;
  GeneMapping?: GeneMappingResolvers<ContextType>;
  HardwareReagent?: HardwareReagentResolvers<ContextType>;
  Modification?: ModificationResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationResponse?: MutationResponseResolvers<ContextType>;
  Nucleoside?: NucleosideResolvers<ContextType>;
  NucleosideConnection?: NucleosideConnectionResolvers<ContextType>;
  NucleosideEdge?: NucleosideEdgeResolvers<ContextType>;
  Oligo?: OligoResolvers<ContextType>;
  OligoConnection?: OligoConnectionResolvers<ContextType>;
  OligoEdge?: OligoEdgeResolvers<ContextType>;
  OligoReagent?: OligoReagentResolvers<ContextType>;
  OligoReagentConnection?: OligoReagentConnectionResolvers<ContextType>;
  OligoReagentEdge?: OligoReagentEdgeResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderConnection?: OrderConnectionResolvers<ContextType>;
  OrderEdge?: OrderEdgeResolvers<ContextType>;
  OrderItem?: OrderItemResolvers<ContextType>;
  OrderType?: OrderTypeResolvers<ContextType>;
  OrderTypeConnection?: OrderTypeConnectionResolvers<ContextType>;
  OrderTypeEdge?: OrderTypeEdgeResolvers<ContextType>;
  Organism?: OrganismResolvers<ContextType>;
  OrganismConnection?: OrganismConnectionResolvers<ContextType>;
  OrganismEdge?: OrganismEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PeptideReagent?: PeptideReagentResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductConnection?: ProductConnectionResolvers<ContextType>;
  ProductEdge?: ProductEdgeResolvers<ContextType>;
  ProductType?: ProductTypeResolvers<ContextType>;
  ProductTypeConnection?: ProductTypeConnectionResolvers<ContextType>;
  ProductTypeEdge?: ProductTypeEdgeResolvers<ContextType>;
  Protein?: ProteinResolvers<ContextType>;
  ProteinMapping?: ProteinMappingResolvers<ContextType>;
  PurchaseOrder?: PurchaseOrderResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Rna?: RnaResolvers<ContextType>;
  RnaConnection?: RnaConnectionResolvers<ContextType>;
  RnaEdge?: RnaEdgeResolvers<ContextType>;
  RnaMapping?: RnaMappingResolvers<ContextType>;
  Sample?: SampleResolvers<ContextType>;
  SampleCartItem?: SampleCartItemResolvers<ContextType>;
  SampleConnection?: SampleConnectionResolvers<ContextType>;
  SampleEdge?: SampleEdgeResolvers<ContextType>;
  SampleType?: SampleTypeResolvers<ContextType>;
  SirnaCartItem?: SirnaCartItemResolvers<ContextType>;
  SirnaDuplex?: SirnaDuplexResolvers<ContextType>;
  SirnaDuplexConnection?: SirnaDuplexConnectionResolvers<ContextType>;
  SirnaDuplexEdge?: SirnaDuplexEdgeResolvers<ContextType>;
  SirnaSeries?: SirnaSeriesResolvers<ContextType>;
  Sku?: SkuResolvers<ContextType>;
  SkuConnection?: SkuConnectionResolvers<ContextType>;
  SkuEdge?: SkuEdgeResolvers<ContextType>;
  SolventReagent?: SolventReagentResolvers<ContextType>;
  Structure?: StructureResolvers<ContextType>;
  Sugar?: SugarResolvers<ContextType>;
  SugarConnection?: SugarConnectionResolvers<ContextType>;
  SugarEdge?: SugarEdgeResolvers<ContextType>;
  Target?: TargetResolvers<ContextType>;
  TargetConnection?: TargetConnectionResolvers<ContextType>;
  TargetEdge?: TargetEdgeResolvers<ContextType>;
  UpdateDeliveryResponse?: UpdateDeliveryResponseResolvers<ContextType>;
  UpdateSampleResponse?: UpdateSampleResponseResolvers<ContextType>;
  UpdateSkuQuantityResponse?: UpdateSkuQuantityResponseResolvers<ContextType>;
  UpdateUserPasswordResponse?: UpdateUserPasswordResponseResolvers<ContextType>;
  UpdateUserResponse?: UpdateUserResponseResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserConnection?: UserConnectionResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  datetime?: GraphQLScalarType;
};

