import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Index from '../pages';
import Backbones from '../pages/chemistry/backbones';
import Couplings from '../pages/chemistry/couplings';
import NotFound from '../pages/not-found';
import Oligos from '../pages/oligo/oligos';
import Companies from '../pages/order/companies';
import CompanyOverview from '../pages/order/company-overview';
import CustomerOverview from '../pages/order/customer-overview';
import Customers from '../pages/order/customers';
import Deliveries from '../pages/order/deliveries';
import DeliveryOverview from '../pages/order/delivery-overview';
import OrderOverview from '../pages/order/order-overview';
import ProductOverview from '../pages/order/product-overview';
import Products from '../pages/order/products';
import CurrentProduction from '../pages/production/current-production';
import SampleOverview from '../pages/production/sample-overview';
import Samples from '../pages/production/samples';
import Me from '../pages/profile/me';
import Chromosomes from '../pages/genome/chromosomes';
import Rnas from '../pages/genome/rnas';
import Targets from '../pages/antisense/targets';
import TargetOverview from '../pages/antisense/target-overview';
import TargetAntisenseSeries from '../pages/antisense/target-antisense-series';
import TargetSirnaSeries from '../pages/antisense/target-sirna-series';
import Editor from '../pages/chemistry/editor';
import Sugars from '../pages/chemistry/sugars';
import Bases from '../pages/chemistry/bases';
import Nucleosides from '../pages/chemistry/nucleosides';
import Amidites from '../pages/chemistry/amidites';
import Inventory from '../pages/reagent/inventory';
import Security from '../pages/account/security';
import Users from '../pages/admin/users';
import Orders from '../pages/order/orders';

const AuthenticatedRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/profile/me" element={<Me />} />

      {/* Genome routes. */}
      <Route path="/genome/chromosomes" element={<Chromosomes />} />
      {/*<Route path="/genome/genes" element={<Genes />} />*/}
      <Route path="/genome/rnas" element={<Rnas />} />

      {/* Antisense routes. */}
      <Route path="/antisense/targets" element={<Targets />} />
      <Route path="/targets/:targetId" element={<TargetOverview />} />
      <Route path="/targets/:targetIdRnaId/antisense" element={<TargetAntisenseSeries />} />
      <Route path="/targets/:targetIdRnaId/sirna" element={<TargetSirnaSeries />} />

      {/*Chemistry routes.*/}
      <Route path="/chemistry/editor" element={<Editor />} />
      <Route path="/chemistry/sugars" element={<Sugars />} />
      <Route path="/chemistry/bases" element={<Bases />} />
      <Route path="/chemistry/nucleosides" element={<Nucleosides />} />
      <Route path="/chemistry/backbones" element={<Backbones />} />
      <Route path="/chemistry/couplings" element={<Couplings />} />
      <Route path="/chemistry/amidites" element={<Amidites />} />

      {/* Oligo routes. */}
      <Route path="/oligos" element={<Oligos />} />

      {/*Reagent routes.*/}
      <Route path="/inventory" element={<Inventory />} />

      {/*Order routes.*/}
      <Route path="/products" element={<Products />} />
      <Route path="/products/:productId" element={<ProductOverview />} />
      <Route path="/companies" element={<Companies />} />
      <Route path="/companies/:companyId" element={<CompanyOverview />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/customers/:customerId" element={<CustomerOverview />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:orderId" element={<OrderOverview />} />
      <Route path="/deliveries" element={<Deliveries />} />
      <Route path="/deliveries/:deliveryId" element={<DeliveryOverview />} />

      {/*Production routes.*/}
      <Route path="/samples" element={<Samples />} />
      <Route path="/samples/:sampleId" element={<SampleOverview />} />
      <Route path="/production" element={<CurrentProduction />} />

      {/*Account settings.*/}
      <Route path="/account/security" element={<Security />} />

      {/* Admin routes. */}
      <Route path="/admin/users" element={<Users />} />

      {/* Page not found. */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
