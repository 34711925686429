import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import Toggle from '@atlaskit/toggle';
import Form, { FormFooter } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';

import { SelectOptionStringValueType } from 'types/select-option';
import { antisenseCartItemsVar } from 'apollo/cache';
import { GetAntisenseSequenceDocument, GetAntisenseSequenceQuery, useCreateAsosMutation } from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import MixmerItem from './mixmer-item';

export interface GapmerProps {
  antisenseId: string;
  name: string;
  antisenseSeq: string;
  start: number;
  symbol: string;
  wing5: number;
  wing5SugarId: string;
  wing3: number;
  wing3SugarId: string;
}

export interface MixmerProps {
  // targetId: string;
  antisenseId: string;
  name: string;
  antisenseSeq: string;
  start: number;
  symbol: string;
  rangeNodes: number[];
  segmentSugars: SelectOptionStringValueType[];
}

export interface OligoConfigProps {
  antisenseId: string;
  cpIds?: string[];
}

interface Props {
  setTotalCount: Function;
}

function CreateMixmerTab({ setTotalCount }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const antisenseCartItems = useReactiveVar(antisenseCartItemsVar);

  // Backbone chemistry.
  const [ps, setPs] = useState<boolean>(true);

  // Payload for creation of mixmers.
  const [antisenseList, setAntisenseList] = useState<MixmerProps[]>(antisenseCartItems.map(({
    antisenseId,
    targetLength,
    symbol,
  }) => {
    const antisense = client.readQuery<GetAntisenseSequenceQuery>({
      query: GetAntisenseSequenceDocument,
      variables: { id: antisenseId },
    });
    const { antisenseSequence } = antisense!;
    const { seq, start } = antisenseSequence;
    const rangeNodes = [0, Math.floor(antisenseSequence.seq.length / 2), antisenseSequence.seq.length];

    return {
      antisenseId,
      name: `${symbol}_${start.toString().padStart(targetLength.toString().length, '0')}${seq.length === 16 ? '' : '_' + seq.length}_${rangeNodes.slice(1).map((node, i) => {
        if (i === 0) {
          return node;
        } else {
          return rangeNodes[i + 1] - rangeNodes[i];
        }
      }).join('-')}`,
      // name: `${symbol}_${start.toString().padStart(targetLength.toString().length, '0')}${seq.length === 16 ? '' : '_' + seq.length}`,
      antisenseSeq: seq,
      start,
      symbol,
      rangeNodes,
      segmentSugars: [{ label: 1, value: '' }, { label: 2, value: '' }],
    };
  }));
  const [cpIdsList, setCpIdsList] = useState<OligoConfigProps[]>(antisenseList.map(({ antisenseId }) => ({ antisenseId })));

  useEffect(() => {
    setAntisenseList(prevAntisenseList => {
      return prevAntisenseList.map(antisense => {
        return { ...antisense };
      });
    });
  }, [antisenseCartItems]);

  useEffect(() => {
    setCpIdsList(prevCpIdsList => {
      if (antisenseList.length > prevCpIdsList.length) {
        return [...prevCpIdsList, { antisenseId: antisenseList[antisenseList.length - 1].antisenseId }];
      } else if (antisenseList.length < prevCpIdsList.length) {
        return prevCpIdsList.filter(({ antisenseId }) => {
          return antisenseList.find(({ antisenseId: id }) => id === antisenseId);
        });
      } else {
        return prevCpIdsList || [];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [antisenseList]);

  const [create, { loading }] = useCreateAsosMutation({
    onCompleted: ({ createAsos: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) {
        setTotalCount(0);
        // TODO: Filter cart items based on name of returned asos.
        antisenseCartItemsVar([]);
      }
    },
  });

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px 0 20px 0',
        }}>
        <div
          style={{
            marginTop: '6px',
            cursor: 'pointer',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
          }}
        >
          <Toggle
            value={ps.toString()}
            isChecked={ps}
            onChange={() => setPs(prevPs => !prevPs)}
          />
          <span onClick={() => setPs(prevPs => !prevPs)}>
            {' '} PS backbone
          </span>
        </div>
      </div>
      <Form
        onSubmit={(values) => {
          const asos = antisenseCartItems.map(({ antisenseId, targetId }, i) => ({
            name: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.name,
            cpIds: cpIdsList.find(cpIds => cpIds.antisenseId === antisenseId)!.cpIds! || [],
            targetId: parseInt(targetId),
            antisenseId: parseInt(antisenseId),
            antisenseSeq: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.antisenseSeq,
          }));
          // console.log(asos);
          create({ variables: { asos } });
        }}
      >
        {({ formProps }: any) => (
          <form {...formProps} noValidate>
            {antisenseList.map(({ antisenseId, name, antisenseSeq, rangeNodes, segmentSugars }) => (
              <MixmerItem
                id={antisenseId}
                name={name}
                seq={antisenseSeq}
                ps={ps}
                setAntisenseList={setAntisenseList}
                setCpIdsList={setCpIdsList}
                rangeNodes={rangeNodes}
                segmentSugars={segmentSugars}
                setTotalCount={setTotalCount}
                key={antisenseId}
              />
            ))}
            <FormFooter>
              <LoadingButton
                type="submit"
                appearance="primary"
                isDisabled={!cpIdsList.length || !cpIdsList[cpIdsList.length - 1].cpIds} // TODO: Add conditional checks to make sure no names are ''
                isLoading={loading}
                // onClick={() => {
                //   const asos = antisenseCartItems.map(({ antisenseId, targetId }, i) => ({
                //     name: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.name,
                //     cpIds: cpIdsList.find(cpIds => cpIds.antisenseId === antisenseId)!.cpIds! || [],
                //     targetId: parseInt(targetId),
                //     antisenseId: parseInt(antisenseId),
                //     antisenseSeq: antisenseList.find(antisense => antisense.antisenseId === antisenseId)!.antisenseSeq,
                //   }));
                //   // console.log(asos);
                //   create({ variables: { asos } });
                // }}
                style={{ marginBottom: '20px' }}
              >
                Submit
              </LoadingButton>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
}

export default CreateMixmerTab;
