import React from 'react';
import { ApolloQueryResult } from '@apollo/client';

import { SearchTargetsQuery } from 'apollo/graphql';
import { SelectOptionStringValueType } from 'types/select-option';

export interface TargetOptionProps {
  id: string;
  custom: boolean;
  rnaId: string;
  spliced: boolean;
  symbol: string;
  geneName: string;
}

export const createTargetSwitcherOption = ({ id, custom, rnaId, spliced, symbol, geneName }: TargetOptionProps) => {
  return {
    label: (
      <>
        <div style={{ fontSize: '14px', fontWeight: 500 }}>
          {custom ? `CUSTOM_${id.padStart(3, '0')}` : rnaId} {!spliced ? 'pre-' : ''}{custom ? '' : 'm'}RNA
        </div>
        <div
          style={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: '2px 0',
          }}
        >
          {symbol && geneName ? `[ ${symbol} ] ${geneName}` : '\u00A0'}
        </div>
      </>
    ),
    value: `${custom ? id : rnaId}__${spliced.toString()}`,
  };
};

const createTargetOptions = ({ data }: ApolloQueryResult<SearchTargetsQuery>, mode: 'drawer' | 'switcher') => {
  const targets = data?.searchTargets.edges || [];

  const humanOptions: SelectOptionStringValueType[] = [];
  const mouseOptions: SelectOptionStringValueType[] = [];
  const ratOptions: SelectOptionStringValueType[] = [];

  targets.forEach(({ node: { organismId, id, custom, rnaId, spliced, symbol, geneName, length } }) => {
    const option = {
      drawer: {
        label: `${custom ? `CUSTOM_${id.padStart(3, '0')}` : rnaId} [${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol}] ${spliced ? '' : 'pre-'}mRNA (${length.toLocaleString()} nt)`,
        value: id,
      },
      switcher: createTargetSwitcherOption({ id, custom, rnaId: rnaId || '', spliced, symbol, geneName }),
    };

    organismId === 9606
      ? humanOptions.push(option[mode])
      : organismId === 10090
        ? mouseOptions.push(option[mode])
        : ratOptions.push(option[mode]);
  });

  return ratOptions.length > 0
    ? [
      {
        label: 'Human',
        options: humanOptions,
      },
      {
        label: 'Mouse',
        options: mouseOptions,
      },
      {
        label: 'Rat',
        options: ratOptions,
      },
    ]
    : [
      {
        label: 'Human',
        options: humanOptions,
      },
      {
        label: 'Mouse',
        options: mouseOptions,
      },
    ];
};

export default createTargetOptions;
