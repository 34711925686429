import React from 'react';
// import Lozenge from '@atlaskit/lozenge';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { Product, ProductEdge } from 'apollo/graphql';
import { ProductOrderBy } from 'pages/order/products';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
import TableLink from '../tables/table-link';
import OrderTypeLozenge from './order-type-lozenge';

interface Props {
  products: ProductEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: ProductOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function ProductList({
  products,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      createTableHead<Product>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<Product>({
      //   fieldKey: 'type',
      //   fieldName: 'Type',
      //   width: 2,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<Product>({
        fieldKey: 'name',
        fieldName: 'Name',
        width: 6,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'oligoId',
        colSpan: 1,
        content: 'Oligo',
      },
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={products.map(({ node: { id, type, name, oligo } }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: (
                <>
                  <TableLink to={`/products/${id}`}>{id.toString().padStart(3, '0')}</TableLink>&nbsp;&nbsp;&nbsp;&nbsp;
                  <OrderTypeLozenge orderType={type} />
                </>
              ),
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: type,
            // },
            {
              key: id,
              colSpan: 1,
              content: name,
            },
            {
              key: id,
              colSpan: 1,
              content: oligo?.name || '\u2013',
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No products found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default ProductList;
