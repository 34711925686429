import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonItem } from '@atlaskit/menu';

interface Props {
  children: ReactNode;
  to: string;
  state?: any;
  onClick?: () => void;
  isSelected?: boolean;
  iconBefore?: JSX.Element;
}

const ButtonLink = ({ children, to, state, onClick, isSelected, iconBefore }: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ButtonItem
      onClick={() => {
        if (onClick) onClick();
        navigate(to, { state });
      }}
      isSelected={isSelected}
      iconBefore={iconBefore}
    >
      {children}
    </ButtonItem>
  );
};

export default ButtonLink;
