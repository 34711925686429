import React from 'react';
import Lozenge from '@atlaskit/lozenge';

interface Props {
  orderType: string;
}

function OrderTypeLozenge({ orderType }: Props) {
  return (
    <Lozenge
      appearance={['oligo', 'primer', 'sirna'].includes(orderType) ? 'inprogress' : orderType === 'peptide' ? 'removed' : ['conjugation', 'dynabeads'].includes(orderType) ? 'success' : ['triphosphate', 'small molecule'].includes(orderType) ? 'new' : 'default'}
    >
      {orderType}
    </Lozenge>
  )
}

export default OrderTypeLozenge;
