import { DrawerType } from 'components/drawers/drawer-manager';
import { DrawerProps } from './actions';

export interface Drawer extends DrawerProps {
  drawerType: DrawerType | null;
  isOpen: boolean;
}

export enum DrawerActionType {
  OPEN_DRAWER = 'OPEN_DRAWER',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
}
