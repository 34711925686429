import React from 'react';
import Select, { ValueType } from '@atlaskit/select';

import { SelectOptionNumberValueType } from 'types/select-option';

const options: SelectOptionNumberValueType[] = [
  { label: 'All years', value: -1 },
  { label: '2023', value: 2023 },
  { label: '2022', value: 2022 },
];

interface Props {
  year: SelectOptionNumberValueType;
  setYear: (value: SelectOptionNumberValueType) => void;
  setCurrentPage?: (num: number) => void;
}

function YearsSelect({
  year,
  setYear,
  setCurrentPage,
}: Props): JSX.Element {
  const onYearSelectChange = (value: ValueType<SelectOptionNumberValueType>): void => {
    if (value && value.value !== year.value) {
      setYear(value);
      if (setCurrentPage) setCurrentPage(1);
    }
  };

  return (
    <div style={{ zIndex: 3 }}>
      <Select
        spacing="compact"
        options={options}
        defaultValue={{ label: 'All years', value: -1 }}
        onChange={onYearSelectChange}
        placeholder="Select year"
        aria-label="Select year"
        styles={{ control: base => ({ ...base, width: '116px' }) }}
      />
    </div>
  );
}

export default YearsSelect;
