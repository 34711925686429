import { useApolloClient } from '@apollo/client';
import { LoadingButton } from '@atlaskit/button';
import Drawer from '@atlaskit/drawer';
import Form, { ErrorMessage, Field, FormFooter } from '@atlaskit/form';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setAlert } from 'redux/alerts';
import {
  GetAllOligosDocument,
  GetAllOligosQuery,
  GetAllProductsDocument,
  GetAllProductTypesDocument,
  GetAllProductTypesQuery,
  OligoSortField,
  useCreateProductMutation,
} from 'apollo/graphql';
import { closeDrawer, DrawerProps } from 'redux/drawers';
import { SelectOptionStringValueType } from 'types/select-option';
import ProductNameField from '../forms/order/product-name-field';

interface Props extends DrawerProps {
  isOpen: boolean;
  totalCount?: number;
}

function CreateProductDrawer({ isOpen }: Props): JSX.Element {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [productType, setProductType] = useState<SelectOptionStringValueType | null>(null);
  const [count, setCount] = useState<number>(0);
  const reset = () => {
    setProductType(null);
    setCount(prevCount => prevCount + 1);
  };

  const [createProduct, { loading }] = useCreateProductMutation({
    onCompleted: ({ createProduct: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllProductsDocument],
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <Form
          onSubmit={({
            name,
            productType,
            oligo,
          }: { name: string; productType: SelectOptionStringValueType; oligo?: SelectOptionStringValueType; }) => {
            createProduct({
              variables: {
                name,
                productType: productType?.value || 'general',
                ...(oligo && { oligoId: parseInt(oligo.value) }),
              },
            });
          }}
          key={count}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div>
                <ProductNameField />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '12px' }}>
                  <div style={{ width: '200px' }}>
                    <Field<ValueType<SelectOptionStringValueType>>
                      name="productType"
                      label="Product type"
                      isRequired
                      defaultValue={productType}
                      validate={(value) => {
                        if (!value?.value) return 'Select a product type';
                      }}
                    >
                      {({ fieldProps, error }) => (
                        <>
                          <AsyncSelect
                            {...fieldProps}
                            value={productType}
                            cacheOptions
                            defaultOptions
                            loadOptions={(inputValue: string) => client.query<GetAllProductTypesQuery>({
                              query: GetAllProductTypesDocument,
                              variables: {
                                filter: {
                                  search: inputValue,
                                },
                              },
                              fetchPolicy: 'network-only',
                            })
                              .then(({ data }) => {
                                const productTypes = data?.productTypes.edges || [];

                                return productTypes.map(({ node: { name } }) => ({
                                  label: name,
                                  value: name,
                                }));
                              })}
                            onChange={value => {
                              if (value) setProductType(value);
                            }}
                            placeholder={<span style={{ whiteSpace: 'nowrap' }}>Select product type</span>}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                          {/*{!error && <HelperMessage>&nbsp;</HelperMessage>}*/}
                        </>
                      )}
                    </Field>
                  </div>
                  {productType?.value === 'oligo' ? (
                    <div style={{ width: '304px' }}>
                      <Field<ValueType<SelectOptionStringValueType>>
                        name="oligo"
                        label="Oligo"
                        isRequired
                        validate={(value) => {
                          if (!value?.value) return 'Select an oligo';
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <AsyncSelect
                              {...fieldProps}
                              cacheOptions
                              defaultOptions
                              loadOptions={(inputValue: string) => client.query<GetAllOligosQuery>({
                                query: GetAllOligosDocument,
                                variables: {
                                  filter: {
                                    search: inputValue,
                                  },
                                  orderBy: OligoSortField.IdAsc,
                                },
                                fetchPolicy: 'network-only',
                              })
                                .then(({ data }) => {
                                  const oligos = data?.oligos.edges || [];

                                  return oligos.map(({ node: { id, name } }) => ({
                                    label: name,
                                    value: id,
                                  }));
                                })}
                              placeholder="Select oligo"
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                            {/*{!error && <HelperMessage>&nbsp;</HelperMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                  ) : productType?.value === 'peptide' ? (
                    <div style={{ width: '304px' }}>
                      <Field<ValueType<SelectOptionStringValueType>>
                        name="peptide"
                        label="Peptide"
                        isRequired
                        validate={(value) => {
                          if (!value?.value) return 'Select a peptide';
                        }}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <AsyncSelect
                              {...fieldProps}
                              cacheOptions
                              defaultOptions
                              loadOptions={(inputValue: string) => client.query<GetAllOligosQuery>({
                                query: GetAllOligosDocument,
                                variables: {
                                  filter: {
                                    name: inputValue,
                                  },
                                  orderBy: OligoSortField.IdAsc,
                                },
                                fetchPolicy: 'network-only',
                              })
                                .then(({ data }) => {
                                  const oligos = data?.oligos.edges || [];

                                  return oligos.map(({ node: { id, name } }) => ({
                                    label: name,
                                    value: id,
                                  }));
                                })}
                              placeholder="Select peptide"
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                            {/*{!error && <HelperMessage>&nbsp;</HelperMessage>}*/}
                          </>
                        )}
                      </Field>
                    </div>
                  ) : null}
                </div>
              </div>
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                >
                <span style={{ fontWeight: 400 }}>
                  Submit
                </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateProductDrawer;
