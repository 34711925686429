import React, { useEffect, useState } from 'react';
import Page, { GridColumn } from '@atlaskit/page';
import { PanelStateless } from '@atlaskit/panel';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';

import TableLink from '../tables/table-link';

const RnaInfo = styled.div`
  & > div {
    margin: 0 0 0 0;
  }

  & > div > div {
    margin: 0 0 0 0;
  }
`;

interface Props {
  isOpen?: boolean;
  id: string;
  geneName: string;
  title: string;
  desc: string;
  protId?: string | null;
  protTitle?: string | null;
  protLength?: number | null;
  cdsStart?: number | null;
  cdsEnd?: number | null;
}

function ExpandableRnaInfo({
  isOpen = false,
  id,
  geneName,
  title,
  desc,
  protId,
  protTitle,
  protLength,
  cdsStart,
  cdsEnd,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(isOpen);

  useEffect(() => {
    if (isOpen && !isExpanded) setIsExpanded(true);
    if (!isOpen) setIsExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setIsExpanded(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Page>  {/* GridColumn must be wrapped by Page element! */}
      <GridColumn medium={12}>
        <RnaInfo>
          <PanelStateless
            header={(
              <span
                style={{
                  whiteSpace: isExpanded ? 'normal' : 'inherit',
                  WebkitUserSelect: 'none',
                  MozUserSelect: 'none',
                  msUserSelect: 'none',
                }}
              >
              {geneName}
            </span>
            )}
            isExpanded={isExpanded}
            onChange={() => setIsExpanded(isExpanded => !isExpanded)}
          >
            <div
              style={{
                whiteSpace: 'normal',
                color: colors.N300,
                fontSize: '0.8rem',
                marginBottom: '4px',
              }}
            >
              <div style={{ paddingTop: '4px', fontStyle: 'italic' }}>{title}</div>
              {desc !== '-' && <div style={{ paddingTop: '4px', fontSize: '0.75rem' }}>{desc}</div>}
              {protId !== '-' && (
                <>
                  <span><TableLink to={`/genome/proteins/${protId}`}>{protId}</TableLink> - {protTitle}</span><br />
                  [ <span>{protLength && protLength.toLocaleString()}</span> aa &nbsp;/&nbsp;
                  CDS: <span>{cdsStart && cdsStart.toLocaleString()}</span>&minus;
                  <span>{cdsEnd && cdsEnd.toLocaleString()}</span> &nbsp;( <span>{cdsStart && cdsEnd && (cdsEnd - cdsStart + 1).toLocaleString()}</span> nt
                  ) ]
                </>
              )}
            </div>
          </PanelStateless>
        </RnaInfo>
      </GridColumn>
    </Page>
  );
}

export default ExpandableRnaInfo;
