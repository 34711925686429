import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { LoadingButton } from '@atlaskit/button';
import Drawer from '@atlaskit/drawer';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { AsyncSelect, ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { useDispatch } from 'react-redux';

import {
  GetAllTargetsDocument,
  GetTargetAntisenseSeriesDocument,
  SearchTargetsDocument,
  SearchTargetsQuery,
  useCreateAntisenseSeriesMutation,
} from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import { closeDrawer, DrawerProps } from 'redux/drawers';
import { SelectOptionStringValueType } from 'types/select-option';
import createTargetOptions from '../antisense/create-target-options';
import { TargetProps } from 'types/antisense';

interface Props extends DrawerProps {
  isOpen: boolean;
  target: TargetProps;
}

const CreateAntisenseSeriesDrawer: React.FC<Props> = ({
  isOpen,
  target: { organismId, id, rnaId, spliced, custom, symbol, length },
}): JSX.Element => {
  const [target, setTarget] = useState<SelectOptionStringValueType>({
    label: `${custom ? `CUSTOM_${id.padStart(3, '0')}` : rnaId}  [ ${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol} ] ${spliced ? '' : 'pre-'}mRNA (${length.toLocaleString()} nt)`,
    value: id,
  });
  const [antisenseLength, setAntisenseLength] = useState<number>(16);

  useEffect(() => {
    setTarget({
      label: `${custom ? `CUSTOM_${id.padStart(3, '0')}` : rnaId}  [ ${organismId === 10090 ? 'm' : organismId === 10116 ? 'r' : ''}${symbol} ] ${spliced ? '' : 'pre-'}mRNA (${length.toLocaleString()} nt)`,
      value: id,
    });
    setAntisenseLength(16);
  }, [custom, id, spliced, length, organismId, rnaId, symbol]);

  const client = useApolloClient();
  const dispatch = useDispatch();

  const [createAntisenseSeries, { loading }] = useCreateAntisenseSeriesMutation({
    onCompleted: ({ createAntisenseSeries: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
    },
    refetchQueries: [GetAllTargetsDocument, GetTargetAntisenseSeriesDocument],
  });

  const promiseOptions = (inputValue: string) => client.query<SearchTargetsQuery>({
    query: SearchTargetsDocument,
    variables: {
      filter: { search: inputValue },
    },
    fetchPolicy: 'network-only',
  })
    .then(res => createTargetOptions(res, 'drawer'));

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <AsyncSelect
          autoFocus
          defaultOptions
          loadOptions={promiseOptions}
          placeholder="Search for target..."
          value={target}
          onChange={(value: ValueType<SelectOptionStringValueType>): void => {
            if (value) setTarget(value);
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '24px',
          }}
        >
          <div style={{ width: '80px' }}>
            <Textfield
              autoComplete="off"
              placeholder="Length"
              type="number"
              min={12}
              max={30}
              value={antisenseLength}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (parseInt(e.target.value)) {
                  if (parseInt(e.target.value) < 12) {
                    setAntisenseLength(12);
                  } else if (parseInt(e.target.value) > 30) {
                    setAntisenseLength(30);
                  } else {
                    setAntisenseLength(parseInt(e.target.value));
                  }
                } else {
                  setAntisenseLength(16);
                }
              }}
              isCompact
              elemAfterInput={<span style={{ paddingRight: '8px', color: colors.DN90A }}>nt</span>}
            />
          </div>
          <LoadingButton
            type="submit"
            appearance="primary"
            isLoading={loading}
            onClick={() => {
              createAntisenseSeries({
                variables: {
                  targetId: target.value,
                  length: antisenseLength,
                },
              });
            }}
            isDisabled={!target.value}
          >
          <span style={{ fontWeight: 400 }}>
            Submit
          </span>
          </LoadingButton>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateAntisenseSeriesDrawer;
