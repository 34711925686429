import Badge from '@atlaskit/badge';
import { Code } from '@atlaskit/code';
import React from 'react';
import Button from '@atlaskit/button';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
// import CopyIcon from '@atlaskit/icon/glyph/copy';
import MoreIcon from '@atlaskit/icon/glyph/more';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
// import { GridColumn } from '@atlaskit/page';
// import { PanelStateless } from '@atlaskit/panel';
import { codeFontFamily, colors } from '@atlaskit/theme';
import Tooltip from '@atlaskit/tooltip';
// import { useState } from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import styled from 'styled-components';

import { Oligo, OligoEdge } from 'apollo/graphql';
import { OligoOrderBy } from 'pages/oligo/oligos';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';

// const ExpandableInfo = styled.div`
//   & > div {
//     margin: 0 0 0 0;
//   }
//
//   & > div > div {
//     padding-top: 2px;
//     padding-bottom: 0;
//     margin: 2px 0 0 0;
//   }
// `;

// interface ExpandableSeqInfoProps {
//   displaySeq: string;
//   primarySeq: string;
// }

// const ExpandableSeqInfo = ({ displaySeq, primarySeq }: ExpandableSeqInfoProps) => {
//   const [isExpanded, toggleExpanded] = useState<boolean>(false);
//
//   return (
//     <GridColumn>
//       <ExpandableInfo>
//         <PanelStateless
//           header={(
//             <div
//               style={{
//                 fontFamily: codeFontFamily(),
//                 fontSize: '1.1em',
//                 whiteSpace: isExpanded ? 'normal' : 'inherit',
//                 marginBottom: '2px',
//                 WebkitUserSelect: 'none',
//                 MozUserSelect: 'none',
//                 msUserSelect: 'none',
//               }}
//             >
//               {!isExpanded && displaySeq.length > 63 ? (
//                 <span>
//                   {displaySeq.substring(0, 63)}<br />
//                 </span>
//               ) : displaySeq}
//             </div>
//           )}
//           isExpanded={isExpanded}
//           onChange={() => {
//             toggleExpanded(isExpanded => !isExpanded);
//           }}
//         >
//           <div style={{ whiteSpace: 'normal' }}>
//             <span style={{ fontSize: '0.85em', color: colors.N300 }}>Primary seq:&nbsp;&nbsp;</span>
//             <span style={{ fontFamily: codeFontFamily(), fontSize: '1.1em', color: colors.N300 }}>{primarySeq}</span>
//           </div>
//         </PanelStateless>
//       </ExpandableInfo>
//     </GridColumn>
//   );
// };

interface Props {
  oligos: OligoEdge[];
  isLoading: boolean;
  // setFilters: Function;
  orderByList: OligoOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function OligoList({
  oligos,
  isLoading,
  // setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props) {
  const head = {
    cells: [
      createTableHead<Oligo>({
        fieldKey: 'id',
        fieldName: 'ID',
        width: 1,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Oligo>({
        fieldKey: 'name',
        fieldName: 'Name',
        width: 5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Oligo>({
        fieldKey: 'length',
        fieldName: 'Len',
        width: 1,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<Oligo>({
      //   fieldKey: 'primarySeq',
      //   fieldName: 'Sequence',
      //   width: 18,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      {
        isSortable: false,
        width: 20,
        shouldTruncate: false,
        key: 'idtSeq',
        colSpan: 1,
        content: 'IDT sequence',
      },
      {
        isSortable: false,
        width: 3,
        shouldTruncate: false,
        key: 'mods',
        colSpan: 1,
        content: 'Modifications',
      },
      createTableHead<Oligo>({
        fieldKey: 'mass',
        fieldName: 'Mass',
        width: 2,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<Oligo>({
        fieldKey: 'ec260',
        fieldName: (
          <span>EC<sub style={{ fontSize: '0.6rem' }}>260</sub></span>
        ),
        width: 2.5,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<Oligo>({
      //   fieldKey: 'atContent',
      //   fieldName: 'AT %',
      //   width: 3,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<Oligo>({
      //   fieldKey: 'gcContent',
      //   fieldName: 'GC %',
      //   width: 3,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<Oligo>({
      //   fieldKey: 'tm',
      //   fieldName: (
      //     <span>T<sub style={{ fontSize: '0.6rem' }}>m</sub></span>
      //   ),
      //   width: 3,
      //   // setFilters,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<Oligo>({
        fieldKey: 'coreSeq',
        fieldName: 'Core sequence',
        width: 4,
        // setFilters,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // {
      //   isSortable: false,
      //   width: 16,
      //   shouldTruncate: false,
      //   key: 'ntIds',
      //   colSpan: 1,
      //   content: 'Nucleotide IDs',
      // },
      {
        isSortable: false,
        width: 1.5,
        shouldTruncate: false,
        key: 'actions',
        colSpan: 1,
        content: '',
      },
    ],
  };

  return (
    <StickyHeader withBar>
      <DynamicTableStateless
        head={head}
        rows={oligos.map(({
          node: {
            id,
            antisenseId,
            // sirnaDuplexId,
            // sirnaSense,
            coreSeq,
            name,
            aliases,
            cpIds,
            bbIds,
            mods,
            modsPos,
            // primarySeq,
            idtSeq,
            length,
            mass,
            ec260,
            // atContent,
            // gcContent,
            // tm,
            // display,
          },
        }) => ({
          cells: [
            {
              key: id,
              colSpan: 1,
              content: id,
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <Tooltip
                  content={(
                    <>
                      {name}
                      {aliases.length > 0 && <div>[ {aliases.join(', ')} ]</div>}
                    </>
                  )}
                  position="right"
                  delay={200}
                >
                  <span style={{ textOverflow: 'ellipsis', cursor: 'pointer' }}>{name}</span>
                </Tooltip>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: length,
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: (
            //     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            //       <ExpandableSeqInfo
            //         displaySeq={display.replace(/p/g, '').replace(/\*/g, '\u2217')}
            //         // displaySeq={display.split('').map(char => `${char === '*' ? '\u2217' : char}`)}
            //         primarySeq={primarySeq}
            //       />
            //       <Tooltip
            //         content="Copy"
            //         position="top"
            //         delay={100}
            //         hideTooltipOnClick
            //       >
            //         <div style={{ cursor: 'pointer', marginTop: '6px' }}>
            //           {/* TODO: Replace text with displayIdt. */}
            //           <CopyToClipboard text={display.replace(/p/g, '').replace(/\+mC/g, '+C')}>
            //             <span>
            //               <CopyIcon
            //                 size="small"
            //                 primaryColor={colors.DN80A}
            //                 label="Copy to clipboard"
            //               />
            //             </span>
            //           </CopyToClipboard>
            //         </div>
            //       </Tooltip>
            //     </div>
            //   ),
            // },
            {
              key: id,
              colSpan: 1,
              content: (
                <Code style={{ fontSize: '0.92rem', backgroundColor: 'transparent', whiteSpace: 'nowrap' }}>
                  {idtSeq?.map((idtSymbol, i) => {
                    const display = idtSymbol ? ['A', 'C', 'G', 'T', 'rA', 'rC', 'rG', 'rU', 'mA', 'mC', 'mG', 'mU', '+A', '+C', '+G', '+T'].includes(idtSymbol) ? idtSymbol : `/${i === 0 ? '5' : i === idtSeq.length - 1 ? '3' : 'i'}${idtSymbol}/` : '-';

                    return `${display}${bbIds[i] === '2' ? '\u2217' : ''}${((i % 3 === 2) && (i !== idtSeq.length - 1)) ? ' ' : ''}`;
                  }) || ''}
                </Code>
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: mods ? mods.map(({ name }, i) => (
                <span key={modsPos ? modsPos[i] : ''}>
                  {name}
                  {modsPos ? (
                    <span>
                      @
                      <Badge appearance={'added'}>{modsPos[i] + 1}</Badge>
                    </span>
                  ) : null}
                </span>
              )) : '\u2212',
            },
            {
              key: id,
              colSpan: 1,
              content: mass.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
            },
            {
              key: id,
              colSpan: 1,
              content: ec260.toLocaleString(),
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: atContent.toFixed(1),
            // },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: gcContent.toFixed(1),
            // },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: tm.toFixed(1),
            // },
            {
              key: id,
              colSpan: 1,
              content: coreSeq ? (
                <div style={{ paddingTop: '1px', whiteSpace: 'nowrap' }}>
                  <span style={{ fontFamily: codeFontFamily(), fontSize: '1.1em' }}>{coreSeq}{` `}</span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // TODO: Link to antisense/sirnaDuplex page
                    }}
                  >
                  <ShortcutIcon
                    size="small"
                    primaryColor={colors.B500}
                    label="Go to antisense/sirnaDuplex page"
                  />
                </span>
                </div>
              ) : '\u2212',
            },
            // {
            //   key: id,
            //   colSpan: 1,
            //   content: ntIds.join(','),
            // },
            {
              key: id,
              colSpan: 1,
              content: (
                <div style={{ width: '36px' }}>
                  <Button
                    iconBefore={<MoreIcon label="More icon" size="medium" />}
                    shouldFitContainer
                    appearance="subtle"
                  />
                </div>
              ),
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No oligos found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default OligoList;
