import React from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

interface Props {
  fieldName: string;
  fieldLabel: string;
  autoFocus?: boolean;
}

function NameField({ fieldName = 'name', fieldLabel = 'name', autoFocus = false }: Props): JSX.Element {
  return (
    <Field
      name={fieldName}
      label={fieldLabel}
      defaultValue=""
      validate={async value => {
        if (!value) {
          return `${fieldLabel} is required.`;
        }
      }}
    >
      {({ fieldProps, error }) => (
        <>
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder={`Enter ${fieldLabel}`}
            autoFocus={autoFocus}
          />
          {!error && <HelperMessage>&nbsp;</HelperMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
}

export default NameField;
