import Lozenge from '@atlaskit/lozenge';
import React from 'react';
import Select from '@atlaskit/select';

import { SelectOptionStringValueType } from 'types/select-option';

const options: SelectOptionStringValueType[] = [
  { label: '\u2013', value: '' },
  { label: <Lozenge isBold appearance="success">Grab</Lozenge>, value: 'grab' },
  { label: <Lozenge isBold appearance="removed">Lala</Lozenge>, value: 'lala' },
  { label: <Lozenge isBold appearance="inprogress">In-person</Lozenge>, value: 'in-person' },
  { label: <Lozenge isBold appearance="new">digital</Lozenge>, value: 'digital' },
];

interface Props {
  deliveryMode: SelectOptionStringValueType;
  setDeliveryMode: (value: SelectOptionStringValueType) => void;
  setCurrentPage?: (num: number) => void;
}

function DeliveryModesSelect({
  deliveryMode,
  setDeliveryMode,
  setCurrentPage,
}: Props): JSX.Element {
  const onDeliveryModeSelectChange = (value: SelectOptionStringValueType | null): void => {
    if (value) setDeliveryMode(value);
    if (setCurrentPage) setCurrentPage(1);
  };

  return (
    <Select
      appearance="subtle"
      spacing="compact"
      options={options}
      value={deliveryMode}
      onChange={onDeliveryModeSelectChange}
      placeholder={deliveryMode ? deliveryMode.label : undefined}
      // aria-label={deliveryMode.label}
      styles={{ control: base => ({ ...base, width: '120px', height: '36px' }) }}
    />
  );
}

export default DeliveryModesSelect;
