import { Alert, AlertActionType } from './types';

interface SetAlertAction {
  type: AlertActionType.SET_ALERT;
  payload: Omit<Alert, 'id'>;
}

export const setAlert = ({
  title,
  msg = '',
  icon,
  appearance,
}: Omit<Alert, 'id'>): SetAlertAction => ({
  type: AlertActionType.SET_ALERT,
  payload: {
    title,
    msg,
    icon,
    appearance,
  },
});

interface RemoveAlertAction {
  type: AlertActionType.REMOVE_ALERT;
}

export const removeAlert = (): RemoveAlertAction => ({
  type: AlertActionType.REMOVE_ALERT,
});

export type AlertAction = SetAlertAction | RemoveAlertAction;
