import { MenuGroup, Section } from '@atlaskit/menu';
import React from 'react';
import { useMatch } from 'react-router-dom';

import { useAuth } from 'contexts/auth';

// import {
//   CompanySortField,
//   CustomerSortField,
//   DeliverySortField,
//   OrderSortField,
//   OrderStatusSelect,
//   ProductSortField,
//   useGetAllCompaniesLazyQuery,
//   useGetAllCustomersLazyQuery,
//   useGetAllDeliveriesLazyQuery,
//   useGetAllOrdersLazyQuery,
//   useGetAllOrderTypesLazyQuery,
//   useGetAllProductsLazyQuery,
// } from 'apollo/graphql';
import ButtonLink from '../button-link';
import PrimaryDropdown, { ContentProps } from './primary-dropdown';

const Order = ({ onClose }: ContentProps): JSX.Element => {
  return (
    <MenuGroup minWidth="240px">
      {/*<Section>*/}
      {/*  <ButtonLink to="/products" onClick={onClose}>*/}
      {/*    Products*/}
      {/*  </ButtonLink>*/}
      {/*</Section>*/}
      <Section hasSeparator>
        <ButtonLink to="/companies" onClick={onClose}>
          Companies
        </ButtonLink>
        <ButtonLink to="/customers" onClick={onClose}>
          Customers
        </ButtonLink>
      </Section>
      <Section hasSeparator>
        <ButtonLink to="/orders" onClick={onClose}>
          Orders
        </ButtonLink>
        <ButtonLink to="/deliveries" onClick={onClose}>
          Deliveries
        </ButtonLink>
      </Section>
    </MenuGroup>
  );
};

const OrderContent = (): JSX.Element | null => {
  const { currentUser } = useAuth()!;

  // const match1 = useMatch('/products');
  // const match2 = useMatch('/products/*');
  const match1 = useMatch('/companies');
  const match2 = useMatch('/companies/*');
  const match3 = useMatch('/customers');
  const match4 = useMatch('/customers/*');
  const match5 = useMatch('/orders');
  const match6 = useMatch('/orders/*');
  const match7 = useMatch('/deliveries');
  const match8 = useMatch('/deliveries/*');

  // const [getAllProducts] = useGetAllProductsLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 25,
  //     filter: {
  //       search: '',
  //     },
  //     orderBy: [ProductSortField.NameAsc],
  //   },
  // });

  // const [getAllCompanies] = useGetAllCompaniesLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 25,
  //     filter: {
  //       search: '',
  //     },
  //     orderBy: [CompanySortField.IdAsc],
  //   },
  // });

  // const [getAllCustomers] = useGetAllCustomersLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 25,
  //     filter: {
  //       search: '',
  //       companies: [],
  //     },
  //     orderBy: [CustomerSortField.FullNameAsc],
  //   },
  // });

  // const [getAllOrderTypes] = useGetAllOrderTypesLazyQuery();

  // const [getAllOrders] = useGetAllOrdersLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 20,
  //     filter: {
  //       po: '',
  //       orderTypes: [],
  //       customers: [],
  //       companies: [],
  //       year: -1,
  //       status: OrderStatusSelect.None,
  //     },
  //     orderBy: [OrderSortField.IdDesc],
  //   },
  // });

  // const [getAllDeliveries] = useGetAllDeliveriesLazyQuery({
  //   variables: {
  //     page: 1,
  //     perPage: 20,
  //     filter: {
  //       search: '',
  //     },
  //     orderBy: [DeliverySortField.DeliverAtDesc],
  //   },
  // });

  return currentUser?.isOrders
    ? (
      <span
        onMouseOver={() => {
          // getAllProducts();
          // getAllCompanies();
          // getAllCustomers();
          // getAllOrderTypes();
          // getAllOrders();
          // getAllDeliveries();
        }}
      >
        <PrimaryDropdown
          content={Order}
          text="Orders"
          isHighlighted={!!match1 || !!match2 || !!match3 || !!match4 || !!match5 || !!match6 || !!match7 || !!match8}
        />
      </span>
    ) : null;
};

export default OrderContent;
