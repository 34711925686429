import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import { Helmet } from 'react-helmet-async';
import PageHeader from '@atlaskit/page-header';
import Form, { FormHeader, FormSection } from '@atlaskit/form';
import { LoadingButton } from '@atlaskit/button';

import { UpdateUserPasswordInput, useUpdateUserPasswordMutation } from 'apollo/graphql';
import { PasswordField } from 'components/forms/auth/fields';
import { setAlert } from 'redux/alerts';
import { useAuth } from 'contexts/auth';

function Security(): JSX.Element {
  const { currentUser } = useAuth()!;
  const dispatch = useDispatch();

  const [count, setCount] = useState<number>(0);
  const reset = () => setCount(prevCount => prevCount + 1);

  const [updatePassword, { loading }] = useUpdateUserPasswordMutation({
    onCompleted: ({ updateUserPassword: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
  });

  return (
    <Page>
      <Helmet title="My Profile" />

      <Grid>
        <GridColumn medium={12}>
          <PageHeader>Security</PageHeader>
          <Form
            onSubmit={({ password, newPassword }: UpdateUserPasswordInput) => {
              updatePassword({
                variables: {
                  id: currentUser!.id,
                  password,
                  newPassword,
                },
              });
            }}
            key={count}
          >
            {({ formProps }) => (
              <form {...formProps} noValidate style={{ width: '420px' }}>
                <FormHeader title={<span style={{ fontSize: '0.7em' }}>Change your password</span>} />
                <FormSection>
                  <PasswordField
                    name="password"
                    label="Current password"
                    placeholder="Enter current password"
                  />
                  <PasswordField
                    name="newPassword"
                    label="New password"
                    placeholder="Enter new password"
                  />
                </FormSection>
                <div style={{ textAlign: 'left', marginTop: '10px' }}>
                  <LoadingButton
                    type="submit"
                    appearance="primary"
                    isLoading={loading}
                  >
                    Save changes
                  </LoadingButton>
                </div>
              </form>
            )}
          </Form>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Security;
