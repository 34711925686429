import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import Loader from './loader';

const UnauthenticatedApp = (): JSX.Element => {
  const { pathname } = useLocation();

  const { login } = useAuth()!;
  login();  // Redirect to Auth0 login page.

  return pathname === '/login' ? (
    <Routes>
      <Route path="/login" element={<Loader />} />
    </Routes>
  ) : <Loader />;
};

export default UnauthenticatedApp;
