import React from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

import { useAuth } from '../contexts/auth';

const Index = (): JSX.Element => {
  const { currentUser } = useAuth()!;

  return (
    <Page>
      <Helmet title="Home" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            Welcome to NAS Bioscience, {currentUser?.username}!
          </PageHeader>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default Index;
