import { useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@atlaskit/button';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import Drawer from '@atlaskit/drawer';
import Tooltip from '@atlaskit/tooltip';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { SelectedType } from '@atlaskit/tabs/types';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import { antisenseCartItemsVar } from 'apollo/cache';
import AntisenseBadge from '../antisense/antisense-badge';
import CreateGapmerTab from '../antisense/create-gapmer-tab';
import CreateMixmerTab from '../antisense/create-mixmer-tab';

interface Props extends DrawerProps {
  isOpen: boolean;
}

function AntisenseCartDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();
  const antisenseCartItems = useReactiveVar(antisenseCartItemsVar);

  const [totalCount, setTotalCount] = useState<number>(
    antisenseCartItems.length,
  );

  useEffect(() => {
    setTotalCount(antisenseCartItemsVar().length);
  }, [antisenseCartItems]);

  const [selected, setSelected] = useState(0);

  const handleUpdate = useCallback(
    (index: SelectedType) => setSelected(index),
    [setSelected],
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ marginRight: '12px' }}>
        {totalCount ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '8px',
                marginBottom: '2px',
              }}
            >
              <AntisenseBadge/>
              <Tooltip content="Remove all" position="left" delay={100}>
                <Button
                  spacing="none"
                  iconBefore={
                    <EditorRemoveIcon label="delete icon">
                      remove all
                    </EditorRemoveIcon>
                  }
                  appearance="danger"
                  onClick={() => {
                    setTotalCount(0);
                    antisenseCartItemsVar([]);
                  }}
                />
              </Tooltip>
            </div>
            <Tabs onChange={handleUpdate} selected={selected} id="create-asos">
              <TabList>
                <Tab>Gapmer</Tab>
                <Tab>Mixmer</Tab>
              </TabList>
              <TabPanel>
                <CreateGapmerTab setTotalCount={setTotalCount}/>
              </TabPanel>
              <TabPanel>
                <CreateMixmerTab setTotalCount={setTotalCount}/>
              </TabPanel>
            </Tabs>
          </>
        ) : (
          <h4 style={{ margin: '60px', textAlign: 'center' }}>
            No antisense sequences in cart.
          </h4>
        )}
      </div>
    </Drawer>
  );
}

export default AntisenseCartDrawer;
