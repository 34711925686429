import styled from 'styled-components';

const Card = styled.div`
  min-width: 140px;
  box-sizing: border-box;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
  cursor: pointer;
  border-radius: 3px;
  transition: box-shadow 250ms ease-in-out 0s;
  padding: 3px 8px;
  margin: 8px 0;

  &:hover {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 8px 16px -4px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  }
`;

export default Card;
