import React from 'react';
import { ErrorMessage, Field, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

interface Props {
  autoFocus?: boolean;
}

function UsernameField({ autoFocus = false }: Props): JSX.Element {
  return (
    <Field
      name="username"
      label="Username"
      defaultValue=""
      validate={value => {
        if (!value) {
          return 'Username is required.';
        } else if (!value.match(/^[a-zA-Z][a-zA-Z0-9_.]{2,}$/)) {
          return 'Invalid username.';
        } else if (value.length > 15) {
          return 'Username should not exceed 15 characters.';
        }
      }}
    >
      {({ fieldProps, error }) => (
        <>
          <Textfield
            {...fieldProps}
            autoComplete="off"
            placeholder="Enter username"
            autoFocus={autoFocus}
          />
          {!error && <HelperMessage>&nbsp;</HelperMessage>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    </Field>
  );
}

export default UsernameField;
