import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';

import { SirnaDuplex, SirnaDuplexEdge } from 'apollo/graphql';
import { SirnaDuplexOrderBy } from 'pages/antisense/target-sirna-series';
import createTableHead from '../tables/create-table-head';
import StickyHeader from '../tables/sticky-header';
// import AntisenseCheckbox from './antisense-checkbox';
import ExpandableSirnaDuplexInfo from './expandable-sirna-info';
import SirnaCheckbox from './sirna-checkbox';

interface Props {
  targetId: string;
  targetLength?: number;
  symbol: string;
  sirnaDuplexes: SirnaDuplexEdge[];
  flipDuplex?: boolean;
  isLoading: boolean;
  setFilters?: Function;
  orderByList: SirnaDuplexOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
  expandAll?: boolean;
}

const SirnaDuplexList: React.FC<Props> = ({
  targetId,
  targetLength,
  symbol,
  sirnaDuplexes,
  flipDuplex = false,
  isLoading,
  setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
  expandAll = false,
}): JSX.Element => {
  const head = {
    cells: [
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: "id",
        colSpan: 1,
        content: "",
      },
      createTableHead<SirnaDuplex>({
        fieldKey: 'startAs',
        fieldName: <span>Start<sub>AS</sub></span>,
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'endAs',
        fieldName: <span>End<sub>AS</sub></span>,
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'lengthAs',
        fieldName: <span>Length<sub>AS</sub></span>,
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'lengthS',
        fieldName: <span>Length<sub>S</sub></span>,
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'seqAs',
        fieldName: 'siRNA duplex (AS/S)',
        width: 15,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'exonId',
        fieldName: 'Exon',
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'region',
        fieldName: 'Region',
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'atContentSeed',
        fieldName: <span>AT %&lt;s&gt;</span>,
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'tmSeed',
        fieldName: <span>T<sub>m</sub>&lt;s&gt;</span>,
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'tm',
        fieldName: <span>T<sub>m</sub></span>,
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'blastMatchesAs',
        fieldName: <span>Match<sub>AS</sub></span>,
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<SirnaDuplex>({
        fieldKey: 'blastMatchesS',
        fieldName: <span>Match<sub>S</sub></span>,
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'gcContent',
      //   fieldName: 'GC %',
      //   width: 6,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'hairpin',
      //   fieldName: 'Hairpin',
      //   width: 6,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'cpgCount',
      //   fieldName: 'CpG',
      //   width: 5,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'blastNextBest',
      //   fieldName: 'Next best',
      //   width: 6,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
    ],
  };

  return (
    <StickyHeader withBreadcrumbs withBar alignTop>
      <DynamicTableStateless
        head={head}
        rows={sirnaDuplexes.map(({
          node: {
            id,
            startAs,
            endAs,
            lengthAs,
            seqAs,
            startS,
            endS,
            lengthS,
            seqS,
            exonId,
            region,
            atContentSeed,
            tmSeed,
            tm,
            blastMatchesAs,
            blastMatchesS,
            isInCart,
          },
        }) => ({
          cells: [
            {
                key: id,
                colSpan: 1,
                content: (
                  <SirnaCheckbox
                    key={id}
                    targetId={targetId}
                    targetLength={targetLength!}
                    symbol={symbol}
                    id={id}
                    startAs={startAs}
                    isInCart={isInCart}
                  />
                ),
              },
            {
              key: id,
              colSpan: 1,
              content: startAs.toLocaleString(),
            },
            {
              key: id,
              colSpan: 1,
              content: endAs.toLocaleString(),
            },
            {
              key: id,
              colSpan: 1,
              content: lengthAs,
            },
            {
              key: id,
              colSpan: 1,
              content: lengthS,
            },
            {
              key: id,
              colSpan: 1,
              content: (
                <ExpandableSirnaDuplexInfo
                  id={id}
                  seqTop={!flipDuplex ? ("5'-" + (endS - endAs > 0 ? '\u00a0'.repeat(endS - endAs) : '') + seqAs + (startAs - startS > 0 ? '\u00a0'.repeat(Math.abs(startAs - startS)) : '') + "-3'") : ("3'-" + (startAs - startS > 0 ? '\u00a0'.repeat(startAs - startS) : '') + seqAs.split('').reverse().join('') + (endS - endAs > 0 ? '\u00a0'.repeat(Math.abs(endS - endAs)) : '') + "-5'")}
                  basePairing={'\u00a0'.repeat(3) + (!flipDuplex ? '\u00a0'.repeat(Math.abs(endAs - endS)) : '\u00a0'.repeat(Math.abs(startS - startAs))) + '|'.repeat(endAs - (startS > startAs ? startS : startAs) - (endAs - endS > 0 ? endAs - endS : 0) + 1)}
                  seqBtm={!flipDuplex ? ("3'-" + (endAs - endS > 0 ? '\u00a0'.repeat(endAs - endS) : '') + seqS.split('').reverse().join('') + (startS - startAs > 0 ? '\u00a0'.repeat(Math.abs(startS - startAs)) : '') + "-5'") : ("5'-" + (startS - startAs > 0 ? '\u00a0'.repeat(startS - startAs) : '') + seqS + (endAs - endS > 0 ? '\u00a0'.repeat(Math.abs(endAs - endS)) : '') + "-3'")}
                  // seqTop={(endS - endAs > 0 ? '\u00a0'.repeat(endS - endAs) : '') + seqAs}
                  // basePairing={'\u00a0'.repeat(Math.abs(endAs - endS)) + '|'.repeat(endAs - (startS > startAs ? startS : startAs) - (endAs - endS > 0 ? endAs - endS : 0) + 1)}
                  // seqBtm={(endAs - endS > 0 ? '\u00a0'.repeat(endAs - endS) : '') + seqS.split('').reverse().join('')}
                  isOpen={expandAll}
                />
              ),
            },
            {
              key: id,
              colSpan: 1,
              content: exonId,
            },
            {
              key: id,
              colSpan: 1,
              content: region,
            },
            {
              key: id,
              colSpan: 1,
              content: atContentSeed!.toFixed(1),
            },
            {
              key: id,
              colSpan: 1,
              content: tmSeed!.toFixed(1),
            },
            {
              key: id,
              colSpan: 1,
              content: tm!.toFixed(1),
            },
            {
              key: id,
              colSpan: 1,
              content: blastMatchesAs || '\u2212',
            },
            {
              key: id,
              colSpan: 1,
              content: blastMatchesS || '\u2212',
            },
          ],
        }))}
        emptyView={(
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <h3>No siRNA duplexes found.</h3>
          </div>
        )}
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
};

export default SirnaDuplexList;
