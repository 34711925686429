import React from 'react';
import { useSelector } from 'react-redux';
import { ModalTransition } from '@atlaskit/modal-dialog';

import { selectModal } from 'redux/modals';

export enum ModalType {}

const modalLookup: Record<ModalType, React.FC<any>> = {};

const ModalManager = () => {
  let renderedModal: JSX.Element | null = null;

  const { modalType, ...modalProps } = useSelector(selectModal);
  if (modalType) {
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <ModalTransition>{renderedModal}</ModalTransition>;
};

export default ModalManager;
