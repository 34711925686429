/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface Props {
  children: ReactNode;
  withBar?: boolean;
}

function ScrollableContent({ children, withBar = false }: Props): JSX.Element {
  return (
    <PerfectScrollbar
      css={css`
        overflow-y: scroll;
        width: 100vw;
        height: ${withBar ? `calc(100vh - 262px)` : `calc(100vh - 214px)`};
        //min-height: 675px;
      `}
    >
      {children}
    </PerfectScrollbar>
  );
}

export default ScrollableContent;
