import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@atlaskit/button';
import Form, { FormFooter } from '@atlaskit/form';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import Drawer from '@atlaskit/drawer';

import { closeDrawer, DrawerProps } from 'redux/drawers';
import { CreateCompanyInput, GetAllCompaniesDocument, useCreateCompanyMutation } from 'apollo/graphql';
import { setAlert } from 'redux/alerts';
import IdField from '../forms/order/id-field';
import CompanyNameField from '../forms/order/company-name-field';

interface Props extends DrawerProps {
  isOpen: boolean;
  totalCount?: number;
}

function CreateCompanyDrawer({ isOpen }: Props): JSX.Element {
  const dispatch = useDispatch();

  const [count, setCount] = useState<number>(0);
  const reset = () => setCount(prevCount => prevCount + 1);

  const [createCompany, { loading }] = useCreateCompanyMutation({
    onCompleted: ({ createCompany: { success, message } }) => {
      dispatch(setAlert({
        title: message,
        icon: success
          ? <SuccessIcon primaryColor={colors.G300} label="Success" />
          : <ErrorIcon primaryColor={colors.R400} label="Error" />,
      }));
      if (success) reset();
    },
    refetchQueries: [GetAllCompaniesDocument],
  });

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      width="wide"
    >
      <div style={{ paddingRight: '20px' }}>
        <Form
          onSubmit={({ id, name }: CreateCompanyInput) => {
            createCompany({
              variables: { id, name },
            });
          }}
          key={count}
        >
          {({ formProps }) => (
            <form {...formProps} noValidate>
              <div>
                <IdField />
                <CompanyNameField />
              </div>
              <FormFooter>
                <LoadingButton
                  type="submit"
                  appearance="primary"
                  isLoading={loading}
                >
                <span style={{ fontWeight: 400 }}>
                  Submit
                </span>
                </LoadingButton>
              </FormFooter>
            </form>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default CreateCompanyDrawer;
