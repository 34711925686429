import { RootState } from '../store';
import { SidebarAction } from './actions';
import { Sidebar, SidebarActionType } from './types';

export const selectSidebar = (rootState: RootState) => rootState.sidebar;

const sidebarReducer = (
  state: Sidebar = { sidebarType: null },
  action: SidebarAction,
) => {
  switch (action.type) {
    case SidebarActionType.OPEN_SIDEBAR:
      return { ...action.payload };
    case SidebarActionType.CLOSE_SIDEBAR:
      return { sidebarType: null };
    default:
      return state;
  }
};

export default sidebarReducer;
