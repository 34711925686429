import React from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';

const NotFound = (): JSX.Element => {
  return (
    <Page>
      <Helmet title="Not found" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            Page not found
          </PageHeader>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default NotFound;
