import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { colors } from '@atlaskit/theme';

import { BuildingBlockSortField, CouplingConnection, useGetAllCouplingsQuery } from 'apollo/graphql';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import useWindowSize from 'hooks/use-window-size';
import CouplingCard from 'components/chemistry/coupling-card';
import ScrollableContent from 'components/tables/scrollable-content';
import SugarMultiSelect from 'components/chemistry/sugar-multi-select';
import BaseMultiSelect from 'components/chemistry/base-multi-select';
import BackboneMultiSelect from '../../components/chemistry/backbone-multi-select';

function Couplings(): JSX.Element {
  const size = useWindowSize();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    // RowsSelect,
    TablePagination,
  } = usePagination({
    // defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 20,
    defaultTotalCount: 113,
    defaultFilteredCount: 113,
    defaultPageCount: 6,
  });

  // Setup filters.
  const [setSearch, { search, debouncedSearch }] = useSearch();
  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const [sugarIds, setSugarIds] = useState<string[]>([]);
  const [baseIds, setBaseIds] = useState<string[]>([]);
  const [backboneIds, setBackboneIds] = useState<string[]>([]);

  const { loading, data, previousData } = useGetAllCouplingsQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        search: debouncedSearch,
        sugarIds,
        baseIds,
        backboneIds,
      },
      orderBy: ['id_ASC' as BuildingBlockSortField],
    },
  });

  useEffect(() => {
    updatePagination<CouplingConnection>(data?.couplings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const barContent = (
    <div style={{ display: 'flex', paddingLeft: '6px' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
      <SugarMultiSelect setSugarIds={setSugarIds} setCurrentPage={setCurrentPage} />
      <BaseMultiSelect setBaseIds={setBaseIds} setCurrentPage={setCurrentPage} />
      <BackboneMultiSelect setBackboneIds={setBackboneIds} setCurrentPage={setCurrentPage} />
    </div>
  );

  const couplings = data ? data.couplings.edges : previousData?.couplings.edges || [];

  return (
    <Page>
      <Helmet title="Couplings" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader bottomBar={barContent}>
            <span style={{ paddingLeft: '6px' }}>Couplings</span>
          </PageHeader>
        </GridColumn>

        <ScrollableContent withBar>
          <Grid layout="fluid">
            {!loading && couplings.length === 0
              ? <h3 style={{ margin: '40px auto' }}>No couplings found.</h3>
              : couplings.map(({ node: { id, symbol, name, mass, cml } }) => (
                <CouplingCard
                  id={id}
                  symbol={symbol}
                  name={name}
                  mass={mass}
                  structure={cml || ''}
                  key={`${id}_${size.width}`}
                />
              ))
            }
          </Grid>
        </ScrollableContent>

        <GridColumn medium={12}>
          <span style={{ paddingLeft: '6px' }}>
            <TablePagination />
          </span>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Couplings;
