import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@atlaskit/icon/glyph/search';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import Button, { ButtonGroup } from '@atlaskit/button';

import { useGetAllUsersQuery, User, UserConnection, UserSortField } from 'apollo/graphql';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import { DrawerType } from 'components/drawers/drawer-manager';
import { openDrawer } from 'redux/drawers';
import UserList from 'components/admin/user-list';
import useSearch from 'hooks/use-search';
import AdminOnly from 'components/admin/admin-only';
import useAdmin from 'hooks/use-admin';

export interface UserOrderBy {
  field: Exclude<keyof User, '__typename'>;
  order: AscOrDesc;
}

function Users(): JSX.Element {
  useAdmin();
  const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [25, 50, 100],
    defaultRowsPerPage: 25,
    defaultTotalCount: 2,
    defaultFilteredCount: 2,
    defaultPageCount: 1,
  });

  // Setup filters.
  const [adminOnly, setAdminOnly] = useState<boolean>(false);
  const [setSearch, { search, debouncedSearch }] = useSearch();

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<UserOrderBy[]>([{ field: 'username', order: 'ASC' }]);

  const { loading, data, previousData, startPolling } = useGetAllUsersQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        ...(adminOnly && { adminOnly }),
        search: debouncedSearch.length > 2 ? debouncedSearch : '',
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as UserSortField),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    updatePagination<UserConnection>(data?.users);
    if (data) startPolling(30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <RowsSelect />
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateUserDrawer))}
      >
        <span style={{ fontWeight: 400 }}>
          Create user
        </span>
      </Button>
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }} />
            </>
          }
        />
      </div>
      <div style={{ marginLeft: '8px' }}>
        <AdminOnly
          adminOnly={adminOnly}
          setAdminOnly={setAdminOnly}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );

  return (
    <Page>
      <Helmet title="Users" />

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Users
          </PageHeader>

          <UserList
            users={data?.users.edges || previousData?.users.edges || []}
            isLoading={loading}
            // setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination />
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Users;
