import React from "react";
import { DynamicTableStateless } from "@atlaskit/dynamic-table";
// import Lozenge from '@atlaskit/lozenge';
import { codeFontFamily } from "@atlaskit/theme";

import { AntisenseSequence, AntisenseSequenceEdge } from "apollo/graphql";
import { AntisenseSequenceOrderBy } from "pages/antisense/target-antisense-series";
import createTableHead from "../tables/create-table-head";
import StickyHeader from "../tables/sticky-header";
import AntisenseCheckbox from "./antisense-checkbox";
import OffTargetPopup from "./off-target-popup";

interface Props {
  targetId: string;
  targetLength?: number;
  symbol: string;
  spliced: boolean;
  antisenseSequences: AntisenseSequenceEdge[];
  isLoading: boolean;
  setFilters?: Function;
  orderByList: AntisenseSequenceOrderBy[];
  setOrderByList: Function;
  setCurrentPage: (num: number) => void;
}

function AntisenseSequenceList({
  targetId,
  targetLength,
  symbol,
  spliced,
  antisenseSequences,
  isLoading,
  setFilters,
  orderByList,
  setOrderByList,
  setCurrentPage,
}: Props): JSX.Element {
  const head = {
    cells: [
      {
        isSortable: false,
        width: 2,
        shouldTruncate: false,
        key: "id",
        colSpan: 1,
        content: "",
      },
      createTableHead<AntisenseSequence>({
        fieldKey: "start",
        fieldName: "Start",
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "end",
        fieldName: "End",
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "length",
        fieldName: "Len",
        width: 3,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "seq",
        fieldName: "Antisense sequence",
        width: 12.5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: spliced ? "exonId" : "intronId",
        fieldName: spliced ? "Exon" : "Intron",
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "region",
        fieldName: "Region",
        width: 6,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "atContent",
        fieldName: "AT %",
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "gcContent",
        fieldName: "GC %",
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "tm",
        fieldName: (
          <span>
            T<sub>m</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "hairpin",
        fieldName: "hp",
        width: 3.5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'before40',
      //   fieldName: <span>5'<sub>40</sub></span>,
      //   width: 4,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<AntisenseSequence>({
        fieldKey: "before20",
        fieldName: (
          <span>
            5'<sub>20</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "before10",
        fieldName: (
          <span>
            5'<sub>10</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "before5",
        fieldName: (
          <span>
            5'<sub>5</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "front8",
        fieldName: (
          <span>
            F<sub>8</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "mid8",
        fieldName: (
          <span>
            M<sub>8</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'mid16',
      //   fieldName: <span>mid<sub>16</sub></span>,
      //   width: 4,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<AntisenseSequence>({
        fieldKey: "back8",
        fieldName: (
          <span>
            B<sub>8</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "after5",
        fieldName: (
          <span>
            3'<sub>5</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "after10",
        fieldName: (
          <span>
            3'<sub>10</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      createTableHead<AntisenseSequence>({
        fieldKey: "after20",
        fieldName: (
          <span>
            3'<sub>20</sub>
          </span>
        ),
        width: 4,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'after40',
      //   fieldName: <span>3'<sub>40</sub></span>,
      //   width: 4,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<AntisenseSequence>({
        fieldKey: "cpgCount",
        fieldName: "CpG",
        width: 3.5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      // createTableHead<AntisenseSequence>({
      //   fieldKey: 'blastMatches',
      //   fieldName: 'Match',
      //   width: 5,
      //   setFilters,
      //   select: null,
      //   orderByList,
      //   setOrderByList,
      //   setCurrentPage,
      // }),
      createTableHead<AntisenseSequence>({
        fieldKey: "blastNextBest",
        fieldName: "Blast",
        width: 5,
        setFilters,
        select: null,
        orderByList,
        setOrderByList,
        setCurrentPage,
      }),
      {
        isSortable: false,
        width: 6,
        shouldTruncate: false,
        key: "oligos",
        colSpan: 1,
        content: "Oligos",
      },
    ],
  };

  return (
    <StickyHeader withBreadcrumbs withBar>
      <DynamicTableStateless
        head={head}
        rows={antisenseSequences.map(
          ({
            node: {
              target,
              id,
              start,
              end,
              length,
              exonId,
              intronId,
              region,
              seq,
              atContent,
              gcContent,
              tm,
              hairpin,
              // before40,
              before20,
              before10,
              before5,
              front8,
              mid8,
              // mid16,
              back8,
              after5,
              after10,
              after20,
              // after40,
              cpgCount,
              // blastMatches,
              blastNextBestLength,
              blastNextBestCount,
              // oligoIds,
              // oligoNames,
              isInCart,
            },
          }) => ({
            cells: [
              {
                key: id,
                colSpan: 1,
                content: (
                  <AntisenseCheckbox
                    key={id}
                    targetId={targetId}
                    targetLength={targetLength!}
                    symbol={symbol}
                    id={id}
                    start={start}
                    isInCart={isInCart}
                  />
                ),
              },
              {
                key: id,
                colSpan: 1,
                content: (
                  <div
                    onClick={() => {}}
                    // onClick={() => router.push('/antisense/[id]', `/antisense/${id}`)}
                  >
                    {start.toLocaleString()}
                  </div>
                ),
              },
              {
                key: id,
                colSpan: 1,
                content: end.toLocaleString(),
              },
              {
                key: id,
                colSpan: 1,
                content: length,
              },
              {
                key: id,
                colSpan: 1,
                content: (
                  <span
                    style={{
                      fontFamily: codeFontFamily(),
                      fontSize: "15px",
                    }}
                  >
                    {seq}
                  </span>
                ),
              },
              {
                key: id,
                colSpan: 1,
                content: spliced ? exonId : intronId,
              },
              {
                key: id,
                colSpan: 1,
                content: <span style={{ whiteSpace: 'nowrap' }}>{region}</span>,
              },
              {
                key: id,
                colSpan: 1,
                content: atContent!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: gcContent!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: tm!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: hairpin!.toFixed(1),
              },
              // {
              //   key: id,
              //   colSpan: 1,
              //   content: before40!.toFixed(1),
              // },
              {
                key: id,
                colSpan: 1,
                content: before20!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: before10!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: before5!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: front8!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: mid8!.toFixed(1),
              },
              // {
              //   key: id,
              //   colSpan: 1,
              //   content: mid16!.toFixed(1),
              // },
              {
                key: id,
                colSpan: 1,
                content: back8!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: after5!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: after10!.toFixed(1),
              },
              {
                key: id,
                colSpan: 1,
                content: after20!.toFixed(1),
              },
              // {
              //   key: id,
              //   colSpan: 1,
              //   content: after40!.toFixed(1),
              // },
              {
                key: id,
                colSpan: 1,
                content: cpgCount,
              },
              // {
              //   key: id,
              //   colSpan: 1,
              //   content: blastMatches || '\u2212',
              // },
              {
                key: id,
                colSpan: 1,
                content:
                  blastNextBestLength === 0 ? (
                    "\u2212"
                  ) : (
                    <OffTargetPopup
                      id={id}
                      length={length}
                      blastNextBestLength={blastNextBestLength!}
                      blastNextBestCount={blastNextBestCount!}
                    />
                  ),
              },
              {
                key: id,
                colSpan: 1,
                content: "\u2212",
              },
              // {
              //   key: id,
              //   colSpan: 1,
              //   content: (
              //     oligoIds?.length
              //       ? oligoIds.map((id, i) => (
              //         <span style={{ cursor: 'pointer' }}>
              //           <Lozenge appearance="success">{oligoNames![i]}</Lozenge>
              //         </span>
              //       ))
              //       : '\u2212'
              //   ),
              // },
            ],
          })
        )}
        emptyView={
          <div
            style={{
              width: "100%",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            <h3>No antisense sequences found.</h3>
          </div>
        }
        rowsPerPage={undefined}
        loadingSpinnerSize="large"
        isLoading={isLoading}
        isFixedSize
      />
    </StickyHeader>
  );
}

export default AntisenseSequenceList;
