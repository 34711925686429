import { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import React, { useEffect, useState } from 'react';
import { codeFontFamily, colors } from '@atlaskit/theme';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import Lozenge from '@atlaskit/lozenge';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Slider from 'rc-slider';

import { GapmerProps, OligoConfigProps } from './create-gapmer-tab';
import { antisenseCartItemsVar } from 'apollo/cache';
// import InlineEditableField from '../forms/oligo/inline-editable-field';
import {
  GetAllOligosDocument,
  GetAllOligosQuery,
  GetBaseBySymbolDocument,
  GetBaseBySymbolQuery,
  GetCouplingByCompositionDocument,
  GetCouplingByCompositionQuery,
} from 'apollo/graphql';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export const DeleteIcon = styled.div`
  color: ${colors.N50};
  padding-top: 3px;
  cursor: pointer;

  &:hover {
    color: ${colors.R400};
  }

  &:active {
    color: ${colors.R500};
  }
`;

interface Props {
  id: string;
  name: string;
  seq: string;
  ps: boolean;
  wing5: number;
  wing5SugarId: string;
  wing3: number;
  wing3SugarId: string;
  setAntisenseList: Function;
  setCpIdsList: Function;
  setTotalCount: Function;
}

function GapmerItem({
  id,
  name,
  seq,
  ps,
  wing5,
  wing5SugarId,
  wing3,
  wing3SugarId,
  setAntisenseList,
  setCpIdsList,
  setTotalCount,
}: Props): JSX.Element {
  const client = useApolloClient();

  const [oligoName, setOligoName] = useState<string>(name);

  // Sugar/backbone
  const gapSugarId = '1';
  const backboneId = ps ? '2' : '1';

  const removeItem = (id: string) => {
    setAntisenseList((prevAntisenseList: GapmerProps[]) => {
      return prevAntisenseList.filter(({ antisenseId }) => antisenseId !== id);
    });
    antisenseCartItemsVar(antisenseCartItemsVar().filter(({ antisenseId }) => antisenseId !== id));
  };

  const setWing = (wing: 5 | 3) => (value: number) => {
    setAntisenseList((prevAntisenseList: GapmerProps[]) => {
      return prevAntisenseList.map(({ antisenseId, ...rest }) => {
        if (antisenseId === id) {
          return { antisenseId, ...rest, ...(wing === 5 ? { wing5: value } : { wing3: value }) };
        } else {
          return { antisenseId, ...rest };
        }
      });
    });
    antisenseCartItemsVar(antisenseCartItemsVar().map(({ antisenseId, ...rest }) => {
      if (antisenseId === id) {
        return { antisenseId, ...rest, ...(wing === 5 ? { wing5: value } : { wing3: value }) };
      } else {
        return { antisenseId, ...rest };
      }
    }));
  };

  const setWing5 = setWing(5);
  const setWing3 = setWing(3);

  useEffect(() => {
    interface NtConfigProps {
      sugarId: string;
      baseId: string;
      backboneId: string;
    }

    const configuration: NtConfigProps[] = [];

    const generateNucleotideIds = async (wing5seq: string, gap: string, wing3seq: string) => {
      for (const x of wing5seq) {
        await client.query<GetBaseBySymbolQuery>({
          query: GetBaseBySymbolDocument,
          variables: { symbol: x === 'C' && (wing5SugarId === '5' || wing5SugarId === '6') ? 'mC' : x },
        })
          .then(({ data }) => {
            configuration.push({
              sugarId: wing5SugarId,
              baseId: data!.baseBySymbol!.id,
              backboneId,
            });
          });
      }
      for (const x of gap) {
        await client.query<GetBaseBySymbolQuery>({
          query: GetBaseBySymbolDocument,
          variables: { symbol: x },
        })
          .then(({ data }) => {
            configuration.push({
              sugarId: gapSugarId,
              baseId: data!.baseBySymbol!.id,
              backboneId,
            });
          });
      }
      for (const [i, x] of wing3seq.split('').entries()) {
        await client.query<GetBaseBySymbolQuery>({
          query: GetBaseBySymbolDocument,
          variables: { symbol: x === 'C' && (wing3SugarId === '5' || wing3SugarId === '6') ? 'mC' : x },
        })
          .then(({ data }) => {
            configuration.push({
              sugarId: wing3SugarId,
              baseId: data!.baseBySymbol!.id,
              backboneId: i === wing3seq.length - 1 ? '0' : backboneId,
            });
          });
      }

      const cpIds: string[] = [];

      for (const cp of configuration) {
        await client.query<GetCouplingByCompositionQuery>({
          query: GetCouplingByCompositionDocument,
          variables: {
            filter: {
              sugarId: cp.sugarId,
              baseId: cp.baseId,
              backboneId: cp.backboneId,
            },
          },
        })
          .then(({ data }) => {
            cpIds.push(data?.couplingByComposition?.id!);
          });
      }

      return cpIds;
    };

    generateNucleotideIds(
      seq.substring(0, wing5),
      seq.substring(wing5, seq.length - wing3),
      seq.substring(seq.length - wing3),
    )
      .then(cpIds => {
        setCpIdsList((prevNtIdsList: OligoConfigProps[]) => {
          if (prevNtIdsList.find(({ antisenseId }) => antisenseId === id)) {
            return prevNtIdsList.map(({ antisenseId, ...rest }) => {
              if (antisenseId === id) {
                return { antisenseId, cpIds };
              } else {
                return { antisenseId, ...rest };
              }
            });
          } else {
            return [...prevNtIdsList, { antisenseId: id, cpIds }];
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backboneId, wing5, wing5SugarId, wing3, wing3SugarId]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px',
      }}
    >
      <div>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            width: '384px',
          }}
        >
          <div style={{ display: 'inline-flex', marginTop: '8px' }}>
            <div style={{ paddingTop: '3px' }}>
              <Lozenge
                isBold
                appearance="default"
              >
                {id}
              </Lozenge>
            </div>
            <DeleteIcon
              onClick={() => {
                setTotalCount((prevTotalCount: number) => prevTotalCount - 1);
                removeItem(id);
              }}
            >
              <EditorCloseIcon label="Remove item" />
            </DeleteIcon>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <Field
              name={`name${id}`}
              defaultValue={oligoName}
              validate={async (value) => {
                if (!value) {
                  return 'Name is empty';
                } else {
                  const { data } = await client.query<GetAllOligosQuery>({
                    query: GetAllOligosDocument,
                    variables: {
                      filter: { name: value },
                    },
                    fetchPolicy: 'network-only',
                  });
                  if (data && data.oligos.filteredCount) {
                    return 'Oligo already exists.';
                  }

                  setOligoName(value);
                  setAntisenseList((prevAntisenseList: any) => {
                    return prevAntisenseList.map(({ antisenseId, ...rest }: { antisenseId: any }) => {
                      if (antisenseId === id) {
                        return { antisenseId, ...rest, name: value };
                      } else {
                        return { antisenseId, ...rest };
                      }
                    });
                  });
                }
              }}
            >
              {({ fieldProps, error }) => (
                <div style={{ display: 'flex', width: '310px' }}>
                  <div style={{ width: '172px' }}>
                    <Textfield
                      {...fieldProps}
                      appearance={'subtle'}
                      autoComplete="off"
                      placeholder="Enter name"
                      // value={oligoName}
                      // autoFocus={autoFocus}
                      // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      //   setOligoName(event.target.value);
                      //   setAntisenseList((prevAntisenseList: any) => {
                      //     return prevAntisenseList.map(({ antisenseId, ...rest }: { antisenseId: any }) => {
                      //       if (antisenseId === id) {
                      //         return { antisenseId, ...rest, name: event.target.value };
                      //       } else {
                      //         return { antisenseId, ...rest };
                      //       }
                      //     });
                      //   });
                      // }}
                      style={{ paddingTop: '0', paddingBottom: '0', marginTop: '-6px', marginBottom: '-5px' }}
                    />
                  </div>
                  {error && (
                    <div style={{ marginTop: '3px', marginLeft: '4px', width: '124px' }}>
                      <ErrorMessage>{error}</ErrorMessage>
                    </div>
                  )}
                </div>
                // <InlineEditableField
                //   validate={() => {
                //     // if name exists, update oligoName to '' & antisenseCartItemsVar() to { name: '' }
                //   }}
                //   fieldValue={oligoName || '-'}
                //   // validate={async value => {
                //   //   return await validateOligoName(value, client);
                //   // }}
                //   onConfirm={value => {
                //     setOligoName(value);
                //     setAntisenseList((prevAntisenseList: GapmerProps[]) => {
                //       return prevAntisenseList.map(({ antisenseId, ...rest }) => {
                //         if (antisenseId === id) {
                //           return { antisenseId, ...rest, name: value };
                //         } else {
                //           return { antisenseId, ...rest };
                //         }
                //       });
                //     });
                //   }}
                //   fitWidth
                //   hideActionButtons={false}
                // />
              )}
            </Field>
          </div>
        </div>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '315px',
            fontFamily: codeFontFamily(),
            fontSize: seq.length > 18 ? '1.05em' : '1.1em',
          }}
        >
          <span>{[...seq.substring(0, wing5)].map(char => (char + (ps ? '\u2217' : '\u22c5')))}</span>
          <span>{[...seq.substring(wing5, seq.length - wing3)].map(char => (char + (ps ? '\u2217' : '\u22c5')))}</span>
          <span>{seq.substring(seq.length - wing3).split('').join(ps ? '\u2217' : '\u22c5')}</span>
        </div>
      </div>
      <div
        style={{
          width: `${seq.length * 7}px`,
          paddingTop: '14px',
        }}
      >
        <Range
          min={0}
          max={seq.length}
          value={[wing5, seq.length - wing3]}
          pushable
          // pushable={1}
          onChange={([value5, value3]: number[]) => {
            if (value5 < 1) {
              setWing5(1);
            } else if (value5 > seq.length - 2) {
              setWing5(seq.length - 2);
            } else {
              setWing5(value5);
            }
            if (seq.length - value3 < 1) {
              setWing3(1);
            } else if (value3 < 2) {
              setWing3(seq.length - 2);
            } else {
              setWing3(seq.length - value3);
            }
          }}
          trackStyle={[{ backgroundColor: colors.Y100 }]}
          // handleStyle={[{ backgroundColor: '#fff', borderColor: colors.B400 }, { backgroundColor: '#fff', borderColor: colors.B400 }]}
          railStyle={{ backgroundColor: '#abe2fb' }}
          // tipFormatter={(value: number) => `${value}`}
          tipProps={{ prefixCls: 'rc-slider-tooltip-hidden' }}
        />
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: `${seq.length * 7 - 10}px`,
            padding: '0 5px',
            fontSize: '0.75rem',
          }}
        >
          <span>{wing5}</span>
          <span>&minus;</span>
          <span>{seq.length - wing5 - wing3}</span>
          <span>&minus;</span>
          <span>{wing3}</span>
        </div>
      </div>
    </div>
  );
}

export default GapmerItem;
