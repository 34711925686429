import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BoardIcon from '@atlaskit/icon/glyph/board';
import PageIcon from '@atlaskit/icon/glyph/page';
import { Section } from '@atlaskit/menu';
import { LeftSidebar } from '@atlaskit/page-layout';
import { LinkItem, NavigationHeader, NestableNavigationContent, SideNavigation } from '@atlaskit/side-navigation';

import { SelectOptionStringValueType } from 'types/select-option';
import { createTargetSwitcherOption, TargetOptionProps } from '../antisense/create-target-options';
import TargetSwitcher from './target-switcher';

function TargetSidebar({ id, custom, rnaId, spliced, symbol, geneName }: TargetOptionProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [target, setTarget] = useState<SelectOptionStringValueType>(createTargetSwitcherOption({
    id,
    custom,
    rnaId,
    spliced,
    symbol,
    geneName,
  }));

  useEffect(() => {
    setTarget(createTargetSwitcherOption({ id, custom, rnaId, spliced, symbol, geneName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, spliced]);

  const [sirnaDisabled, setSirnaDisabled] = useState<boolean>(!spliced);

  useEffect(() => {
    setSirnaDisabled(!spliced);
  }, [spliced]);

  // const targetId = target.value.split('__')[0];
  const targetSpliced = target.value.split('__')[1] !== 'false';

  return (
    <div style={{ zIndex: 3 }}>
      <LeftSidebar
        isFixed={true}
        id="antisense-sidebar"
        // collapsedState="expanded"
      >
        <SideNavigation label="Antisense series">
          <NavigationHeader>
            <div style={{ padding: '0 8px' }}>
              <TargetSwitcher
                // key={Router.pathname.endsWith('/sirna').toString()}
                target={target}
                setTarget={setTarget}
              />
            </div>
          </NavigationHeader>
          <NestableNavigationContent
            initialStack={[]}
            testId="nestable-navigation-content"
          >
            <div style={{ padding: '0 8px' }}>
              <Section>
                {[
                  { path: '', icon: BoardIcon, label: 'Overview' },
                  { path: '/antisense', icon: PageIcon, label: 'Antisense series' },
                  { path: '/sirna', icon: PageIcon, label: 'siRNA series', isDisabled: sirnaDisabled },
                ].map(({ path, icon: Icon, label, isDisabled }, i): JSX.Element => (
                  <LinkItem
                    key={i}
                    isSelected={location.pathname === `/targets/${custom ? id : rnaId}${path}`}
                    iconBefore={<Icon label={label} />}
                    onClick={() => navigate(`/targets/${custom ? id : rnaId}${path}`, { state: { spliced: targetSpliced } })}
                    isDisabled={isDisabled}
                  >
                    {label}
                  </LinkItem>
                ))}
              </Section>
            </div>
          </NestableNavigationContent>
          {/*<NavigationFooter>*/}
          {/*  Footer*/}
          {/*</NavigationFooter>*/}
        </SideNavigation>
      </LeftSidebar>
    </div>
  );
}

export default TargetSidebar;
