import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lozenge from '@atlaskit/lozenge';

import { Sample } from 'apollo/graphql';

interface Props {
  // orderId: string;
  samples: Sample[];
}

function SampleLozengeList({ samples }: Props) {
  const navigate = useNavigate();

  return (
    <div onMouseOver={() => {
    }}>
      {samples && samples.length > 0 ? (
        samples.map(({ id }) => (
          <span
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              navigate(`/samples/${id}`);
            }}
            key={id}
            style={{ cursor: 'pointer', marginRight: '4px' }}
          >
            <Lozenge appearance={'moved'}>
              <div style={{ paddingBottom: '1px' }}>{id}</div>
            </Lozenge>
          </span>
        ))
      ) : '\u2013'}
    </div>
  );
}

export default SampleLozengeList;
