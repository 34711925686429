import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Button, { ButtonGroup } from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import TextField from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';

import { Order, OrderConnection, OrderSortField, OrderStatusSelect, useGetAllOrdersQuery } from 'apollo/graphql';
import OrderList from 'components/order/order-list';
import OrderTypeMultiSelect from 'components/order/order-type-multi-select';
import { AscOrDesc } from 'components/tables/create-table-head';
import usePagination from 'hooks/use-pagination';
import useSearch from 'hooks/use-search';
import CompanyMultiSelect from 'components/order/company-multi-select';
import CustomerMultiSelect from 'components/order/customer-multi-select';
import OrderStatusesSelect, { OrderStatusSelectOptionType } from 'components/order/status-select';
import YearsSelect from 'components/order/year-select';
import { SelectOptionNumberValueType } from 'types/select-option';
import { DrawerType } from 'components/drawers/drawer-manager';
import { openDrawer } from 'redux/drawers';
import useOrders from 'hooks/use-orders';

export interface OrderOrderBy {
  field: Exclude<keyof Order, '__typename'>;
  order: AscOrDesc;
}

function Orders(): JSX.Element {
  useOrders();
  const dispatch = useDispatch();

  // Initialize pagination settings.
  const {
    rowsPerPage,
    currentPage,
    setCurrentPage,
    updatePagination,
    RowsSelect,
    TablePagination,
  } = usePagination({
    defaultRowsOptions: [20, 50, 100],
    defaultRowsPerPage: 50,
    defaultTotalCount: 47,
    defaultFilteredCount: 47,
    defaultPageCount: 3,
  });

  // Setup filters.
  const [status, setStatus] = useState<OrderStatusSelectOptionType>({
    label: 'All statuses',
    value: OrderStatusSelect.None,
  });
  const [year, setYear] = useState<SelectOptionNumberValueType>({ label: 'All years', value: -1 });
  const [setPo, { search: po, debouncedSearch: debouncedPo }] = useSearch();

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPo]);

  // const [setSearch, { search, debouncedSearch }] = useSearch();
  // useEffect(() => {
  //   setCurrentPage(1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedSearch]);

  const [orderTypes, setOrderTypes] = useState<string[]>([]);
  const [customers, setCustomers] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);

  // Initialize table sorting.
  const [orderByList, setOrderByList] = useState<OrderOrderBy[]>([{ field: 'id', order: 'DESC' }]);

  const { loading, data, previousData } = useGetAllOrdersQuery({
    variables: {
      page: currentPage,
      perPage: rowsPerPage.value,
      filter: {
        po: debouncedPo,
        // search: debouncedSearch,
        orderTypes,
        customers,
        companies,
        ...(year.value && { year: year.value }),
        ...(status.value && { status: status.value }),
      },
      orderBy: orderByList.map(({ field, order }) => `${field}_${order}` as OrderSortField),
    },
    notifyOnNetworkStatusChange: false,
  });

  useEffect(() => {
    updatePagination<OrderConnection>(data?.orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const actionsContent = (
    <ButtonGroup>
      <YearsSelect
        year={year}
        setYear={setYear}
        setCurrentPage={setCurrentPage}
      />
      <OrderStatusesSelect
        status={status}
        setStatus={setStatus}
        setCurrentPage={setCurrentPage}
      />
      <RowsSelect/>
      <Button
        appearance="primary"
        onClick={() => dispatch(openDrawer(DrawerType.CreateOrderDrawer))}
      >
        <span style={{ fontWeight: 400 }}>
          Create order
        </span>
      </Button>
    </ButtonGroup>
  );

  const barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 184px' }}>
        <TextField
          value={po}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPo(e.target.value)}
          isCompact
          aria-label="Filter"
          elemAfterInput={
            <>
              <SearchIcon
                label="Search icon"
                primaryColor={colors.DN90A}
                size="small"
              />
              <span style={{ paddingRight: '6px' }}/>
            </>
          }
          placeholder="PO number"
        />
      </div>
      <OrderTypeMultiSelect setOrderTypes={setOrderTypes} setCurrentPage={setCurrentPage}/>
      <CustomerMultiSelect setCustomers={setCustomers} setCurrentPage={setCurrentPage}/>
      <CompanyMultiSelect setCompanies={setCompanies} setCurrentPage={setCurrentPage}/>
    </div>
  );

  return (
    <Page>
      <Helmet title="Orders"/>

      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader actions={actionsContent} bottomBar={barContent}>
            Orders
          </PageHeader>

          <OrderList
            orders={data?.orders.edges || previousData?.orders.edges || []}
            isLoading={loading}
            // setFilters={setFilters}
            orderByList={orderByList}
            setOrderByList={setOrderByList}
            setCurrentPage={setCurrentPage}
          />

          <TablePagination/>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default Orders;
