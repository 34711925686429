import { useDispatch, useSelector } from 'react-redux';

import { PanelType } from '../components/panels/panel-manager';
import { closePanel, mountPanel as globalMountPanel, openPanel, selectPanel } from '../redux/panels';

interface Props {
  panelType: PanelType;
  [key: string]: number | string | boolean | undefined | Function;
}

const usePanel = ({ panelType, ...panelProps }: Props) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectPanel);

  const mountPanel = (isOpen: boolean = false) => {
    dispatch(globalMountPanel(panelType, { ...panelProps, ...(isOpen && { isOpen }) }));
  };

  const unmountPanel = () => {
    dispatch(closePanel());
  };

  const togglePanel = () => {
    dispatch(isOpen ? closePanel() : openPanel());
  };

  return {
    isPanelOpen: isOpen,
    mountPanel,
    unmountPanel,
    togglePanel,
  };
};

export default usePanel;
