import React, { useState } from 'react';
import Button from '@atlaskit/button';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup, { TriggerProps } from '@atlaskit/popup';

import { useGetAntisenseSequenceOffTargetsLazyQuery } from 'apollo/graphql';
import ButtonLink from 'layouts/navbar/button-link';
import RnaNotFound from './rna-not-found';

interface Props {
  id: string;
  length: number;
  blastNextBestLength: number;
  blastNextBestCount: number;
}

const OffTargetPopup: React.FC<Props> = ({
  id,
  length,
  blastNextBestLength,
  blastNextBestCount,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = () => setIsOpen(false);

  const [getOffTargets, { loading, data }] = useGetAntisenseSequenceOffTargetsLazyQuery();

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      placement="left-start"
      content={() => (
        <MenuGroup css={{ minWidth: '120px' }}>
          <Section>
            {loading && <ButtonItem>Loading...</ButtonItem>}
            {data?.antisenseSequence.offTargets?.map((offTarget, i) => (
              offTarget
                ? (
                  <ButtonLink to={`/genome/rnas/${offTarget.id}`} key={i.toString()}>
                    {`${offTarget.id} [ ${offTarget.symbol} ]`}
                  </ButtonLink>
                )
                : (
                  <RnaNotFound
                    id={id.toString()}
                    index={i}
                    key={i}
                  />
                )
            ))}
          </Section>
        </MenuGroup>
      )}
      trigger={(triggerProps: TriggerProps) => (
        <Button
          {...triggerProps}
          spacing="none"
          shouldFitContainer
          onMouseOver={() => getOffTargets({ variables: { id } })}
          onClick={() => {
            if (blastNextBestLength !== length - 4) setIsOpen(isOpen => !isOpen);
          }}
          style={{
            background: 'inherit',
            cursor: 'pointer',
            fontWeight: 400,
            textAlign: 'left',
          }}
        >{blastNextBestLength === length - 4
          ? `<${length - 3}`
          : `${blastNextBestLength} (${blastNextBestCount})`}
        </Button>
      )}
    />
  );
};

export default OffTargetPopup;
