import React, { useState } from 'react';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { Popup } from '@atlaskit/popup';

export interface ContentProps {
  onClose: () => void;
}

type PrimaryDropdownProps = {
  content: React.FC<ContentProps>;
  text: string;
  isHighlighted?: boolean;
}

const PrimaryDropdown = ({
  content: Content,
  text,
  isHighlighted,
}: PrimaryDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => setIsOpen(open => !open);
  const onClose = () => setIsOpen(false);

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      content={() => <Content onClose={onClose} />}
      placement="bottom-start"
      trigger={triggerProps => (
        <PrimaryDropdownButton
          {...triggerProps}
          onClick={onClick}
          isHighlighted={isHighlighted}
          isSelected={isOpen}
        >
          {text}
        </PrimaryDropdownButton>
      )}
    />
  );
};

export default PrimaryDropdown;
