import React from 'react';
import Toggle from '@atlaskit/toggle';

interface Props {
  exclude: boolean;
  setExcludeModel: (f: (exclude: boolean) => boolean) => void;
  setCurrentPage: (num: number) => void;
}

const ModelExclude: React.FC<Props> = ({
  exclude,
  setExcludeModel,
  setCurrentPage,
}): JSX.Element => {
  const toggleExclude = () => {
    setExcludeModel((prevExclude: boolean) => !prevExclude);
    setCurrentPage(1);
  };

  return (
    <div
      onClick={toggleExclude}
      style={{
        cursor: 'pointer',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        paddingTop: '3px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ paddingTop: '3px' }}>Include models (XM/XR)</div>
      <Toggle
        id="toggle-model-exclude"
        isChecked={!exclude}
        onChange={toggleExclude}
      />
    </div>
  );
};

export default ModelExclude;
