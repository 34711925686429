import { ReactNode } from 'react';

export interface Alert {
  id: number;
  title: string;
  msg?: string | ReactNode;
  icon?: JSX.Element;
  appearance?: 'error' | 'info' | 'normal' | 'success' | 'warning';
}

export enum AlertActionType {
  SET_ALERT = 'SET_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}
