import { RootState } from '../store';
import { ModalAction } from './actions';
import { Modal, ModalActionType } from './types';

export const selectModal = (rootState: RootState) => rootState.modal;

const modalReducer = (
  state: Modal = { modalType: null },
  action: ModalAction,
) => {
  switch (action.type) {
    case ModalActionType.OPEN_MODAL:
      return action.payload;
    case ModalActionType.CLOSE_MODAL:
      return { modalType: null };
    default:
      return state;
  }
};

export default modalReducer;
