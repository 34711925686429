import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  alignRight?: boolean;
}

function FilterInputLabel({ children, alignRight = false }: Props): JSX.Element {
  return (
    <div
      style={{
        fontSize: '0.8rem',
        marginBottom: '3px',
        ...(alignRight ? { paddingRight: '2px' } : { paddingLeft: '2px' }),
        ...(alignRight && { textAlign: 'right' }),
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
      }}
    >
      {children}
    </div>
  );
}

export default FilterInputLabel;
