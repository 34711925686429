import Tooltip from '@atlaskit/tooltip';
import React from 'react';

import TableLink from '../tables/table-link';

interface Props {
  id: string;
  enstId: string;
  status: string;
}

function RefseqRnaIdColumn({ id, enstId, status }: Props) {
  return (
    <Tooltip
      content={(
        <>
          <div style={{ paddingBottom: `${enstId !== '-' ? 6 : 0}px` }}>{status}</div>
          {enstId !== '-' ? <div style={{ borderTop: '1px solid white', paddingTop: '6px' }}>{enstId}</div> : null}
        </>
      )}
      position={'right-start'}
    >
      <TableLink to={`/genome/rnas/${id}`}>{id}</TableLink>
    </Tooltip>
  );
}

export default RefseqRnaIdColumn;
