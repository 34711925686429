import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format, parseISO as parse } from 'date-fns';

import { Oligo, Order } from 'apollo/graphql';
import Logo from './logo';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Regular.woff' },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Medium.woff', fontWeight: 500 },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff', fontWeight: 700 },
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Black.woff', fontWeight: 900 },
  ],
});
Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
  body: {
    flexDirection: 'column',
    paddingTop: 40,
    paddingHorizontal: 0,
    paddingBottom: 45,
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  logo: {
    paddingHorizontal: 35,
  },
  header: {
    marginTop: 5,
    paddingHorizontal: 35,
    paddingVertical: 3,
    fontWeight: 500,
    backgroundColor: 'rgba(165, 165, 165, 0.5)',
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: 35,
  },
  table: {
    marginTop: 10,
    borderTop: '0.5px solid black',
    borderLeft: '0.3px solid black',
    borderRight: '0.3px solid black',
    borderBottom: '0.5px solid black',
  },
  tableHeader: {
    padding: 4,
    fontWeight: 500,
    textAlign: 'center',
    borderBottom: '0.2px solid black',
    backgroundColor: 'rgba(165, 165, 165, 0.5)',
  },
  tableRow: {
    flexDirection: 'row',
    // borderBottom: '0.2px solid black',
  },
  fieldLabel: {
    fontWeight: 500,
  },
  maldi: {
    marginTop: 16,
  },
  footnote: {
    fontSize: 10,
    marginTop: 16,
  },
  acknowledgement: {
    marginTop: 30,
  },
  signatureBox: {
    flexDirection: 'row',
  },
  footer: {
    fontSize: 9.5,
    paddingTop: 4,
    paddingLeft: 2,
    borderTop: '0.2px solid black',
  },
});

interface Props {
  author?: string;
  id?: string;
  oligo?: Oligo;
  maldi?: string;
  amount?: number;
}

function base64ToArrayBuffer(base64: string) {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

const OligoSpecSheet = ({ author, id = '', oligo, maldi, amount }: Props) => {

  return (
    <Document
      title={`Sample:${id}`}
      author={author}
      creator="NAS Bioscience Pte. Ltd."
      producer="NAS Bioscience Pte. Ltd."
    >
      <Page size="A4" style={styles.body}>
        <View fixed style={styles.logo}>
          <Logo/>
        </View>
        <View style={styles.header}>
          <Text>SPECIFICATION SHEET</Text>
        </View>
        <View style={styles.content}>
          <View style={styles.table}>
            <Text style={styles.tableHeader}>Certificate of Analysis</Text>
            {oligo ? (
              <>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4 }]}>Product name:</Text>
                  <Text style={{ flexBasis: '40%', padding: 4 }}>{oligo.name}</Text>
                  <Text style={[styles.fieldLabel, { flexBasis: '16%', padding: 4 }]}>Amount (nmol):</Text>
                  <Text style={{ flexBasis: '26%', padding: 4 }}>{amount ?? '\u2212'}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[styles.fieldLabel, { flexBasis: '18%', padding: 4 }]}>Molecular weight:</Text>
                  <Text style={{ flexBasis: '40%', padding: 4 }}>{`${oligo.mass.toLocaleString()} Da`}</Text>
                </View>
              </>
            ) : null}
          </View>
          {oligo ? (
            <>
              <View style={{ marginTop: '12px' }}>
                <Text style={{ fontWeight: 500 }}>{`Sequence - ${oligo.name}`}</Text>
                <Text style={{ fontFamily: 'Courier' }}>{oligo.idtSeq?.length ? (
                  <>
                    {oligo.idtSeq.map((idtSymbol, i) => {
                      const display = idtSymbol ? ['A', 'C', 'G', 'T', 'rA', 'rC', 'rG', 'rU', 'mA', 'mC', 'mG', 'mU', '+A', '+C', '+G', '+T'].includes(idtSymbol) ? idtSymbol : `/${i === 0 ? '5' : i === oligo.idtSeq!.length - 1 ? '3' : 'i'}${idtSymbol}/` : '-';

                      return `${display}${oligo.bbIds[i] === '2' ? '\u2217' : ''}${((i % 3 === 2) && (i !== oligo.idtSeq!.length - 1)) ? ' ' : ''}`;
                    })}
                  </>
                ) : ''}
                </Text>
              </View>
              <View style={{ marginTop: '20px' }}>
                <Text style={{ fontWeight: 500 }}>MALDI-ToF</Text>
                {maldi ? <Image src={Buffer.from(maldi, 'base64')} style={styles.maldi} /> : null}
              </View>
            </>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default OligoSpecSheet;
