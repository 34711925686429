import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import Badge from "@atlaskit/badge";
import Tooltip from "@atlaskit/tooltip";

import { AntisenseCartItem, antisenseCartItemsVar } from "apollo/cache";

interface Props {
  targetId?: string;
}

function TargetAntisenseCartItemsCounter({ targetId }: Props): JSX.Element {
  const antisenseCartItems = useReactiveVar(antisenseCartItemsVar);
  const [targetCartItems, setTargetCartItems] = useState<AntisenseCartItem[]>(
    antisenseCartItems.filter(
      ({ targetId: cartItemTargetId }) => cartItemTargetId === (targetId || "0")
    )
  );

  useEffect(() => {
    setTargetCartItems(
      targetId
        ? antisenseCartItems.filter(
            ({ targetId: cartItemTargetId }) => cartItemTargetId === targetId
          )
        : []
    );
  }, [targetId, antisenseCartItems]);

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip
        content={
          targetCartItems.length
            ? targetCartItems.map(({ antisenseId, start }) => (
                <div key={antisenseId}>{start}</div>
              ))
            : null
        }
        position="bottom"
        delay={100}
      >
        <span
          style={{
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          <Badge appearance="primary">{targetCartItems.length}</Badge>
        </span>
      </Tooltip>
    </div>
  );
}

export default TargetAntisenseCartItemsCounter;
