import React from 'react';
import { useParams } from 'react-router-dom';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlaskit/page-header';
import { colors, fontSizeSmall } from '@atlaskit/theme';

import { useGetCustomerQuery } from 'apollo/graphql';
import useOrders from 'hooks/use-orders';

function CustomerOverview() {
  useOrders();
  const { customerId } = useParams();

  const { data } = useGetCustomerQuery({
    variables: {
      id: customerId!,
    },
  });

  return (
    <Page>
      <Grid layout="fluid">
        <GridColumn medium={12}>
          <PageHeader>
            {data?.customer?.fullName || customerId}
          </PageHeader>
        </GridColumn>

        <GridColumn medium={12}>
          <div
            style={{
              fontSize: fontSizeSmall(),
              color: colors.subtleHeading(),
              fontWeight: 500,
            }}
          >
            Shipping address
          </div>
          <div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{data?.customer?.shippingAddress}</div>
          <div
            style={{
              fontSize: fontSizeSmall(),
              color: colors.subtleHeading(),
              fontWeight: 500,
              marginTop: '20px',
            }}
          >
            Billing address
          </div>
          <div style={{ marginTop: '2px', whiteSpace: 'pre-line' }}>{data?.customer?.billingAddress}</div>
        </GridColumn>
      </Grid>
    </Page>
  );
}

export default CustomerOverview;
