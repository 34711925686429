import { InMemoryCache, makeVar } from '@apollo/client';

export interface AntisenseCartItem {
  targetId: string;
  targetLength: number;
  symbol: string;
  antisenseId: string;
  start: number;
  wing5?: number;
  wing3?: number;
  // rangeNodes?: number[];
}

export const antisenseCartItemsVar = makeVar<AntisenseCartItem[]>([]);

export interface SirnaCartItem {
  targetId: string;
  targetLength: number;
  // targetLength: number;
  symbol: string;
  sirnaId: string;
  startAs: number;
}

export const sirnaCartItemsVar = makeVar<SirnaCartItem[]>([]);

export interface SampleCartItem {
  id: string;
  mass?: number;
  ec260?: number;
}

export const sampleCartItemsVar = makeVar<SampleCartItem[]>([]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        antisenseSequence(_, { args, toReference }) {
          return toReference({
            __typename: 'AntisenseSequence',
            id: args!.id,
          });
        },
        sirnaDuplex(_, { args, toReference }) {
          return toReference({
            __typename: 'SirnaDuplex',
            id: args!.id,
          });
        },
        sample(_, { args, toReference }) {
          return toReference({
            __typename: 'Sample',
            id: args!.id,
          });
        },
      },
    },
    AntisenseSequence: {
      fields: {
        isInCart: {
          read(_, { variables }) {
            return !!antisenseCartItemsVar().find(({ antisenseId }) => antisenseId === variables!.id);
          },
        },
      },
    },
    SirnaDuplex: {
      fields: {
        isInCart: {
          read(_, { variables }) {
            return !!sirnaCartItemsVar().find(({ sirnaId }) => sirnaId === variables!.id);
          },
        },
      },
    },
    Sample: {
      fields: {
        isInCart: {
          read(_, { variables }) {
            return !!sampleCartItemsVar().find(({ id }) => id === variables!.id);
          },
        },
      },
    },
  },
});

export default cache;
