import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '@atlaskit/theme';
import WarningIcon from '@atlaskit/icon/glyph/warning';

import { useAuth } from '../contexts/auth';
import { setAlert } from 'redux/alerts';

const useAdmin = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth()!;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser?.isAdmin) {
      dispatch(setAlert({
        title: 'Admin is required.',
        icon: <WarningIcon primaryColor={colors.R400} label="Error" />,
      }));
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
};

export default useAdmin;
