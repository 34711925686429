import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

import { createApolloClient } from '../apollo/apollo-client';
import { GetCurrentUserDocument, GetCurrentUserQuery, User } from '../apollo/graphql';

export interface AuthContextType {
  checkingSession: boolean;
  isAuthenticated: boolean;
  currentUser: User | null;
  updateCurrentUser: (user: User) => void;
  login: () => any;
  logout: () => any;
  getToken: () => any;
}

const AuthContext = createContext<AuthContextType | null>(null);
const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [auth0Client, setAuth0Client] = useState<Auth0Client | undefined>();
  const [checkingSession, setCheckingSession] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const initializeAuth0 = async () => {
      try {
        const client = await createAuth0Client({
          audience: (process.env.NODE_ENV === 'development' && process.env.REACT_APP_GRAPHQL_ENDPOINT) || 'https://nasbio.app/graphql',
          client_id: (process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH0_CLIENT_ID) || 'qjxBAz5onyLLCNVQ45eGVAVh318oohCp',
          domain: (process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH0_DOMAIN) || 'nasbio.jp.auth0.com',
          redirect_uri: (process.env.NODE_ENV === 'development' && process.env.REACT_APP_AUTH0_CALLBACK_URL) || 'https://nasbio.app/login',
          // audience: process.env.REACT_APP_GRAPHQL_ENDPOINT,
          // client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
          // domain: process.env.REACT_APP_AUTH0_DOMAIN!,
          // redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        });
        setAuth0Client(client);

        if (pathname === '/login' && window.location.search.includes('code=')) {
          await client.handleRedirectCallback();
        }

        const authenticated = await client.isAuthenticated();
        setIsAuthenticated(authenticated);

        if (authenticated) {
          const apolloClient = createApolloClient((...p: any) => client.getTokenSilently(...p));
          const { data } = await apolloClient.query<GetCurrentUserQuery>({
            query: GetCurrentUserDocument,
            fetchPolicy: 'network-only',
          });
          setCurrentUser(data.currentUser!);

          if (pathname === '/login') navigate('/');
        }
      } catch {
        setIsAuthenticated(false);
        setCurrentUser(null);
        pathname !== '/login' && navigate('/login');
      } finally {
        setCheckingSession(false);
      }
    };

    initializeAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        checkingSession,
        isAuthenticated,
        currentUser,
        updateCurrentUser: (user: User | null) => setCurrentUser(user),
        login: (...p: any) => auth0Client?.loginWithRedirect(...p),
        logout: (...p: any) => auth0Client?.logout({
          ...p,
          returnTo: process.env.NODE_ENV === 'development' ? process.env.AUTH0_LOGOUT_URL : 'https://nasbio.app/login',
        }),
        getToken: (...p: any) => auth0Client?.getTokenSilently(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
export default AuthContext;
